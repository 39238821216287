import React, { Component } from "react";

import ReactGA from "react-ga";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import { signupConfirmation } from "../js/actions/user";

class SignUpActivationScreen extends Component {
	async componentDidMount() {
		const token = this.props.match.params.token;

		const { error } = await this.props.signupConfirmation(token);

		if (error) {
			ReactGA.event({
				category: "sign-up",
				action: "sign-up-confirm-error",
				label: "token-not-found",
				value: 1
			});
			alert("Token de ativação não encontrado.");
		} else {
			ReactGA.event({
				category: "sign-up",
				action: "sign-up-confirm-success",
				label: "email",
				value: 1
			});
			alert("Cadastro ativado com sucesso!");
		}

		this.props.history.push("/");
	}

	render() {
		return <div />;
	}
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
	bindActionCreators({ signupConfirmation }, dispatch);
export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(SignUpActivationScreen)
);
