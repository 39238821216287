import { rem } from "polished";

const styles = () => ({
  dialog: {
    width: rem(800),
    borderRadius: rem(17),
    paddingTop: rem(35),
    paddingBottom: rem(45),
    textAlign: "center"
  },
  title: {
    color: "#8080EC",
    fontFamily: "Bree Serif, serif",
    fontSize: rem(26)
  },
  text: {
    fontSize: rem(16),
    color: "#000"
  },
  buttons: {
    display: "flex",
    justifyContent: "center"
  },
  closeButton: {
    position: "absolute",
    right: 5,
    top: 5
  }
});

export default styles;
