import React, { Component, Fragment } from "react";

import HomePage from "../components/Home";
import Footer from "../components/UI/Footer";

export default class Home extends Component {
	render() {
		return (
			<Fragment>
				<HomePage />
				<Footer backgroundColor="#2E2545" />
			</Fragment>
		);
	}
}
