import React, { Fragment } from "react";

import { rem } from "polished";
import Helmet from "react-helmet";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	root: {
		backgroundColor: props => props.backgroundColor,
		paddingTop: props => props.paddingTop,
		display: "flex",
		justifyContent: "center"
	},
	page: {
		width: "100%",
		maxWidth: props => props.width,
		padding: `${rem(32)} 0`
	}
});

export default function Page(props) {
	const { backgroundColor, children, paddingBottom } = props;
	const classes = useStyles(props);

	return (
		<Fragment>
			<Helmet
				bodyAttributes={{
					style: `background-color : ${backgroundColor};
					padding-bottom: ${paddingBottom}`
				}}
			/>
			<div className={classes.root}>
				<div className={classes.page}>{children}</div>
			</div>
		</Fragment>
	);
}
