export const GET_USER_DATA = "GET_USER_DATA";
export const SIGNIN = "SIGNIN";
export const SIGNOUT = "SIGNOUT";
export const SIGNUP = "SIGNUP";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";
export const SIGNUP_CONFIRMATION = "SIGNUP_CONFIRMATION";
export const SIGNUP_CONFIRMATION_FAILURE = "SIGNUP_FAILURE";
export const RECOVER_PASSWORD = "RECOVER_PASSWORD";
export const RESET_USER_PASSWORD = "RESET_USER_PASSWORD";
export const CONFIRM_RESET_USER_PASSWORD = "CONFIRM_RESET_USER_PASSWORD";
export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_PROFILE_FAILURE = "GET_USER_PROFILE_FAILURE";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_FAILURE = "UPDATE_USER_PROFILE_FAILURE";

export const GET_USER_CLINICS = "USER_CLINICS_FETCHED";
export const CREATE_USER_CLINIC = "USER_CLINIC_CREATED";
export const CREATE_USER_CLINIC_FAILURE = "CREATE_USER_CLINIC_FAILED";
export const REMOVE_USER_CLINIC = "REMOVE_USER_CLINIC";
export const UPDATE_USER_CLINIC = "UPDATE_USER_CLINIC";
export const UPDATE_USER_CLINIC_FAILURE = "UPDATE_USER_CLINIC_FAILURE";
export const ZIPCODE_INFORMATION_FETCHED = "ZIPCODE_INFORMATION_FETCHED";

export const GET_PATIENT = "GET_PATIENT";
export const GET_ALL_PATIENTS = "GET_ALL_PATIENTS";
export const GET_ALL_PRESCRIPTION_FOR_PATIENT =
  "GET_ALL_PRESCRIPTION_FOR_PATIENT";
export const CREATE_PATIENT = "CREATE_PATIENT";
export const CREATE_PATIENT_FAILURE = "CREATE_PATIENT_FAILURE";
export const REMOVE_PATIENT = "REMOVE_PATIENT";
export const UPDATE_PATIENT = "UPDATE_PATIENT";
export const UPDATE_PATIENT_FAILURE = "UPDATE_PATIENT_FAILURE";

export const SEARCH_MEDICATION = "SEARCH_MEDICATION";
export const SEARCH_MEDICATION_FAILURE = "SEARCH_MEDICATION_FAILURE";
export const SELECT_MEDICATION = "SELECT_MEDICATION";
export const ADD_MEDICATION = "ADD_MEDICATION";
export const EDIT_MEDICATION = "EDIT_MEDICATION";
export const IS_EDIT_MEDICATION_MODE = "IS_EDIT_MEDICATION_MODE";
export const REMOVE_MEDICATION = "REMOVE_MEDICATION";
export const SAVE_PRESCRIPTION = "SAVE_PRESCRIPTION";
export const SAVE_PRESCRIPTION_FAILURE = "SAVE_PRESCRIPTION_FAILURE";
export const UPDATE_PRESCRIPTION = "UPDATE_PRESCRIPTION";
export const UPDATE_PRESCRIPTION_FAILURE = "UPDATE_PRESCRIPTION_FAILURE";
export const REMOVE_PRESCRIPTION = "REMOVE_PRESCRIPTION";
export const GET_SINGLE_PRESCRIPTION = "GET_SINGLE_PRESCRIPTION";
export const GET_ALL_PRESCRIPTIONS = "GET_ALL_PRESCRIPTIONS";
export const CLEAR_PRESCRIPTION = "CLEAR_PRESCRIPTION";

export const SET_FORM_IN_PROGRESS = "SET_FORM_IN_PROGRESS";
export const IS_LOADING = "IS_LOADING";

export const TOUR_RUN = "TOUR_RUN";
export const TOUR_STOP = "TOUR_STOP";
export const TOUR_RESET = "TOUR_RESET";
export const TOUR_PREVIOUS_STEP = "TOUR_PREVIOUS_STEP";
export const TOUR_NEXT_STEP = "TOUR_NEXT_STEP";
export const TOUR_SET_STEP = "TOUR_SET_STEP";
