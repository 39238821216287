import PropTypes from "prop-types";
import React from "react";
import styled, { css, keyframes } from "styled-components";
import { rem } from "polished";

const skBounceDelay = keyframes`
  0%, 80%, 100% {
    transform: scale(0)
  }

  40% {
    transform: scale(1.0);
  }
`;

const Wrapper = styled.div`
  ${props =>
    props.full &&
    css`
      background: rgba(255, 255, 255, 0.8);
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 999;
      font-size: ${rem(16)};
      display: flex;
      align-items: center;
      justify-content: center;
    `} ${props =>
    props.modal &&
    css`
      background: rgba(255, 255, 255, 0.8);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 998;
      font-size: ${rem(16)};
      display: flex;
      align-items: center;
      justify-content: center;
    `};
`;

const Spinner = styled.div`
  margin: 0;
  width: 70px;
  text-align: center;

  & > div {
    width: 18px;
    height: 18px;
    background-color: ${props => props.color || "#7C7CE4"};
    border-radius: 100%;
    display: inline-block;
    animation: ${skBounceDelay} 1.4s infinite ease-in-out both;
  }

  & > div:nth-child(1) {
    animation-delay: -0.32s;
  }

  & > div:nth-child(2) {
    animation-delay: -0.16s;
  }
`;

const Loader = props => (
  <Wrapper modal={props.modal} full={props.full}>
    <Spinner color={props.color}>
      <div />
      <div />
      <div />
    </Spinner>
  </Wrapper>
);

Loader.propTypes = {
  full: PropTypes.bool,
  color: PropTypes.string,
  modal: PropTypes.bool
};

export default Loader;
