const styles = theme => ({
  root: {
    width: "80%",
    alignSelf: "center",
    paddingTop: "2rem",
    maxWidth: "19.65rem",
    minWidth: "19.65rem",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  submitButton: {
    margin: ".9375rem 0",
    [theme.breakpoints.down("sm")]: {
      alignSelf: "center"
    }
  },
  form: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left"
  },
  title: {
    fontSize: "2.375rem",
    lineHeight: "3.375rem",
    color: "#8080EC",
    fontFamily: "Bree Serif, serif",
    marginBottom: ".625rem",
    paddingRight: "30%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.75rem",
      lineHeight: "normal",
      padding: 0
    }
  },
  subtitle: {
    fontSize: "1rem",
    color: "#372F4E",
    fontFamily: "Montserrat, sans-serif",
    margin: ".625rem 0"
  },
  textfield: {
    margin: ".625rem 0"
  },
  passwordTip: {
    fontSize: ".75rem",
    color: "#9AAFBF",
    fontFamily: "Montserrat, sans-serif",
    margin: ".625rem 0"
  },
  socialMediaButtons: {
    display: "flex",
    margin: "2rem 0 .5rem 0",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-around"
    }
  },
  faq: {
    fontSize: ".75rem",
    color: "#372F4E",
    fontFamily: "Montserrat, sans-serif",
    textDecoration: "underline",
    margin: ".625rem 0",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  }
});

export default styles;
