import { rem } from "polished";

const styles = () => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: `${rem(20)} ${rem(20)}`,
    fontWeight: "bold",
    color: "#9AAFBF",
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      color: "#FFFFFF"
    }
  },
  icon: {
    marginRight: rem(10)
  },
  purple: {
    "&:hover": {
      backgroundColor: "#8080EC"
    }
  },
  red: {
    "&:hover": {
      backgroundColor: "#E87265"
    }
  },
  green: {
    "&:hover": {
      backgroundColor: "#42C39B"
    }
  }
});

export default styles;
