import React, { Component } from "react";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { signout, getUserProfile } from "../../../js/actions/user";
import { isAuthenticated } from "../../../js/utils/authCheck";

import { AppBar, Grid, Toolbar, withStyles } from "@material-ui/core";

import Controllers from "./Controllers";
import FlatButton from "../FlatButton";
import Menu from "./Menu";

import styles from "./Style";

class HeaderBar extends Component {
	static propTypes = {
		signout: PropTypes.func
	};

	constructor(props) {
		super(props);

		this.handleExitPrescription = this.handleExitPrescription.bind(this);
		this.setButtonPosition = this.setButtonPosition.bind(this);
		this.prescriptionButtonRef = React.createRef();
	}

	async componentWillMount() {
		const authenticated = isAuthenticated();
		if (authenticated) {
			if (!this.props.user.first_name) {
				await this.props.getUserProfile();
			}
		}
	}

	componentDidMount() {
		if (this.props.showButton) {
			this.setButtonPosition();
			window.addEventListener("resize", this.setButtonPosition);
		}
	}

	componentWillUnmount() {
		if (this.props.showButton) {
			window.removeEventListener("resize", this.setButtonPosition);
		}
	}

	handleExitPrescription(e, route) {
		e.preventDefault();
		this.props.history.push(route);
	}

	setButtonPosition() {
		const prescriptionButton = this.prescriptionButtonRef.current;
		const pos = prescriptionButton.getBoundingClientRect();

		if (this.props.onButtonPositioned) {
			this.props.onButtonPositioned(pos.left);
		}
	}

	render() {
		const { classes } = this.props;

		return (
			<div className={classes.root}>
				<AppBar
					id="headerBar"
					className={classes.paper}
					position="fixed"
					color="inherit"
				>
					<Toolbar className={classes.page} disableGutters={true}>
						<Grid
							container
							direction={"row"}
							alignItems={"center"}
							justify={"space-between"}
							spacing={0}
						>
							<Grid item>
								<Controllers />
							</Grid>
							<Grid item>
								<Grid container spacing={4} justify={"space-between"}>
									{this.props.showButton && (
										<Grid item>
											<div ref={this.prescriptionButtonRef}>
												<FlatButton to="/novo-paciente" text="Criar receita" />
											</div>
										</Grid>
									)}
									<Grid item>
										<Menu
											user={this.props.user}
											onSignOut={this.props.signout}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: state.user,
	isLoading: state.loader.isLoading
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			signout,
			getUserProfile
		},
		dispatch
	);

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(styles)(HeaderBar))
);
