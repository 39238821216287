import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Alert from "react-s-alert";

import ScrollableAnchor, { goToAnchor } from "react-scrollable-anchor";
import classNames from "classnames";
import { pick, values } from "lodash";

import {
	getZipCodeInformation,
	getClinics,
	createClinic,
	removeClinic,
	updateClinic
} from "./ClinicActions";

import "./ClinicProfile.css";

import { getUserProfile } from "../../actions/user";
import { isAuthenticated } from "../../utils/authCheck";
import LoginDialog from "../LoginDialog";

import { STATE_UF } from "../../data/signup";

import { Grid, MenuItem, Checkbox } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";

import ToggleButton from "../common/button/ToggleButton";

import CellPhoneMaskedInput from "../common/form/CellPhoneMaskedInput";
import PhoneMaskedInput from "../common/form/PhoneMaskedInput";
import ZipCodeMaskedInput from "../common/form/ZipCodeMaskedInput";
import TextField from "../common/form/TextField";

import RoundedButton from "../Button";
import Icon from "../Icon";

import AlertList from "../AlertList";

const styles = theme => ({
	marginTop: {
		marginTop: "10px"
	},
	left: {
		textAlign: "left"
	},
	right: {
		textAlign: "right"
	},
	item: {
		backgroundColor: "#EAEAEA",
		padding: "35px 35px 35px 25px",
		margin: "0 0 1px 0"
	},
	defaultItem: {
		backgroundColor: "#7C7CE4",
		color: "#FFF"
	},
	button: {
		border: "1px solid rgba(0, 0, 0, 0.54)",
		marginLeft: theme.spacing(2),
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		color: "rgba(0, 0, 0, 0.54)",
		height: "38px",
		borderRadius: "20px",
		textTransform: "capitalize",
		"&:hover": {
			backgroundColor: "#6666CC",
			borderColor: "#6666CC",
			color: "#fff"
		}
	},
	defaultItemButton: {
		border: "1px solid #FFF",
		color: "#FFF"
	},
	iconButton: {
		minWidth: "38px",
		width: "38px"
	},
	deleteButton: {
		"&:hover": {
			backgroundColor: "#bf5449",
			borderColor: "#bf5449",
			color: "#fff"
		}
	},
	tableTitle: {
		textAlign: "center",
		fontSize: "14px",
		fontWeight: 500,
		color: "#000",
		marginTop: "50px"
	},
	checkbox: {
		paddingTop: "20px"
	},
	address: {
		paddingLeft: "5px"
	},
	root: {
		color: "#FFF",
		"&$checked": {
			color: "#FFF"
		}
	},
	checked: {},
	size: {
		width: 40,
		height: 40
	},
	miniForm: {
		width: "80%",
		margin: "0 auto"
	},
	controlButtons: {
		padding: "15px 0 10px 0"
	},
	photoInstruction: {
		padding: "50px 30px"
	},
	photoInstructionTitle: {
		color: "#7C7CE7",
		fontSize: 16,
		fontWeight: "bold"
	}
});

class ClinicProfile extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			id: "",
			zip: "",
			phone: "",
			district: "",
			complement: "",
			city: "",
			state: "",
			number: "",
			name: "",
			street: "",
			isSelected: false,
			clinics: [],
			clinicToEdit: "",
			showForm: false,
			isEditMode: false,
			showLogin: false
		};

		this.getClinics = this.getClinics.bind(this);
		this.showForm = this.showForm.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.createClinic = this.createClinic.bind(this);
		this.updateClinic = this.updateClinic.bind(this);
		this.handleSignin = this.handleSignin.bind(this);
		this.showErrors = this.showErrors.bind(this);
		this.editClinic = this.editClinic.bind(this);
		this.cancelEditClinic = this.cancelEditClinic.bind(this);
		this.removeClinic = this.removeClinic.bind(this);
		this.handleSelected = this.handleSelected.bind(this);
	}

	async componentWillMount() {
		const authenticated = isAuthenticated();
		if (authenticated) {
			await this.getClinics();
		}
	}

	async getClinics() {
		await this.props.getUserProfile();
		await this.props.getClinics();

		const currentClinic = this.props.user.currentClinic;
		let clinics = [...this.props.clinics];

		if (this.props.user.currentClinic && clinics.length > 0) {
			const index = [clinics.findIndex(clinic => clinic.id === currentClinic)];
			if (index) {
				clinics[index].isDefault = true;
			}
		}

		this.setState({ clinics: clinics }, () => {
			if (this.state.clinics.length === 0) {
				this.setState({ showForm: true });
			}
		});
	}

	isCellPhone = () => {
		const phone = this.state.phone.replace(/\D/g, "");
		return phone.length > 10;
	};

	handleClinicPhone = event => {
		this.setState({ phone: event.target.value.replace(/\D/g, "") });
	};

	async handleZipCode(zipcode) {
		if (zipcode && zipcode.length > 0) {
			await this.props.getZipCodeInformation(zipcode);
			this.setState({
				street: this.props.zipCodeInformation.logradouro,
				city: this.props.zipCodeInformation.cidade,
				district: this.props.zipCodeInformation.bairro,
				state: this.props.zipCodeInformation.estado
			});
		}
	}

	showForm() {
		this.setState({ showForm: true }, () => {
			goToAnchor("clinicForm");
		});
	}

	async handleSubmit(event) {
		event.preventDefault();

		const clinic = {
			...pick(this.state, ["name", "id"]),
			address: {
				...pick(this.state, [
					"phone",
					"zip",
					"street",
					"district",
					"number",
					"complement",
					"city",
					"state"
				])
			}
		};

		if (this.state.isEditMode) {
			this.updateClinic(clinic);
		} else {
			this.createClinic(clinic);
		}
	}

	async createClinic(clinic) {
		const { error } = await this.props.createClinic(clinic);

		if (error) {
			this.showErrors(error);
		} else {
			this.setState(
				{
					id: "",
					zip: "",
					phone: "",
					district: "",
					complement: "",
					city: "",
					state: "",
					number: "",
					name: "",
					street: "",
					showForm: false
				},
				() => this.getClinics()
			);
		}
	}

	async updateClinic(clinic, fetchClinics = false) {
		const { error } = await this.props.updateClinic(clinic, clinic.id);
		if (error) {
			this.showErrors(error);
			return;
		}

		if (fetchClinics) {
			this.getClinics();
		} else {
			const clinics = [...this.state.clinics];
			clinics[this.state.clinicToEdit] = clinic;

			this.setState({
				id: "",
				zip: "",
				phone: "",
				district: "",
				complement: "",
				city: "",
				state: "",
				number: "",
				name: "",
				street: "",
				clinics: clinics,
				showForm: false,
				isEditMode: false,
				clinicToEdit: ""
			});
		}
		//Alert.success("Clínica alterada com sucesso!", { offset: 39 });
	}

	handleSignin(response) {
		if (response.error) return;
		this.setState({ showLogin: false });
	}

	showErrors(error) {
		if (error) {
			this.setState({ error });

			if (error.response.status === 403) {
				this.setState({ showLogin: true });
				return;
			}

			const messages = values(error.response.data.validation_errors);
			if (messages.length > 0) {
				Alert.error(
					<AlertList>
						{messages.map((message, index) => (
							<li key={index}>{message}</li>
						))}
					</AlertList>,
					{ timeout: 5000, offset: 39 }
				);
			} else {
				Alert.error("Endereço inválido! Verifique as informações.", {
					timeout: 5000,
					offset: 39
				});
			}
		}
	}

	editClinic(index) {
		this.setState({
			...pick(this.state.clinics[index], ["name", "id"]),
			...pick(this.state.clinics[index].address, [
				"phone",
				"zip",
				"street",
				"district",
				"number",
				"complement",
				"city",
				"state"
			]),
			showForm: true,
			isEditMode: true,
			clinicToEdit: index
		});
	}

	cancelEditClinic(event) {
		event.preventDefault();
		this.setState(
			{
				id: "",
				zip: "",
				phone: "",
				district: "",
				complement: "",
				city: "",
				state: "",
				number: "",
				name: "",
				street: "",
				showForm: false,
				isEditMode: false,
				clinicToEdit: ""
			},
			() => {
				if (this.props.isModal && this.state.clinics.length === 0) {
					this.props.onClose(event);
				}
			}
		);
	}

	async removeClinic(id) {
		await this.props.removeClinic(id);
		this.getClinics();
	}

	handleSelected(clinic) {
		clinic.isDefault = true;
		this.updateClinic(clinic, true);
	}

	render() {
		const { classes } = this.props;

		return (
			<div>
				<Collapse in={this.state.clinics.length > 0}>
					{this.state.clinics.map((clinic, index) => (
						<Grid
							key={index}
							container
							className={
								clinic.isDefault
									? classNames(classes.item, classes.defaultItem)
									: classes.item
							}
						>
							<Grid item xs={1} className={classes.checkbox}>
								<Checkbox
									color="primary"
									checked={clinic.isDefault ? true : false}
									onChange={() => this.handleSelected(clinic)}
									value="checkedA"
									classes={
										clinic.isDefault
											? {
													root: classes.root,
													checked: classes.checked
											  }
											: {}
									}
								/>
							</Grid>

							<Grid item xs={8} className={classes.address}>
								<div>{clinic.name}</div>
								<div>
									{clinic.address.street +
										" - " +
										clinic.address.district +
										" - " +
										clinic.address.city +
										", " +
										clinic.address.state}
								</div>
								<div>Telefone: {clinic.address.phone}</div>
								<div>CEP: {clinic.address.zip}</div>
							</Grid>
							<Grid
								item
								xs={3}
								className={classNames(classes.marginTop, classes.right)}
							>
								{!(this.props.isModal && this.state.clinics.length <= 1) && (
									<ToggleButton
										style={
											clinic.isDefault
												? {
														border: "1px solid #FFF",
														color: "#FFF"
												  }
												: {}
										}
										hoverStyle={{ width: 105 }}
										isDelete
										icon="delete"
										label="Excluir"
										onClick={() => this.removeClinic(clinic.id)}
									/>
								)}

								<ToggleButton
									style={
										clinic.isDefault
											? {
													border: "1px solid #FFF",
													color: "#FFF"
											  }
											: {}
									}
									hoverStyle={{ width: 100 }}
									icon="edit"
									label="Editar"
									onClick={() => this.editClinic(index)}
								/>
							</Grid>
						</Grid>
					))}
				</Collapse>

				<Collapse in={this.state.showForm}>
					<ScrollableAnchor id="clinicForm">
						<form id="clinicForm" onSubmit={this.handleSubmit}>
							<Grid
								className={this.props.isModal ? classes.miniForm : ""}
								container
								direction={"row"}
								alignItems={"center"}
								justify={"center"}
								spacing={3}
							>
								{this.state.clinics.length > 0 && (
									<Grid item xs={12}>
										<div className={classes.tableTitle}>
											{this.state.isEditMode ? (
												<p>Altere as informações do local de trabalho</p>
											) : (
												<p>Preencha seu novo local de trabalho</p>
											)}
										</div>
									</Grid>
								)}
								<Grid item xs={12}>
									<Grid
										container
										direction={"row"}
										alignItems={"center"}
										justify={"flex-start"}
										spacing={2}
									>
										<Grid item xs={12} sm={6}>
											<TextField
												required
												id="estabelecimento"
												label="Nome do seu local de trabalho"
												type="text"
												name="estabelecimento"
												value={this.state.name}
												onChange={event => {
													this.setState({ name: event.target.value });
												}}
												fullWidth
												inputProps={{
													maxLength: 200
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												required
												value={this.state.phone}
												label="Telefone"
												id="phone"
												name="phone"
												onChange={this.handleClinicPhone}
												InputProps={{
													inputComponent: this.isCellPhone()
														? CellPhoneMaskedInput
														: PhoneMaskedInput
												}}
												fullWidth
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid
										container
										direction={"row"}
										alignItems={"center"}
										justify={"flex-start"}
										spacing={2}
									>
										<Grid item xs={12} sm={6}>
											<TextField
												value={this.state.zip}
												label="CEP"
												id="zip"
												name="zip"
												onChange={event => {
													this.setState({ zip: event.target.value });
												}}
												onBlur={event => this.handleZipCode(event.target.value)}
												InputProps={{
													inputComponent: ZipCodeMaskedInput
												}}
												fullWidth
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												required
												id="endereco"
												label="Endereço do seu local de trabalho"
												type="text"
												name="endereco"
												value={this.state.street}
												onChange={event => {
													this.setState({ street: event.target.value });
												}}
												fullWidth
												inputProps={{
													maxLength: 200
												}}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid
										container
										direction={"row"}
										alignItems={"center"}
										justify={"flex-start"}
										spacing={2}
									>
										<Grid item xs={12} sm={4}>
											<TextField
												required
												id="district"
												label="Bairro"
												type="text"
												name="district"
												value={this.state.district}
												onChange={event => {
													this.setState({
														district: event.target.value
													});
												}}
												fullWidth
												inputProps={{
													maxLength: 200
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={2}>
											<TextField
												required
												id="number"
												label="Número"
												type="number"
												name="number"
												value={this.state.number}
												onChange={event => {
													this.setState({ number: event.target.value });
												}}
												fullWidth
												inputProps={{
													maxLength: 10
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<TextField
												id="complement"
												label="Complemento"
												type="text"
												name="complement"
												value={this.state.complement}
												onChange={event => {
													this.setState({
														complement: event.target.value
													});
												}}
												fullWidth
												inputProps={{
													maxLength: 200
												}}
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Grid
										container
										direction={"row"}
										alignItems={"center"}
										justify={"flex-start"}
										spacing={2}
									>
										<Grid item xs={12} sm={4}>
											<TextField
												required
												id="city"
												label="Cidade"
												type="text"
												name="city"
												value={this.state.city}
												onChange={event => {
													this.setState({ city: event.target.value });
												}}
												fullWidth
												inputProps={{
													maxLength: 200
												}}
											/>
										</Grid>
										<Grid item xs={12} sm={2}>
											<TextField
												required
												fullWidth
												select
												label="Estado"
												value={this.state.state}
												onChange={event => {
													this.setState({ state: event.target.value });
												}}
												inputProps={{
													name: "state",
													id: "state"
												}}
											>
												{STATE_UF.map((state, index) => (
													<MenuItem key={index} value={state.value}>
														{state.label}
													</MenuItem>
												))}
											</TextField>
										</Grid>
									</Grid>
								</Grid>

								{this.state.clinics.length > 0 &&
									this.state.showForm &&
									!this.props.isModal && (
										<Grid item xs={6} className={classes.left}>
											<RoundedButton
												onClick={this.cancelEditClinic}
												type="bordered-danger"
											>
												<Icon name="close" />
												<span>Cancelar</span>
											</RoundedButton>
										</Grid>
									)}
								{!this.props.isModal && (
									<Grid
										item
										xs={
											this.state.clinics.length > 0 && this.state.showForm
												? 6
												: 12
										}
										className={classes.right}
									>
										<RoundedButton type="submit">
											<Icon name="arrow_forward" />
											<span>Salvar este local</span>
										</RoundedButton>
									</Grid>
								)}

								{this.props.isModal && (
									<Grid item xs={12}>
										<Grid container justify="space-around">
											<Grid item>
												<RoundedButton
													onClick={this.cancelEditClinic}
													type="bordered-danger"
												>
													<Icon name="close" />
													<span>Cancelar</span>
												</RoundedButton>
											</Grid>
											<Grid item>
												<RoundedButton type="submit">
													<Icon name="arrow_forward" />
													<span>Salvar este local</span>
												</RoundedButton>
											</Grid>
										</Grid>
									</Grid>
								)}
							</Grid>
						</form>
					</ScrollableAnchor>
				</Collapse>

				<Collapse in={!this.state.showForm && this.state.clinics.length > 0}>
					<Grid
						className={classes.controlButtons}
						container
						justify={this.props.isModal ? "space-around" : "space-between"}
					>
						<Grid item className={classes.left}>
							<RoundedButton onClick={this.showForm} type="bordered-submit">
								<Icon name="add" />
								<span>Adicionar outro local</span>
							</RoundedButton>
						</Grid>
						<Grid item className={classes.right}>
							{this.props.showSaveButton && (
								<RoundedButton
									onClick={
										this.props.isModal ? this.props.onSave : this.completeSignUp
									}
									type="submit"
								>
									<Icon name="arrow_forward" />
									<span>Salvar alterações</span>
								</RoundedButton>
							)}
						</Grid>
					</Grid>
				</Collapse>
				<LoginDialog show={this.state.showLogin} onSignin={this.handleSignin} />
			</div>
		);
	}
}

const mapStateToProps = state => ({
	zipCodeInformation: state.clinic.zipCodeInformation,
	clinics: state.clinic.clinics,
	user: state.user,
	isLoading: state.loader.isLoading
});
const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			getUserProfile,
			getZipCodeInformation,
			getClinics,
			createClinic,
			removeClinic,
			updateClinic
		},
		dispatch
	);
ClinicProfile = connect(
	mapStateToProps,
	mapDispatchToProps
)(ClinicProfile);
export default withStyles(styles)(ClinicProfile);
