import React from "react";

const mainStyle = {
	position: "fixed",
	top: 0,
	right: 0,
	bottom: 0,
	left: "42.5%",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	backgroundColor: "#E7ECF0"
};

const illustrationStyle = {
	width: "54.95%",
	minWidth: "25.28rem",
	maxWidth: "37.91rem"
};

const Illustration = ({ path, alt }) => (
	<div style={mainStyle}>
		<img style={illustrationStyle} src={path} alt={alt} />
	</div>
);

export default Illustration;
