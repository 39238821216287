import React, { Component } from "react";

import PropTypes from "prop-types";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Alert from "react-s-alert";

import Loader from "./Loader";

import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/stackslide.css";

const mapStateToProps = state => {
  const { loader } = state;

  return {
    isLoading: loader.isLoading
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

class Main extends Component {
  static propTypes = {
    children: PropTypes.any.isRequired,
    isLoading: PropTypes.bool
  };

  render() {
    const { isLoading, children } = this.props;

    return (
      <div>
        {isLoading && <Loader full />}

        {children}
        <Alert stack={{ limit: 1 }} effect="stackslide" timeout={1500} />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main);
