import React, { PureComponent } from "react";

import ReactGA from "react-ga";

import { pick, isEmpty } from "lodash";
import { Formik } from "formik";
import * as Yup from "yup";

import isValidPhone from "@brazilian-utils/is-valid-phone";

import Form from "./Form";

import SplitLayout from "../../UI/layout/SplitWithIllustration";

import illustrationSVG from "../../../img/illustrations/general-info.svg";
import illustrationMiniSVG from "../../../img/illustrations/general-info-mini.svg";

import { OCUPATIONS, MEDICAL_SPECIALTIES } from "../../../js/data/signup";

class SignUpGeneralInfo extends PureComponent {
	constructor(props) {
		super(props);

		this.USER_FIELDS = [
			"first_name",
			"last_name",
			"user_type",
			"specialty",
			"title",
			"phone",
			"allowed_call",
			"tos_read"
		];
	}

	getInitialValues() {
		const receivedData = { ...pick(this.props.data, this.USER_FIELDS) };

		if (!isEmpty(receivedData)) {
			return receivedData;
		}

		return {
			first_name: "",
			last_name: "",
			user_type: "",
			specialty: "",
			title: "",
			phone: "",
			allowed_call: true,
			tos_read: false
		};
	}

	isInitialValuesValid() {
		const receivedData = { ...pick(this.props.data, this.USER_FIELDS) };

		return !isEmpty(receivedData);
	}

	validationSchema = () =>
		Yup.object().shape({
			first_name: Yup.string("")
				.min(2, "O nome deve conter no mínimo 2 letras.")
				.max(255, "O nome deve conter no máximo 255 letras.")
				.required("O nome é obrigatório."),
			last_name: Yup.string("")
				.min(2, "O sobrenome deve conter no mínimo 2 letras.")
				.max(255, "O sobrenome deve conter no máximo 255 letras.")
				.required("O sobrenome é obrigatório."),
			user_type: Yup.string("")
				.oneOf(OCUPATIONS, "Você deve escolher a sua ocupação.")
				.required("A ocupação é obrigatória."),
			specialty: Yup.string("").when("user_type", {
				is: "physician",
				then: Yup.string("")
					.required("A especialidade é obrigatória.")
					.oneOf(MEDICAL_SPECIALTIES, "Você deve escolher a especialidade."),
				otherwise: Yup.string("").notRequired()
			}),
			phone: Yup.string("")
				.test("phone", "Esse número de telefone é inválido", phone =>
					phone ? isValidPhone(phone) : true
				)
				.when("allowed_call", {
					is: true,
					then: Yup.string("").required(
						"Precisamos do seu telefone para ligar para você."
					),
					otherwise: Yup.string("").notRequired()
				}),
			allowed_call: Yup.bool(),
			tos_read: Yup.bool().test(
				"tos_read",
				"Você precisa aceitar os termos de uso para avançar.",
				tos_read => tos_read
			)
		});

	handleSubmit(values) {
		if (this.props.onSubmit) {
			values.phone = values.phone.replace(/\D/g, "");
			this.props.onSubmit(values);
		}
	}

	trackPage() {
		window.history.pushState("", "", "/cadastrar/informacoes-gerais");
		const url = `${window.location.pathname}${window.location.search}`;
		ReactGA.pageview(url);
	}

	render() {
		const inititalValues = this.getInitialValues();
		const isInitialValuesValid = this.isInitialValuesValid();

		this.trackPage();

		return (
			<SplitLayout
				miniIllustrationPath={illustrationMiniSVG}
				miniillustrationAlt="mini-general-info-illustration"
				illustrationPath={illustrationSVG}
				illustrationAlt="general-info-illustration"
				onBackClick={this.props.onBackClick}
				show={this.props.show}
			>
				<Formik
					isInitialValid={isInitialValuesValid}
					onSubmit={values => this.handleSubmit(values)}
					render={props => <Form {...props} />}
					initialValues={inititalValues}
					validationSchema={this.validationSchema}
				/>
			</SplitLayout>
		);
	}
}

export default SignUpGeneralInfo;
