import React from "react";

import classNames from "classnames";
import { Link } from "react-router-dom";
import { Icon, withStyles } from "@material-ui/core";

import styles from "./Style";

const getClassByColor = (classes, color) => {
	if (classes && color && classes[color]) {
		return classes[color];
	}

	return "";
};

const ListButton = ({ classes, color, icon, label, onClick, to }) => {
	return to ? (
		<Link
			to={to}
			className={classNames(classes.root, getClassByColor(classes, color))}
		>
			<Icon className={classes.icon}>{icon}</Icon>
			{label}
		</Link>
	) : (
		<div
			className={classNames(classes.root, getClassByColor(classes, color))}
			onClick={e => {
				e.preventDefault();
				onClick();
			}}
		>
			<Icon className={classes.icon}>{icon}</Icon>
			{label}
		</div>
	);
};

export default withStyles(styles)(ListButton);
