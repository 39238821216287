import React, { Component } from "react";

import Signin from "../components/Signin";
import { isAuthenticated } from "../utils/authCheck";
import { withRouter } from "react-router-dom";
import logo from "../../img/logo-pulsares.png";

import { Grid } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    paddingTop: 35,
    textAlign: "center"
  },
  version: {
    marginTop: -19,
    marginLeft: 164,
    fontSize: 11,
    color: "grey"
  }
});

class SigninPage extends Component {
  constructor(props) {
    super(props);
    this.handleSignin = this.handleSignin.bind(this);
  }

  componentWillMount() {
    if (isAuthenticated()) {
      this.props.history.push("/inicio");
      return;
    }
  }

  handleSignin() {
    this.props.history.push("/inicio");
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        direction={"row"}
        alignItems={"stretch"}
        justify={"center"}
      >
        <Grid item xs={12} className={classes.root}>
          <img src={logo} alt="Logotipo" />
          <p className={classes.version}>Versão Beta</p>
        </Grid>

        <Grid item xs={10} sm={6} md={5} lg={4} className={classes.root}>
          <Signin onSignin={this.handleSignin} showLegend={false} />
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(SigninPage));
