import * as Yup from "yup";

import moment from "moment";
import isValidCpf from "@brazilian-utils/is-valid-cpf";

import { STATES, OCUPATIONS } from "../../../js/data/signup";

const validationSchema = () =>
  Yup.object().shape({
    first_name: Yup.string("")
      .min(2, "O nome deve conter no mínimo 2 letras.")
      .max(255, "O nome deve conter no máximo 255 letras.")
      .required("O nome é obrigatório."),
    last_name: Yup.string("")
      .min(2, "O sobrenome deve conter no mínimo 2 letras.")
      .max(255, "O sobrenome deve conter no máximo 255 letras.")
      .required("O sobrenome é obrigatório."),
    birthday: Yup.date("A data de nascimento é inválida")
      .required("A data de nascimento é obrigatória.")
      .max(new Date(), "A data de nascimento é inválida.")
      .test(
        "birthday",
        "Você precisa ter mais de 18 anos para usar o software.",
        birthday => moment().diff(moment(birthday), "years") >= 18
      ),
    user_type: Yup.string("")
      .oneOf(OCUPATIONS, "Você deve escolher a sua ocupação.")
      .required("A ocupação é obrigatória."),
    document: Yup.string("")
      .required("O CPF é obrigatório.")
      .test("document", "O CPF inserido não é valido.", document =>
        isValidCpf(document)
      ),
    registration: Yup.object().when("user_type", {
      is: user_type => user_type !== "student",
      then: Yup.object().shape({
        number: Yup.string("")
          .min(4, "O número do registro deve conter no mínimo 4 caracteres.")
          .max(10, "O número do registro deve conter no máximo 10 caracteres.")
          .required("O número do registro é obrigatório."),
        state: Yup.string("")
          .oneOf(STATES, "O estado escolhido deve ser válido.")
          .required("O estado do registro é obrigatório.")
      })
    }),
    university: Yup.object().when("user_type", {
      is: user_type => user_type === "student",
      then: Yup.object().shape({
        enrollment: Yup.string("")
          .min(2, "O número de matrícula deve conter no mínimo 2 caracteres.")
          .max(
            255,
            "O número de matrícula deve conter no máximo 255 caracteres."
          )
          .required("O número de matrícula é obrigatório."),
        name: Yup.string("")
          .min(2, "O nome da universidade deve conter no mínimo 2 caracteres.")
          .max(
            255,
            "O nome da universidade deve conter no máximo 255 caracteres."
          )
          .required("O nome da universidade é obrigatório.")
      })
    })
  });

export default validationSchema;
