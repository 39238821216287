import * as Yup from "yup";

import { STATES, OCUPATIONS } from "../../../js/data/signup";

const validationSchema = () =>
  Yup.object().shape({
    user_type: Yup.string("")
      .oneOf(OCUPATIONS, "Você deve escolher a sua ocupação.")
      .required("A ocupação é obrigatória."),
    registration: Yup.object().when("user_type", {
      is: user_type => user_type !== "student",
      then: Yup.object().shape({
        number: Yup.string("")
          .min(4, "O número do registro deve conter no mínimo 4 caracteres.")
          .max(10, "O número do registro deve conter no máximo 10 caracteres.")
          .required("O número do registro é obrigatório."),
        state: Yup.string("")
          .oneOf(STATES, "O estado escolhido deve ser válido.")
          .required("O estado do registro é obrigatório.")
      })
    }),
    university: Yup.object().when("user_type", {
      is: user_type => user_type === "student",
      then: Yup.object().shape({
        enrollment: Yup.string("")
          .min(2, "O número de matrícula deve conter no mínimo 2 caracteres.")
          .max(
            255,
            "O número de matrícula deve conter no máximo 255 caracteres."
          )
          .required("O número de matrícula é obrigatório."),
        name: Yup.string("")
          .min(2, "O nome da universidade deve conter no mínimo 2 caracteres.")
          .max(
            255,
            "O nome da universidade deve conter no máximo 255 caracteres."
          )
          .required("O nome da universidade é obrigatório.")
      })
    })
  });

export default validationSchema;
