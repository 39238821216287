import { rem } from "polished";

const styles = theme => ({
	row: {
		width: "100%",
		padding: "12px 10%",
		borderWidth: "1px 1px 0px 1px",
		borderStyle: "solid",
		borderColor: "#EFF2F5",
		fontFamily: "Montserrat, sans-serif",
		"&:hover": {
			backgroundColor: "#E8EEF3"
		}
	},
	cellName: {
		color: "#372F4E"
	},

	cellNameObservation: {
		color: "#9AAFBF"
	},
	tableTitle: {
		fontSize: "14px",
		color: "#8788e5"
	},
	iconTableTitle: {
		float: "left",
		marginRight: "5px",
		transform: "rotate(90deg)"
	},
	expansionPanel: {
		padding: 0,
		margin: 0,
		"&:before": {
			position: "relative"
		}
	},
	pageContent: {
		width: "100%",
		maxWidth: rem(800),
		margin: "0 auto"
	},
	form: {
		border: "1px solid #dfe8ec",
		backgroundColor: "#E8EEF3",
		textAlign: "center"
	},
	centeredButton: {
		margin: "20px 0"
	},
	noMargin: {
		margin: 0
	},
	noPadding: {
		padding: 0
	},
	deleteIcon: {
		"&:hover": {
			color: "#8080EC"
		}
	}
});

export default styles;
