import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { partial, isEmpty, find } from "lodash";
import Modal from "../../Modal";
import Button from "../../Button";
import {
	addMedication,
	getSinglePrescription,
	savePrescription,
	updatePrescription,
	clearPrescription,
	removeMedication
} from "../../../actions/prescription";
import { getFrequencyOptions } from "../../../data/prescription";

import { isAuthenticated } from "../../../utils/authCheck";

import {
	isEditMedicationMode,
	currentMedication
} from "../../../actions/medication";
import { getPatient } from "../../../actions/patient";
import { setFormInProgress } from "../../../actions/ui-prescription";

import Header from "./Header";
import Alert from "react-s-alert";
import * as ALERT from "../../../data/alerts";

import NewPatientPage from "../../../containers/NewPatientPage";

import PageTitle from "../../../components/PageTitle";
import AlertDialog from "../../AlertDialog";

import { darken } from "polished";

import classNames from "classnames";
import {
	Grid,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import { Edit } from "@material-ui/icons";

import ClinicModal from "../../Clinic/ClinicModal";
import MedicationList from "../MedicationList";

import { goToAnchor } from "react-scrollable-anchor";

import { rem } from "polished";

import LoginDialog from "../../LoginDialog";

const styles = {
	root: {
		marginTop: "100px"
	},
	form: {
		border: "1px solid #dfe8ec",
		backgroundColor: "#E8EEF3",
		textAlign: "center"
	},
	dialog: {
		textAlign: "center",
		borderRadius: 10,
		padding: "15px 40px 10px 40px",
		width: 470
	},
	content: {
		padding: 0
	},
	contentText: {
		padding: "0 62px 0 62px",
		color: "#7C7CE4",
		fontSize: "20px",
		fontWeight: 500
	},
	pageTitle: {
		marginBottom: rem(15) + " !important"
	},
	pageContent: {
		width: "100%",
		maxWidth: rem(800),
		margin: "0 auto"
	},
	centeredButton: {
		margin: "20px 0"
	},
	iconButton: {
		cursor: "pointer",
		color: "#7C7CE4",
		"&:hover": {
			color: darken(0.05, "#7C7CE4")
		}
	}
};

const mapStateToProps = state => {
	const { medication, prescription, uiPrescription, patient, user } = state;

	return {
		user,
		prescription,
		uiPrescription,
		medication,
		selectedPatient: patient.selected,
		selectedMedication: medication.selectedMedication,
		editedMedication: medication.editMedicationMode,
		currentMedication: state.currentMedication
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			savePrescription,
			updatePrescription,
			clearPrescription,
			removeMedication,
			getPatient,
			isEditMedicationMode,
			currentMedication,
			setFormInProgress,
			getSinglePrescription,
			addMedication
		},
		dispatch
	);

class RenewPrescription extends Component {
	static propTypes = {
		patient: PropTypes.string,
		id: PropTypes.string,
		prescription: PropTypes.object,
		uiPrescription: PropTypes.object,
		selectedPatient: PropTypes.object,
		selectedMedication: PropTypes.object,
		medication: PropTypes.object,
		savePrescription: PropTypes.func,
		updatePrescription: PropTypes.func,
		clearPrescription: PropTypes.func,
		removeMedication: PropTypes.func,
		getPatient: PropTypes.func,
		isEditMedicationMode: PropTypes.func,
		currentMedication: PropTypes.func,
		setFormInProgress: PropTypes.func,
		getSinglePrescription: PropTypes.func,
		addMedication: PropTypes.func
	};

	constructor(props) {
		super(props);
		this.state = {
			patient_id: null,
			count: 1,
			isEditMode: false,
			editPrescriptionId: null,
			isConfirmDeletionModalVisible: false,
			isConfirmEditModalVisible: false,
			medicationToDelete: null,
			medicationToEdit: null,
			showMedicationIncompleteModal: false,
			imcompletedMedicationName: "",
			openAddPanel: false,
			showClinicModal: false,
			showPatientPage: false
		};

		this.closeClinicModal = this.closeClinicModal.bind(this);
		this.closeAndSaveClinicModal = this.closeAndSaveClinicModal.bind(this);
		this.handleSignin = this.handleSignin.bind(this);
		this.generatePrescriptionWithWarnings = this.generatePrescriptionWithWarnings.bind(
			this
		);
		this.verifyIncompleteMedicationAndSubmit = this.verifyIncompleteMedicationAndSubmit.bind(
			this
		);
		this.onError = this.onError.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.editMedication = this.editMedication.bind(this);
		this.cancelEditMedicationAndAdd = this.cancelEditMedicationAndAdd.bind(
			this
		);
		this.openEditModal = this.openEditModal.bind(this);
		this.openDeletionModal = this.openDeletionModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.addMedications = this.addMedications.bind(this);
		this.cancelAddMedications = this.cancelAddMedications.bind(this);
		this.renderConfirmDeletionModal = this.renderConfirmDeletionModal.bind(
			this
		);
		this.showPatientPage = this.showPatientPage.bind(this);
		this.hidePatientPage = this.hidePatientPage.bind(this);
		this.renderConfirmEditModal = this.renderConfirmEditModal.bind(this);
	}

	async componentWillMount() {
		const authenticated = isAuthenticated();
		if (authenticated) {
			if (!this.props.patient && !this.props.id) {
				this.props.history.push("/inicio");
			}

			this.props.clearPrescription();

			if (this.props.patient) {
				this.setState({ patient_id: this.props.patient });
				this.props.getPatient(this.props.patient);
			}

			if (this.props.id) {
				await this.props.getSinglePrescription(this.props.id);
				this.setState({ patient_id: this.props.prescription.saved.patient.id });
				this.props.getPatient(this.props.prescription.saved.patient.id);
				this.props.prescription.saved.medications.forEach(medication =>
					this.props.addMedication({
						doses: medication.doses.map(dose => ({
							amount: dose.amount,
							frequency: find(getFrequencyOptions(), {
								value: dose.frequency.id
							})
						})),
						form_id: medication.form.id,
						medicationName: medication.medication.name,
						medicationRoute: medication.form.route,
						medication_id: medication.medication.id,
						notes: medication.notes,
						treatment_days: medication.treatment_description,
						treatment_days_label: medication.treatment_days,
						unit: medication.form.unit
					})
				);
			}
		}
	}

	componentWillUnmount() {
		this.props.isEditMedicationMode({});
	}

	closeClinicModal(event, save = false) {
		event.preventDefault();
		this.setState({ showClinicModal: false }, () => {
			if (save) {
				if (this.props.user.currentClinic) {
					this.verifyIncompleteMedicationAndSubmit(this.state.submittedForm);
				}
			}
		});
	}

	closeAndSaveClinicModal(event) {
		event.preventDefault();
		this.closeClinicModal(event, true);
	}

	handleSignin(response) {
		if (response.error) return;
		this.setState({ error: undefined });
	}

	generatePrescriptionWithWarnings(event) {
		this.closeModal();
		this.handleSubmit(event);
	}

	verifyIncompleteMedicationAndSubmit(event) {
		if (event) event.preventDefault();
		if (this.props.selectedMedication.medicationName) {
			this.setState({
				showMedicationIncompleteModal: true,
				incompletedMedicationName: this.props.selectedMedication.medicationName
			});
		} else {
			if (this.props.editedMedication.isEditMode) {
				const editedMedicationName = this.props.prescription.entries[
					this.props.editedMedication.editPrescriptionId
				].medicationName;
				this.setState({
					showMedicationIncompleteModal: true,
					incompletedMedicationName: editedMedicationName
				});
			} else {
				if (this.props.user.currentClinic) {
					this.setState({ submittedForm: {} });
					this.handleSubmit(event);
				} else {
					this.setState({
						submittedForm: event,
						showClinicModal: true
					});
				}
			}
		}
	}

	onError(error) {
		this.setState({ error });
	}

	async handleSubmit(event) {
		if (event) event.preventDefault();

		let entries = this.props.prescription.entries;

		if (!entries.length && isEmpty(this.props.selectedMedication)) {
			Alert.error(ALERT.prescription.emptyMedication, { offset: 39 });
			return;
		}

		if (!entries.length && !isEmpty(this.props.selectedMedication)) {
			entries = [this.props.selectedMedication];
		}

		// @TODO: Find a better way to structure these data.
		const data = {
			patient_id: this.state.patient_id,
			medications: entries.map(entry => {
				const dataToSend = {
					medicationName: entry.medicationName,
					treatment_days: !isNaN(entry.treatment_days)
						? entry.treatment_days
						: null,
					treatment_description: entry.treatment_days,
					medication_id: entry.medication_id,
					form_id: entry.form_id,
					notes: entry.notes,
					doses: entry.doses.map(dose => ({
						amount: dose.amount,
						frequency_id: dose.frequency.value
					}))
				};

				return dataToSend;
			})
		};

		const requestMethod = `${
			this.props.toCopy ? "save" : this.props.id ? "update" : "save"
		}Prescription`;

		const { error } = await this.props[requestMethod](data, this.props.id);

		if (error) {
			this.setState({ error });
			return;
		}

		this.props.currentMedication({});
		const prescriptionId = this.props.prescription.saved.id;
		//Alert.success(ALERT.prescription.saved, { offset: 39 });
		this.props.history.push(`/visualizar-receita/easy?id=${prescriptionId}`);
	}

	editMedication(index) {
		const data = {
			isEditMode: true,
			editPrescriptionId: index
		};

		this.props.isEditMedicationMode(data);
	}

	cancelEditMedicationAndAdd() {
		this.props.currentMedication({});
		this.props.isEditMedicationMode({});
		this.props.setFormInProgress(false);

		this.addMedications();
	}

	openEditModal(id, event) {
		event.preventDefault();
		event.stopPropagation();

		const { editPrescriptionId } = this.props.medication.editMedicationMode;

		if (editPrescriptionId === id) return;

		this.props.setFormInProgress(true);
		this.editMedication(id);
	}

	openDeletionModal(id, event) {
		event.preventDefault();
		event.stopPropagation();
		this.setState({
			isConfirmDeletionModalVisible: true,
			medicationToDelete: id
		});
	}

	closeModal() {
		this.setState({
			isConfirmDeletionModalVisible: false,
			isConfirmEditModalVisible: false,
			medicationToDelete: null,
			showMedicationIncompleteModal: false,
			incompletedMedicationName: ""
		});
	}

	handleDelete() {
		const { medicationToDelete } = this.state;
		this.props.removeMedication(medicationToDelete).then(() => {
			this.closeModal();
		});
	}

	addMedications(event) {
		if (event) event.preventDefault();
		this.setState({ openAddPanel: true }, () => goToAnchor("medicationForm"));
	}

	cancelAddMedications() {
		this.setState({ openAddPanel: false });
	}

	renderConfirmDeletionModal() {
		const id = this.state.medicationToDelete;

		return (
			<AlertDialog
				open={this.state.isConfirmDeletionModalVisible}
				onClose={this.closeModal}
				onConfirm={partial(this.handleDelete, id)}
				title="Aviso!"
				contentText={
					<div>
						Você tem certeza que deseja excluir esse medicamento da receita?
					</div>
				}
				confirmLabel="Excluir"
				confirmColor="secondary"
				cancelLabel="Cancelar"
			/>
		);
	}

	showPatientPage() {
		this.setState({ showPatientPage: true });
	}

	hidePatientPage() {
		this.setState({ showPatientPage: false });
	}

	renderConfirmEditModal() {
		const id = this.state.medicationToEdit;

		return (
			<Modal
				opened={this.state.isConfirmEditModalVisible}
				onClose={this.closeModal}
				onConfirm={partial(this.editMedication, id)}
				kind="confirmation"
				title="Aviso!"
				hasFooter
			>
				<p>Os dados preenchidos no formulário serão perdidos.</p>
				<p>Tem certeza que deseja continuar?</p>
			</Modal>
		);
	}

	render() {
		const {
			addMedications,
			cancelAddMedications,
			cancelEditMedicationAndAdd,
			openDeletionModal,
			openEditModal,
			onError
		} = this;

		const { selectedPatient, classes, prescription } = this.props;

		const { openAddPanel } = this.state;

		if (this.state.showPatientPage) {
			return (
				<NewPatientPage
					showBackButton
					onBack={this.hidePatientPage}
					id={selectedPatient.id}
				/>
			);
		} else {
			return (
				<div className={classes.root}>
					<Dialog
						classes={{ paper: classes.dialog }}
						open={this.state.showMedicationIncompleteModal}
						onClose={this.closeModal}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogContent classes={{ root: classes.content }}>
							<DialogContentText
								classes={{ root: classes.contentText }}
								id="alert-dialog-description"
							>
								Você esqueceu de adicionar o medicamento
								<span style={{ color: "#000" }}>
									{" " + this.state.incompletedMedicationName + " "}
								</span>
								em sua receita.
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Grid
								container
								direction={"row"}
								alignItems={"center"}
								justify={"space-between"}
							>
								<Grid item>
									<Button
										type="bordered-submit"
										onClick={this.generatePrescriptionWithWarnings}
									>
										<span>
											{this.props.id ? "Salvar" : "Gerar"} mesmo assim
										</span>
									</Button>
								</Grid>
								<Grid item>
									<Button type="submit" onClick={this.closeModal}>
										<span> Voltar e incluir</span>
									</Button>
								</Grid>
							</Grid>
						</DialogActions>
					</Dialog>

					<div className={classNames(classes.pageContent, classes.pageTitle)}>
						<PageTitle>
							Sua receita para:
							<span> {selectedPatient.name} </span>
							<Edit
								onClick={this.showPatientPage}
								className={classes.iconButton}
							/>
						</PageTitle>
					</div>

					<form onSubmit={this.verifyIncompleteMedicationAndSubmit}>
						<Header
							disableSubmit={
								this.props.prescription.entries.length === 0 ||
								(this.state.error &&
									this.state.error.response.status === 403 &&
									!isAuthenticated())
							}
						/>

						<MedicationList
							label="medicamentos"
							showTitle={true}
							title="Medicamentos:"
							entries={prescription.entries}
							entryNameAttribute="medicationName"
							showAddPanel={openAddPanel}
							cancelUpdateAndAdd={cancelEditMedicationAndAdd}
							onDelete={openDeletionModal}
							onCancel={cancelAddMedications}
							onUpdate={openEditModal}
							onAdd={addMedications}
							onError={onError}
							clinicModalisOpen={this.state.showClinicModal}
						/>

						{this.state.isConfirmDeletionModalVisible &&
							this.renderConfirmDeletionModal()}
						{this.state.isConfirmEditModalVisible &&
							this.renderConfirmEditModal()}
					</form>
					<LoginDialog
						show={
							this.state.error &&
							this.state.error.response.status === 403 &&
							!isAuthenticated()
						}
						onSignin={this.handleSignin}
					/>

					<ClinicModal
						title={
							this.props.user.currentClinic
								? "Locais de trabalho"
								: "Você ainda não preencheu seu local de trabalho"
						}
						subTitle={
							this.props.user.currentClinic
								? "Salve as alterações para gerar a receita"
								: "Para continuar a gerar a receita por favor preencha seu local de trabalho"
						}
						show={this.state.showClinicModal}
						onClose={this.closeClinicModal}
						onSave={this.closeAndSaveClinicModal}
					/>
				</div>
			);
		}
	}
}

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(styles)(RenewPrescription))
);
