import { rem } from "polished";
import { darken } from "polished";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    boxShadow: "0px 1px 1px -1px rgba(0, 0, 0, 0.2)"
  },
  page: {
    minWidth: rem(800),
    maxWidth: rem(800),
    width: "100%",
    margin: "0 auto",
    padding: 0
  },
  icon: {
    fontSize: "35px !important",
    color: "#c2ccd1",
    "&:hover": {
      color: "#7C7CE4",
      borderBottom: "4px solid #5555B7",
      cursor: "pointer",
      transition: "all 0.1s"
    },
    "&.active": {
      color: "#7C7CE4",
      borderBottom: "4px solid #5555B7"
    }
  },
  dialog: {
    textAlign: "center",
    borderRadius: 10,
    padding: "15px 40px 10px 40px",
    width: 470
  },
  dialogContent: {
    padding: 0
  },
  dialogContentText: {
    padding: "0 62px 0 62px",
    color: "#7C7CE4",
    fontSize: "20px",
    fontWeight: 500
  },
  dialogCancelButton: {
    background: "transparent",
    border: "none",
    padding: 0,
    outline: "none",
    cursor: "pointer",
    color: "#7C7CE4",
    fontSize: "14px",
    fontWeight: 700,
    transition: "all 0.3s",
    "&:hover": {
      color: darken(0.05, "#7C7CE4")
    }
  },
  iconButton: {
    background: "#FFF"
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  profileIcon: {
    alignItems: "center",
    background: "#dfe8ec",
    borderRadius: "50%",
    color: "#fff",
    display: "flex",
    height: "30px",
    justifyContent: "center",
    width: "30px"
  },
  button: {
    color: "#808080",
    fontSize: "16px",
    fontWeight: "bold",
    background: "#FFF",
    border: "none",
    textTransform: "none"
  },
  submitButton: {
    margin: "0 !important"
  },
  menu: {
    marginRight: "5px"
  },
  menuItem: {
    fontSize: "14px",
    padding: "10px 0",
    color: "#2f2f2f"
  },
  logo: {
    padding: "0 50px 0 0 !important"
  },
  linkIcon: {
    fontSize: "35px !important"
  },
  menuIcon: {
    color: "#c2ccd1",
    "&:active": {
      color: "#7c7ce4",
      borderBottom: "4px solid #5555b7"
    },
    "&:hover": {
      color: "#7C7CE4",
      borderBottom: "4px solid #5555B7",
      cursor: "pointer",
      transition: "all 0.1s"
    }
  },
  version: {
    fontFamily: "Montserrat, sans-serif",
    marginTop: -13,
    marginLeft: 92,
    fontSize: 9,
    color: "grey"
  }
});

export default styles;
