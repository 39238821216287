import React, { Component, Fragment } from "react";

import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

import { isAuthenticated } from "../../../js/utils/authCheck";

import {
  getSinglePrescription,
  updatePrescription
} from "../../../js/actions/prescription";
import { getUserProfile } from "../../../js/actions/user";
import { Table, TableBody } from "../../../js/components/Table";

import { rem } from "polished";

import { translateUserTypeToPTBR } from "../../../js/data/user";

import { Box, Grid } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import classNames from "classnames";

import ClinicModal from "../../../js/components/Clinic/ClinicModal";
import ToggleButton from "../../../js/components/common/button/ToggleButton";

import logoSUS from "../../../img/logo_sus.svg";
import Logo from "../../../img/prescription-creation/header-logo.png";

import Page from "../../UI/Page";

const styles = theme => ({
  prescriptionHeader: {
    padding: "25px 20px",
    fontFamily: "Montserrat, sans-serif",
    color: "#9AAFBF",
    fontSize: 13
  },
  prescriptionContent: {
    padding: "20px",
    marginTop: "5px !important"
  },
  prescriptionLabel: {
    fontSize: "24px",
    fontWeight: 700,
    color: "#7C7CE4",
    marginLeft: theme.spacing(2)
  },
  textButton: {
    border: "1px solid #B0B0B0",
    color: "#B0B0B0",
    height: "38px",
    borderRadius: "20px",
    textTransform: "capitalize",
    paddingLeft: "8px !important",
    "&:hover": {
      backgroundColor: "#8080EB",
      borderColor: "#8080EB",
      color: "#fff"
    }
  },
  menuButton: {
    paddingRight: "0 !important",
    color: "#959595",
    textTransform: "none"
  },
  button: {
    border: "1px solid rgba(0, 0, 0, 0.54)",
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.54)",
    height: "38px",
    borderRadius: "20px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#8080EB",
      borderColor: "#8080EB",
      color: "#fff"
    }
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  page: {
    minWidth: rem(800),
    maxWidth: rem(800),
    margin: "0 auto"
  },
  topBar: {
    padding: "28px 0 "
  },
  editLocalButton: {
    textAlign: "right",
    paddingTop: "20px"
  },

  clinicHeader: {
    color: "#000"
  },
  paddingTop: {
    paddingTop: 128
  }
});

const formatDate = date => moment(date).format("DD/MM/YYYY");

const upperCaseFirstLetter = string =>
  string.charAt(0).toUpperCase() + string.slice(1);

const mapStateToProps = state => ({
  prescription: state.prescription,
  requestedPrescription: state.prescription.saved,
  user: state.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSinglePrescription,
      getUserProfile,
      updatePrescription
    },
    dispatch
  );

class ViewPrescription extends Component {
  static propTypes = {
    prescriptionId: PropTypes.string,
    requestedPrescription: PropTypes.object,
    getSinglePrescription: PropTypes.func,
    user: PropTypes.object,
    getUserProfile: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      anchorEl: null,
      showClinicModal: false
    };

    this.showClinicModal = this.showClinicModal.bind(this);
    this.closeClinicModal = this.closeClinicModal.bind(this);
    this.closeAndSaveClinicModal = this.closeAndSaveClinicModal.bind(this);
    this.remakePrescription = this.remakePrescription.bind(this);
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  async componentWillMount() {
    const authenticated = isAuthenticated();
    if (authenticated) {
      const { prescriptionId } = this.props;
      if (!prescriptionId) return;

      await this.props.getSinglePrescription(prescriptionId);

      this.setState({
        patientId: this.props.prescription.saved.patient.id,
        medications: this.props.prescription.saved.medications
      });

      await this.props.getSinglePrescription(prescriptionId, "regular");
      await this.props.getUserProfile();
      this.setState({ loading: false });
    }
  }

  print(event) {
    event.preventDefault();
    window.print();
  }

  showClinicModal() {
    this.setState({
      showClinicModal: true
    });
  }

  closeClinicModal(event, save = false) {
    event.preventDefault();
    this.setState({ showClinicModal: false }, () => {
      if (save) {
        if (
          this.props.requestedPrescription.header.clinicId !==
          this.props.user.currentClinic
        ) {
          this.remakePrescription(this.props.prescriptionId);
        }
      }
    });
  }

  closeAndSaveClinicModal(event) {
    event.preventDefault();
    this.closeClinicModal(event, true);
  }

  async remakePrescription(prescriptionId) {
    let entries = [];
    this.state.medications.forEach(medication =>
      entries.push({
        doses: medication.doses.map(dose => ({
          amount: { value: dose.amount },
          frequency: { value: dose.frequency.id }
        })),
        form_id: medication.form.id,
        medicationName: medication.medication.name,
        medicationRoute: medication.form.route,
        medication_id: medication.medication.id,
        notes: medication.notes,
        treatment_days: { value: medication.treatment_days },
        unit: medication.form.unit
      })
    );

    const data = {
      patient_id: this.state.patientId,
      medications: entries.map(entry => {
        const dataToSend = {
          medicationName: entry.medicationName,
          treatment_days: entry.treatment_days.value,
          medication_id: entry.medication_id,
          form_id: entry.form_id,
          notes: entry.notes,
          doses: entry.doses.map(dose => ({
            amount: dose.amount.value,
            frequency_id: dose.frequency.value
          }))
        };

        return dataToSend;
      })
    };

    this.setState(
      {
        loading: true
      },
      async () => {
        const { error } = await this.props.updatePrescription(
          data,
          prescriptionId
        );

        if (error) {
          //Alert.error(ALERT.prescription.updateError, { offset: 39 });
        } else {
          //Alert.success(ALERT.prescription.updated, { offset: 39 });
          await this.props.getSinglePrescription(prescriptionId, "regular");
          this.setState({ loading: false });
        }
      }
    );
  }

  getRegistrationCouncil(user_type) {
    switch (user_type) {
      case "pharmacist":
        return "CRF";
      case "physician":
        return "CRM";
      case "nurse":
        return "COREN";
      case "nurse-technician":
        return "COREN";
      default:
        return "";
    }
  }

  render() {
    const { requestedPrescription, user, classes } = this.props;

    if (this.state.loading) {
      return <div />;
    }

    const { header, groups, footer, updated } = requestedPrescription;

    return (
      <Fragment>
        <Box className={classes.paddingTop} displayPrint="none"></Box>
        <Page
          backgroundColor="#E7ECF0"
          width="100%"
          paddingTop={0}
          paddingBottom={0}
        >
          <div className="prescription-view">
            <div className="watermark">
              <img src={logoSUS} alt="Logotipo SUS" />
            </div>
            <Grid
              container
              justify="space-between"
              className={classNames(
                classes.page,
                classes.prescriptionHeader,
                "transparentOnPrint"
              )}
            >
              {header.clinic && (
                <Grid item className={classes.clinicHeader}>
                  <p>
                    {translateUserTypeToPTBR(user.user_type, user.gender)}:{" "}
                    {header.physician}
                  </p>
                  <p>
                    Local: {header.clinic} - {header.address}
                  </p>
                  <p>
                    Paciente: <strong>{header.patient}</strong>
                  </p>
                  {header.patient_address && (
                    <p>
                      Endereço: <strong>{header.patient_address}</strong>
                    </p>
                  )}
                </Grid>
              )}

              {!header.clinic && (
                <Grid item>
                  <Grid container alignItems="center" spacing={2}>
                    <img src={Logo} alt="logo" />

                    <Grid item>Receita organizada pelo sistema Pulsares.</Grid>
                    <Grid item>{formatDate(updated)}</Grid>
                  </Grid>
                </Grid>
              )}
              <Grid
                item
                className={classNames(classes.editLocalButton, "noPrint")}
              >
                <ToggleButton
                  style={{ margin: 0 }}
                  hoverStyle={{ width: 140 }}
                  icon="edit"
                  label="Editar Local"
                  onClick={this.showClinicModal}
                />
              </Grid>
            </Grid>

            <div
              className={classNames(
                classes.page,
                classes.prescriptionContent,
                "transparentOnPrint"
              )}
            >
              {groups.map((group, key) => {
                const { route_groups } = group;
                let days = "Todos os dias";
                if (group.treatment_days) {
                  days = `Tomar por ${group.treatment_days} dias`;
                } else {
                  days =
                    group.treatment_description &&
                    group.treatment_description !== "uso contínuo"
                      ? upperCaseFirstLetter(group.treatment_description)
                      : days;
                }

                return (
                  <Table key={key}>
                    <TableBody>
                      <tr>
                        <th colSpan="2" className="routes-container">
                          <Grid
                            container
                            className={classes.regularRoutesDays}
                            justify="space-between"
                          >
                            <Grid item />
                            <Grid item>
                              <p className="txt-center regular-routes-days">
                                {days}
                              </p>
                            </Grid>
                            <Grid item>
                              <div className="noPrint">
                                {key === 0 && (
                                  <ToggleButton
                                    style={{ margin: 0 }}
                                    hoverStyle={{ width: 100 }}
                                    icon="edit"
                                    label="Editar"
                                    component={Link}
                                    to={`/receitas/${this.props.prescriptionId}`}
                                  />
                                )}
                              </div>
                            </Grid>
                          </Grid>
                        </th>
                      </tr>
                      {route_groups.map((item, i) => {
                        const { medications } = item;

                        return medications.map((medication, j) => (
                          <tr key={j} className="medication-row">
                            <td>
                              <div>{`${j + 1}. ${medication.name}`}</div>
                              <div>{medication.doses}</div>
                              {medication.notes && (
                                <div className="txt-italic">{`${medication.notes}`}</div>
                              )}
                            </td>
                          </tr>
                        ));
                      })}
                    </TableBody>
                  </Table>
                );
              })}

              <div className="prescription-metadata">
                <div>{formatDate(requestedPrescription.updated)}</div>
                <div className="prescription-metadata__signature">
                  <p>
                    {user.full_name} -{" "}
                    {this.getRegistrationCouncil(user.user_type)}
                    {user.user_type !== "student"
                      ? user.registration.state + " " + user.registration.number
                      : ""}
                  </p>
                </div>
              </div>

              <div className="prescription-footer">
                <p>
                  {`${footer.clinic ? footer.clinic + " -" : ""} - ${
                    footer.patient
                  }`}
                  {footer.medications.map((item, key) => (
                    <span key={key}>
                      {` - ${item.treatment_days}: `}
                      {item.medications.map((med, medKey) =>
                        medKey % 2 === 0 ? (
                          <span key={medKey}>
                            <strong>{`${med}. `}</strong>
                          </span>
                        ) : (
                          <span key={medKey}>{`${med}. `}</span>
                        )
                      )}
                    </span>
                  ))}
                </p>
              </div>
            </div>
          </div>
          <ClinicModal
            show={this.state.showClinicModal}
            onClose={this.closeClinicModal}
            onSave={this.closeAndSaveClinicModal}
          />
        </Page>
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ViewPrescription));
