import React, { Component } from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import { authCheck } from "../../../utils/authCheck";
import { signout, getUserProfile } from "../../../actions/user";

import RoundedButton from "../../Button";
import Icon from "../../Icon";
import logo from "../../../../img/logo-pulsares.png";

import {
	ArrowDropDown,
	Email,
	ExitToApp,
	Settings,
	Place,
	Search,
	PersonAdd
} from "@material-ui/icons";

import {
	AppBar,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Grid,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Toolbar
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import { rem } from "polished";
import classNames from "classnames";
import { darken } from "polished";

const styles = theme => ({
	root: {
		flexGrow: 1
	},
	paper: {
		boxShadow: "0px 1px 1px -1px rgba(0, 0, 0, 0.2)"
	},
	page: {
		minWidth: rem(800),
		maxWidth: rem(800),
		width: "100%",
		margin: "0 auto",
		padding: 0
	},
	icon: {
		fontSize: "35px !important",
		color: "#c2ccd1",
		"&:hover": {
			color: "#7C7CE4",
			borderBottom: "4px solid #5555B7",
			cursor: "pointer",
			transition: "all 0.1s"
		},
		"&.active": {
			color: "#7C7CE4",
			borderBottom: "4px solid #5555B7"
		}
	},
	dialog: {
		textAlign: "center",
		borderRadius: 10,
		padding: "15px 40px 10px 40px",
		width: 470
	},
	dialogContent: {
		padding: 0
	},
	dialogContentText: {
		padding: "0 62px 0 62px",
		color: "#7C7CE4",
		fontSize: "20px",
		fontWeight: 500
	},
	dialogCancelButton: {
		background: "transparent",
		border: "none",
		padding: 0,
		outline: "none",
		cursor: "pointer",
		color: "#7C7CE4",
		fontSize: "14px",
		fontWeight: 700,
		transition: "all 0.3s",
		"&:hover": {
			color: darken(0.05, "#7C7CE4")
		}
	},
	iconButton: {
		background: "#FFF"
	},
	leftIcon: {
		marginRight: theme.spacing(1)
	},
	rightIcon: {
		marginLeft: theme.spacing(1)
	},
	profileIcon: {
		alignItems: "center",
		background: "#dfe8ec",
		borderRadius: "50%",
		color: "#fff",
		display: "flex",
		height: "30px",
		justifyContent: "center",
		width: "30px"
	},
	button: {
		color: "#808080",
		fontSize: "16px",
		fontWeight: "bold",
		background: "#FFF",
		border: "none",
		textTransform: "none"
	},
	submitButton: {
		margin: "0 !important"
	},
	menu: {
		marginRight: "5px"
	},
	menuItem: {
		fontSize: "14px",
		padding: "10px 0",
		color: "#2f2f2f"
	},
	logo: {
		padding: "0 50px 0 0 !important"
	},
	version: {
		marginTop: -13,
		marginLeft: 97,
		fontSize: 9,
		color: "grey"
	}
});

const mapStateToProps = state => ({
	user: state.user
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			signout,
			getUserProfile
		},
		dispatch
	);

class Header extends Component {
	static propTypes = {
		signout: PropTypes.func
	};

	constructor(props) {
		super(props);
		this.state = {
			anchorEl: null
		};

		this.logout = this.logout.bind(this);
		this.handleExitPrescription = this.handleExitPrescription.bind(this);
		this.closeExitPrescriptionDialog = this.closeExitPrescriptionDialog.bind(
			this
		);
		this.continueWithWarnings = this.continueWithWarnings.bind(this);
	}

	async logout(event) {
		event.preventDefault();
		await this.props.signout();
		authCheck();
	}

	async componentWillMount() {
		await this.props.getUserProfile();
	}

	handleMenu = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	handleExitPrescription(e, route) {
		e.preventDefault();
		this.setState({
			showAlertModal: true,
			route: route
		});
	}

	closeExitPrescriptionDialog() {
		this.setState({
			showAlertModal: false,
			route: ""
		});
	}

	continueWithWarnings() {
		this.setState({ showAlertModal: false });
		this.props.history.push(this.state.route);
	}

	render() {
		const { classes } = this.props;
		const { anchorEl } = this.state;
		const open = Boolean(anchorEl);

		return (
			<div className={classes.root}>
				<AppBar className={classes.paper} position="fixed" color="inherit">
					<Toolbar className={classes.page} disableGutters={true}>
						<Grid
							container
							direction={"row"}
							alignItems={"center"}
							justify={"space-between"}
							spacing={0}
						>
							<Grid item>
								<Grid
									container
									direction={"row"}
									alignItems={"center"}
									justify={"flex-start"}
									spacing={3}
								>
									<Grid item className={classes.logo}>
										<a
											href="#button"
											onClick={event =>
												this.handleExitPrescription(event, "/inicio")
											}
										>
											<img src={logo} alt="Pulsares" width={145} />
										</a>
										<p className={classes.version}>Versão Beta</p>
									</Grid>
									<Grid item>
										<a
											href="#button"
											onClick={event =>
												this.handleExitPrescription(event, "/inicio")
											}
										>
											<Search className={classes.icon} />
										</a>
									</Grid>
									<Grid item>
										<a
											href="#button"
											onClick={event =>
												this.handleExitPrescription(event, "/novo-paciente")
											}
										>
											<PersonAdd className={classes.icon} />
										</a>
									</Grid>
								</Grid>
							</Grid>

							<Grid item>
								<Grid container>
									<Grid item>
										<div className={classes.menu}>
											<Button
												className={classes.button}
												aria-owns={open ? "menu-appbar" : null}
												aria-haspopup="true"
												onClick={this.handleMenu}
											>
												<div
													className={classNames(
														classes.profileIcon,
														classes.leftIcon
													)}
												>
													{(this.props.user.first_name || "").charAt(0)}
												</div>
												{this.props.user.first_name || ""}
												<ArrowDropDown />
											</Button>
											<Menu
												id="menu-appbar"
												anchorEl={anchorEl}
												anchorOrigin={{
													vertical: "top",
													horizontal: "right"
												}}
												transformOrigin={{
													vertical: "top",
													horizontal: "right"
												}}
												open={open}
												onClose={this.handleClose}
											>
												<MenuItem
													onClick={event =>
														this.handleExitPrescription(event, "/perfil")
													}
												>
													<ListItemIcon>
														<Settings />
													</ListItemIcon>
													<ListItemText inset primary="Editar Perfil" />
												</MenuItem>
												<MenuItem
													onClick={event =>
														this.handleExitPrescription(event, "/locais")
													}
												>
													<ListItemIcon>
														<Place />
													</ListItemIcon>
													<ListItemText inset primary="Locais de Trabalho" />
												</MenuItem>
												<MenuItem
													component={"a"}
													href="http://www.pulsares.com.br/#!/contato"
													target="_blank"
												>
													<ListItemIcon>
														<Email />
													</ListItemIcon>
													<ListItemText inset primary="Contato" />
												</MenuItem>
												<MenuItem onClick={this.logout}>
													<ListItemIcon>
														<ExitToApp />
													</ListItemIcon>
													<ListItemText inset primary="Sair" />
												</MenuItem>
											</Menu>
										</div>
									</Grid>
									<Grid item>
										<RoundedButton
											type="submit"
											className={classes.submitButton}
											disabled={this.props.disableSubmit}
										>
											<Icon name="done" />
											<span>{this.props.id ? "Salvar" : "Gerar"} receita</span>
										</RoundedButton>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				<Dialog
					classes={{ paper: classes.dialog }}
					open={this.state.showAlertModal}
					onClose={this.closeExitPrescriptionDialog}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogContent classes={{ root: classes.dialogContent }}>
						<DialogContentText
							classes={{ root: classes.dialogContentText }}
							id="alert-dialog-description"
						>
							Você ainda não terminou a sua receita. Você tem certeza que
							gostaria de sair agora?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Grid
							container
							direction={"row"}
							alignItems={"center"}
							f
							justify={"space-between"}
						>
							<Grid item>
								<div
									className={classes.dialogCancelButton}
									onClick={this.closeExitPrescriptionDialog}
								>
									<Grid
										container
										direction={"row"}
										alignItems={"center"}
										justify={"space-between"}
									>
										<Grid item>
											<Icon name="keyboard_arrow_left" />
										</Grid>
										<Grid item>
											<span> Não, desejo ficar</span>
										</Grid>
									</Grid>
								</div>
							</Grid>
							<Grid item>
								<RoundedButton
									type="submit"
									onClick={this.continueWithWarnings}
								>
									<span>Sim, tenho certeza!</span>
								</RoundedButton>
							</Grid>
						</Grid>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(styles)(Header))
);
