import React, { PureComponent } from "react";

import classNames from "classnames";

import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";

import logo from "../../../img/white-logo.svg";

import styles from "./styles";

const DRIFT_CHAT_SELECTOR = "drift-open-chat";

class Success extends PureComponent {
	static propTypes = {};

	constructor(props) {
		super(props);
		this.driftApi = "";

		this.openSidebar = this.openSidebar.bind(this);
	}

	openSidebar(event) {
		event.preventDefault();
		this.driftApi.sidebar.open();
		return false;
	}

	render() {
		const { classes, email } = this.props;
		return (
			<Grid className={classes.root} container alignItems="stretch">
				<Grid item xs={12}>
					<img className={classes.logo} src={logo} alt="logo" />
				</Grid>
				<Grid item xs={12} className={classes.text}>
					<Hidden mdUp>
						<h1 className={classes.title}>
							Parabéns!
							<br />
							Sua conta foi criada.
						</h1>
					</Hidden>
					<Hidden smDown>
						<h1 className={classes.title}>Parabéns! Sua conta foi criada.</h1>
					</Hidden>

					<h2 className={classes.subTitle}>
						Para acessar o sistema, enviamos um email para
						<span className={classes.email}> {email} </span>
						com o link de acesso.
					</h2>
					<a
						href="/"
						className={classNames(DRIFT_CHAT_SELECTOR, classes.faq)}
						onClick={this.openSidebar}
					>
						Problemas para criar seu cadastro?
					</a>
				</Grid>
				<Grid item xs={12} />
			</Grid>
		);
	}
}

const mapStateToProps = state => ({
	email: state.user.confirmationEmail
});

export default connect(
	mapStateToProps,
	null
)(withStyles(styles)(Success));
