import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "../js/utils/authCheck";

import ReactGA from "react-ga";

const ProtectedRoute = ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={props => {
				if (isAuthenticated()) {
					ReactGA.event({
						category: "log-in",
						action: "auto-login"
					});
					return <Component {...props} />;
				} else {
					return (
						<Redirect
							to={{
								pathname: "/entrar",
								state: {
									from: props.location
								}
							}}
						/>
					);
				}
			}}
		/>
	);
};

export default ProtectedRoute;
