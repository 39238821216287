import React, { PureComponent } from "react";

import { connect } from "react-redux";

import { Grid, withStyles } from "@material-ui/core";

import VideoWithCover from "./videoWithCover";
import MenuBar from "../UI/MenuBar";
import MenuItem from "../UI/MenuBar/MenuItem";

import HowToCreateCover from "../../img/home/how-to-create.png";
import UnderstandLiteracy from "../../img/home/understand-literacy.png";
import OurHistoryCover from "../../img/home/our-history.png";

import NewUserHomePage from "./NewUser";

import styles from "./Style";

class Home extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			arrowLeftPosition: 0
		};

		this.setArrowPosition = this.setArrowPosition.bind(this);
	}

	setArrowPosition(left) {
		this.setState({ arrowLeftPosition: left });
	}

	render() {
		const { classes, user } = this.props;
		const { arrowLeftPosition } = this.state;

		return (
			<div className={classes.root}>
				<MenuBar
					showButton
					onButtonPositioned={this.setArrowPosition}
					showAlerts
					showSettings
				>
					<MenuItem to={"/meus-pacientes"}>Meus pacientes</MenuItem>
					<MenuItem to={"/minhas-receitas"}>Minhas receitas</MenuItem>
				</MenuBar>
				<NewUserHomePage
					userName={user.first_name}
					arrowLeftPosition={arrowLeftPosition}
				/>
				<Grid
					container
					alignItems="center"
					justify="center"
					className={classes.videos}
				>
					<Grid item>
						<VideoWithCover
							videoId="gx1xMZZqf-0"
							coverImage={HowToCreateCover}
							title="Como criar minha receita"
						/>
					</Grid>
					<Grid item>
						<VideoWithCover
							videoId="l9-LSHlxeLQ"
							coverImage={UnderstandLiteracy}
							title="Entenda health iliteracy"
						/>
					</Grid>
					<Grid item className={classes.videoCover}>
						<VideoWithCover
							videoId="l9-LSHlxeLQ"
							coverImage={OurHistoryCover}
							title="Nossa história"
						/>
					</Grid>
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: state.user
});

export default connect(
	mapStateToProps,
	null
)(withStyles(styles)(Home));
