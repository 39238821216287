import createReducer from "../utils/createReducer";
import storageService from "../services/storage";
import {
  SIGNUP,
  SIGNUP_FAILURE,
  SIGNIN,
  SIGNOUT,
  GET_USER_DATA,
  GET_USER_PROFILE,
  GET_USER_PROFILE_FAILURE,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_FAILURE
} from "../constants/";

const jwtDecode = require("jwt-decode");

const INITIAL_STATE = {};

const user = createReducer(INITIAL_STATE, {
  [SIGNUP]: (state, action) => ({
    ...state,
    registered: true,
    confirmationEmail: action.payload.email
  }),

  [SIGNUP_FAILURE]: (state, action) => ({
    ...state,
    error: action.error,
    errorStatus: action.status
  }),

  [SIGNIN]: (state, action) => {
    const token = action.payload.refresh_token;

    storageService().set("refresh-token", token);
    storageService().set("access-token", null);

    // parse token to json.
    const finalData = jwtDecode(token);

    return Object.assign({}, state, {
      token,
      name: finalData.name,
      email: finalData.email
    });
  },

  [SIGNOUT]: (state, action) => {
    storageService().set("refresh-token", null);
    storageService().set("access-token", null);

    return Object.assign({}, state, {
      token: null,
      name: null,
      email: null
    });
  },

  [GET_USER_DATA]: (state, action) => {
    const token = action.payload.token;

    // parse token to json.
    const finalData = jwtDecode(token);

    return Object.assign({}, state, {
      refresh_token: token,
      name: finalData.name,
      email: finalData.email
    });
  },

  [GET_USER_PROFILE]: (state, action) =>
    Object.assign({}, state, action.payload),

  [GET_USER_PROFILE_FAILURE]: (state, action) =>
    Object.assign({}, state, {
      error: action.error
    }),

  [UPDATE_USER_PROFILE]: (state, action) =>
    Object.assign({}, state, {
      error: action.error
    }),

  [UPDATE_USER_PROFILE_FAILURE]: (state, action) =>
    Object.assign({}, state, {
      error: action.error
    })
});

export default user;
