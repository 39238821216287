import * as pictograms from "../../../config/pictograms";

export const renderPictogram = medication => {
  if (medication.pictogram === "comp") {
    if (medication.amount === "Meio") {
      return pictograms.meio_comprimido;
    } else if (medication.amount === "Um quarto") {
      return pictograms.um_quarto_de_comprimido;
    }
  }
  return pictograms[medication.pictogram];
};
