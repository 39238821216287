import React, { Fragment } from "react";

import { connect } from "react-redux";

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Joyride from "react-joyride";

import PhysicianIllustration from "../../img/prescription-creation/tour/physician.png";

const tourStyles = {
	options: {
		arrowColor: "#A1E8D2",
		backgroundColor: "#A1E8D2",
		overlayColor: "rgba(55, 47, 78, .48)"
	},
	tooltip: {
		borderRadius: 20,
		width: "100%"
	}
};

const stepStyles = makeStyles({
	text: {
		maxWidth: props => props.textWidth,
		fontFamily: "Montserrat, sans-serif",
		fontSize: 14,
		color: "#6844D5",
		textAlign: "left"
	},
	button: {
		cursor: "pointer",
		marginTop: 28,
		fontWeight: 700
	}
});

const Step = ({ text, textWidth, onClose }) => {
	const classes = stepStyles({ textWidth });

	return (
		<Grid container alignItems="center" justify="center" spacing={2}>
			<Grid item>
				<img src={PhysicianIllustration} alt="illustration" />
			</Grid>
			<Grid item className={classes.text}>
				<div>{text}</div>
				<div className={classes.button} onClick={onClose}>
					Ok, entendi
				</div>
			</Grid>
		</Grid>
	);
};

class Tour extends React.Component {
	state = {
		steps: [
			{
				target: ".tour-step-1",
				content: (
					<Step
						text="Para adicionar a pessoa, clique no sinal de +"
						onClose={this.props.onClose}
						textWidth={189}
					/>
				),
				disableBeacon: true,
				disableOverlayClose: true,
				hideCloseButton: true,
				hideFooter: true,
				placement: "bottom"
			},
			{
				target: ".tour-step-2",
				content: (
					<Step
						text={
							"Muito bem! Para organizar a receita usamos dois horários: O horário em que a pessoa toma a primeira medicação e o horário em que vai dormir. Aqui você escolhe os horários e pode alterar quando quiser, clicando no botão acima."
						}
						textWidth={287}
						onClose={this.props.onClose}
					/>
				),
				disableBeacon: true,
				disableOverlayClose: true,
				hideCloseButton: true,
				hideFooter: true,
				placement: "bottom-end"
			},
			{
				target: ".tour-step-3",
				content: (
					<Step
						text={"Clique aqui para adicionar as medicações."}
						textWidth={287}
						onClose={this.props.onClose}
					/>
				),
				disableBeacon: true,
				disableOverlayClose: true,
				hideCloseButton: true,
				hideFooter: true,
				placement: "bottom"
			},
			{
				target: ".tour-step-4",
				content: (
					<Step
						text={
							"Aqui você escreve o nome da medicação, são 10.000 medicações disponíveis no total!"
						}
						textWidth={287}
						onClose={this.props.onClose}
					/>
				),
				disableBeacon: true,
				disableOverlayClose: true,
				hideCloseButton: true,
				hideFooter: true,
				placement: "bottom"
			},
			{
				target: ".tour-step-5",
				content: (
					<Step
						text={
							"Neste campo você escolhe quando e por quanto tempo tomar. Por enquanto a forma 'se necessário' está neste campo."
						}
						textWidth={287}
						onClose={this.props.onClose}
					/>
				),
				disableBeacon: true,
				disableOverlayClose: true,
				hideCloseButton: true,
				hideFooter: true,
				placement: "bottom"
			},
			{
				target: ".tour-step-6",
				content: (
					<Step
						text={
							"Aqui você escolhe a quantidade: Quantos comprimido, ml, vezes de passar uma pomada..."
						}
						textWidth={287}
						onClose={this.props.onClose}
					/>
				),
				disableBeacon: true,
				disableOverlayClose: true,
				hideCloseButton: true,
				hideFooter: true,
				placement: "bottom"
			},
			{
				target: ".tour-step-7",
				content: (
					<Step
						text={
							"Aqui você seleciona a forma de tomar, podendo escolher horários específicos."
						}
						textWidth={287}
						onClose={this.props.onClose}
					/>
				),
				disableBeacon: true,
				disableOverlayClose: true,
				hideCloseButton: true,
				hideFooter: true,
				placement: "bottom"
			},
			{
				target: ".tour-step-8",
				content: (
					<Step
						text={
							"Se você escolher, por exemplo, 1 comprimido de manhã e quiser adicionar 1 comprimido de tarde, neste botão você adiciona essa segunda orientação"
						}
						textWidth={287}
						onClose={this.props.onClose}
					/>
				),
				disableBeacon: true,
				disableOverlayClose: true,
				hideCloseButton: true,
				hideFooter: true,
				placement: "bottom"
			},
			{
				target: ".tour-step-9",
				content: (
					<Step
						text={
							"Depois de preencher as informações, clique aqui para adicionar a medicação na receita."
						}
						textWidth={287}
						onClose={this.props.onClose}
					/>
				),
				disableBeacon: true,
				disableOverlayClose: true,
				hideCloseButton: true,
				hideFooter: true,
				placement: "bottom-end"
			},
			{
				target: ".tour-step-10",
				content: (
					<Step
						text={`Quando terminar, clique aqui para ver a receita.`}
						textWidth={287}
						onClose={this.props.onClose}
					/>
				),
				disableBeacon: true,
				disableOverlayClose: true,
				hideCloseButton: true,
				hideFooter: true,
				placement: "bottom-end"
			},
			{
				target: ".tour-step-11",
				content: (
					<Step
						text="Este é nosso cabeçalho padrão. Você pode editar as informações que aparecerão aqui, bem como o local de trabalho."
						textWidth={287}
						onClose={this.props.onClose}
					/>
				),
				disableBeacon: true,
				disableOverlayClose: true,
				hideCloseButton: true,
				hideFooter: true,
				placement: "bottom"
			},
			{
				target: ".tour-step-12",
				content: (
					<Step
						text="Quando imprimir, você pode assinar - deixar a sua marca - aqui."
						textWidth={287}
						onClose={this.props.onClose}
					/>
				),
				disableBeacon: true,
				disableOverlayClose: true,
				hideCloseButton: true,
				hideFooter: true,
				placement: "bottom"
			},
			{
				target: ".tour-step-13",
				content: (
					<Step
						text="Pronto! Agora é só imprimir a sua receita."
						textWidth={287}
						onClose={this.props.onClose}
					/>
				),
				disableBeacon: true,
				disableOverlayClose: true,
				hideCloseButton: true,
				hideFooter: true,
				placement: "bottom"
			}
		]
	};

	render() {
		const { steps } = this.state;
		const { tour, disableScrolling } = this.props;

		return (
			<Fragment>
				<Joyride
					styles={tourStyles}
					steps={steps}
					run={tour.run}
					stepIndex={tour.currentStep}
					disableScrolling={disableScrolling}
					showSkipButton
				/>
			</Fragment>
		);
	}
}

const mapStateToProps = state => {
	const { tour } = state;

	return {
		tour
	};
};

export default connect(
	mapStateToProps,
	null
)(Tour);
