import React, { PureComponent } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";

import { Grid, withStyles } from "@material-ui/core";

import Page from "../../UI/Page";
import Form from "./Form";
import styles from "../Style";

import {
	createPatient,
	updatePatient,
	getPatient
} from "../../../js/actions/patient";

const mapStateToProps = state => ({
	patient: state.patient.selected
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			createPatient,
			updatePatient,
			getPatient
		},
		dispatch
	);

class PatientFormPage extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			loaded: false,
			isSaving: false,
			isPatientUpdated: false
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleFieldChange = this.handleFieldChange.bind(this);
	}

	async componentDidMount() {
		if (this.props.id) {
			await this.props.getPatient(this.props.id);
		}
		this.setState({ loaded: true });
	}

	handleFieldChange() {
		this.setState({ isPatientUpdated: false });
	}

	async handleSubmit(values) {
		await this.setState({ isSaving: true });

		if (this.props.id) {
			this.updatePatient(values);
		} else {
			this.createPatient(values);
		}
	}

	async createPatient(patientData) {
		const response = await this.props.createPatient(patientData);

		if (response.error) {
			console.error(response.error.message);
		} else {
			console.info("Paciente criado com sucesso");
			await this.setState({ isPatientUpdated: true });
			this.props.history.push("/meus-pacientes");
		}

		await this.setState({ isSaving: false });

		ReactGA.event({
			category: "patient",
			action: "submit-form",
			label: "create"
		});
	}

	async updatePatient(patientData) {
		const response = await this.props.updatePatient(patientData, this.props.id);

		if (response) {
			console.error(response);
		} else {
			console.info("Paciente criado com sucesso");
			await this.setState({ isPatientUpdated: true });
		}

		await this.setState({ isSaving: false });

		ReactGA.event({
			category: "patient",
			action: "submit-form",
			label: "update"
		});
	}

	render() {
		const { classes, id, patient } = this.props;
		const { loaded, isSaving, isPatientUpdated } = this.state;

		const title = id ? "Perfil do paciente" : "Novo paciente";

		return (
			loaded && (
				<Page width={816} backgroundColor="#EFF2F5" paddingTop={128}>
					<Grid container>
						<Grid item xs={12}>
							<h1 className={classes.title}>{title}</h1>
						</Grid>
						<Grid item xs={12}>
							<Form
								initialData={patient}
								saving={isSaving}
								saved={isPatientUpdated}
								onSubmit={this.handleSubmit}
								onFieldChange={this.handleFieldChange}
							/>
						</Grid>
					</Grid>
				</Page>
			)
		);
	}
}

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(styles)(PatientFormPage))
);
