import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import moment from "moment";
import { partial } from "lodash";

import AlertDialog from "../AlertDialog";

import { getAllPrescriptionForPatient } from "../../actions/patient";
import { removePrescription } from "../../actions/prescription";

import { isAuthenticated } from "../../utils/authCheck";

import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import ToggleButton from "../common/button/ToggleButton";

const DATE_FORMAT = "DD/MM/YYYY";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  pageTitle: {
    marginTop: "25px"
  },
  table: {
    minWidth: 700,
    margin: "10px 0 15px 0"
  },
  row: {
    "&:hover": {
      backgroundColor: "#E8EEF3"
    }
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  button: {
    border: "1px solid rgba(0, 0, 0, 0.54)",
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.54)",
    height: "38px",
    borderRadius: "20px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#8080EB",
      borderColor: "#8080EB",
      color: "#fff"
    }
  },
  iconButton: {
    minWidth: "38px",
    width: "38px"
  },
  deleteButton: {
    "&:hover": {
      backgroundColor: "#bf5449",
      borderColor: "#bf5449",
      color: "#fff"
    }
  },
  nameCell: {
    fontWeight: 500
  },
  tableTitle: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#000",
    marginTop: "50px"
  },
  iconTableTitle: {
    float: "left",
    marginRight: "5px",
    transform: "rotate(90deg)"
  }
});

const mapStateToProps = state => ({
  prescription: state.patient.prescription
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      removePrescription,
      getAllPrescriptionForPatient
    },
    dispatch
  );

class PrescriptionList extends Component {
  static propTypes = {
    patientId: PropTypes.string,
    prescription: PropTypes.array,
    removePrescription: PropTypes.func,
    getAllPrescriptionForPatient: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false,
      prescriptionToDelete: null,
      hoveredEntry: null
    };

    this.onModalClose = this.onModalClose.bind(this);
    this.deletePrescription = this.deletePrescription.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.selectEntry = this.selectEntry.bind(this);
    this.renderTableRows = this.renderTableRows.bind(this);
  }

  componentWillMount() {
    const authenticated = isAuthenticated();
    if (authenticated) {
      if (this.props.patientId) {
        this.props.getAllPrescriptionForPatient(this.props.patientId);
      }
    }
  }

  onModalClose() {
    this.showModal(false, null);
  }

  async deletePrescription() {
    await this.props.removePrescription(this.state.prescriptionToDelete.id);
    //Alert.success(ALERT.prescription.removed, { offset: 39 });
    this.showModal(false, null);
  }

  confirmDelete(prescription) {
    this.showModal(true, prescription);
  }

  selectEntry(id) {
    this.setState({ hoveredEntry: id });
  }

  showModal(modalOpened, prescriptionToDelete) {
    this.setState({ modalOpened, prescriptionToDelete });
  }

  renderTableRows(index, entry) {
    const { classes } = this.props;
    return (
      <TableRow key={index} className={classes.row}>
        <TableCell component="th" scope="row" className={classes.nameCell}>
          {`${moment(entry.updated).format(DATE_FORMAT)} - ${
            entry.patient.name
          }`}
        </TableCell>
        <TableCell align="right">
          <ToggleButton
            hoverStyle={{ width: 140 }}
            icon="visibility"
            label="Ver receita"
            component={Link}
            to={`/visualizar-receita/easy?id=${entry.id}`}
          />

          <ToggleButton
            hoverStyle={{ width: 175 }}
            icon="edit"
            label="Editar uma cópia"
            component={Link}
            to={`/receitas/${entry.id}/copiar`}
          />

          <ToggleButton
            hoverStyle={{ width: 105 }}
            isDelete
            icon="delete"
            label="Excluir"
            onClick={partial(this.confirmDelete, entry)}
          />
        </TableCell>
      </TableRow>
    );
  }

  render() {
    const { classes } = this.props;

    if (!this.props.prescription.length) {
      return (
        <div>
          <p>Não há registro de receitas para este paciente.</p>
          <p>
            <Link to="/historico">
              <span>Voltar para a lista de pacientes</span>
            </Link>
          </p>
        </div>
      );
    }

    return (
      <div>
        <Table className={classes.table}>
          <TableBody>
            {this.props.prescription.map((entry, index) =>
              this.renderTableRows(index, entry)
            )}
          </TableBody>
        </Table>

        {this.state.prescriptionToDelete && (
          <AlertDialog
            open={this.state.modalOpened}
            onClose={this.onModalClose}
            onConfirm={this.deletePrescription}
            title="Aviso!"
            contentText={
              <div>
                Essa ação não poderá ser desfeita.
                <strong>Você tem certeza que deseja apagar essa receita</strong>
                ?
              </div>
            }
            confirmLabel="Excluir"
            confirmColor="secondary"
            cancelLabel="Cancelar"
          />
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PrescriptionList));
