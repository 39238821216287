import React, { Component } from "react";

import classnames from "classnames";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { AddCircleOutline } from "@material-ui/icons";

const styles = theme => ({
	root: {
		padding: theme.spacing(2.5),
		border: "1px solid #EFF2F5"
	},
	button: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontFamily: "Montserrat, sans-serif",
		fontSize: 14,
		fontWeight: 700,
		color: "#9AAFBF",
		cursor: "pointer",
		"& span": {
			marginLeft: theme.spacing(1.5)
		}
	}
});

class PrescriptionCreationMedicationList extends Component {
	render() {
		const { classes, onClick } = this.props;

		return (
			<Grid container justify="center" alignItems="center">
				<Grid item xs={12} className={classes.root}>
					<div
						className={classnames(classes.button, "tour-step-3")}
						onClick={onClick}
					>
						<AddCircleOutline htmlColor="#9AAFBF" />
						<span>Adicione uma medicação</span>
					</div>
				</Grid>
			</Grid>
		);
	}
}

export default withStyles(styles)(PrescriptionCreationMedicationList);
