import React from "react";

import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import logo from "../../img/grey-logo.svg";

const styles = theme => ({
	root: {
		padding: "13px 0",
		alignSelf: "stretch",
		color: "#9AAFBF",
		[theme.breakpoints.down("sm")]: {
			border: "1px solid #E7ECF0"
		}
	},
	logo: {
		marginLeft: "1.25rem",
		width: "9.5375rem",
		height: "2.5rem",
		[theme.breakpoints.down("sm")]: {
			margin: 0
		}
	}
});

const LogoBar = ({ classes }) => {
	return (
		<Grid
			container
			justify="center"
			alignItems="center"
			className={classes.root}
		>
			<Grid item>
				<img className={classes.logo} src={logo} alt="logo" />
			</Grid>
		</Grid>
	);
};

export default withStyles(styles)(LogoBar);
