import React from "react";

import TextField from "../../../js/components/common/form/TextField";
import { getIn } from "formik";
import { MenuItem } from "@material-ui/core";

const FormTextField = ({
	field,
	form: { touched, errors, handleChange },
	select,
	items,
	onChange,
	...props
}) => {
	const error = getIn(errors, field.name);
	const touch = getIn(touched, field.name);

	return (
		<TextField
			{...field}
			{...props}
			select={select}
			margin="normal"
			variant="filled"
			error={error && touch}
			verified={!error && touch}
			helperText={touch ? error : null}
			onChange={e => {
				handleChange(e);
				if (onChange) onChange();
			}}
		>
			{select &&
				items.map((option, key) => (
					<MenuItem key={key} value={option.value}>
						{option.label}
					</MenuItem>
				))}
		</TextField>
	);
};

export default FormTextField;
