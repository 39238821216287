export default class Utility {
  static convertModelToFormData(model, form, namespace = "") {
    let formData = form || new FormData();

    for (let propertyName in model) {
      if (
        !model.hasOwnProperty(propertyName) ||
        (!model[propertyName] && model[propertyName] !== "")
      )
        continue;

      let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;

      if (model[propertyName] instanceof Date) {
        formData.append(formKey, model[propertyName].toISOString());
      } else if (model[propertyName] instanceof Array) {
        model[propertyName].forEach((element, index) => {
          const tempFormKey = `${formKey}[${index}]`;
          this.convertModelToFormData(element, formData, tempFormKey);
        });
      } else if (
        typeof model[propertyName] === "object" &&
        !(model[propertyName] instanceof File)
      ) {
        this.convertModelToFormData(model[propertyName], formData, formKey);
      } else {
        if (model[propertyName] instanceof File) {
          formData.append(formKey, model[propertyName]);
        } else {
          formData.append(formKey, model[propertyName].toString());
        }
      }
    }
    return formData;
  }
}
