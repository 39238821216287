import React, { Component } from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";

import RoundedButton from "../Button";
import Icon from "../Icon";
import logo from "../../../img/logo-pulsares.png";

import { rem } from "polished";
import { AppBar, Grid, Toolbar } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
	root: {
		flexGrow: 1
	},
	paper: {
		boxShadow: "0px 1px 1px -1px rgba(0, 0, 0, 0.2)"
	},
	page: {
		minWidth: rem(800),
		maxWidth: rem(800),
		width: "100%",
		margin: "0 auto",
		padding: 0
	},
	submitButton: {
		margin: "0 !important"
	},
	logo: {
		padding: "0 50px 0 0 !important"
	},
	version: {
		marginTop: -13,
		marginLeft: 97,
		fontSize: 9,
		color: "grey"
	}
});

class HeaderWithPrint extends Component {
	static propTypes = {
		signout: PropTypes.func
	};

	constructor(props) {
		super(props);

		this.handleExitPrescription = this.handleExitPrescription.bind(this);
	}

	handleExitPrescription(e, route) {
		e.preventDefault();
		this.props.history.push(route);
	}

	print(event) {
		event.preventDefault();
		window.print();
	}

	render() {
		const { classes } = this.props;

		return (
			<div id="headerBar" className={classes.root}>
				<AppBar className={classes.paper} position="fixed" color="inherit">
					<Toolbar className={classes.page} disableGutters={true}>
						<Grid
							container
							direction={"row"}
							alignItems={"center"}
							justify={"space-between"}
							spacing={0}
						>
							<Grid item>
								<Grid
									container
									direction={"row"}
									alignItems={"center"}
									justify={"flex-start"}
									spacing={3}
								>
									<Grid item className={classes.logo}>
										<a
											href="#button"
											onClick={event =>
												this.handleExitPrescription(event, "/inicio")
											}
										>
											<img src={logo} alt="Pulsares" width={145} />
										</a>
										<p className={classes.version}>Versão Beta</p>
									</Grid>
								</Grid>
							</Grid>

							<Grid item>
								<RoundedButton
									type="submit"
									className={classes.submitButton}
									disabled={this.props.disableSubmit}
									onClick={this.print}
								>
									<Icon name="print" />
									<span>Imprima sua receita</span>
								</RoundedButton>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
			</div>
		);
	}
}

export default withRouter(withStyles(styles)(HeaderWithPrint));
