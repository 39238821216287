import React from 'react'
import Page from '../components/Page'
import PageTitle from '../components/PageTitle'
import { Flex } from 'grid-styled'
import logo from '../../img/logo-pulsares.png'
import Terms from '../components/Terms'

const TermsPage = () => (
  <Page className="terms">
    <Flex justify="center">
      <img src={logo} alt="Logotipo"/>
    </Flex>
    <Flex justify="center">
      <PageTitle>
        TERMOS DE USO SOFWARE “RENOVA”
      </PageTitle>
    </Flex>
    <Terms />
  </Page>
)

export default TermsPage
