export const translateUserTypeToPTBR = (userType = "", gender = "") => {
  if (userType === "physician" && gender === "M") return "Médico";
  if (userType === "physician" && gender === "F") return "Médica";
  if (userType === "physician") return "Médico(a)";

  if (userType === "nurse" && gender === "M") return "Enfermeiro";
  if (userType === "nurse" && gender === "F") return "Enfermeira";
  if (userType === "nurse") return "Enfermeiro(a)";

  if (userType === "pharmacist" && gender === "M") return "Farmacêutico";
  if (userType === "pharmacist" && gender === "F") return "Farmacêutica";
  if (userType === "pharmacist") return "Farmacêutico(a)";

  if (userType === "nurse-technician" && gender === "M")
    return "Técnico de enfermagem";
  if (userType === "nurse-technician" && gender === "F")
    return "Técnica de enfermagem";

  if (userType === "nurse-technician") return "Técnico(a) de enfermagem";

  if (userType === "student") return "Estudante";

  return "Profissional";
};
