import React from "react";

import { Link } from "react-router-dom";
import { rem } from "polished";
import classNames from "classnames";

import { Grid, Icon, withStyles } from "@material-ui/core";

const styles = () => ({
	root: {
		display: "flex",
		alignItems: "center",
		fontFamily: "Montserrat, sans-serif",
		fontSize: rem(14),
		fontWeight: "bold",
		textTransform: "uppercase",
		color: "#9AAFBF",
		textDecoration: "none",
		paddingBottom: rem(14),
		marginRight: rem(70),
		"&:hover": {
			color: "#8080EC",
			borderBottom: `${rem(4)} solid #8080EC`,
			paddingBottom: rem(10)
		}
	},
	active: {
		color: "#8080EC",
		borderBottom: `${rem(4)} solid #8080EC`,
		paddingBottom: `${rem(10)} !important`
	},
	icon: {
		marginRight: rem(10)
	},
	flex: {
		display: "flex"
	}
});

const SubMenuItem = ({ active, children, classes, icon, to }) => {
	return (
		<Grid
			item
			className={classNames(
				classes.root,
				"noPrint",
				active ? classes.active : ""
			)}
			component={Link}
			to={to}
		>
			{icon && <Icon className={classes.icon}>{icon}</Icon>}
			{children}
		</Grid>
	);
};

export default withStyles(styles)(SubMenuItem);
