/* eslint-disable max-len */

export const generic = {
  somethingWrong: "Ocorreu algum erro. Tente novamente."
};

export const form = {
  missingEmail: "Preencha o campo email.",
  missingPassword: "Preencha o campo senha.",
  invalidEmail: "Email inválido."
};

export const patient = {
  created: "Paciente criado com sucesso.",
  updated: "Paciente atualizado com sucesso.",
  removed: "Paciente removido com sucesso.",
  error: "Ocorreu algum erro ao cadastar paciente.",
  nameMissing: "Preencha o nome do paciente."
};

export const prescription = {
  added: "Medicamento adicionado com sucesso.",
  edited: "Medicamento editado com sucesso.",
  saved: "Receita salva.",
  updated: "Receita atualizada.",
  updateError: "Erro ao editar a receita.",
  removed: "Receita removida com sucesso.",
  medicationNameEmpty: "Por favor, informe o nome do medicamento.",
  emptyMedication: "Por favor, adicone medicamentos à receita.",
  chooseMedicationFromList:
    "Por favor, faça uma busca e selecione um medicamento."
};

export const forgot = {
  checkYourEmail:
    "Se seu email estiver cadastrado em nossa base de dados, enviaremos instruções para redefinir sua senha"
};

export const recover = {
  success: "Senha alterada com sucesso.",
  invalidData:
    "Informações de redefinição de senha inválidas. Confira se o endereço de email foi digitado corretamente."
};

export const signup = {
  doneWithSuccess: "Cadastro efetuado com sucesso.",
  terms: "Você precisa aceitar os termos de uso.",
  passwordNotMatch: "As senhas não conferem",
  profileUpdated: "Perfil atualizado com sucesso."
};

export const signin = {
  loginFailure: "E-mail ou senha inválidos."
};

/* eslint-enable max-len */
