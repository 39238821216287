import styled from 'styled-components'

const AlertList = styled.ul`
  list-style: disc;
  padding: 0 10px;

  li {
    margin: 5px 0;
  }
`

export default AlertList
