import React, { Component } from "react";

import { FormControlLabel, Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import TextField from "../../js/components/common/form/TextField";
import PasswordField from "../../js/components/common/form/PasswordField";
import SubmitIconButton from "../UI/SubmitIconButton";

import styles from "./Style";

class SignInForm extends Component {
	render() {
		const {
			classes,
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			isValid
		} = this.props;

		return (
			<form className={classes.form} onSubmit={handleSubmit} valitade>
				<TextField
					id="email"
					name="email"
					type="email"
					value={values.email}
					onChange={handleChange}
					onBlur={handleBlur}
					label="Email"
					margin="normal"
					variant="filled"
					className={classes.textfield}
					required
					error={errors.email && touched.email && values.email !== ""}
					verified={!errors.email && touched.email}
					helperText={values.email !== "" && touched.email ? errors.email : ""}
				/>
				<PasswordField
					id="password"
					name="password"
					value={values.password}
					onChange={handleChange}
					onBlur={handleBlur}
					label="Senha"
					margin="normal"
					variant="filled"
					className={classes.textfield}
					required
					error={errors.password && touched.password && values.password !== ""}
					verified={!errors.password && touched.password}
					helperText={
						values.password !== "" && touched.password ? errors.password : ""
					}
				/>
				<FormControlLabel
					control={
						<Checkbox
							name="remember"
							checked={values.remember}
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.remember}
							color="primary"
						/>
					}
					label="Mantenha-me conectado"
					className={classes.checkbox}
				/>
				<div className={classes.submitButton}>
					<SubmitIconButton
						showIcon
						disabled={isSubmitting || !isValid}
						type="submit"
						text="Entrar"
					/>
				</div>
			</form>
		);
	}
}

export default withStyles(styles)(SignInForm);
