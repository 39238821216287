import React from "react";

import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";

import BackButton from "./BackButton";
import logo from "../../img/grey-logo.svg";

const styles = theme => ({
	root: {
		alignSelf: "stretch",
		color: "#9AAFBF",
		[theme.breakpoints.down("sm")]: {
			border: "1px solid #E7ECF0"
		}
	},
	logo: {
		marginLeft: "1.25rem",
		width: "9.5375rem",
		height: "2.5rem",
		[theme.breakpoints.down("sm")]: {
			margin: 0
		}
	},
	flex: {
		[theme.breakpoints.down("sm")]: {
			flex: 1
		}
	}
});

const LogoWithBackButton = ({ classes, width, onBackClick }) => {
	const isSmallScreen = isWidthDown("sm", width);

	return (
		<Grid container alignItems="center" className={classes.root}>
			<BackButton flex={isSmallScreen} onClick={onBackClick} />
			<img className={classes.logo} src={logo} alt="logo" />
			<div className={classes.flex} />
		</Grid>
	);
};

export default withStyles(styles)(withWidth()(LogoWithBackButton));
