import React, { Component, Fragment } from "react";

import { find, pick } from "lodash";
import classnames from "classnames";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactGA from "react-ga";

import { Grid } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";

import Intake from "./Intake";

import AutoComplete from "../../UI/AutoComplete";

import {
	createPatient,
	getAllPatients,
	updatePatient
} from "../../../js/actions/patient";

import {
	next as tourNextStep,
	previous as tourPreviousStep,
	run as runTour,
	stop as stopTour,
	reset as resetTour
} from "../../../js/actions/tour";

import ClipboardIMG from "../../../img/prescription-creation/clipboard.png";

import {
	FIRST_INTAKE_OPTIONS,
	LAST_INTAKE_OPTIONS
} from "../../../js/data/patient";

const styles = theme => ({
	root: {
		padding: "0 10% 30px 10%"
	},
	patient: {
		display: "flex",
		alignItems: "center",
		minWidth: 442,
		"& img": {
			height: 43.91,
			marginRight: theme.spacing(3)
		}
	},
	button: {
		cursor: "pointer",
		margin: theme.spacing(0, 1)
	}
});

class PrescriptionCreationPatient extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: "",
			name: "",
			first_intake: FIRST_INTAKE_OPTIONS[2].value,
			last_intake: LAST_INTAKE_OPTIONS[2].value,
			showAddPatient: false,
			showIntake: false
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleAutoCompleteChange = this.handleAutoCompleteChange.bind(this);
		this.handleAddPatient = this.handleAddPatient.bind(this);
	}

	async componentDidMount() {
		await this.props.getAllPatients();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.patientId !== this.props.patientId) {
			const patient = this.getPatientById(this.props.patientId);

			this.setState({
				id: this.props.patientId,
				...patient,
				showIntake: true
			});
		}
	}

	getPatientById = id => {
		const patient = find(this.props.patients, { id });

		return patient ? patient : {};
	};

	getSuggestions() {
		const suggestions = this.props.patients.map(patient => ({
			value: patient.id,
			label: patient.name
		}));

		return suggestions;
	}

	async handleAutoCompleteChange(name) {
		await this.setState({
			id: "",
			name,
			first_intake: FIRST_INTAKE_OPTIONS[2].value,
			last_intake: LAST_INTAKE_OPTIONS[2].value
		});

		this.verifyIfPatientExists(name);
	}

	handleChange = name => async event => {
		await this.setState({ [name]: event.target.value });
	};

	verifyIfPatientExists = async patientName => {
		const { patients } = this.props;
		if (patientName) {
			const patient = find(patients, { name: patientName });
			if (patient) {
				await this.setState({
					...patient,
					showAddPatient: false,
					showIntake: true
				});

				this.props.onSelect(this.state.id);
			} else {
				if (patientName.length >= 2 && patientName.length <= 255) {
					await this.setState({
						showAddPatient: true
					});
					if (this.props.isNewUser) {
						setTimeout(() => {
							this.props.runTour();
						}, 1500);
					}
				} else {
					this.setState({ showAddPatient: false });
				}
			}
		} else {
			this.setState({
				showAddPatient: false
			});
		}
	};

	handleAddPatient = async () => {
		this.setState({
			showAddPatient: false
		});

		const patientData = {
			name: this.state.name,
			first_intake: FIRST_INTAKE_OPTIONS[2].value,
			last_intake: LAST_INTAKE_OPTIONS[2].value
		};

		const response = await this.props.createPatient(patientData);

		if (response && response.data && !response.error) {
			await this.setState({
				...response.data,
				showAddPatient: false,
				showIntake: true
			});
			this.props.onSelect(this.state.id);
			ReactGA.event({
				category: "prescription-creation",
				action: "submit-form",
				label: "create-patient"
			});
		} else {
			await this.setState({
				showAddPatient: true
			});
		}
	};

	handleSaveIntake = async (first_intake, last_intake) => {
		await this.setState({
			first_intake,
			last_intake
		});

		this.props.updatePatient(this.getPatientData(), this.state.id);

		ReactGA.event({
			category: "prescription-creation",
			action: "submit-form",
			label: "update-patient-intake"
		});
	};

	getPatientData() {
		return {
			...pick(this.state, ["name", "first_intake", "last_intake"])
		};
	}

	render() {
		const { classes, disabled } = this.props;
		const { showAddPatient, showIntake } = this.state;
		const patientData = this.getPatientData();

		return (
			<Fragment>
				<Grid container justify="space-between" className={classes.root}>
					<Grid item>
						<div className={classes.patient}>
							<img src={ClipboardIMG} alt="prancheta médica" />
							<AutoComplete
								autoFocus={!disabled}
								disabled={disabled}
								value={this.state.name}
								suggestions={this.getSuggestions()}
								label=""
								onChange={this.handleAutoCompleteChange}
								placeholder="Digite o nome do paciente aqui"
							/>
							{showAddPatient && (
								<AddCircleOutline
									className={classnames(classes.button, "tour-step-1")}
									htmlColor="#8080EC"
									onClick={this.handleAddPatient}
								/>
							)}
						</div>
					</Grid>
					{showIntake && (
						<Grid item>
							<Intake patient={patientData} onSave={this.handleSaveIntake} />
						</Grid>
					)}
				</Grid>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	patients: state.patient.entries
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			createPatient,
			getAllPatients,
			updatePatient,
			runTour,
			stopTour,
			resetTour,
			tourNextStep,
			tourPreviousStep
		},
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(PrescriptionCreationPatient));
