import { post } from "./service";
import { requestLoader } from "../../js/actions/loader";
import * as types from "../../js/constants";

export const signup = data => async dispatch => {
  dispatch(requestLoader(true));
  try {
    const response = await post(data);

    dispatch(requestLoader(false));

    return dispatch(signupSuccess(response.data));
  } catch (error) {
    dispatch(requestLoader(false));

    return dispatch(
      signupFailure(
        error.response || { data: { error: "Erro inesperado!" }, status: 500 }
      )
    );
  }
};

const signupSuccess = data => ({ type: types.SIGNUP, payload: data });

const signupFailure = ({ data, status }) => ({
  type: types.SIGNUP_FAILURE,
  error: data ? data.validation_errors || [data.error] : "",
  status: status
});
