import * as Yup from "yup";

import { STATES } from "../../../js/data/signup";
import isValidPhone from "@brazilian-utils/is-valid-phone";

const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string("Insira o nome do local de trabalho").required(
      "O nome do local de trabalho deve ser informado."
    ),
    address: Yup.object().shape({
      zip: Yup.string("").notRequired(),
      street: Yup.string("")
        .required("A rua deve ser informada.")
        .min(2, "A rua deve ter no mínimo 3 letras.")
        .max(255, "A rua deve conter no máximo 255 letras."),
      number: Yup.string("")
        .min(1, "O número informado não é valido.")
        .max(20, "O número informado não é valido.")
        .required("O número deve ser informado."),
      complement: Yup.string("")
        .notRequired()
        .max(255, "O complemento deve conter no máximo 255 letras."),
      district: Yup.string("")
        .min(1, "O bairro deve ter no mínimo 1 letra.")
        .max(60, "O bairro deve conter no máximo 60 letras.")
        .required("O bairro deve ser informado."),
      city: Yup.string("")
        .min(3, "A cidade deve ter no mínimo 3 letras.")
        .max(60, "A cidade deve conter no máximo 60 letras.")
        .required("A cidade é deve ser informada."),
      phone: Yup.string("")
        .test("phone", "Esse número de telefone é inválido", phone =>
          phone ? isValidPhone(phone) : true
        )
        .required("Um número de telefone válido deve ser informado."),
      state: Yup.string("")
        .oneOf(STATES, "O estado escolhido deve ser válido.")
        .required("O estado do registro deve ser informado.")
    })
  });

export default validationSchema;
