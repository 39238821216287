import { API_URL } from "../config/api";
import services from "../services/";
import * as types from "../constants/";
import { requestLoader } from "./loader";

export const addMedicationSuccess = data => ({
  type: types.ADD_MEDICATION,
  payload: data
});

export const addMedication = data =>
  function addMedicationFn(dispatch) {
    return new Promise(resolve => {
      resolve(dispatch(addMedicationSuccess(data)));
    });
  };

export const editMedicationSuccess = (id, data) => ({
  type: types.EDIT_MEDICATION,
  payload: { id, data }
});

export const editMedication = (id, data) =>
  function editMedicationFn(dispatch) {
    return new Promise(resolve => {
      resolve(dispatch(editMedicationSuccess(id, data)));
    });
  };

export const removeMedicationSuccess = id => ({
  type: types.REMOVE_MEDICATION,
  payload: { id }
});

export const removeMedication = id =>
  function removeMedicationFn(dispatch) {
    return new Promise(resolve => {
      resolve(dispatch(removeMedicationSuccess(id)));
    });
  };

export const clearPrescription = () => ({
  type: types.CLEAR_PRESCRIPTION,
  payload: null
});

export const savePrescriptionSuccess = data => ({
  type: types.SAVE_PRESCRIPTION,
  payload: data
});

export const savePrescriptionFailure = error => ({
  type: types.SAVE_PRESCRIPTION_FAILURE,
  error
});

export const savePrescription = data =>
  function savePrescriptionFn(dispatch) {
    const url = `${API_URL}/prescriptions/`;

    dispatch(requestLoader(true));

    return services
      .create(url, data)
      .then(response => {
        dispatch(savePrescriptionSuccess(response.data));
        dispatch(requestLoader(false));
        return response;
      })
      .catch(e => {
        dispatch(requestLoader(false));
        return savePrescriptionFailure(e);
      });
  };

export const updatePrescriptionSuccess = data => ({
  type: types.UPDATE_PRESCRIPTION,
  payload: data
});

export const updatePrescriptionFailure = error => ({
  type: types.UPDATE_PRESCRIPTION_FAILURE,
  error
});

export const updatePrescription = (data, id) =>
  function updatePrescriptionFn(dispatch) {
    const url = `${API_URL}/prescriptions/${id}`;

    dispatch(requestLoader(true));

    return services
      .update(url, data)
      .then(response => {
        dispatch(updatePrescriptionSuccess(response.data));
        dispatch(requestLoader(false));
        return response;
      })
      .catch(e => {
        dispatch(requestLoader(false));
        return updatePrescriptionFailure(e);
      });
  };

export const getSinglePrescriptionSuccess = data => ({
  type: types.GET_SINGLE_PRESCRIPTION,
  payload: data
});

export const getSinglePrescription = (id, format) =>
  function getSinglePrescriptionFn(dispatch) {
    let url = `${API_URL}/prescriptions/${id}`;

    dispatch(requestLoader(true));

    if (format) {
      url = `${API_URL}/prescriptions/${id}?format=${format}`;
    }

    return services.fetch(url).then(response => {
      dispatch(getSinglePrescriptionSuccess(response.data));
      dispatch(requestLoader(false));
    });
  };

export const getAllPrescriptionsSuccess = data => ({
  type: types.GET_ALL_PRESCRIPTIONS,
  payload: data
});

export const getAllPrescriptions = () =>
  function getSinglePrescriptionFn(dispatch) {
    let url = `${API_URL}/prescriptions/`;

    dispatch(requestLoader(true));

    return services.fetch(url).then(response => {
      dispatch(getAllPrescriptionsSuccess(response.data));
      dispatch(requestLoader(false));
    });
  };

export const removePrescriptionSuccess = id => ({
  type: types.REMOVE_PRESCRIPTION,
  payload: { id }
});

export const removePrescription = id =>
  function removePrescriptionFn(dispatch) {
    const url = `${API_URL}/prescriptions/${id}`;

    dispatch(requestLoader(true));

    return services.delete(url).then(() => {
      dispatch(removePrescriptionSuccess(id));
      dispatch(requestLoader(false));
    });
  };
