import HandsImage from "../../img/signin/background.png";

const styles = theme => ({
  headbar: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  },
  left: {
    paddingTop: "5%",
    width: "42.5%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      textAlign: "center"
    }
  },
  content: {
    width: "80%",
    maxWidth: "21.875rem",
    minWidth: "19rem",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "19rem"
    }
  },
  right: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: "42.5%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url(${HandsImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  overlay: {
    width: "100%",
    height: "100%",
    opacity: ".77",
    backgroundImage: "linear-gradient(to top right, #6844D5, #A9A9F2)"
  },
  logo: {
    width: "70%",
    maxWidth: "32.5rem",
    position: "absolute"
  },
  submitButton: {
    margin: ".9375rem 0",
    [theme.breakpoints.down("sm")]: {
      alignSelf: "center"
    }
  },
  form: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    marginBottom: "1rem",
    alignItem: "stretch",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  title: {
    fontSize: "2.375rem",
    lineHeight: "3.375rem",
    color: "#8080EC",
    fontFamily: "Bree Serif, serif",
    marginBottom: "1.25rem",
    paddingRight: "30%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.75rem",
      lineHeight: "normal",
      padding: 0
    }
  },
  subtitle: {
    fontSize: "1rem",
    color: "#372F4E",
    fontFamily: "Montserrat, sans-serif",
    margin: "1.25rem 0"
  },
  link: {
    color: "#42C39B",
    fontFamily: "Montserrat, sans-serif",
    fontSize: ".875rem",
    cursor: "pointer"
  },
  textfield: {
    margin: ".625rem 0"
  }
});

export default styles;
