import React, { PureComponent } from "react";

import Grid from "@material-ui/core/Grid";

import TextField from "../../../../js/components/common/form/TextField";
import CPFMaskedInput from "../../../../js/components/common/form/CPFMaskedInput";
import SubmitIconButton from "../../../UI/SubmitIconButton";
import { withStyles } from "@material-ui/core/styles";

import styles from "./style";

class SignUpErrorConflictForm extends PureComponent {
	render() {
		const {
			classes,
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			isValid
		} = this.props;

		return (
			<form className={classes.form} onSubmit={handleSubmit} noValidate>
				<Grid
					container
					direction="column"
					alignItems="center"
					className={classes.fields}
				>
					<TextField
						id="email"
						name="email"
						type="email"
						value={values.email}
						onChange={handleChange}
						onBlur={handleBlur}
						label="Seu email"
						margin="normal"
						variant="filled"
						className={classes.textfield}
						required
						error={errors.email && touched.email}
						verified={!errors.email && touched.email}
						helperText={touched.email ? errors.email : ""}
					/>
					<TextField
						label="CPF"
						value={values.document}
						onChange={handleChange}
						onBlur={handleBlur}
						id="document"
						margin="normal"
						variant="filled"
						className={classes.textfield}
						required
						error={errors.document && touched.document}
						verified={!errors.document && touched.document}
						helperText={touched.document ? errors.document : ""}
						InputProps={{
							inputComponent: CPFMaskedInput
						}}
					/>
				</Grid>
				<div className={classes.buttons}>
					<SubmitIconButton
						showIcon
						text="Confirmar!"
						disabled={isSubmitting || !isValid}
						type="submit"
					/>
				</div>
			</form>
		);
	}
}

export default withStyles(styles)(SignUpErrorConflictForm);
