import createReducer from "../utils/createReducer";
import {
  ADD_MEDICATION,
  EDIT_MEDICATION,
  REMOVE_MEDICATION,
  SAVE_PRESCRIPTION,
  SAVE_PRESCRIPTION_FAILURE,
  UPDATE_PRESCRIPTION,
  UPDATE_PRESCRIPTION_FAILURE,
  GET_SINGLE_PRESCRIPTION,
  GET_ALL_PRESCRIPTIONS,
  CLEAR_PRESCRIPTION,
  REMOVE_PRESCRIPTION
} from "../constants/";

const INITIAL_STATE = {
  entries: [],
  saved: {},
  list: []
};

const prescription = createReducer(INITIAL_STATE, {
  [ADD_MEDICATION]: (state, action) =>
    Object.assign({}, state, {
      entries: state.entries.concat(action.payload)
    }),

  [EDIT_MEDICATION]: (state, action) => {
    const { entries } = state;
    const { payload } = action;

    const newEntries = [].concat(
      entries.slice(0, payload.id),
      payload.data,
      entries.slice(payload.id + 1)
    );

    return Object.assign({}, state, {
      entries: newEntries
    });
  },

  [REMOVE_MEDICATION]: (state, action) => {
    const { entries } = state;
    const { payload } = action;

    const newEntries = [].concat(
      entries.slice(0, payload.id),
      entries.slice(payload.id + 1)
    );

    return Object.assign({}, state, {
      entries: newEntries
    });
  },

  [SAVE_PRESCRIPTION]: (state, action) =>
    Object.assign({}, state, {
      saved: action.payload
    }),

  [SAVE_PRESCRIPTION_FAILURE]: (state, action) =>
    Object.assign({}, state, {
      error: action.error
    }),

  [UPDATE_PRESCRIPTION]: (state, action) =>
    Object.assign({}, state, {
      saved: action.payload
    }),

  [UPDATE_PRESCRIPTION_FAILURE]: (state, action) =>
    Object.assign({}, state, {
      error: action.error
    }),

  [GET_SINGLE_PRESCRIPTION]: (state, action) =>
    Object.assign({}, state, {
      saved: action.payload
    }),

  [GET_ALL_PRESCRIPTIONS]: (state, action) => ({
    ...state,
    list: action.payload
  }),

  [REMOVE_PRESCRIPTION]: (state, action) => {
    const newState = state.list.filter(entry => entry.id !== action.payload.id);

    return Object.assign({}, state, {
      list: newState
    });
  },

  [CLEAR_PRESCRIPTION]: state =>
    Object.assign({}, state, {
      entries: INITIAL_STATE.entries
    })
});

export default prescription;
