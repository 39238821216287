import * as types from '../constants/'


export const setFormInProgressSuccess = (data) => ({
  type: types.SET_FORM_IN_PROGRESS,
  payload: data,
})

export const setFormInProgress = (data) => (
  function addMedicationFn(dispatch) {
    return new Promise((resolve) => {
      resolve(dispatch(setFormInProgressSuccess(data)))
    })
  }
)
