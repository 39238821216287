import React, { PureComponent } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

import { Close } from "@material-ui/icons";

import { rem } from "polished";

const styles = {
	root: {
		padding: 0
	},
	paper: {
		minWidth: rem(800),
		width: rem(800),
		maxWidth: rem(800),
		borderRadius: rem(8),
		textAlign: "center"
	},
	dialogTitle: {
		color: "#7C7CE4",
		fontSize: rem(20),
		textAlign: "center",
		fontWeight: 700
	},
	subTitle: {
		color: "#282828",
		fontSize: rem(14),
		paddingTop: rem(14)
	},
	closeButton: {
		position: "absolute",
		right: 5,
		top: 5
	},
	content: {
		paddingBottom: rem(30)
	}
};

class Modal extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			scroll: "body"
		};
	}

	render() {
		const { classes } = this.props;
		return (
			<Dialog
				open={this.props.show}
				onClose={event => this.props.onClose(event)}
				scroll={this.state.scroll}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				classes={{
					root: classes.root,
					paper: classes.paper
				}}
			>
				<DialogTitle
					disableTypography={true}
					classes={{
						root: classes.dialogTitle
					}}
					id="alert-dialog-title"
				>
					<IconButton
						className={classes.closeButton}
						color="inherit"
						onClick={this.props.onClose}
						aria-label="Fechar"
					>
						<Close />
					</IconButton>
					{this.props.title && this.props.title}

					<p className={classes.subTitle}>
						{this.props.subTitle && this.props.subTitle}
					</p>
				</DialogTitle>
				<div className={classes.content}>{this.props.children}</div>
			</Dialog>
		);
	}
}

export default withStyles(styles)(Modal);
