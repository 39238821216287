import React, { Component, Fragment } from "react";

import PropTypes from "prop-types";

import View from "../components/Prescription/View/Easy";
import MenuBar from "../components/UI/MenuBar";
import MenuItem from "../components/UI/MenuBar/MenuItem";
import SubMenu from "../components/UI/MenuBar/SubMenu";
import SubMenuItem from "../components/UI/MenuBar/SubMenu/SubMenuItem";

class PrescriptionViewEasyScreen extends Component {
	// @TODO: set correct PropType for location prop.
	static propTypes = {
		location: PropTypes.any
	};

	constructor(props) {
		super(props);

		this.handlePrint = this.handlePrint.bind(this);
	}

	async handlePrint(event) {
		event.preventDefault();
		window.print();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.location !== this.props.location) {
			this.setState({ prevPath: this.props.location });
		}
	}

	render() {
		const { location } = this.props;
		let params = new URLSearchParams(location.search);

		return (
			<Fragment>
				<MenuBar
					showButton
					buttonText="Imprimir receita"
					buttonIcon="print"
					buttonClassName={"tour-step-13"}
					buttonOnClick={this.handlePrint}
					showSettings
					showBackButton
					onBackClick={() =>
						this.props.history.push(`/receitas/${params.get("id")}`)
					}
				>
					<MenuItem active>Sua receita</MenuItem>
				</MenuBar>
				<SubMenu>
					<SubMenuItem
						active
						to={`/visualizar-receita/easy?id=${params.get("id")}`}
						icon="access_alarm"
					>
						Receita organizada
					</SubMenuItem>
					<SubMenuItem
						to={`/visualizar-receita?id=${params.get("id")}`}
						icon="list_alt"
					>
						Receita simples
					</SubMenuItem>
				</SubMenu>
				<View prescriptionId={params.get("id")} />
			</Fragment>
		);
	}
}

export default PrescriptionViewEasyScreen;
