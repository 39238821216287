import React, { PureComponent } from "react";

import { withStyles } from "@material-ui/core/styles";

import DriftComponentWrapper from "./DriftComponentWrapper";

const styles = () => ({
	root: {
		cursor: "pointer",
		position: "fixed",
		right: "-3.5rem",
		top: "45vh",
		fontSize: ".75rem",
		fontWeight: "bold",
		fontFamily: "Montserrat, sans-serif",
		color: "#FFFFFF",
		backgroundColor: "#C2D5E5",
		borderRadius: ".5rem .5rem 0 0",
		padding: ".5rem .625rem",
		transform: "rotate(270deg)",
		"&:hover": {
			backgroundColor: "#C2C8E5"
		}
	}
});

class DriftButton extends PureComponent {
	render() {
		const { classes } = this.props;

		return (
			<DriftComponentWrapper>
				<div className={classes.root}>Ajuda com o cadastro</div>
			</DriftComponentWrapper>
		);
	}
}

export default withStyles(styles)(DriftButton);
