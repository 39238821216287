import React from "react";

import { BrowserRouter as Router, Route } from "react-router-dom";
import ProtectedRoute from "../components/ProtectedRoute";

import * as containers from "./containers";

import { withTracker } from "./withTracker";

import SignInScreen from "../screens/SignIn";

import SignUpScreen from "../screens/SignUp";
import SignUpActivationScreen from "../screens/SignUpActivation";
import SignUpSuccessScreen from "../screens/SignUpSuccess";

import HomeScreen from "../screens/Home";

import PatientListScreen from "../screens/Patient/ListScreen";
import PatientPageScreen from "../screens/Patient/PageScreen";
import PrescriptionScreen from "../screens/PrescriptionScreen";
import PrescriptionCreationScreen from "../screens/PrescriptionCreation";
import PrescriptionViewEasyScreen from "../screens/PrescriptionViewEasy";
import PrescriptionViewRegularScreen from "../screens/PrescriptionViewRegular";

import ProfilePersonalDataScreen from "../screens/Profile/PersonalData";
import ProfileAccountScreen from "../screens/Profile/Account";
import ProfileWorkplaceListScreen from "../screens/Profile/Workplace/List";
import ProfileWorkplaceNewScreen from "../screens/Profile/Workplace/New";

const {
  ForgotPage,
  TermsPage,
  PrescriptionListPage,
  RecoverPasswordPage,
  ClinicsConfigPage,
  HistoryPage
} = containers;

const Routes = () => (
  <Router>
    <div>
      <Route exact path="/" component={withTracker(SignInScreen)} />
      <Route exact path="/entrar" component={withTracker(SignInScreen)} />
      <Route
        exact
        path="/recuperar-senha"
        component={withTracker(ForgotPage)}
      />
      <Route
        exact
        path="/redefinir-senha/:token"
        component={withTracker(RecoverPasswordPage)}
      />
      <Route exact path="/termos-de-uso" component={withTracker(TermsPage)} />

      <Route exact path="/cadastrar" component={withTracker(SignUpScreen)} />
      <Route
        exact
        path="/cadastrar/conta"
        component={withTracker(SignUpScreen)}
      />
      <Route
        exact
        path="/cadastrar/informacoes-gerais"
        component={withTracker(SignUpScreen)}
      />
      <Route
        exact
        path="/cadastrar/contato"
        component={withTracker(SignUpScreen)}
      />
      <Route
        exact
        path="/cadastrar/planos"
        component={withTracker(SignUpScreen)}
      />
      <Route
        exact
        path="/ativar-cadastro/:token"
        component={withTracker(SignUpActivationScreen)}
      />
      <Route
        exact
        path="/cadastrado"
        component={withTracker(SignUpSuccessScreen)}
      />

      <ProtectedRoute
        exact
        path="/inicio"
        component={withTracker(HomeScreen)}
      />

      <ProtectedRoute
        exact
        path="/historico"
        component={withTracker(HistoryPage)}
      />
      <ProtectedRoute
        exact
        path="/minhas-receitas"
        component={withTracker(PrescriptionScreen)}
      />

      <ProtectedRoute
        exact
        path="/meus-pacientes"
        component={withTracker(PatientListScreen)}
      />
      <ProtectedRoute
        exact
        path="/novo-paciente"
        component={withTracker(PatientPageScreen)}
      />
      <ProtectedRoute
        exact
        path="/pacientes/:id"
        component={withTracker(PatientPageScreen)}
      />
      <ProtectedRoute
        exact
        path="/nova-receita"
        component={withTracker(PrescriptionCreationScreen)}
      />
      <ProtectedRoute
        exact
        path="/receitas/:id"
        component={withTracker(PrescriptionCreationScreen)}
      />
      <ProtectedRoute
        exact
        path="/receitas/:id/copiar"
        component={withTracker(PrescriptionCreationScreen)}
      />
      <ProtectedRoute
        exact
        path="/receitas"
        component={withTracker(PrescriptionListPage)}
      />
      <ProtectedRoute
        exact
        path="/visualizar-receita"
        component={withTracker(PrescriptionViewRegularScreen)}
      />
      <ProtectedRoute
        exact
        path="/visualizar-receita/easy"
        component={withTracker(PrescriptionViewEasyScreen)}
      />

      <ProtectedRoute
        exact
        path="/meu-perfil/dados-pessoais"
        component={withTracker(ProfilePersonalDataScreen)}
      />
      <ProtectedRoute
        exact
        path="/meu-perfil/conta"
        component={withTracker(ProfileAccountScreen)}
      />
      <ProtectedRoute
        exact
        path="/meu-perfil/local-de-trabalho"
        component={withTracker(ProfileWorkplaceListScreen)}
      />
      <ProtectedRoute
        exact
        path="/meu-perfil/local-de-trabalho/novo/"
        component={withTracker()}
      />

      <ProtectedRoute
        exact
        path="/meu-perfil/local-de-trabalho/atualizar/:id"
        component={withTracker(ProfileWorkplaceNewScreen)}
      />

      <ProtectedRoute
        exact
        path="/locais"
        component={withTracker(ClinicsConfigPage)}
      />
    </div>
  </Router>
);

export default Routes;
