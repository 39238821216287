import React, { Component } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { rem, lighten } from "polished";

import { partial, find, isEqual } from "lodash";
import Label from "./Label";
import Icon from "./Icon";

const Wrapper = styled.div`
  position: relative;
  cursor: default;
`;

const Placeholder = styled.div`
  background: inherit;
  height: ${props => rem(props.height)};
  border-bottom: 1px solid #ccc;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  color: inherit;

  p {
    width: 100%;
  }

  ${props =>
    props.active &&
    css`
      border-color: ${lighten(0.1, "#7C7CE4")};
    `};
`;

const Search = styled(Placeholder)`
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
`;

const List = styled.ul`
  background: #fff;
  width: 100%;
  max-height: ${rem(200)};
  overflow: auto;
  border: 1px solid #ccc;
  position: absolute;
  left: 0;
  top: ${props => rem(props.height + 1)};
  z-index: 1;
`;

const Option = styled.li`
  padding: 5px 10px;

  :hover {
    background: #eee;
  }
`;

const Buttons = styled.div`
  background: inherit;
  position: absolute;
  right: 0;
  bottom: ${props => rem(props.height / 6)};
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  background: none;
  border: 0;
  outline: none;
`;

class Select extends Component {
  static propTypes = {
    id: PropTypes.string,
    searchable: PropTypes.bool,
    name: PropTypes.string,
    items: PropTypes.array,
    label: PropTypes.string,
    value: PropTypes.object,
    onChange: PropTypes.func,
    height: PropTypes.number,
    as: PropTypes.oneOf(["text"])
  };

  static defaultProps = {
    searchable: false,
    height: 40
  };

  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.value || this.props.items[0],
      listVisible: false,
      filter: ""
    };

    this.setFilter = this.setFilter.bind(this);
    this.handleDocumentClick = this.setFilter.bind(this);
    this.selectItem = this.setFilter.bind(this);
    this.showList = this.setFilter.bind(this);
    this.hideList = this.setFilter.bind(this);
    this.clearFilter = this.setFilter.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleDocumentClick);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value && !isEqual(nextProps.value, this.props.value)) {
      this.setState({ selected: nextProps.value });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleDocumentClick);
  }

  setFilter(e) {
    this.setState({ filter: e.target.value });
  }

  handleDocumentClick(e) {
    if (!this.containerEl.contains(e.target)) {
      this.hideList();
    }
    return;
  }

  selectItem(item) {
    this.hideList();
    this.setState({ selected: item });
    this.props.onChange(item);
  }

  showList() {
    this.setState({ listVisible: true });
  }

  hideList() {
    this.clearFilter();
    this.setState({ listVisible: false });
  }

  clearFilter() {
    this.setState({ filter: "" });
  }

  get selected() {
    return find(this.props.items, this.state.selected);
  }

  render() {
    const { name, items, label, searchable, id, height, as } = this.props;

    const { listVisible, filter = "" } = this.state;

    return (
      <div
        ref={ref => {
          this.containerEl = ref;
        }}
      >
        {label && <Label htmlFor={id}>{label}</Label>}

        <Wrapper>
          <Placeholder height={height} active={listVisible}>
            <p onClick={this.showList}>
              {!listVisible ? this.selected.label : ""}
            </p>
            <Buttons height={height}>
              {as !== "text" && (
                <Icon
                  name={listVisible ? "arrow_drop_up" : "arrow_drop_down"}
                  onClick={listVisible ? this.hideList : this.showList}
                />
              )}
            </Buttons>
          </Placeholder>

          {searchable && listVisible && (
            <Search height={height} active>
              <Input
                type="text"
                onChange={this.setFilter}
                value={filter}
                autoFocus
              />
              <Buttons height={height}>
                <Icon name="close" onClick={this.clearFilter} size={20} />
                {as !== "text" && (
                  <Icon name={"arrow_drop_up"} onClick={this.hideList} />
                )}
              </Buttons>
            </Search>
          )}

          {listVisible && (
            <List height={height}>
              {items
                .filter(
                  item =>
                    `${item.label}`.match(filter.toLowerCase()) ||
                    (item.options &&
                      item.options.some(option =>
                        `${option}`.match(filter.toLowerCase())
                      ))
                )
                .map((item, index) => (
                  <Option key={index} onClick={partial(this.selectItem, item)}>
                    {item.label}
                  </Option>
                ))}
            </List>
          )}

          <input type="hidden" name={name} defaultValue={this.selected.value} />
        </Wrapper>
      </div>
    );
  }
}

export default Select;
