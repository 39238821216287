const styles = theme => ({
	root: {
		padding: "30px 18%"
	},
	row: {
		margin: theme.spacing(1, 0)
	},
	textField: {
		"& .MuiInputBase-input": { color: "#372F4E" }
	},
	paper: {
		padding: theme.spacing(1, 1.25),
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0
	},
	notes: {
		fontFamily: "Montserrat, sans-serif",
		fontSize: 14,
		fontWeight: 700,
		color: "#9AAFBF"
	},
	buttons: {
		margin: theme.spacing(5, 0, 1, 0)
	},
	iconButton: {
		cursor: "pointer"
	},
	suggestions: {
		position: "absolute",
		zIndex: "999",
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0
	},
	relative: {
		position: "relative"
	},
	suggestionItem: {
		borderBottom: "1px solid rgba(0,0,0,0.1)"
	},
	cancelDoseButton: {
		marginRight: 15
	}
});

export default styles;
