import * as Yup from "yup";

import isValidCpf from "@brazilian-utils/is-valid-cpf";
import isValidPhone from "@brazilian-utils/is-valid-phone";

const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string("")
      .min(2, "O nome deve conter no mínimo 2 letras.")
      .max(255, "O nome deve conter no máximo 255 letras.")
      .required("O nome é obrigatório."),
    birthday: Yup.date("A data de nascimento é inválida")
      .notRequired()
      .max(new Date(), "A data de nascimento é inválida."),
    document: Yup.string("")
      .notRequired()
      .test("document", "O CPF inserido não é valido.", document =>
        document && document.length > 0 ? isValidCpf(document) : true
      ),
    phone: Yup.string("")
      .notRequired()
      .test("phone", "Esse número de telefone é inválido", phone =>
        phone ? isValidPhone(phone) : true
      ),

    email: Yup.string("")
      .email("Insira um e-mail válido.")
      .notRequired()
  });

export default validationSchema;
