import React, { PureComponent, Fragment } from "react";

import Prescriptions from "../components/Prescription";
import MenuBar from "../components/UI/MenuBar";
import MenuItem from "../components/UI/MenuBar/MenuItem";
import SubMenu from "../components/UI/MenuBar/SubMenu";
import SubMenuItem from "../components/UI/MenuBar/SubMenu/SubMenuItem";
import Footer from "../components/UI/Footer";

class PatientScreen extends PureComponent {
	render() {
		return (
			<Fragment>
				<MenuBar
					buttonText="Adicionar paciente"
					buttonIcon="person_add"
					buttonGoTo="/novo-paciente"
					showAlerts
					showSettings
					showBackButton
					onBackClick={() => this.props.history.goBack()}
				>
					<MenuItem active to={"/minhas-receitas"}>
						Minhas receitas
					</MenuItem>
				</MenuBar>
				<SubMenu>
					<SubMenuItem active to="/minhas-receitas" icon="update">
						Histórico de receitas
					</SubMenuItem>
				</SubMenu>
				<Prescriptions />
				<Footer />
			</Fragment>
		);
	}
}

export default PatientScreen;
