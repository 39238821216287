import React, { PureComponent } from "react";

import { MenuItem as MaterialMenuItem, withStyles } from "@material-ui/core";

import styles from "../Style";

class MenuItem extends PureComponent {
	render() {
		const { classes, children, ...other } = this.props;

		return (
			<MaterialMenuItem {...other} className={classes.menuItem}>
				{children}
			</MaterialMenuItem>
		);
	}
}

export default withStyles(styles)(MenuItem);
