import React, { PureComponent } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { find } from "lodash";
import ReactGA from "react-ga";

import { CircularProgress, Grid, withStyles } from "@material-ui/core";

import { getAllPatients, removePatient } from "../../js/actions/patient";

import PatientList from "./List";
import styles from "./Style";

import SearchBar from "../UI/SearchBar";
import Page from "../UI/Page";
import ConfirmationDialog from "../UI/ConfirmationDialog";

class Patient extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			filteredList: [],
			offset: 20,
			isLoading: false,
			search: "",
			notFoundMessage: "",
			showDeleteConfirmation: false,
			patientToDelete: {}
		};

		this.handleSearch = this.handleSearch.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.closeDeleteConfirmation = this.closeDeleteConfirmation.bind(this);
		this.deletePatient = this.deletePatient.bind(this);
	}

	async componentDidMount() {
		window.addEventListener("scroll", this.handleScroll);

		await this.props.getAllPatients();
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll);
	}

	async getMorePatients() {
		const { limit, offset } = this.state;

		if (offset < limit) {
			await this.setState({ isLoading: true });

			window.setTimeout(async () => {
				await this.setState({ offset: offset + offset });
				await this.setState({ isLoading: false });
			}, 1000);
		}
	}

	getPatientList() {
		const { search, filteredList, offset } = this.state;

		const patients = search ? filteredList : this.props.patients;
		const limit = patients.length;

		this.setState({ limit });

		if (limit === 0) {
			if (search) {
				this.setNotFoundMessage(
					`Nenhum paciente encontrada para a busca '${search}'.`
				);
			} else {
				this.setNotFoundMessage("Ainda não foi criado nenhum paciente. ");
			}
		}

		if (patients && Array.isArray(patients)) {
			return patients.slice(0, offset < limit ? offset : limit);
		}

		return [];
	}

	setNotFoundMessage(message) {
		this.setState({
			notFoundMessage: message
		});
	}

	handleScroll = () => {
		const isEndOfPage =
			document.documentElement.scrollHeight -
				(window.innerHeight + window.scrollY) <=
			64;

		if (isEndOfPage) {
			this.getMorePatients();
		}
	};

	handleSearch = async search => {
		const { patients } = this.props;

		await this.setState({ search });

		const filteredList = patients.filter(patient =>
			patient.name.toLowerCase().match(search.toLowerCase())
		);

		await this.setState({ filteredList });

		ReactGA.event({
			category: "patient-list",
			action: "search",
			label: "by-text"
		});
	};

	async handleDelete(id) {
		const patientToDelete = find(this.props.patients, { id: id });
		await this.setState({ showDeleteConfirmation: true, patientToDelete });

		ReactGA.event({
			category: "patient-list",
			action: "button-click",
			label: "remove-patient"
		});
	}

	async deletePatient() {
		this.closeDeleteConfirmation();
		await this.props.removePatient(this.state.patientToDelete.id);
	}

	closeDeleteConfirmation() {
		this.setState({ showDeleteConfirmation: false, patientToDelete: {} });
	}

	render() {
		const { classes } = this.props;
		const { isLoading, notFoundMessage } = this.state;

		const patients = this.getPatientList();

		return (
			<Page width={816} backgroundColor="#EFF2F5" paddingTop={128}>
				<Grid container justify="center">
					<Grid item xs={12}>
						<h1 className={classes.title}>Pacientes cadastrados</h1>
					</Grid>
					<Grid item xs={12}>
						<SearchBar onSearch={this.handleSearch} />
					</Grid>
					<Grid item xs={12}>
						<PatientList
							patients={patients}
							onDelete={this.handleDelete}
							notFoundMessage={notFoundMessage}
						/>
					</Grid>
					{isLoading && (
						<Grid item>
							<CircularProgress className={classes.loader} />
						</Grid>
					)}
				</Grid>
				<ConfirmationDialog
					cancelText="Cancelar"
					confirmText="Confirmar"
					onClose={this.closeDeleteConfirmation}
					onConfirm={this.deletePatient}
					show={this.state.showDeleteConfirmation}
					title="Confirmar a exclusão"
				>
					{`Você tem certeza excluir o paciente `}
					<strong>
						{this.state.patientToDelete && this.state.patientToDelete.name}
					</strong>
					?
				</ConfirmationDialog>
			</Page>
		);
	}
}

const mapStateToProps = state => ({
	patients: state.patient.entries
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			getAllPatients,
			removePatient
		},
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Patient));
