import React, { Component } from "react";

import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

import {
  getSinglePrescription,
  updatePrescription
} from "../../../actions/prescription";

import { isAuthenticated } from "../../../utils/authCheck";

import { getUserProfile } from "../../../actions/user";
import * as pictograms from "../../../config/pictograms";
import { translatePeriodName } from "../../../data/prescription";
import { translateUserTypeToPTBR } from "../../../data/user";

import ClinicModal from "../../Clinic/ClinicModal";
import ToggleButton from "../../common/button/ToggleButton";

import { renderPictogram } from "../utils/pictogram";

import { rem } from "polished";

import Alert from "react-s-alert";
import * as ALERT from "../../../data/alerts";

import { Button, Grid, Menu, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  KeyboardArrowLeft as BackIcon,
  ArrowDropDown as DownIcon
} from "@material-ui/icons";
import classNames from "classnames";

import logoSUS from "../../../../img/logo_sus.svg";

const formatDate = date => moment(date).format("DD/MM/YYYY");

const upperCaseFirstLetter = string =>
  string.charAt(0).toUpperCase() + string.slice(1);

const styles = theme => ({
  prescriptionHeader: {
    padding: "25px 20px"
  },
  prescriptionContent: {
    padding: "20px",
    marginTop: "5px !important"
  },
  prescriptionLabel: {
    fontSize: "24px",
    fontWeight: 700,
    color: "#7C7CE4",
    marginLeft: theme.spacing(2)
  },
  textButton: {
    border: "1px solid #B0B0B0",
    color: "#B0B0B0",
    height: "38px",
    borderRadius: "20px",
    textTransform: "capitalize",
    paddingLeft: "8px !important",
    "&:hover": {
      backgroundColor: "#8080EB",
      borderColor: "#8080EB",
      color: "#fff"
    }
  },
  menuButton: {
    paddingRight: "0 !important",
    color: "#959595",
    textTransform: "none"
  },
  button: {
    border: "1px solid rgba(0, 0, 0, 0.54)",
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.54)",
    height: "38px",
    borderRadius: "20px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#8080EB",
      borderColor: "#8080EB",
      color: "#fff"
    }
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  page: {
    minWidth: rem(800),
    maxWidth: rem(800),
    margin: "0 auto"
  },
  topBar: {
    padding: "28px 0 "
  },
  editLocalButton: {
    textAlign: "right",
    paddingTop: "20px"
  },
  row: {
    borderBottom: ".5px solid #e0e0e0",
    padding: "10px 20px"
  },
  treatment: {
    display: "flex",
    justifyContent: "space-between",
    textAlign: "center"
  },
  flex: {
    flex: 1
  },
  editButton: {
    textAlign: "right"
  },
  medicationRow: {
    fontSize: "15px"
  },
  period: {
    fontWeight: 700,
    fontSize: "18px",
    width: "180px"
  },
  periodPictogram: {
    maxHeight: "45px",
    width: "45px",
    margin: "0 10px"
  },
  medication: {
    fontWeight: "normal"
  },
  doseAmount: {
    width: "63px",
    textAlign: "right"
  },
  amountPictogram: {
    maxHeight: "35px",
    width: "35px",
    margin: "0 10px"
  }
});

const mapStateToProps = state => ({
  prescription: state.prescription,
  requestedPrescription: state.prescription.saved,
  user: state.user,
  currentMedication: state.currentMedication
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSinglePrescription,
      getUserProfile,
      updatePrescription
    },
    dispatch
  );

class ViewPrescriptionEasy extends Component {
  static propTypes = {
    prescriptionId: PropTypes.string,
    requestedPrescription: PropTypes.object,
    getSinglePrescription: PropTypes.func,
    user: PropTypes.object,
    getUserProfile: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      anchorEl: null,
      showClinicModal: false
    };

    this.showClinicModal = this.showClinicModal.bind(this);
    this.closeClinicModal = this.closeClinicModal.bind(this);
    this.closeAndSaveClinicModal = this.closeAndSaveClinicModal.bind(this);
    this.remakePrescription = this.remakePrescription.bind(this);
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  async componentWillMount() {
    const authenticated = isAuthenticated();
    if (authenticated) {
      const { prescriptionId } = this.props;
      if (!prescriptionId) return;

      await this.props.getSinglePrescription(prescriptionId);

      this.setState({
        patientId: this.props.prescription.saved.patient.id,
        medications: this.props.prescription.saved.medications
      });

      await this.props.getSinglePrescription(prescriptionId, "easy");
      await this.props.getUserProfile();
      this.setState({
        loading: false
      });
    }
  }

  print(event) {
    event.preventDefault();
    window.print();
  }

  showClinicModal() {
    this.setState({
      showClinicModal: true
    });
  }

  closeClinicModal(event, save = false) {
    event.preventDefault();
    this.setState({ showClinicModal: false }, () => {
      if (save) {
        if (
          this.props.requestedPrescription.header.clinicId !==
          this.props.user.currentClinic
        ) {
          this.remakePrescription(this.props.prescriptionId);
        }
      }
    });
  }

  closeAndSaveClinicModal(event) {
    event.preventDefault();
    this.closeClinicModal(event, true);
  }

  async remakePrescription(prescriptionId) {
    let entries = [];
    this.state.medications.forEach(medication =>
      entries.push({
        doses: medication.doses.map(dose => ({
          amount: { value: dose.amount },
          frequency: { value: dose.frequency.id }
        })),
        form_id: medication.form.id,
        medicationName: medication.medication.name,
        medicationRoute: medication.form.route,
        medication_id: medication.medication.id,
        notes: medication.notes,
        treatment_days: { value: medication.treatment_days },
        unit: medication.form.unit
      })
    );

    const data = {
      patient_id: this.state.patientId,
      medications: entries.map(entry => {
        const dataToSend = {
          medicationName: entry.medicationName,
          treatment_days: entry.treatment_days.value,
          medication_id: entry.medication_id,
          form_id: entry.form_id,
          notes: entry.notes,
          doses: entry.doses.map(dose => ({
            amount: dose.amount.value,
            frequency_id: dose.frequency.value
          }))
        };

        return dataToSend;
      })
    };

    this.setState(
      {
        loading: true
      },
      async () => {
        const { error } = await this.props.updatePrescription(
          data,
          prescriptionId
        );

        if (error) {
          Alert.error(ALERT.prescription.updateError, { offset: 39 });
        } else {
          //Alert.success(ALERT.prescription.updated, { offset: 39 });
          await this.props.getSinglePrescription(prescriptionId, "easy");
          this.setState({ loading: false });
        }
      }
    );
  }

  getRegistrationCouncil(user_type) {
    switch (user_type) {
      case "pharmacist":
        return "CRF";
      case "physician":
        return "CRM";
      case "nurse":
        return "COREN";
      case "nurse-technician":
        return "COREN";
      default:
        return "";
    }
  }

  render() {
    const { anchorEl } = this.state;
    const { classes } = this.props;
    const { requestedPrescription, user } = this.props;

    if (this.state.loading) {
      return <div />;
    }

    const { header, footer, groups } = requestedPrescription;

    return (
      <div className="page-with-padding-top">
        <Grid
          container
          className={classNames(classes.page, classes.topBar, "noPrint")}
          justify="space-between"
        >
          <Grid item>
            <Grid container>
              <Grid item>
                <Button
                  className={classNames(classes.textButton, classes.iconButton)}
                  aria-label="Voltar"
                  component={Link}
                  to={`/receitas/${this.props.prescriptionId}`}
                >
                  <BackIcon className={classes.leftIcon} />
                  Voltar
                </Button>
              </Grid>
              <Grid item>
                <div className={classes.prescriptionLabel}>
                  Essa é sua receita
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              aria-owns={anchorEl ? "simple-menu" : null}
              aria-haspopup="true"
              onClick={this.handleClick}
              className={classes.menuButton}
            >
              Você está no modelo otimizado
              <DownIcon className={classes.rightIcon} />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
            >
              <MenuItem
                component={Link}
                to={`/visualizar-receita?id=${this.props.prescriptionId}`}
              >
                Ir para modelo tradicional
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>

        <div className="prescription-view">
          <div className="watermark">
            <img src={logoSUS} alt="Logotipo SUS" />
          </div>
          <Grid
            container
            justify="space-between"
            className={classNames(
              classes.page,
              classes.prescriptionHeader,
              "transparentOnPrint"
            )}
          >
            <Grid item>
              <p>
                {translateUserTypeToPTBR(user.user_type, user.gender)}:{" "}
                {header.physician}
              </p>
              <p>
                Local: {header.clinic} - {header.address}
              </p>
              <p>
                Paciente: <strong>{header.patient}</strong>
              </p>
              {header.patient_address && (
                <p>
                  Endereço: <strong>{header.patient_address}</strong>
                </p>
              )}
            </Grid>
            <Grid
              item
              className={classNames(classes.editLocalButton, "noPrint")}
            >
              <ToggleButton
                style={{ margin: 0 }}
                hoverStyle={{ width: 140 }}
                icon="edit"
                label="Editar Local"
                onClick={this.showClinicModal}
              />
            </Grid>
          </Grid>
          <div
            className={classNames(
              classes.page,
              classes.prescriptionContent,
              "transparentOnPrint"
            )}
          >
            {groups.map((group, key) => {
              let days = "Todos os dias";
              if (group.treatment_days) {
                days = `Tomar por ${group.treatment_days} dias`;
              } else {
                days =
                  group.treatment_description &&
                  group.treatment_description !== "uso contínuo"
                    ? upperCaseFirstLetter(group.treatment_description)
                    : days;
              }

              return (
                <Grid container key={key} className="routes-container">
                  <Grid item xs={12} className={classes.row}>
                    <div className={classes.treatment}>
                      <div className={classNames(classes.flex, "noPrint")} />
                      <div className={classNames(classes.flex, "routes-days")}>
                        {days}
                      </div>
                      <div
                        className={classNames(
                          classes.flex,
                          classes.editButton,
                          "noPrint"
                        )}
                      >
                        {key === 0 && (
                          <ToggleButton
                            style={{ margin: 0 }}
                            hoverStyle={{ width: 100 }}
                            icon="edit"
                            label="Editar"
                            component={Link}
                            to={`/receitas/${this.props.prescriptionId}`}
                          />
                        )}
                      </div>
                    </div>
                  </Grid>
                  {group.periods.map((item, lineIndex) => (
                    <Grid
                      item
                      xs={12}
                      key={lineIndex}
                      className={classNames(classes.row, classes.medicationRow)}
                    >
                      <Grid container alignItems="center" spacing={10}>
                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            className={classes.period}
                          >
                            <Grid item>
                              <img
                                className={classes.periodPictogram}
                                src={pictograms[item.pictogram]}
                                alt=" "
                              />
                            </Grid>
                            <Grid item>{translatePeriodName(item.name)}</Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            justify="center"
                            spacing={3}
                            className={classes.medication}
                          >
                            {item.medications.map(
                              (medication, medicationKey) => (
                                <Grid item key={medicationKey}>
                                  <Grid
                                    container
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    {medication.amount && (
                                      <Grid item className={classes.doseAmount}>
                                        {medication.amount}
                                        {medication.doseText &&
                                          ` ${medication.doseText}`}
                                      </Grid>
                                    )}
                                    {pictograms[medication.pictogram] && (
                                      <Grid item>
                                        <img
                                          src={renderPictogram(medication)}
                                          alt=" "
                                          className={classes.amountPictogram}
                                        />
                                      </Grid>
                                    )}
                                    <Grid item>
                                      <Grid container direction="column">
                                        <Grid item>{`${medication.name}`}</Grid>
                                        {medication.notes && (
                                          <Grid
                                            item
                                            className="txt-italic"
                                          >{`${medication.notes}`}</Grid>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              );
            })}

            <div className="prescription-metadata">
              <div className="routes">
                <h1>Como tomar</h1>
                <ul>
                  {groups.map((group, key) =>
                    group.routes.map((item, groupIndex) => (
                      <li key={groupIndex}>
                        {pictograms[item.pictogram] && (
                          <div>
                            <img src={pictograms[item.pictogram]} alt=" " />
                          </div>
                        )}
                        <div>{item.route}</div>
                      </li>
                    ))
                  )}
                </ul>
              </div>
              <div>
                <div>{formatDate(requestedPrescription.updated)}</div>
                <div className="prescription-metadata__signature">
                  <p>
                    {user.full_name} -{" "}
                    {this.getRegistrationCouncil(user.user_type)}
                    {user.user_type !== "student"
                      ? user.registration.state + " " + user.registration.number
                      : ""}
                  </p>
                </div>
              </div>
            </div>

            <div className="prescription-footer">
              <p>
                {`${footer.clinic} - ${footer.patient}`}
                {footer.medications.map((item, key) => (
                  <span key={key}>
                    {` - ${item.treatment_days}: `}
                    {item.medications.map((med, medKey) =>
                      medKey % 2 === 0 ? (
                        <span key={medKey}>
                          <strong>{`${med}. `}</strong>
                        </span>
                      ) : (
                        <span key={medKey}>{`${med}. `}</span>
                      )
                    )}
                  </span>
                ))}
              </p>
            </div>
          </div>
        </div>
        <ClinicModal
          show={this.state.showClinicModal}
          onClose={this.closeClinicModal}
          onSave={this.closeAndSaveClinicModal}
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ViewPrescriptionEasy));
