import React, { PureComponent } from "react";
import classNames from "classnames";

import { partial } from "lodash";

import { rem } from "polished";
import ScrollableAnchor, {
	goToAnchor,
	configureAnchors
} from "react-scrollable-anchor";

import RoundedButton from "../Button";
import RoundedButtonIcon from "../Icon";
import RenewPrescriptionForm from "./RenewPrescription/RenewPrescriptionForm";

import {
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	Grid,
	Collapse
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import { getFrequencyOptions } from "../../data/prescription";

import ToggleButton from "../common/button/ToggleButton";

const styles = theme => ({
	root: {
		marginTop: rem(25),
		width: "100%"
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular
	},
	table: {
		minWidth: 700,
		margin: "10px 0 0 0"
	},
	footer: {
		borderWidth: "1px",
		borderStyle: "solid",
		borderColor: "#dfe8ec",
		backgroundColor: "#E8EEF3",
		textAlign: "center"
	},
	footerButton: {
		margin: "15px 0"
	},
	row: {
		width: "100%",
		maxWidth: rem(800),
		margin: "0 auto",
		padding: 0,
		borderWidth: "1px 1px 0px 1px",
		borderStyle: "solid",
		borderColor: "#dfe8ec",
		"&:hover": {
			backgroundColor: "#E8EEF3"
		}
	},
	cellName: {
		padding: "15px 25px 0 25px",
		fontWeight: 500
	},

	cellNameObservation: {
		padding: "0px 25px 15px 25px"
	},
	tableTitle: {
		fontSize: "14px",
		color: "#8788e5"
	},
	iconTableTitle: {
		float: "left",
		marginRight: "5px",
		transform: "rotate(90deg)"
	},
	expansionPanel: {
		padding: 0,
		margin: 0,
		"&:before": {
			position: "relative"
		}
	},
	pageContent: {
		width: "100%",
		maxWidth: rem(800),
		margin: "0 auto"
	},
	form: {
		border: "1px solid #dfe8ec",
		backgroundColor: "#E8EEF3",
		textAlign: "center"
	},

	editForm: {
		border: "1px solid #dfe8ec",
		backgroundColor: "#FBF8D6",
		textAlign: "center"
	},

	centeredButton: {
		margin: "20px 0"
	},
	noMargin: {
		margin: 0
	}
});

class MedicationList extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			expanded: false,
			expandedPanel: null
		};

		this.enterKeyPress = this.enterKeyPress.bind(this);
		this.renderAddButton = this.renderAddButton.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
		this.onAdd = this.onAdd.bind(this);
		this.expandMedicationPanel = this.expandMedicationPanel.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.onCancelUpdateAndAdd = this.onCancelUpdateAndAdd.bind(this);
	}

	componentDidMount() {
		document.addEventListener("keydown", this.enterKeyPress, false);
		configureAnchors({ offset: -65, scrollDuration: 400 });
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.enterKeyPress, false);
	}

	enterKeyPress(event) {
		if (
			event.key === "Enter" &&
			!this.props.showAddPanel &&
			!this.props.clinicModalisOpen
		) {
			this.props.onAdd(event);
		}
	}

	renderAddButton() {
		const { classes } = this.props;

		return (
			<div className={classes.footer}>
				<RoundedButton
					type="bordered-primary"
					className={classes.footerButton}
					onClick={this.onAdd}
					onKeyPress={this.handleKeyPress}
				>
					<RoundedButtonIcon name="add" />
					<span>Adicione um medicamento</span>
				</RoundedButton>
			</div>
		);
	}

	onUpdate(event, index) {
		event.preventDefault();
		if (this.state.expanded) {
			configureAnchors({ offset: -565, scrollDuration: 400 });
			this.setState(
				{
					expandedPanel: null,
					expanded: false
				},
				() => this.expandMedicationPanel(event, index)
			);
		} else {
			configureAnchors({ offset: -65, scrollDuration: 400 });
			this.expandMedicationPanel(event, index);
		}
	}

	onAdd(event) {
		if (event) event.preventDefault();
		if (this.state.expanded) {
			this.onCancelUpdateAndAdd(event);
		} else {
			this.props.onAdd(event);
		}
	}

	expandMedicationPanel(event, index) {
		this.setState(
			{
				expandedPanel: index,
				expanded: true
			},
			() => {
				if (this.props.showAddPanel) {
					this.props.onCancel();
				}
				this.props.onUpdate(index, event);
				goToAnchor("medicationForm-" + index);
			}
		);
	}

	onCancel() {
		this.setState(
			{
				expandedPanel: null,
				expanded: false
			},
			() => this.props.onCancel()
		);
	}

	onCancelUpdateAndAdd(event) {
		event.preventDefault();
		this.setState(
			{
				expandedPanel: null,
				expanded: false
			},
			() => this.props.cancelUpdateAndAdd()
		);
	}

	getFrequencyLabel(value) {
		const frequencyOptions = getFrequencyOptions();
		const frequency = frequencyOptions.find(
			frequency => frequency.value === value.value
		);

		return frequency.label;
	}

	render() {
		const {
			entryNameAttribute,
			onDelete,
			classes,
			entries,
			title,
			showTitle,
			showAddPanel
		} = this.props;

		const { expanded, expandedPanel } = this.state;

		const { renderAddButton } = this;

		return (
			<div className={classes.root}>
				{entries.length > 0 && (
					<div>
						<div
							className={classNames(classes.pageContent, classes.tableTitle)}
						>
							{showTitle && <p>{title}</p>}
						</div>

						<div className={classes.table}>
							{entries.map((entry, index) => (
								<ExpansionPanel
									classes={{
										root: classes.expansionPanel,
										expanded: classes.noMargin
									}}
									elevation={0}
									key={index}
									expanded={expanded ? expandedPanel === index : false}
								>
									{(!expanded || (expanded && expandedPanel !== index)) && (
										<ExpansionPanelSummary
											classes={{
												root: classes.row,
												content: classes.noMarginAndPadding
											}}
										>
											<Grid container justify="space-between">
												<Grid item>
													<Grid
														container
														direction="row"
														justify="flex-start"
														alignItems="flex-start"
													>
														<Grid item xs={12} className={classes.cellName}>
															{entry[entryNameAttribute]}
														</Grid>
														<Grid
															xs={12}
															item
															className={classes.cellNameObservation}
														>
															{entry.doses[0].amount +
																" " +
																entry.unit +
																" - " +
																this.getFrequencyLabel(
																	entry.doses[0].frequency
																)}
														</Grid>
													</Grid>
												</Grid>
												<Grid item>
													<ToggleButton
														hoverStyle={{ width: 100 }}
														icon="edit"
														label="Editar"
														onClick={event => this.onUpdate(event, index)}
													/>
													<ToggleButton
														hoverStyle={{ width: 105 }}
														isDelete
														icon="delete"
														label="Excluir"
														onClick={partial(onDelete, index)}
													/>
												</Grid>
											</Grid>
										</ExpansionPanelSummary>
									)}
									<ScrollableAnchor id={"medicationForm-" + index}>
										<ExpansionPanelDetails classes={{ root: classes.editForm }}>
											<RenewPrescriptionForm
												isEditMode
												show={expanded ? expandedPanel === index : false}
												onCancel={this.onCancel}
												onError={this.props.onError}
											/>
										</ExpansionPanelDetails>
									</ScrollableAnchor>
								</ExpansionPanel>
							))}
						</div>
						<Collapse in={!showAddPanel}>
							<div className={classes.pageContent}>{renderAddButton()}</div>
						</Collapse>
					</div>
				)}

				<ScrollableAnchor id="medicationForm">
					<Collapse in={showAddPanel}>
						<div className={classes.form}>
							{showAddPanel && (
								<RenewPrescriptionForm
									show={showAddPanel}
									onCancel={this.onCancel}
									onError={this.props.onError}
								/>
							)}
						</div>
					</Collapse>
				</ScrollableAnchor>

				<Collapse in={!showAddPanel && entries.length === 0}>
					<div className={classes.form}>
						<RoundedButton
							type="bordered-primary"
							className={classes.centeredButton}
							onKeyPress={this.handleKeyPress}
							onClick={this.onAdd}
						>
							<RoundedButtonIcon name="add" />
							<span>Adicione um medicamento</span>
						</RoundedButton>
					</div>
				</Collapse>
			</div>
		);
	}
}

export default withStyles(styles)(MedicationList);
