const styles = theme => ({
  root: {
    height: "100vh",
    width: "100%",
    backgroundImage: "linear-gradient(to top right, #42C39B, #90E5CB)",
    color: "#FFFFFF",
    textAlign: "center"
  },
  text: {
    padding: "0 15%"
  },
  title: {
    fontFamily: "Bree Serif, serif",
    fontSize: "2.375rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.625rem"
    }
  },
  subTitle: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "1.625rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: "2rem",
      fontSize: "1.25rem"
    }
  },
  logo: {
    width: "10.1rem",
    height: "2.88rem",
    marginTop: "3rem",
    [theme.breakpoints.down("sm")]: {
      marginTop: ".875rem"
    }
  },
  email: {
    color: "#372F4E"
  },
  faq: {
    display: "block",
    marginTop: "2rem",
    fontFamily: "Montserrat, sans-serif",
    fontSize: ".875rem",
    color: "#FDFD13"
  }
});

export default styles;
