import PropTypes from 'prop-types';
import React from 'react';

const Table = (props) => (
  <div className="table-wrapper">
    <div className="table-responsive">
      <table className="table">
       {props.children}
      </table>
    </div>
  </div>
)

Table.propTypes = {
  children: PropTypes.node,
}


export default Table
