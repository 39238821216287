export const VOCATIVE_OPTIONS = [
  {
    value: "",
    label: "Sem vocativo"
  },
  {
    value: "Dr.",
    label: "Dr."
  },
  {
    value: "Dra.",
    label: "Dra."
  }
];

export const GENDER_OPTIONS = [
  {
    value: "",
    label: "Gênero"
  },
  {
    value: "M",
    label: "Masculino"
  },
  {
    value: "F",
    label: "Feminino"
  }
];

export const VOCATIVES = [
  {
    value: "Dra.",
    label: "Dra."
  },
  {
    value: "Dr.",
    label: "Dr."
  },
  {
    value: "",
    label: "Sem vocativo"
  }
];

export const USER_TYPES = [
  {
    value: "physician",
    label: "Eu atuo como médico(a)"
  },
  {
    value: "pharmacist",
    label: "Eu atuo como farmacêutico(a)"
  },
  {
    value: "nurse",
    label: "Eu atuo como enfermeiro(a)"
  },
  {
    value: "nurse-technician",
    label: "Eu atuo como técnico(a) de enfermagem"
  },
  {
    value: "student",
    label: "Eu atuo como estudante"
  }
];

export const SPECIALTIES = [
  {
    value: "general-clinic",
    label: "Clínica Geral"
  },
  {
    value: "cardiology",
    label: "Cardiologia"
  },
  {
    value: "geriatrics",
    label: "Geriatria"
  },
  {
    value: "nephrology",
    label: "Nefrologia"
  },
  {
    value: "psychiatry",
    label: "Psiquiatria"
  },
  {
    value: "other",
    label: "Outra"
  }
];

export const OCUPATIONS = [
  "physician",
  "pharmacist",
  "nurse",
  "nurse-technician",
  "student"
];

export const MEDICAL_SPECIALTIES = SPECIALTIES.map(
  specialty => specialty.value
);

export const STATES = [
  "AC",
  "AL",
  "AM",
  "AP",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MG",
  "MS",
  "MT",
  "PA",
  "PB",
  "PE",
  "PI",
  "PR",
  "RJ",
  "RN",
  "RO",
  "RR",
  "RS",
  "SC",
  "SE",
  "SP",
  "TO"
];

export const STATE_UF = [
  {
    value: "AC",
    label: "AC"
  },
  {
    value: "AL",
    label: "AL"
  },
  {
    value: "AM",
    label: "AM"
  },
  {
    value: "AP",
    label: "AP"
  },
  {
    value: "BA",
    label: "BA"
  },
  {
    value: "CE",
    label: "CE"
  },
  {
    value: "DF",
    label: "DF"
  },
  {
    value: "ES",
    label: "ES"
  },
  {
    value: "GO",
    label: "GO"
  },
  {
    value: "MA",
    label: "MA"
  },
  {
    value: "MG",
    label: "MG"
  },
  {
    value: "MS",
    label: "MS"
  },
  {
    value: "MT",
    label: "MT"
  },
  {
    value: "PA",
    label: "PA"
  },
  {
    value: "PB",
    label: "PB"
  },
  {
    value: "PE",
    label: "PE"
  },
  {
    value: "PI",
    label: "PI"
  },
  {
    value: "PR",
    label: "PR"
  },
  {
    value: "RJ",
    label: "RJ"
  },
  {
    value: "RN",
    label: "RN"
  },
  {
    value: "RO",
    label: "RO"
  },
  {
    value: "RR",
    label: "RR"
  },
  {
    value: "RS",
    label: "RS"
  },
  {
    value: "SC",
    label: "SC"
  },
  {
    value: "SE",
    label: "SE"
  },
  {
    value: "SP",
    label: "SP"
  },
  {
    value: "TO",
    label: "TO"
  }
];
