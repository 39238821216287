import createReducer from '../utils/createReducer'
import { SET_FORM_IN_PROGRESS } from '../constants/'


const INITIAL_STATE = {
  isFormInProgress: false,
}

const prescription = createReducer(INITIAL_STATE, {
  [SET_FORM_IN_PROGRESS]: (state, action) => (
    Object.assign({}, state, {
      isFormInProgress: action.payload,
    })
  ),
})


export default prescription
