import { rem } from "polished";

const styles = () => ({
  current: {
    backgroundColor: "#FFFFFF",
    color: "#372F4E",
    fontFamily: "Montserrat, sans-serif",
    fontSize: rem(14),
    padding: rem(12),
    border: `${rem(3)} solid #8080EC`,
    borderRadius: rem(4),
    minHeight: rem(320)
  },
  standard: {
    backgroundColor: "#E7ECF0",
    color: "#372F4E",
    fontFamily: "Montserrat, sans-serif",
    fontSize: rem(14),
    padding: rem(12),
    borderRadius: rem(4),
    minHeight: rem(320)
  },
  add: {
    minHeight: rem(320),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: rem(4)
  },
  illustration: {
    minHeight: rem(320),
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  select: {
    textAlign: "right"
  },
  checkbox: {
    padding: 0
  },
  content: {
    padding: `0 ${rem(28)}`,
    margin: `${rem(42)} 0`
  },
  buttons: {
    padding: `0 ${rem(28)}`
  },
  title: {
    fontWeight: "bold"
  }
});

export default styles;
