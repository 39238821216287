import React, { PureComponent } from "react";

import { withRouter } from "react-router-dom";

import { isAuthenticated } from "../js/utils/authCheck";

import SignUp from "../components/SignUp";

class SignUpScreen extends PureComponent {
	componentWillMount() {
		if (isAuthenticated()) {
			this.props.history.push("/inicio");
			return;
		}
	}

	render() {
		return <SignUp />;
	}
}

export default withRouter(SignUpScreen);
