import React from "react";

import { withRouter } from "react-router-dom";
import { rem } from "polished";
import { Checkbox, Grid, withStyles } from "@material-ui/core";

import ToggleButton from "../../../../js/components/common/button/ToggleButton";
import FlatButton from "../../../UI/FlatButton";

import WorkplacesIllustration from "../../../../img/profile/workplaces.svg";

import styles from "./Style";

const ProfileWorkplaceList = ({
	classes,
	items,
	currentId,
	onDelete,
	onSelected,
	history
}) => {
	return (
		<Grid container justify="center" alignItems="center" spacing={2}>
			{items.map(workplace => {
				const { name, address, id } = workplace;
				const current = id === currentId;

				return (
					<Grid item xs={6}>
						<Grid
							container
							direction="column"
							className={current ? classes.current : classes.standard}
						>
							<Grid item className={classes.select}>
								<Checkbox
									color="primary"
									checked={current}
									onChange={e => onSelected(workplace)}
									value="checkedA"
									classes={{
										root: classes.checkbox,
										checked: classes.checkbox
									}}
								/>
							</Grid>
							<Grid item className={classes.content}>
								<Grid container direction="column">
									<Grid item className={classes.title}>
										{name}
									</Grid>
									<Grid item>
										{`${address.street}, ${address.number} | ${
											address.district
										}`}
									</Grid>
									<Grid item>{`${address.city}, ${address.state}`}</Grid>
									<Grid item>{`Telefone: ${address.phone}`}</Grid>
									<Grid item>{`CEP: ${address.zip}`}</Grid>
								</Grid>
							</Grid>
							<Grid item className={classes.buttons}>
								<ToggleButton
									icon="edit"
									showLabel
									label="Editar"
									style={{ marginLeft: 0 }}
									onClick={() => {
										history.push(
											`/meu-perfil/local-de-trabalho/atualizar/${id}`
										);
									}}
								/>
								<ToggleButton
									icon="delete"
									showLabel
									isDelete
									label="Excluir"
									style={{ marginLeft: rem(30) }}
									onClick={() => onDelete(id)}
								/>
							</Grid>
						</Grid>
					</Grid>
				);
			})}
			<Grid item xs={6} className={classes.add}>
				<FlatButton
					to="/meu-perfil/local-de-trabalho/novo"
					text="Crie um novo local"
					icon="add_location"
					customStyle={{
						color: "#9AAFBF",
						border: "2px solid #9AAFBF"
					}}
				/>
			</Grid>
			<Grid item xs={6} className={classes.illustration}>
				<img src={WorkplacesIllustration} alt="workplaces" />
			</Grid>
		</Grid>
	);
};

export default withRouter(withStyles(styles)(ProfileWorkplaceList));
