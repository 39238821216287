import React, { PureComponent } from "react";

import { bindActionCreators } from "redux";

import { connect } from "react-redux";

import ScrollableAnchor, {
	goToAnchor,
	configureAnchors
} from "react-scrollable-anchor";
import ReactGA from "react-ga";

import {
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	Grid
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Close, Done } from "@material-ui/icons";

import {
	addMedication,
	getSinglePrescription,
	savePrescription,
	updatePrescription,
	clearPrescription,
	removeMedication
} from "../../../../js/actions/prescription";

import {
	isEditMedicationMode,
	currentMedication
} from "../../../../js/actions/medication";
import { getPatient } from "../../../../js/actions/patient";
import { setFormInProgress } from "../../../../js/actions/ui-prescription";

import { getFrequencyOptions } from "../../../../js/data/prescription";

import Form from "../Form";

import styles from "./Style";

class MedicationList extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			expanded: false,
			expandedPanel: null
		};

		this.onUpdate = this.onUpdate.bind(this);
		this.onAdd = this.onAdd.bind(this);
		this.expandMedicationPanel = this.expandMedicationPanel.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.onCancelUpdateAndAdd = this.onCancelUpdateAndAdd.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
	}

	componentDidMount() {
		configureAnchors({ offset: -65, scrollDuration: 400 });
	}

	async onUpdate(event, index) {
		event.preventDefault();

		this.props.onEditFormShow();

		if (this.state.expanded) {
			configureAnchors({ offset: -565, scrollDuration: 400 });
			await this.setState({
				expandedPanel: null,
				expanded: false
			});
			this.expandMedicationPanel(index);
		} else {
			configureAnchors({ offset: -65, scrollDuration: 400 });
			this.expandMedicationPanel(index);
		}
	}

	onAdd(event) {
		if (event) event.preventDefault();
		if (this.state.expanded) {
			this.onCancelUpdateAndAdd(event);
		} else {
			this.props.onAdd(event);
		}
	}

	async expandMedicationPanel(index) {
		await this.setState({
			expandedPanel: index,
			expanded: true
		});

		if (this.props.showAddPanel) {
			this.props.onCancel();
		}

		this.fullfillForm(index);
		goToAnchor("medicationForm-" + index);
	}

	onCancel() {
		this.setState({
			expandedPanel: null,
			expanded: false
		});
		this.props.onEditFormHide();
	}

	onCancelUpdateAndAdd(event) {
		event.preventDefault();
		this.setState(
			{
				expandedPanel: null,
				expanded: false
			},
			() => this.props.cancelUpdateAndAdd()
		);
	}

	getFrequencyLabel(value) {
		const frequencyOptions = getFrequencyOptions();
		const frequency = frequencyOptions.find(
			frequency => frequency.value === value.value
		);

		return frequency.label;
	}

	fullfillForm(id) {
		const { editPrescriptionId } = this.props.medication.editMedicationMode;

		if (editPrescriptionId === id) return;

		this.props.setFormInProgress(true);
		this.editMedication(id);
	}

	editMedication(index) {
		const data = {
			isEditMode: true,
			editPrescriptionId: index
		};

		this.props.isEditMedicationMode(data);
	}

	handleDelete(index) {
		this.props.removeMedication(index);
		ReactGA.event({
			category: "prescription-creation",
			action: "button-click",
			label: "remove-medication"
		});
	}

	render() {
		const { onUpdate } = this;

		const { classes, prescription } = this.props;

		const { expanded, expandedPanel } = this.state;

		const entries =
			prescription && prescription.entries ? prescription.entries : [];

		return (
			entries.length > 0 && (
				<div>
					{prescription.entries.map((entry, index) => (
						<ExpansionPanel
							classes={{
								root: classes.expansionPanel,
								expanded: classes.noMargin
							}}
							elevation={0}
							key={index}
							expanded={expanded ? expandedPanel === index : false}
						>
							{(!expanded || (expanded && expandedPanel !== index)) && (
								<ExpansionPanelSummary
									onClick={event => onUpdate(event, index)}
									classes={{
										root: classes.row,
										content: classes.noMarginAndPadding
									}}
								>
									<Grid container justify="space-between" alignItems="center">
										<Grid item>
											<Grid container spacing={2} alignItems="center">
												<Grid item>
													<Done htmlColor="#42C39B" />
												</Grid>
												<Grid item>
													<Grid container alignItems="center">
														<Grid item xs={12} className={classes.cellName}>
															{entry["medicationName"]}
														</Grid>
														<Grid
															xs={12}
															item
															className={classes.cellNameObservation}
														>
															{`${entry.doses[0].amount} ${
																entry.unit
															} - ${this.getFrequencyLabel(
																entry.doses[0].frequency
															)}`}
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										<Grid item>
											<Close
												onClick={e => {
													e.preventDefault();
													e.stopPropagation();
													this.handleDelete(index);
												}}
												htmlColor="#9AAFBF"
												className={classes.deleteIcon}
											/>
										</Grid>
									</Grid>
								</ExpansionPanelSummary>
							)}
							<ScrollableAnchor id={"medicationForm-" + index}>
								<ExpansionPanelDetails className={classes.noPadding}>
									<Form
										isEditMode
										show={expanded ? expandedPanel === index : false}
										onClose={this.onCancel}
										onError={this.props.onError}
									/>
								</ExpansionPanelDetails>
							</ScrollableAnchor>
						</ExpansionPanel>
					))}
				</div>
			)
		);
	}
}

const mapStateToProps = state => {
	const { medication, prescription, uiPrescription, patient, user } = state;

	return {
		user,
		prescription,
		uiPrescription,
		medication,
		selectedPatient: patient.selected,
		selectedMedication: medication.selectedMedication,
		editedMedication: medication.editMedicationMode,
		currentMedication: state.currentMedication
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			savePrescription,
			updatePrescription,
			clearPrescription,
			removeMedication,
			getPatient,
			isEditMedicationMode,
			currentMedication,
			setFormInProgress,
			getSinglePrescription,
			addMedication
		},
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(MedicationList));
