import React from "react";

import { rem } from "polished";

import ProgressButton from "../../UI/ProgressButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { useTheme, makeStyles } from "@material-ui/core/styles";

import Illustration from "../../../img/prescription-creation/tour/finish/illustration.png";

const useStyles = makeStyles({
	dialog: {
		width: rem(800),
		borderRadius: rem(17),
		paddingTop: rem(45),
		paddingBottom: rem(50),
		textAlign: "center"
	},
	title: {
		color: "#8080EC",
		fontFamily: "Bree Serif, serif",
		fontSize: rem(26)
	},
	text: {
		fontSize: rem(16),
		color: "#000"
	},
	buttons: {
		justifyContent: "center"
	},
	closeButton: {
		position: "absolute",
		right: 5,
		top: 5
	}
});

export default function TourFinish(props) {
	const { onClose, show } = props;

	const classes = useStyles(props);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Dialog
			fullScreen={fullScreen}
			open={show}
			onClose={onClose}
			aria-labelledby="responsive-dialog-title"
			classes={{ paper: classes.dialog }}
		>
			<div className={classes.imageTitle}>
				<img src={Illustration} alt="illustration" />
			</div>

			<DialogTitle
				id="responsive-dialog-title"
				disableTypography
				classes={{ root: classes.title }}
			>
				<IconButton
					className={classes.closeButton}
					aria-label="Cancelar"
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
				Parabéns! Você criou sua primeira receita.
			</DialogTitle>
			<DialogContent>
				<DialogContentText className={classes.text}>
					Agora você está pronta(o) para criar receitas que trazem maior
					entendimento e espalhar o novo jeito de fazer saúde por aí!
				</DialogContentText>
			</DialogContent>
			<DialogActions classes={{ root: classes.buttons }}>
				<ProgressButton
					text="Continuar na receita"
					onClick={onClose}
					style={{ width: "210px" }}
				/>
			</DialogActions>
		</Dialog>
	);
}
