import React, { PureComponent } from "react";

import { Link } from "react-router-dom";
import moment from "moment";

import List, { Row, Cell, Button } from "../UI/List";

class PrescriptionList extends PureComponent {
	render() {
		const { notFoundMessage, onDelete, prescriptions } = this.props;

		return (
			<List>
				{prescriptions.map((prescription, index) => (
					<Row key={index} component={Link} to={`/receitas/${prescription.id}`}>
						<Cell xs={3}>{prescription.patient.name}</Cell>
						<Cell>{moment(prescription.updated).format("DD/MM/YYYY")}</Cell>
						<Cell>
							<Button
								to={`/receitas/${prescription.id}/copiar`}
								label="Copiar receita"
								icon="file_copy"
								color="purple"
							/>
							<Button
								to={`/receitas/${prescription.id}`}
								label="Abrir"
								icon="edit"
								color="green"
							/>
							<Button
								label="Excluir"
								icon="delete"
								color="red"
								onClick={() => onDelete(prescription.id)}
							/>
						</Cell>
					</Row>
				))}

				{prescriptions.length === 0 && (
					<Row justify="center" height="64px">
						<Cell>{notFoundMessage}</Cell>
					</Row>
				)}
			</List>
		);
	}
}

export default PrescriptionList;
