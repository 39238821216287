/**
 * Created by tfurtado on 09/09/16.
 */
import axios from "axios";
import { getRefreshToken } from "../utils/authCheck";
import { API_URL } from "../config/api";
import storageService from "../services/storage";
const jwtDecode = require("jwt-decode");

export default {
  refreshAccessToken(promise) {
    const url = `${API_URL}/auth/access`;
    const token = getRefreshToken();

    if (!token) {
      console.error("Refresh token is not valid!");
    }

    return axios({
      method: "post",
      url,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        storageService().set("access-token", response.data.access_token);
        return promise();
      })
      .catch(e => {
        console.error("Error to get access token!");
        storageService().set("refresh-token", null);
        storageService().set("access-token", null);
        throw e;
      });
  },

  getAccessToken() {
    const storedToken = storageService().get("access-token");
    if (!storedToken) {
      return null;
    }

    const decodedToken = jwtDecode(storedToken);
    const curTime = Math.floor(Date.now() / 1000);
    const validData = curTime < decodedToken.exp;

    if (!validData) {
      return null;
    }

    return storedToken;
  }
};
