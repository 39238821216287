import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components'
import { width } from 'styled-system'
import { rem, lighten } from 'polished'

const I = styled.i`
  color: ${props => props.color || 'inherit'};
  font-size: ${props => rem(props.size || 24)};
  transition: all 0.3s;
  &:hover {
    color: ${props => lighten(0.3, props.color || '#333')};
  }
  ${width}
`

const Icon = ({ name, size, color, onClick }) => (
  <I
    className="material-icons"
    onClick={onClick}
    size={size}
    color={color}
  >{name}</I>
)

Icon.propTypes = {
  name: PropTypes.any.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  onClick: PropTypes.func,
}


export default Icon
