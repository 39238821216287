import createReducer from '../utils/createReducer'
import { IS_LOADING } from '../constants/'


const INITIAL_STATE = {
  isLoading: false,
}

const loader = createReducer(INITIAL_STATE, {
  [IS_LOADING]: (state, action) => (
    Object.assign({}, state, {
      isLoading: action.payload.isLoading,
    })
  ),
})


export default loader
