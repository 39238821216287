import React from "react";
import PropTypes from "prop-types";

import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";

import "react-redux-toastr/lib/css/react-redux-toastr.min.css";

import Routes from "../routes";
import Main from "../components/Main";

const Root = ({ store }) => (
  <Provider store={store}>
    <Main>
      <Routes />
      <ReduxToastr />
    </Main>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default Root;
