import React, { PureComponent, Fragment } from "react";

import ReactGA from "react-ga";

import { pick, isEmpty } from "lodash";
import CryptoJS from "crypto-js";
import { Formik } from "formik";
import * as Yup from "yup";

import Form from "./Form";

import withWidth, { isWidthDown } from "@material-ui/core/withWidth";

import SplitLayout from "../../UI/layout/SplitWithIllustration";

import illustrationUnverifiedSVG from "../../../img/illustrations/account-0.svg";
import illustrationOneLockSVG from "../../../img/illustrations/account-1.svg";
import illustrationTwoLockSVG from "../../../img/illustrations/account-2.svg";
import illustrationVerifiedSVG from "../../../img/illustrations/account-3.svg";

import illustrationMiniSVG from "../../../img/illustrations/account-mini.svg";

class SignUpAccount extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			completedSteps: 0,
			showPassword: false,
			password: ""
		};

		this.ACCOUNT_FIELDS = ["email", "password"];

		this.handleVerifiedSteps = this.handleVerifiedSteps.bind(this);
		this.handlePassword = this.handlePassword.bind(this);
	}

	handleVerifiedSteps(completedSteps) {
		this.setState({ completedSteps: completedSteps });
	}

	handlePassword(password) {
		this.setState({
			showPassword: password ? true : false,
			password: password
		});
	}

	getIllustration() {
		switch (this.state.completedSteps) {
			case 0:
				return illustrationUnverifiedSVG;
			case 1:
				return illustrationOneLockSVG;
			case 2:
				return illustrationTwoLockSVG;
			case 3:
				return illustrationVerifiedSVG;

			default:
				return illustrationUnverifiedSVG;
		}
	}

	getInitialValues() {
		const receivedData = { ...pick(this.props.data, this.ACCOUNT_FIELDS) };

		if (!isEmpty(receivedData)) {
			const decryptedPass = this.decryptPass(receivedData.password);
			return {
				email: receivedData.email,
				password: decryptedPass,
				passwordConfirmation: decryptedPass
			};
		}

		return {
			email: "",
			password: "",
			passwordConfirmation: ""
		};
	}

	isInitialValuesValid() {
		const receivedData = { ...pick(this.props.data, this.ACCOUNT_FIELDS) };

		return !isEmpty(receivedData);
	}

	encryptPass(password) {
		const parsed = CryptoJS.enc.Utf8.parse(password);
		return CryptoJS.enc.Base64.stringify(parsed);
	}

	decryptPass(password) {
		const parsed = CryptoJS.enc.Base64.parse(password);
		return CryptoJS.enc.Utf8.stringify(parsed);
	}

	validationSchema = () =>
		Yup.object().shape({
			email: Yup.string("Insira seu e-mail")
				.email("Insira um e-mail válido.")
				.required("O e-mail é obrigatório."),
			password: Yup.string("")
				.min(8, "A senha deve conter no mínimo 8 caracteres.")
				.max(32, "A senha deve conter no máximo 32 caracteres.")
				.required("A senha é obrigatória."),
			passwordConfirmation: Yup.string("")
				.required("Confirme a sua senha")
				.oneOf([Yup.ref("password")], "As senhas não conferem.")
		});

	handleSubmit(values) {
		if (this.props.onSubmit) {
			values.password = this.encryptPass(values.password);
			this.props.onSubmit(values);
		}
	}

	trackPage() {
		window.history.pushState("", "", "/cadastrar/conta");
		const url = `${window.location.pathname}${window.location.search}`;
		ReactGA.pageview(url);
	}

	render() {
		const inititalValues = this.getInitialValues();
		const isInitialValuesValid = this.isInitialValuesValid();
		const isSmallScreen = isWidthDown("sm", this.props.width);

		this.trackPage();

		return (
			<Fragment>
				<SplitLayout
					miniIllustrationPath={illustrationMiniSVG}
					miniillustrationAlt="mini-account-illustration"
					illustrationPath={this.getIllustration()}
					illustrationAlt="account-illustration"
					onBackClick={this.props.onBackClick}
					show={this.props.show}
				>
					<Formik
						isInitialValid={isInitialValuesValid}
						onSubmit={values => this.handleSubmit(values)}
						render={props => (
							<Form
								{...props}
								onStepVerified={this.handleVerifiedSteps}
								onPasswordChange={this.handlePassword}
								isSmallScreen={isSmallScreen}
							/>
						)}
						initialValues={inititalValues}
						validationSchema={this.validationSchema}
					/>
				</SplitLayout>
			</Fragment>
		);
	}
}

export default withWidth()(SignUpAccount);
