import React, { Component } from "react";
import PropTypes from "prop-types";

import ReactModal from "react-modal";
import Icon from "./Icon";
import Button from "./Button";
import ButtonWrapper from "./ButtonWrapper";
import { darken } from "polished";

import styled from "styled-components";

const CancelButton = styled(ButtonWrapper)`
  color: #7c7ce4;
  font-size: 16px;
  transition: all 0.3s;
  :hover {
    color: ${darken(0.05, "#7C7CE4")};
  }
`;

export default class Modal extends Component {
  static propTypes = {
    opened: PropTypes.bool,
    hasFooter: PropTypes.bool,
    kind: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.node,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.onConfirm = this.onConfirm.bind(this);
  }

  onConfirm() {
    this.props.onClose();
    this.props.onConfirm();
  }

  render() {
    const { hasFooter, opened, onClose, kind, title, children } = this.props;

    return (
      <ReactModal
        className="adv-modal"
        overlayClassName="adv-modal-overlay"
        isOpen={opened}
        onRequestClose={onClose}
      >
        <div className="adv-modal--content">
          <h1 className="title">{title}</h1>
          <div>{children}</div>
        </div>

        {hasFooter && (
          <div className="adv-modal--footer">
            <CancelButton onClick={onClose}>Cancelar</CancelButton>

            {kind === "removal" && (
              <Button type="danger" onClick={this.onConfirm} m={0} ml={20}>
                <Icon name="delete" />
                <span>Delete</span>
              </Button>
            )}

            {kind === "confirmation" && (
              <Button type="primary" onClick={this.onConfirm} m={0} ml={20}>
                <Icon name="done" />
                <span>Confirmar</span>
              </Button>
            )}
          </div>
        )}
      </ReactModal>
    );
  }
}
