import React from "react";

import PropTypes from "prop-types";
import { Flex } from "grid-styled";

import Page from "../components/Page";
import PageTitle from "../components/PageTitle";
import RecoverPassword from "../components/RecoverPassword";

import logo from "../../img/logo-pulsares.png";

const RecoverPasswordPage = props => (
  <Page>
    <Flex justify="center">
      <img src={logo} alt="Logotipo" />
    </Flex>
    <Flex justify="center">
      <PageTitle>Redefinir senha</PageTitle>
    </Flex>
    <Flex justify="center">
      <RecoverPassword token={props.match.params.token} />
    </Flex>
  </Page>
);

RecoverPasswordPage.propTypes = {
  params: PropTypes.any
};

export default RecoverPasswordPage;
