import React, { Component, Fragment } from "react";

import { find } from "lodash";
import classnames from "classnames";

import { Grid, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { AccessTime } from "@material-ui/icons";

import TextField from "../../../js/components/common/form/TextField";
import Dialog from "../../UI/Dialog";

import SunIMG from "../../../img/prescription-creation/sun.png";
import MoonStartIMG from "../../../img/prescription-creation/moon-star.png";
import Illustration from "../../../img/prescription-creation/illustration.png";

import {
	FIRST_INTAKE_OPTIONS,
	LAST_INTAKE_OPTIONS
} from "../../../js/data/patient";

const styles = theme => ({
	intake: {
		display: "flex",
		cursor: "pointer",
		backgroundColor: "#EFF2F5",
		color: "#9AAFBF",
		borderRadius: 20,
		padding: theme.spacing(1, 1.5),
		fontSize: 14,
		"& p": {
			margin: theme.spacing(0, 1.25)
		}
	},
	textField: {
		textAlign: "left"
	}
});

class PrescriptionCreationPatientIntake extends Component {
	constructor(props) {
		super(props);

		this.state = {
			first_intake: props.patient.first_intake,
			last_intake: props.patient.last_intake,
			showIntakeModal: false
		};

		this.handleChange = this.handleChange.bind(this);
		this.showIntakeModal = this.showIntakeModal.bind(this);
		this.closeIntakeModal = this.closeIntakeModal.bind(this);
		this.handleConfirm = this.handleConfirm.bind(this);
	}

	showIntakeModal() {
		this.setState({ showIntakeModal: true });
	}

	closeIntakeModal() {
		this.setState({ showIntakeModal: false });
	}

	handleConfirm() {
		this.props.onSave(this.state.first_intake, this.state.last_intake);
		this.closeIntakeModal();
	}

	handleChange = name => event => {
		this.setState({ [name]: event.target.value });
	};

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			this.setState({
				...this.props.patient
			});
		}
	}

	render() {
		const { showIntakeModal } = this.state;
		const { classes } = this.props;

		const first_intake = find(FIRST_INTAKE_OPTIONS, {
			value: this.state.first_intake
		});

		const last_intake = find(LAST_INTAKE_OPTIONS, {
			value: this.state.last_intake
		});

		return (
			<Fragment>
				<div
					className={classnames(classes.intake, "tour-step-2")}
					onClick={this.showIntakeModal}
				>
					<AccessTime />
					<p>
						Rotina das {first_intake ? first_intake.label : ""} às
						{last_intake ? last_intake.label : ""}
					</p>
				</div>
				<Dialog
					show={showIntakeModal}
					onClose={this.closeIntakeModal}
					onConfirm={this.handleConfirm}
					title="Alterar a rotina do paciente"
					confirmText="Salvar rotina"
					image={Illustration}
					imageAlt="health professional"
				>
					<Grid container spacing={2} alignItems="center" justify="center">
						<Grid item>
							<img src={SunIMG} alt="sun" />
						</Grid>
						<Grid item xs={4}>
							<TextField
								select
								fullWidth
								required
								id="first_intake"
								label="Horário que acorda"
								className={classes.textField}
								value={this.state.first_intake}
								onChange={this.handleChange("first_intake")}
								margin="normal"
								variant="filled"
							>
								{FIRST_INTAKE_OPTIONS.map(option => (
									<MenuItem value={option.value}>{option.label}</MenuItem>
								))}
							</TextField>
						</Grid>
						<Grid item>às</Grid>
						<Grid item>
							<img src={MoonStartIMG} alt="moon and star" />
						</Grid>
						<Grid item xs={4}>
							<TextField
								select
								fullWidth
								required
								id="last_intake"
								label="Horário que dorme"
								className={classes.textField}
								value={this.state.last_intake}
								onChange={this.handleChange("last_intake")}
								margin="normal"
								variant="filled"
							>
								{LAST_INTAKE_OPTIONS.map(option => (
									<MenuItem value={option.value}>{option.label}</MenuItem>
								))}
							</TextField>
						</Grid>
					</Grid>
				</Dialog>
			</Fragment>
		);
	}
}

export default withStyles(styles)(PrescriptionCreationPatientIntake);
