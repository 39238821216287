import React, { PureComponent } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { find } from "lodash";
import ReactGA from "react-ga";

import { Grid, withStyles } from "@material-ui/core";

import { getUserProfile } from "../../../js/actions/user";
import {
	getClinics,
	removeClinic,
	updateClinic
} from "../../../js/components/Clinic/ClinicActions";

import Page from "../../UI/Page";

import List from "./List";
import styles from "../Style";
import ConfirmationDialog from "../../UI/ConfirmationDialog";

class ProfileWorkplace extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			loaded: false,
			isSaving: false,
			isProfileUpdated: false,
			showForm: false,
			showDeleteConfirmation: false,
			clinicToDelete: {}
		};

		this.handleSelected = this.handleSelected.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.closeDeleteConfirmation = this.closeDeleteConfirmation.bind(this);
		this.deleteClinic = this.deleteClinic.bind(this);
	}

	async componentDidMount() {
		await this.loadData();
		this.setState({ loaded: true });
	}

	async loadData() {
		await this.props.getUserProfile();
		await this.props.getClinics();
	}

	async handleDelete(id) {
		const clinicToDelete = find(this.props.clinics, { id: id });
		await this.setState({ showDeleteConfirmation: true, clinicToDelete });
	}

	async deleteClinic() {
		this.closeDeleteConfirmation();
		await this.props.removeClinic(this.state.clinicToDelete.id);
		ReactGA.event({
			category: "workplace",
			action: "button-click",
			label: "remove"
		});
		await this.loadData();

		if (this.props.clinics.length === 1) {
			await this.handleSelected(this.props.clinics[0]);
		}
	}

	closeDeleteConfirmation() {
		this.setState({ showDeleteConfirmation: false, clinicToDelete: {} });
	}

	async handleSelected(clinic) {
		const newCurrentClinic = { ...clinic, isDefault: true };
		const response = await this.props.updateClinic(
			newCurrentClinic,
			newCurrentClinic.id
		);

		if (response.error) {
			//Show error when selected is not worked
		} else {
			await this.loadData();
		}
	}

	render() {
		const { classes, user, clinics } = this.props;
		const { loaded } = this.state;

		return (
			loaded && (
				<Page width={1024} backgroundColor="#EFF2F5" paddingTop={128}>
					<Grid container>
						<Grid item xs={12}>
							<h1 className={classes.title}>Seus locais de trabalho</h1>
							<h2 className={classes.subtitle}>
								Marque uma das opções como padrão para aparecer em sua receita
								ou adicione um novo local.
							</h2>
						</Grid>
						<Grid item xs={12}>
							<List
								items={clinics}
								onDelete={this.handleDelete}
								onSelected={this.handleSelected}
								currentId={user.currentClinic}
							/>
						</Grid>
					</Grid>
					<ConfirmationDialog
						cancelText="Cancelar"
						confirmText="Confirmar"
						onClose={this.closeDeleteConfirmation}
						onConfirm={this.deleteClinic}
						show={this.state.showDeleteConfirmation}
						title="Confirmar a exclusão"
					>
						{`Você tem certeza que deseja excluir a clínica `}
						{this.state.clinicToDelete && (
							<strong>{this.state.clinicToDelete.name}?</strong>
						)}
					</ConfirmationDialog>
				</Page>
			)
		);
	}
}

const mapStateToProps = state => ({
	user: state.user,
	clinics: state.clinic.clinics
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			getUserProfile,
			getClinics,
			removeClinic,
			updateClinic
		},
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(ProfileWorkplace));
