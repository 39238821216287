import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAllPatients, removePatient } from "../../actions/patient";

import { Link } from "react-router-dom";
import { Flex } from "grid-styled";
import AlertDialog from "../AlertDialog";
import Input from "../Input";

import { partial } from "lodash";

import { isAuthenticated } from "../../utils/authCheck";

import UserTour from "../UserTour/UserTour";

import PageTitle from "../PageTitle";
import CustomButton from "../Button";

import { Table, TableBody, TableCell, TableRow, Icon } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import ToggleButton from "../common/button/ToggleButton";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  pageTitle: {
    marginTop: "25px"
  },
  table: {
    minWidth: 700,
    margin: "10px 0 15px 0"
  },
  row: {
    "&:hover": {
      backgroundColor: "#E8EEF3"
    }
  },
  nameCell: {
    fontWeight: 500
  },
  tableTitle: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#000",
    marginTop: "50px"
  },
  iconTableTitle: {
    float: "left",
    marginRight: "5px",
    transform: "rotate(90deg)"
  }
});

const mapStateToProps = state => ({
  entries: state.patient.entries,
  isLoading: state.loader.isLoading
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllPatients,
      removePatient
    },
    dispatch
  );

class PatientList extends Component {
  static propTypes = {
    entries: PropTypes.array,
    getAllPatients: PropTypes.func,
    removePatient: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      searchTerm: "",
      modalOpened: false,
      patientToDelete: null,
      hoveredEntry: null,
      showAll: false
    };

    this.onSearch = this.onSearch.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.deletePatient = this.deletePatient.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.selectEntry = this.selectEntry.bind(this);
    this.toggleShowAll = this.toggleShowAll.bind(this);
    this.renderTableRows = this.renderTableRows.bind(this);
  }

  async componentWillMount() {
    const authenticated = isAuthenticated();
    if (authenticated) {
      await this.props.getAllPatients();
    }
  }

  onSearch(event) {
    this.setState({ searchTerm: event.target.value });
  }

  onModalClose() {
    this.showModal(false, null);
  }

  async deletePatient() {
    await this.props.removePatient(this.state.patientToDelete.id);
    //Alert.success(ALERT.patient.removed, { offset: 39 });
    this.showModal(false, null);
    if (this.props.entries && this.props.entries.length === 0)
      this.setState({
        isReady: true
      });
  }

  confirmDelete(patient) {
    this.showModal(true, patient);
  }

  selectEntry(id) {
    this.setState({ hoveredEntry: id });
  }

  showModal(modalOpened, patientToDelete) {
    this.setState({ modalOpened, patientToDelete });
  }

  toggleShowAll() {
    this.setState({ showAll: !this.state.showAll });
  }

  renderTableRows(index, entry) {
    const { classes } = this.props;
    return (
      <TableRow key={index} className={classes.row}>
        <TableCell component="th" scope="row" className={classes.nameCell}>
          {entry.name}
        </TableCell>
        <TableCell align="right">
          <ToggleButton
            hoverStyle={{ width: 105 }}
            isDelete
            icon="delete"
            label="Excluir"
            onClick={partial(this.confirmDelete, entry)}
          />

          <ToggleButton
            hoverStyle={{ width: 201 }}
            icon="format_list_bulleted"
            label="Histórico de receitas"
            component={Link}
            to={`/receitas?paciente=${entry.id}`}
          />

          <ToggleButton
            hoverStyle={{ width: 161 }}
            icon="edit"
            label="Editar paciente"
            component={Link}
            to={`/pacientes/${entry.id}`}
          />

          <ToggleButton
            hoverStyle={{ width: 147 }}
            icon="note_add"
            label="Criar receita"
            component={Link}
            to={`/nova-receita?paciente=${entry.id}`}
          />
        </TableCell>
      </TableRow>
    );
  }

  render() {
    let miniEntries = this.props.entries
      ? this.props.entries.length >= 3
        ? this.props.entries.slice(0, 3)
        : this.props.entries
      : [];

    const { classes } = this.props;
    if (!this.props.isLoading) {
      if (this.props.entries && this.props.entries.length === 0) {
        return <UserTour />;
      } else
        return (
          <div>
            <PageTitle className={classes.pageTitle}>
              Faça sua busca por pacientes cadastrados
            </PageTitle>
            <Input
              type="text"
              placeholder="Digite aqui o nome do paciente"
              onChange={this.onSearch}
              autoComplete="off"
              autoFocus
              my={30}
              height={65}
            />
            {!this.state.searchTerm && (
              <div className={classes.tableTitle}>
                <Icon className={classes.iconTableTitle}>autorenew</Icon>
                <p>Últimos pacientes</p>
              </div>
            )}
            <Table className={classes.table}>
              <TableBody>
                {(this.state.showAll || this.state.searchTerm) &&
                  this.props.entries
                    .filter(entry =>
                      entry.name
                        .toLowerCase()
                        .match(this.state.searchTerm.toLowerCase())
                    )
                    .map((entry, index) => this.renderTableRows(index, entry))}

                {!this.state.showAll &&
                  !this.state.searchTerm &&
                  miniEntries.map((entry, index) =>
                    this.renderTableRows(index, entry)
                  )}
              </TableBody>
            </Table>

            {this.props.entries && this.props.entries.length > 3 && (
              <Flex justify="center">
                <CustomButton
                  onClick={this.toggleShowAll}
                  type="bordered-submit"
                >
                  <span>
                    {this.state.showAll ? "Esconder" : "Mostrar todos"}
                  </span>
                </CustomButton>
              </Flex>
            )}

            {this.state.patientToDelete && (
              <AlertDialog
                open={this.state.modalOpened}
                onClose={this.onModalClose}
                onConfirm={this.deletePatient}
                title="Aviso!"
                contentText={
                  <div>
                    Essa ação não poderá ser desfeita. Você tem certeza que
                    deseja excluir{" "}
                    <strong>{this.state.patientToDelete.name}</strong>?
                  </div>
                }
                confirmLabel="Excluir"
                confirmColor="secondary"
                cancelLabel="Cancelar"
              />
            )}
          </div>
        );
    } else {
      return <div />;
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PatientList));
