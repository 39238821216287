import * as types from "../../constants/";

const INITIAL_STATE = { clinics: [], zipCodeInformation: {} };

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.GET_USER_CLINICS:
			return { ...state, clinics: action.payload };
		case types.CREATE_USER_CLINIC:
			return { ...state, clinics: [...state.clinics, action.payload] };
		case types.REMOVE_USER_CLINIC:
			return { ...state, clinics: [...state.clinics, action.payload] };
		case types.ZIPCODE_INFORMATION_FETCHED:
			return { ...state, zipCodeInformation: action.payload };
		default:
			return state;
	}
};
