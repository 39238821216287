import axios from "axios";
import { API_URL } from "../config/api";

export default {
  signin(username, password) {
    const url = `${API_URL}/auth/`;

    return axios({
      method: "POST",
      url,
      auth: {
        username,
        password
      }
    });
  },

  signup(userObj) {
    const url = `${API_URL}/users/`;

    return axios({
      method: "POST",
      url,
      data: userObj
    });
  },

  signupconfirmation(token) {
    const url = `${API_URL}/users/confirmation`;

    return axios({
      method: "POST",
      url,
      data: { token: token }
    });
  },

  recover(email) {
    const url = `${API_URL}/users/passwords`;

    return axios({
      method: "POST",
      url,
      data: email
    });
  },

  confirmResetUserPassword(data, resetToken) {
    const url = `${API_URL}/users/passwords?token=${resetToken}`;

    const payload = {
      email: data.email,
      password: data.password
    };

    return axios({
      method: "POST",
      url,
      data: payload
    });
  }
};
