import React, { Component } from "react";

import PropTypes from "prop-types";

import HeaderBar from "../components/HeaderBar";
import Page from "../components/Page";
import PageTitle from "../components/PageTitle";
import PrescriptionList from "../components/Prescription/PrescriptionList";

class PrescriptionListPage extends Component {
  // @TODO: set correct PropType for location prop.
  static propTypes = {
    location: PropTypes.any
  };

  state = {
    patientId: ""
  };

  componentWillMount() {
    const { location } = this.props;
    let params = new URLSearchParams(location.search);

    this.setState({
      patientId: params.get("paciente")
    });
  }

  render() {
    return (
      <Page>
        <HeaderBar />
        <PageTitle>Histórico de receitas</PageTitle>
        <PrescriptionList patientId={this.state.patientId} />
      </Page>
    );
  }
}

export default PrescriptionListPage;
