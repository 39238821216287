import React from "react";

import { rem } from "polished";
import { Grid, withStyles } from "@material-ui/core";

const styles = () => ({
	root: {
		fontFamily: "Montserrat, sans-serif",
		fontSize: rem(14),
		color: "#707070",
		display: "flex",
		alignItems: "center"
	}
});

const ListCell = ({ children, classes, ...other }) => {
	return (
		<Grid item className={classes.root} {...other}>
			{children}
		</Grid>
	);
};

export default withStyles(styles)(ListCell);
