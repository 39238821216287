import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import validator from "validator";
import Icon from "../Icon";
import Button from "../Button";

import { Grid, MenuItem, withStyles } from "@material-ui/core";
import TextField from "../../../js/components/common/form/TextField";

import HeaderBar from "../../../components/UI/HeaderBar";
import SubmitIconButton from "../../../components/UI/SubmitIconButton";

import styles from "./Style";

import {
	createPatient,
	updatePatient,
	getPatient
} from "../../actions/patient";
import { FIRST_INTAKE_OPTIONS, LAST_INTAKE_OPTIONS } from "../../data/patient";
import { GENDER_OPTIONS } from "../../data/signup";
import Alert from "react-s-alert";
import * as ALERT from "../../data/alerts";

import { isAuthenticated } from "../../utils/authCheck";

import { pick, values } from "lodash";
import moment from "moment";
import AlertList from "../AlertList";
import LoginDialog from "../LoginDialog";

const mapStateToProps = state => ({
	patient: state.patient.selected
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			createPatient,
			updatePatient,
			getPatient
		},
		dispatch
	);

class NewPatient extends PureComponent {
	static propTypes = {
		id: PropTypes.string,
		patient: PropTypes.object,
		createPatient: PropTypes.func,
		updatePatient: PropTypes.func,
		getPatient: PropTypes.func
	};

	constructor(props) {
		super(props);
		this.state = {
			gender: "",
			error: false,
			errorStatus: "",
			showLogin: false,
			isNew: !this.props.id,
			name: "",
			first_intake: "07:00",
			last_intake: "22:00"
		};

		this.handleSignin = this.handleSignin.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	async componentWillMount() {
		const authenticated = isAuthenticated();
		if (authenticated) {
			if (!this.state.isNew) {
				await this.props.getPatient(this.props.id);
				this.setState({
					...this.props.patient,
					birthday: this.props.patient.birthday
						? moment(this.props.patient.birthday, "YYYY/MM/DD").format(
								"DD/MM/YYYY"
						  )
						: ""
				});
			}
		}
	}

	handleSignin(response) {
		if (response.error) return;

		this.setState({ error: false, errorStatus: "", showLogin: false });
	}

	handleSubmit(event) {
		event.preventDefault();

		const data = {
			...pick(this.state, [
				"name",
				"first_intake",
				"last_intake",
				"gender",
				"record_number",
				"address"
			]),
			birthday: this.state.birthday
		};

		if (validator.isEmpty(data.name)) {
			Alert.error(ALERT.patient.nameMissing, { offset: 39 });
			return;
		}

		if (this.state.isNew) {
			this.createPatient(data);
		} else {
			this.updatePatient(data);
		}

		if (this.props.showBackButton) {
			this.props.onBack();
		}
	}

	async createPatient(data) {
		const { error } = await this.props.createPatient(data);
		if (error) {
			this.showErrors(error);
			return;
		}
		const { patient } = this.props;
		const { id } = patient;
		//Alert.success(ALERT.patient.created, { offset: 39 });
		this.props.history.push(`/nova-receita?paciente=${id}`);
	}

	async updatePatient(data) {
		const { id } = this.props;
		const { error } = await this.props.updatePatient(data, id);
		if (error) {
			this.showErrors(error);
			return;
		}
		//Alert.success(ALERT.patient.updated, { offset: 39 });
		this.props.history.push(`/nova-receita?paciente=${this.props.id}`);
	}

	showErrors(error) {
		this.setState(
			{
				error: true,
				errorStatus: error.response.status
			},
			() => {
				const authenticated = isAuthenticated();
				if (error.response.status === 403 && !authenticated) {
					this.setState({ showLogin: true });
				}
			}
		);

		const messages = values(error.response.data.validation_errors);
		Alert.error(
			<AlertList>
				{messages.map((message, index) => (
					<li key={index}>{message}</li>
				))}
			</AlertList>,
			{ timeout: 5000, offset: 39 }
		);
	}

	render() {
		const { classes } = this.props;

		return (
			<div className={classes.root}>
				<HeaderBar />
				<form onSubmit={this.handleSubmit}>
					<Grid container justify={"center"}>
						<Grid item xs={12}>
							<h1 className={classes.title}>Novo paciente</h1>
						</Grid>
						<Grid item xs={8} sm={4}>
							<Grid container direction="column" alignItems="stretch">
								<Grid item>
									<TextField
										id="name"
										name="name"
										type="text"
										value={this.state.name}
										onChange={event => {
											this.setState({ name: event.target.value });
										}}
										label="Nome"
										margin="normal"
										variant="filled"
										className={classes.textfield}
										required
										fullWidth
									/>
								</Grid>
								<Grid item>
									<TextField
										id="gender"
										name="gender"
										select
										label="Gênero"
										value={this.state.gender}
										onChange={event => {
											this.setState({ gender: event.target.value });
										}}
										margin="normal"
										variant="filled"
										className={classes.textfield}
										required
										fullWidth
									>
										{GENDER_OPTIONS.map(option => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</TextField>
								</Grid>

								<Grid item>
									<TextField
										label="Data de nascimento"
										value={this.state.birthday}
										onChange={event => {
											this.setState({ birthday: event.target.value });
										}}
										id="birthday"
										type="date"
										margin="normal"
										variant="filled"
										className={classes.textfield}
										shrink
										fullWidth
									/>
								</Grid>
								<Grid item>
									<TextField
										id="record_number"
										name="record_number"
										type="text"
										value={this.state.record_number}
										onChange={event => {
											this.setState({ record_number: event.target.value });
										}}
										label="Número de prontuário"
										margin="normal"
										variant="filled"
										className={classes.textfield}
										fullWidth
									/>
								</Grid>
								<Grid item>
									<TextField
										id="address"
										name="address"
										type="text"
										value={this.state.address}
										onChange={event => {
											this.setState({ address: event.target.value });
										}}
										label="Endereço"
										margin="normal"
										variant="filled"
										className={classes.textfield}
										fullWidth
									/>
								</Grid>
							</Grid>

							<Grid item>
								<legend className={classes.legend}>Rotina da medicação</legend>
							</Grid>

							<Grid item>
								<TextField
									id="first_intake"
									name="first_intake"
									select
									label="Hora da primeira tomada"
									value={this.state.first_intake}
									onChange={event => {
										this.setState({ first_intake: event.target.value });
									}}
									margin="normal"
									variant="filled"
									className={classes.textfield}
									required
									fullWidth
								>
									{FIRST_INTAKE_OPTIONS.map(option => (
										<MenuItem key={option.value} value={option.value}>
											{option.label}
										</MenuItem>
									))}
								</TextField>
							</Grid>

							<Grid item>
								<TextField
									id="last_intake"
									name="last_intake"
									select
									label="Hora da última tomada"
									value={this.state.last_intake}
									onChange={event => {
										this.setState({ last_intake: event.target.value });
									}}
									margin="normal"
									variant="filled"
									className={classes.textfield}
									required
									fullWidth
								>
									{LAST_INTAKE_OPTIONS.map(option => (
										<MenuItem key={option.value} value={option.value}>
											{option.label}
										</MenuItem>
									))}
								</TextField>
							</Grid>
							<Grid item>
								<Grid
									container
									justify="space-around"
									className={classes.buttons}
								>
									{this.props.showBackButton && (
										<Grid item>
											<Button
												onClick={this.props.onBack}
												type="bordered-submit"
											>
												<Icon name="close" />
												<span>Não alterar e voltar para receita</span>
											</Button>
										</Grid>
									)}

									<Grid item>
										<SubmitIconButton
											showIcon
											text={this.state.isNew ? "Próximo" : "Salvar"}
											disabled={this.state.showLogin}
											type="submit"
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</form>
				<LoginDialog show={this.state.showLogin} onSignin={this.handleSignin} />
			</div>
		);
	}
}

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(styles)(NewPatient))
);
