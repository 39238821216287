const styles = theme => ({
  form: {
    display: "flex",
    flexDirection: "column"
  },
  fields: {
    margin: ".825rem 0"
  },
  content: {
    width: "90%",
    margin: "0 auto"
  },
  textfield: {
    width: "19.65rem",
    margin: ".625rem 0"
  },
  title: {
    color: "#8080EC",
    fontSize: "1.625rem",
    fontFamily: "Bree Serif, serif",
    margin: ".825rem 0"
  },
  subTitle: {
    color: "#372F4E",
    fontSize: "1rem",
    fontFamily: "Montserrat, sans-serif",
    margin: ".825rem 0"
  },
  paper: {
    borderRadius: ".5rem",
    textAlign: "center"
  },
  buttons: {
    margin: ".825rem 0",
    display: "flex",
    justifyContent: "center"
  },
  closeButton: {
    position: "absolute",
    right: 5,
    top: 5
  }
});

export default styles;
