import axios from "axios";

import { API_URL } from "../../js/config/api";

const url = `${API_URL}/users/`;

export const post = user =>
  axios({
    method: "POST",
    url,
    data: user
  });
