import React from 'react'
import styled from 'styled-components'

const Text = styled.div`
  & p {
    margin: 1em 0;
  }
  & dt {
    font-weight: bold;
    margin-top: 1em;
  }
`

const Terms = () => (
  <Text>
    <p>
      Olá, tudo bem?<br/>
      Acreditamos que falar bem é falar numa linguagem de fácil compreensão. Por isso adotamos um modelo de termos de
      uso no formato de perguntas e respostas claras e simples: FAQ, do inglês Frequently Asked Questions (Perguntas
      Frequentes).
    </p>
    <p>
      Estamos felizes que você decidiu por usar nosso software e facilitar seu trabalho e a vida de seus pacientes.
      Nesse FAQ existem informações importantes sobre o uso seguro deste software. Saber dessas informações é
      fundamental para a sua melhor utilização. Se você tem alguma dúvida ou não concordar com alguma das informações
      aqui escritas, fale com a gente pelo e-mail <a href="mailto:contato@pulsares.com.br">contato@pulsares.com.br</a>.
      Estamos aqui para ajudá-lo da melhor forma.
    </p>
    <p>
      Quando você clica no botão “Li e Aceito os Termos de Uso”, significa que você aceita eletronicamente todas as
      informações deste documento e concorda em se submeter a esses termos. Não use o software se você não concorda com
      estes termos.
    </p>
    <dl>
      <dt>O que é Pulsares?</dt>
      <dd>
        A Pulsares é uma empresa sediada em Florianópolis que usa tecnologia para deixar saúde mais fácil a todos e
        originou-se em 2015.
      </dd>

      <dt>O que é o software RENOVA?</dt>
      <dd>
        O RENOVA é um software da Pulsares para prescrição eletrônica usado por profissionais de saúde. Ele possui uma
        base de medicamentos própria, construída a partir de dados oficiais da Agência Nacional de Vigilância Sanitária
        (ANVISA).
      </dd>

      <dt>O que é a receita NOVA?</dt>
      <dd>
        É o nosso formato próprio de prescrição de medicamentos. Ela é gerada automaticamente pelo software a partir dos
        dados inseridos pelo profissional. Baseando-se nos horários que o paciente primeiro toma o remédio e que vai
        dormir; ela vem organizada por horários, com linguagem compreensível e pictogramas (imagens) que mostram quando,
        como e o quê tomar.
      </dd>

      <dt>Preciso gastar mais tempo criando a receita NOVA?</dt>
      <dd>
        Não. Os pictogramas, a ordem dos medicamentos e a linguagem fácil são gerados pelo software. As únicas
        informações que são necessárias são os horários que o paciente começa a tomar a medicação e o horário que vai
        dormir.
      </dd>

      <dt>Por que usar pictogramas?</dt>
      <dd>
        Pictogramas nada mais são do que imagens que passam uma instrução. Vários estudos mostram que, quando associados
        a documentos de saúde, pictogramas aumentam a compreensão do material e até a tomada correta do medicamento.
      </dd>

      <dt>De onde vêm os pictogramas usados no software?</dt>
      <dd>
        Todos os pictogramas foram criados pela Pulsares, adaptados para a realidade brasileira. Estamos constantemente
        testando os pictogramas e o formato de receita com pacientes, assim como pensando em novos formatos.
      </dd>

      <dt>Como o banco de medicamentos foi feito?</dt>
      <dd>
        As informações sobre os medicamentos pertencem à Pulsares e foram construídas a partir de dados da ANVISA com
        inúmeras revisões de dose e nomenclatura. Apesar da nossa cuidadosa revisão, se você perceber algum erro ou não
        encontrar algum remédio, por favor fale com a gente no e-mail <a href="mailto:contato@pulsares.com.br">contato@pulsares.com.br</a>
        para que possamos melhorar cada vez mais o software para você.
      </dd>

      <dt>Quem pode usar o software RENOVA?</dt>
      <dd>Para usar o software você deve ser um profissional de saúde devidamente cadastrado no seu Conselho Regional.
        Profissionais de saúde que podem utilizar o software são:<br/>
        Médico(a) – prescrição de receitas médicas em geral; organizar os medicamentos do paciente que estão em várias
        receitas.<br/>
        Farmacêutico(a) clínico – prescrição de medicamentos que não necessitem de receita médica; elaboração de tabela
        vertical de medicamentos e revisão de medicação.<br/>
        Somos a favor de uma saúde interdisciplinar e mais humana, onde cada profissional desempenha sua função sem
        restringir ou invadir o trabalho do outro.
      </dd>

      <dt>Preciso instalar o RENOVA no meu computador?</dt>
      <dd>
        Não. O software funciona como se fosse um site na internet. O que se precisa é: computador com navegador
        (browser) de internet atualizado, impressora e folhas de papel; já que a receita gerada no programa é impressa e
        entregue ao paciente.
      </dd>

      <dt>Esse software é regulamentado pela ANVISA?</dt>
      <dd>
        A ANVISA não requer regularização de softwares de prescrição eletrônica, por entender que é uma ferramenta de
        trabalho do profissional em que este é o responsável por usá-la.
      </dd>

      <dt>Quais as minhas responsabilidades enquanto usuário?</dt>
      <dd>
        - Assegurar que todos os dados fornecidos são autênticos e que você tem autorização para utilizá-los.<br/>
        - Manter suas informações de cadastro sempre atualizadas.<br/>
        - Assegurar que as receitas criadas utilizando o RENOVA são adequadas para os pacientes às quais se destinam,
        assumindo toda a responsabilidade pelas mesmas.<br/>
        - Manter sua senha de acesso ao software em sigilo. É recomendado por diversas organizações e órgãos que tratam
        da questão de segurança de senhas que elas sejam trocadas periodicamente.
      </dd>

      <dt>O que eu não posso fazer?</dt>
      <dd>
        - Informar seus dados de acesso a terceiros, em caráter temporário ou definitivo.<br/>
        - Copiar, ceder ou vender este software ou quaisquer de suas partes; ou facilitar tais práticas. O software é
        protegido pela legislação de direitos autorais e de software do Brasil, tratados internacionais e outras leis e
        tratados aplicáveis.<br/>
        - Utilizar o conteúdo do software, tal como o banco de medicamentos e as receitas geradas, sem o consentimento
        da Pulsares.
      </dd>

      <dt>E quais as obrigações da Pulsares?</dt>
      <dd>
        - Fornecer a você uma licença não exclusiva de acesso ao software RENOVA.<br/>
        - Fornecer garantia limitada e restrita quanto ao funcionamento do software. Sob condições normais de
        utilização, o RENOVA geralmente irá funcionar conforme anunciado em seus materiais promocionais impressos e
        publicados na internet e demais descrições que estão neste termo, porém o software pode conter erros e bugs
        normais. Portanto, o RENOVA é disponibilizado numa base "TAL COMO ESTÁ" tendo em consideração que,
        ocasionalmente, serão fornecidas correções de erros e Atualizações.<br/>
        - Garantir a segurança dos seus dados e o de seus pacientes.<br/>
        - Não usar suas informações e as de seus pacientes sem que vocês as tenham autorizado.<br/>
        - Melhorar e mudar a saúde cada vez mais ajudando no seu trabalho e na saúde de seus pacientes : ) Estamos aqui
        para trabalhar junto com você, por isso seu feedback é sempre valioso para nós.
      </dd>
    </dl>
    <p>
      Quando você começa a usar este software, você o está adquirindo em Florianópolis, já que a empresa está sediada
      nessa cidade. Caso você venha a levantar alguma demanda contra a Pulsares (o que acreditamos e esperamos que não vai
      acontecer) fica definido o foro da comarca de Florianópolis para resolver quaisquer questões que possam surgir.
    </p>
  </Text>
)

export default Terms
