import React from "react";

import {
	FormControl,
	FormControlLabel,
	FormHelperText,
	Checkbox,
	MenuItem,
	Zoom
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import SubmitIconButton from "../../UI/SubmitIconButton";

import PhoneMaskedInput from "../../../js/components/common/form/PhoneMaskedInput";
import TextField from "../../../js/components/common/form/TextField";
import CellPhoneMaskedInput from "../../../js/components/common/form/CellPhoneMaskedInput";

import { SPECIALTIES, USER_TYPES, VOCATIVES } from "../../../js/data/signup";

import styles from "./Style";

const isCellPhone = value => {
	const phone = value.replace(/\D/g, "");
	return phone.length > 10;
};

const GeneralInfoForm = ({
	classes,
	values,
	handleChange,
	handleBlur,
	handleSubmit,
	errors,
	touched,
	isSubmitting,
	isValid
}) => (
	<div className={classes.root}>
		<form className={classes.form} onSubmit={handleSubmit}>
			<TextField
				id="first_name"
				label="Seu nome"
				value={values.first_name}
				onChange={handleChange}
				onBlur={handleBlur}
				margin="normal"
				variant="filled"
				className={classes.textfield}
				error={
					errors.first_name && touched.first_name && values.first_name !== ""
				}
				verified={!errors.first_name && touched.first_name}
				helperText={
					values.first_name !== "" && touched.first_name
						? errors.first_name
						: null
				}
				required
			/>

			<TextField
				id="last_name"
				label="Sobrenome"
				value={values.last_name}
				onChange={handleChange}
				onBlur={handleBlur}
				margin="normal"
				variant="filled"
				className={classes.textfield}
				error={errors.last_name && touched.last_name && values.last_name !== ""}
				verified={!errors.last_name && touched.last_name}
				helperText={
					values.last_name !== "" && touched.last_name ? errors.last_name : null
				}
				required
			/>
			<TextField
				id="user_type"
				name="user_type"
				select
				label="Eu atuo como"
				value={values.user_type}
				onChange={handleChange}
				onBlur={handleBlur}
				margin="normal"
				variant="filled"
				className={classes.textfield}
				required
				error={errors.user_type && touched.user_type && values.user_type !== ""}
				verified={!errors.user_type && touched.user_type}
				helperText={
					values.user_type !== "" && touched.user_type ? errors.user_type : ""
				}
			>
				{USER_TYPES.map(option => (
					<MenuItem key={option.value} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</TextField>
			{values.user_type === "physician" && (
				<Zoom in={values.user_type === "physician"}>
					<TextField
						id="specialty"
						name="specialty"
						select
						label="Especialidade"
						value={values.specialty}
						onChange={handleChange}
						onBlur={handleBlur}
						margin="normal"
						variant="filled"
						className={classes.textfield}
						required
						error={
							errors.specialty && touched.specialty && values.specialty !== ""
						}
						verified={!errors.specialty && touched.specialty}
						helperText={
							values.specialty !== "" && touched.specialty
								? errors.specialty
								: ""
						}
					>
						{SPECIALTIES.map(option => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</TextField>
				</Zoom>
			)}

			<TextField
				id="title"
				name="title"
				select
				label="Vocativo"
				value={values.title}
				onChange={handleChange}
				onBlur={handleBlur}
				margin="normal"
				variant="filled"
				className={classes.textfield}
			>
				{VOCATIVES.map(option => (
					<MenuItem key={option.value} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</TextField>
			<TextField
				required={values.allowed_call}
				label="Telefone"
				value={values.phone}
				id="phone"
				name="phone"
				margin="normal"
				variant="filled"
				className={classes.textfield}
				onChange={handleChange}
				onBlur={handleBlur}
				error={errors.phone && touched.phone && values.phone !== ""}
				verified={!errors.phone && touched.phone}
				helperText={values.phone !== "" && touched.phone ? errors.phone : null}
				InputProps={{
					inputComponent: isCellPhone(values.phone)
						? CellPhoneMaskedInput
						: PhoneMaskedInput
				}}
			/>
			<FormControlLabel
				control={
					<Checkbox
						name="allowed_call"
						checked={values.allowed_call}
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.allowed_call}
						color="primary"
					/>
				}
				label="Podemos entrar em contato através do seu telefone"
				className={classes.checkbox}
			/>
			<FormControl
				required
				error={errors.tos_read && touched.tos_read}
				component="fieldset"
				className={classes.formControl}
			>
				<FormControlLabel
					control={
						<Checkbox
							name="tos_read"
							checked={values.tos_read}
							onChange={handleChange}
							value={values.tos_read}
							color="primary"
						/>
					}
					label={
						<div>
							Li e aceito os{" "}
							<a href="/termos-de-uso" target="_blank">
								termos de uso
							</a>
						</div>
					}
					className={classes.checkbox}
					required
				/>
				{errors.tos_read && touched.tos_read && (
					<FormHelperText>{errors.tos_read}</FormHelperText>
				)}
			</FormControl>
			<div className={classes.submitButton}>
				<SubmitIconButton
					text="Finalizar"
					showIcon
					disabled={isSubmitting || !isValid}
					type="submit"
				/>
			</div>
		</form>
	</div>
);

export default withStyles(styles)(GeneralInfoForm);
