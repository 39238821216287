import React, { Component } from "react";

import PropTypes from "prop-types";

import NewPatient from "../components/Patient/New";

class NewPatientPage extends Component {
  static propTypes = {
    params: PropTypes.object
  };

  render() {
    const id = this.props.params ? this.props.params.id : this.props.id;
    return (
      <NewPatient
        showBackButton={this.props.showBackButton}
        onBack={this.props.onBack}
        id={id}
      />
    );
  }
}

export default NewPatientPage;
