import storageService from "../services/storage";
import { withRouter } from "react-router-dom";

const jwtDecode = require("jwt-decode");

export const getRefreshToken = () => storageService().get("refresh-token");

export const isAuthenticated = () => {
  const token = getRefreshToken();
  let validData = false;

  if (!token) return false;

  try {
    const data = jwtDecode(token);

    if (data) {
      const curTime = Math.floor(Date.now() / 1000);
      validData = curTime < data.exp;
    }
  } catch (e) {
    validData = false;
  }

  return validData;
};

export const authCheck = withRouter(() => {
  const authenticated = isAuthenticated();
  if (!authenticated) {
    this.props.history.push("/entrar");
  }

  return authenticated;
});
