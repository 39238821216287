import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { partial, isArray, trim, debounce } from "lodash";
import Highlighter from "react-highlight-words";
import Icon from "../../Icon";
import { Table, TableBody } from "../../Table";

import Input from "../../Input";
import {
	currentMedication,
	searchMedication,
	isEditMedicationMode
} from "../../../actions/medication";
import { addMedication, editMedication } from "../../../actions/prescription";
import { setFormInProgress } from "../../../actions/ui-prescription";
import {
	getTreatmentOptions,
	getDefaultAmountOptions,
	getMillilitersAmountOptions,
	getPillAmountOptions,
	getFrequencyOptions
} from "../../../data/prescription";
import Alert from "react-s-alert";
import * as ALERT from "../../../data/alerts";
import HelpInfo from "../../HelpInfo";

import { Flex } from "grid-styled";
import Button from "../../Button";
import RelativeWrapper from "../../RelativeWrapper";
import AbsoluteWrapper from "../../AbsoluteWrapper";
import Loader from "../../Loader";

import { rem, darken } from "polished";

import { Grid, TextField, MenuItem } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import { Close } from "@material-ui/icons";

const TREATMENT_OPTIONS = getTreatmentOptions();
const TREATMENT_DEFAULT_VALUE = TREATMENT_OPTIONS[0].value;

const AMOUNT_OPTIONS = getDefaultAmountOptions();
const AMOUNT_DEFAULT_OPTION = AMOUNT_OPTIONS[0].value;

const FREQUENCY_OPTIONS = getFrequencyOptions();
const FREQUENCY_DEFAULT_VALUE = FREQUENCY_OPTIONS[0];

const styles = theme => ({
	root: {
		width: "100%",
		maxWidth: rem(800),
		margin: "0 auto"
	},
	form: {
		paddingBottom: 20,

		textAlign: "left"
	},
	row: {
		marginTop: 10
	},
	options: {
		paddingTop: 30,
		textAlign: "right"
	},
	closeButton: {
		cursor: "pointer",
		color: "#7C7CE4",
		"&:hover": {
			color: darken(0.05, "#7C7CE4")
		}
	}
});

const mapStateToProps = state => {
	const suggestion = state.medication.entries || [];

	return {
		suggestion,
		prescriptionEntries: state.prescription.entries,
		editMedicationMode: state.medication.editMedicationMode,
		isLoading: state.loader.isLoading,
		selectedMedication: state.currentMedication
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			searchMedication,
			addMedication,
			editMedication,
			isEditMedicationMode,
			setFormInProgress,
			currentMedication
		},
		dispatch
	);

class RenewPrescriptionForm extends Component {
	static propTypes = {
		suggestion: PropTypes.array,
		prescriptionEntries: PropTypes.array,
		editMedicationMode: PropTypes.object,
		searchMedication: PropTypes.func,
		addMedication: PropTypes.func,
		editMedication: PropTypes.func,
		isEditMedicationMode: PropTypes.func,
		setFormInProgress: PropTypes.func,
		currentMedication: PropTypes.func,
		isLoading: PropTypes.bool
	};

	constructor(props) {
		super(props);
		this.state = {
			enterKeyHits: 0,
			hasSelectedMedication: false,
			medicationName: "",
			queryToSearch: "",
			suggestion: [],
			shouldShowSuggestion: false,
			isEditMode: false,
			medicationRoute: "",
			treatment_days: TREATMENT_DEFAULT_VALUE,
			notes: "",
			unit: "",
			doses: [
				{
					amount: AMOUNT_DEFAULT_OPTION,
					frequency: FREQUENCY_DEFAULT_VALUE
				}
			],
			amountOptions: AMOUNT_OPTIONS,
			showMlInput: false
		};

		this.keyPress = this.keyPress.bind(this);
		this.onChangeField = this.onChangeField.bind(this);
		this.setData = this.setData.bind(this);
		this.handleSignin = this.handleSignin.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.handleMedicationClick = this.handleMedicationClick.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.resetFormState = this.resetFormState.bind(this);
		this.addNewDose = this.addNewDose.bind(this);
		this.deleteDose = this.deleteDose.bind(this);
		this.handleDoseChange = this.handleDoseChange.bind(this);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.keyPress, false);
	}

	keyPress(event) {
		if (this.props.show) {
			switch (event.key) {
				case "Enter":
					this.handleSubmit(event);
					break;
				case "Escape":
					this.handleCancel(event);
					break;
				default:
					break;
			}
		}
	}

	componentDidMount() {
		document.addEventListener("keydown", this.keyPress, false);

		const { editMedicationMode } = this.props;

		if (editMedicationMode.isEditMode) {
			const itemToEdit = this.props.prescriptionEntries[
				editMedicationMode.editPrescriptionId
			];

			const data = {
				editPrescriptionId: editMedicationMode.editPrescriptionId,
				isEditMode: true,
				hasSelectedMedication: true,
				medicationName: itemToEdit.medicationName,
				medicationRoute: itemToEdit.medicationRoute,
				form_id: itemToEdit.form_id,
				treatment_days: itemToEdit.treatment_days,
				medication_id: itemToEdit.medication_id,
				notes: itemToEdit.notes,
				doses: itemToEdit.doses,
				unit: itemToEdit.unit
			};

			this.setData(data);
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.state.suggestion !== nextProps.suggestion) {
			this.setState({ suggestion: nextProps.suggestion });
		}

		const { editMedicationMode } = nextProps;

		if (editMedicationMode.isEditMode) {
			const itemToEdit = this.props.prescriptionEntries[
				editMedicationMode.editPrescriptionId
			];

			let treatment = itemToEdit.treatment_days;

			if (!itemToEdit.treatment_days.label) treatment = TREATMENT_DEFAULT_VALUE;

			const data = {
				editPrescriptionId: editMedicationMode.editPrescriptionId,
				isEditMode: true,
				hasSelectedMedication: true,
				medicationName: itemToEdit.medicationName,
				medicationRoute: itemToEdit.medicationRoute,
				form_id: itemToEdit.form_id,
				treatment_days: treatment,
				medication_id: itemToEdit.medication_id,
				notes: itemToEdit.notes,
				doses: itemToEdit.doses,
				unit: itemToEdit.unit
			};

			this.setData(data);
		}
	}

	onChangeField() {
		const dosesData = this.state.doses.map(dose => ({
			amount: dose.amount,
			frequency: dose.frequency
		}));

		const data = {
			medicationName: this.state.medicationName,
			treatment_days: this.state.treatment_days,
			medication_id: this.state.medication_id,
			form_id: this.state.form_id,
			notes: this.state.notes,
			doses: dosesData,
			unit: this.state.unit,
			medicationRoute: this.state.medicationRoute
		};

		this.props.currentMedication(data);
	}

	setData(data) {
		let newAmountOptions = [];

		let showMlInput = false;

		if (data.unit === "comprimido") {
			newAmountOptions = getPillAmountOptions();
		} else {
			if (data.unit === "ml") {
				showMlInput = true;
				newAmountOptions = getMillilitersAmountOptions();
			} else {
				newAmountOptions = getDefaultAmountOptions();
			}
		}

		this.setState({
			editPrescriptionId: data.editPrescriptionId,
			isEditMode: data.isEditMode,
			hasSelectedMedication: data.hasSelectedMedication,
			medicationName: data.medicationName,
			medicationRoute: data.medicationRoute,
			form_id: data.form_id,
			treatment_days: data.treatment_days,
			medication_id: data.medication_id,
			notes: data.notes,
			doses: data.doses,
			unit: data.unit,
			amountOptions: newAmountOptions,
			showMlInput: showMlInput
		});
	}

	handleSignin(response) {
		if (response.error) return;
		this.setState({ error: undefined });
	}

	handleSearch(event) {
		this.doSearch(event.target.value.toString());
		this.setState({ medicationName: event.target.value }, function() {
			this.onChangeField();
		});
		this.props.setFormInProgress(true);
	}

	doSearch = debounce(
		async value => {
			if (value.length < 3) {
				this.setState({ shouldShowSuggestion: false, loading: false });
				return;
			}

			this.setState({ queryToSearch: value, loading: true });
			const { error } = await this.props.searchMedication({
				query: trim(value)
			});
			if (error) {
				this.setState({ error, loading: false });
				this.props.onError(error);
			} else {
				this.setState({ shouldShowSuggestion: true, loading: false });
			}
		},
		1000,
		{ trailing: true }
	);

	handleMedicationClick(medication, variation) {
		let newAmountOptions = [];
		let newInitialAmountOption;
		let showMlInput = false;

		if (variation.unit === "comprimido") {
			newAmountOptions = getPillAmountOptions();
			newInitialAmountOption = newAmountOptions[3].value;
		} else {
			if (variation.unit === "ml") {
				showMlInput = true;
				newAmountOptions = getMillilitersAmountOptions();
				newInitialAmountOption = newAmountOptions[1].value;
			} else {
				newAmountOptions = getDefaultAmountOptions();
				newInitialAmountOption = newAmountOptions[0].value;
			}
		}

		const dosesData = this.state.doses.map(dose => ({
			amount: newInitialAmountOption,
			frequency: dose.frequency
		}));

		this.setState(
			{
				hasSelectedMedication: true,
				medication_id: medication.id,
				form_id: variation.id,
				medicationRoute: variation.route,
				unit: variation.unit,
				notes: variation.note || "",
				treatment_days: TREATMENT_DEFAULT_VALUE,
				medicationName: medication.name,
				shouldShowSuggestion: false,
				amountOptions: newAmountOptions,
				doses: dosesData,
				showMlInput: showMlInput
			},
			function() {
				this.onChangeField();
			}
		);
	}

	handleCancel(event) {
		if (event) event.preventDefault();

		this.resetFormState(event);
		this.props.onCancel();
	}

	handleSubmit(event) {
		event.preventDefault();

		const { medicationName, hasSelectedMedication } = this.state;

		if (!medicationName) {
			Alert.error(ALERT.prescription.medicationNameEmpty, { offset: 39 });
			return;
		}

		if (!hasSelectedMedication) {
			Alert.error(ALERT.prescription.chooseMedicationFromList, { offset: 39 });
			return;
		}

		const dosesData = this.state.doses.map(dose => ({
			amount: dose.amount,
			frequency: dose.frequency
		}));

		const data = {
			medicationName: this.state.medicationName,
			treatment_days: this.state.treatment_days,
			medication_id: this.state.medication_id,
			form_id: this.state.form_id,
			notes: this.state.notes,
			doses: dosesData,
			unit: this.state.unit,
			medicationRoute: this.state.medicationRoute
		};

		if (this.state.isEditMode) {
			this.props
				.editMedication(this.state.editPrescriptionId, data)
				.then(() => {
					this.props.currentMedication({});
					//Alert.success(ALERT.prescription.edited, { offset: 39 });
					this.handleCancel();
				});
		} else {
			this.props.addMedication(data).then(() => {
				this.props.currentMedication({});
				//Alert.success(ALERT.prescription.added, { offset: 39 });
				this.handleCancel();
			});
		}
	}

	resetFormState(e) {
		if (e) {
			e.preventDefault();
		}

		this.setState({
			hasSelectedMedication: false,
			medicationName: "",
			queryToSearch: "",
			suggestion: [],
			shouldShowSuggestion: false,
			isEditMode: false,
			medicationRoute: "",
			treatment_days: TREATMENT_DEFAULT_VALUE,
			unit: "",
			notes: "",
			amountOptions: AMOUNT_OPTIONS,
			doses: [
				{
					amount: AMOUNT_DEFAULT_OPTION,
					frequency: FREQUENCY_DEFAULT_VALUE
				}
			],
			showMlInput: false
		});

		this.props.currentMedication({});
		this.props.isEditMedicationMode({});
		this.props.setFormInProgress(false);
	}

	addNewDose(event) {
		event.preventDefault();
		const newDoses = this.state.doses;
		newDoses.push({
			amount: AMOUNT_DEFAULT_OPTION,
			frequency: FREQUENCY_DEFAULT_VALUE
		});
		this.setState({ doses: newDoses });
	}

	deleteDose(event, key) {
		event.preventDefault();
		const newDoses = this.state.doses;
		const first = newDoses.slice(0, key);
		const second = newDoses.slice(key + 1);
		this.setState({ doses: first.concat(second) });
		this.onChangeField();
	}

	handleDoseChange(index, type, event) {
		const { doses } = this.state;
		const editedDose = doses[index];

		switch (type) {
			case "amount":
				editedDose.amount = event.target.value;
				break;
			case "frequency":
				editedDose.frequency = event.target.value;
				break;
			default:
				return;
		}

		const newDoses = [].concat(
			doses.slice(0, index),
			editedDose,
			doses.slice(index + 1)
		);

		this.setState({ doses: newDoses });
		this.onChangeField();
	}

	renderSuggestions(item) {
		const { forms } = item;
		const singleForm = forms.length === 1;

		return forms.map(variation => {
			let title = `${item.name}`;
			let form = `${variation.form}`;
			const subtitle = `${item.active_ingredient}`;

			if (!singleForm) {
				form += ` (${variation.unit})`;
			}

			return (
				<tr
					key={variation.id}
					className="suggestion-result__item"
					onClick={partial(this.handleMedicationClick, item, variation)}
				>
					<td>
						<span className="medication">
							<b>
								<Highlighter
									highlightClassName="highlight-word"
									searchWords={this.state.queryToSearch.split(" ")}
									textToHighlight={title}
									autoEscape={true}
								/>
							</b>
							<span className="form"> {form}</span>
						</span>
						<small>
							<Highlighter
								highlightClassName="highlight-word"
								searchWords={this.state.queryToSearch.split(" ")}
								textToHighlight={subtitle}
								autoEscape={true}
							/>
						</small>
					</td>
					<td className="txt-r">{item.manufacturer}</td>
				</tr>
			);
		});
	}

	renderDoseLine(item, key) {
		const { doses } = this.state;
		const currentDose = doses[key];

		return (
			<Grid
				container
				direction={"row"}
				alignItems={"center"}
				justify={"space-between"}
				spacing={2}
			>
				<Grid item xs={3}>
					{!this.state.showMlInput && (
						<TextField
							id="amount"
							label="Quantidade"
							name="amount"
							items={TREATMENT_OPTIONS}
							select
							value={currentDose.amount}
							onChange={event => {
								this.handleDoseChange(key, "amount", event);
							}}
							margin="normal"
							variant="filled"
							required
							fullWidth
						>
							{this.state.amountOptions.map((option, index) => (
								<MenuItem key={index} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>
					)}

					{this.state.showMlInput && (
						<TextField
							id="amount"
							name="amount"
							type="number"
							value={this.state.amountOptions}
							onChange={event => {
								this.handleDoseChange(key, "amount", event);
							}}
							label="Quantidade"
							margin="normal"
							variant="filled"
							fullWidth
						/>
					)}
				</Grid>
				<Grid item xs={3}>
					<Input
						label="Unidade"
						type="text"
						name="unit"
						value={this.state.unit}
						readOnly
					/>
				</Grid>
				<Grid item xs={3}>
					<TextField
						id="frequency"
						label="Como tomar"
						name="frequency"
						select
						value={currentDose.frequency}
						onChange={event => {
							this.handleDoseChange(key, "frequency", event);
						}}
						margin="normal"
						variant="filled"
						required
						fullWidth
					>
						{FREQUENCY_OPTIONS.map((option, index) => (
							<MenuItem key={index} value={option}>
								{option.label}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item xs={3}>
					{doses.length > 1 && (
						<Button
							type="danger"
							onClick={event => {
								this.deleteDose(event, key);
							}}
							mt={20}
							mb={0}
						>
							<Icon name="close" />
						</Button>
					)}
					{key === doses.length - 1 && (
						<Flex align="baseline">
							<Button
								type="primary"
								size={14}
								onClick={this.addNewDose}
								mt={45}
								mb={0}
							>
								<Icon name="add" />
							</Button>
							<Button
								type="raw-primary"
								size={14}
								onClick={this.addNewDose}
								mt={45}
								mb={0}
							>
								Adicione outra forma de tomar
							</Button>
							<HelpInfo position="right">
								Adicionar outra forma de tomar. Exemplo: 1 comprimido no almoço
								e 1 comprimido no jantar
							</HelpInfo>
						</Flex>
					)}
				</Grid>
			</Grid>
		);
	}

	render() {
		const { classes } = this.props;
		const { doses, isEditMode } = this.state;
		const submitLabel = isEditMode
			? "Salvar alterações"
			: "Adicionar medicamento";

		return (
			<div className={classes.root}>
				<div className={classes.options}>
					<Close onClick={this.handleCancel} className={classes.closeButton} />
				</div>
				<Grid
					container
					direction={"column"}
					alignItems={"stretch"}
					justify={"flex-start"}
					spacing={2}
					className={classes.form}
				>
					<Grid item component={RelativeWrapper}>
						<Input
							autoFocus
							label={
								this.props.isEditMode
									? "Edite o medicamento"
									: "Selecione o medicamento"
							}
							type="text"
							name="medication"
							autoComplete="off"
							value={this.state.medicationName}
							onChange={this.handleSearch}
							readOnly={this.state.loading}
						/>

						{this.state.loading && (
							<AbsoluteWrapper right={0} top={30}>
								<Loader />
							</AbsoluteWrapper>
						)}

						{this.state.shouldShowSuggestion && (
							<div className="suggestion-result">
								{this.state.suggestion.length ? (
									<Table>
										<TableBody>
											{this.state.suggestion.map(item =>
												this.renderSuggestions(item)
											)}
										</TableBody>
									</Table>
								) : (
									<p>Nenhum resultado encontrado.</p>
								)}
							</div>
						)}
					</Grid>
					<Grid item>
						<Grid
							container
							direction={"row"}
							alignItems={"center"}
							justify={"space-between"}
							spacing={2}
						>
							<Grid item xs={6}>
								<Input
									label="Via"
									type="text"
									name="route"
									value={this.state.medicationRoute}
									readOnly
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									id="treatment-days"
									label="Tempo de uso"
									name="treatment-days"
									items={TREATMENT_OPTIONS}
									select
									value={this.state.treatment_days}
									onChange={event => {
										let treatmentDays = TREATMENT_DEFAULT_VALUE;
										if (event && !isArray(event))
											treatmentDays = event.target.value;
										this.setState({ treatment_days: treatmentDays });
										this.onChangeField();
									}}
									margin="normal"
									variant="filled"
									required
									fullWidth
								>
									{TREATMENT_OPTIONS.map((option, index) => (
										<MenuItem key={index} value={option.value}>
											{option.label}
										</MenuItem>
									))}
								</TextField>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						{doses.map((dose, key) => this.renderDoseLine(dose, key))}
					</Grid>
					<Grid item>
						<Input
							label="Observações"
							name="notes"
							value={this.state.notes}
							onChange={event => {
								this.setState({ notes: event.target.value });
								this.onChangeField();
							}}
							multiLine
						>
							{this.state.notes}
						</Input>
					</Grid>
					<Grid item>
						<Grid
							container
							direction={"row"}
							alignItems={"center"}
							justify={"center"}
							spacing={2}
						>
							<Grid item>
								<Button type="bordered-danger" onClick={this.handleCancel}>
									<Icon name="close" />
									<span>Cancelar</span>
								</Button>
							</Grid>
							<Grid item>
								<Button type="bordered-primary" onClick={this.handleSubmit}>
									<Icon name="add" />
									<span> {submitLabel} </span>
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(RenewPrescriptionForm));
