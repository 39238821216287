import React from "react";

import { rem } from "polished";
import classnames from "classnames";
import { Grid, withStyles } from "@material-ui/core";

const styles = () => ({
	root: {
		zIndex: 2,
		height: rem(64),
		backgroundColor: "#FFFFFF",
		position: "fixed",
		top: rem(64),
		left: 0,
		padding: `0 ${rem(24)}`,
		borderBottom: `${rem(1)} solid #9AAFBF`
	}
});

const SubMenu = ({ classes, children }) => {
	return (
		<Grid
			container
			className={classnames(classes.root, "noPrint")}
			alignItems="flex-end"
		>
			{children}
		</Grid>
	);
};

export default withStyles(styles)(SubMenu);
