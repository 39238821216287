import React, { PureComponent } from "react";

import { rem } from "polished";
import { Formik, Field } from "formik";

import { Grid, withStyles } from "@material-ui/core";

import ProgressButton from "../../UI/ProgressButton";

import FormTextField from "../../UI/Form/TextField";
import validationSchema from "./Validation";

const styles = () => ({
	root: {},
	textfield: { width: "100%", maxWidth: rem(394) },
	submitButton: {
		margin: `${rem(25)} 0`
	}
});

class ProfileAccountForm extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			showFields: false
		};
	}

	render() {
		const {
			classes,
			initialData,
			onFieldChange,
			onSubmit,
			saving,
			saved
		} = this.props;
		const { showFields } = this.state;

		const saveButtonText = saved
			? "Alterações salvas!"
			: saving
			? "Salvando..."
			: "Salvar dados de login e senha";

		return (
			<Formik
				isInitialValid={false}
				initialValues={initialData}
				validationSchema={validationSchema}
				onSubmit={values => onSubmit(values)}
			>
				{({ handleSubmit, isValid }) => (
					<Grid
						container
						direction="column"
						justify="center"
						alignItems="center"
						className={classes.root}
						component="form"
						onSubmit={handleSubmit}
					>
						<Field
							name="email"
							type="email"
							label="Email"
							className={classes.textfield}
							required
							component={FormTextField}
							onChange={onFieldChange}
						/>
						{showFields && (
							<Field
								name="emailConfirmation"
								type="email"
								label="Confirmar email"
								className={classes.textfield}
								required
								component={FormTextField}
								onChange={onFieldChange}
							/>
						)}
						<Field
							name="password"
							type="password"
							label="Senha"
							className={classes.textfield}
							required
							component={FormTextField}
							onChange={onFieldChange}
						/>
						{showFields && (
							<Field
								name="passwordConfirmation"
								type="password"
								label="Confirmar senha"
								className={classes.textfield}
								required
								component={FormTextField}
								onChange={onFieldChange}
							/>
						)}

						<Grid item className={classes.submitButton}>
							{!showFields && (
								<ProgressButton
									onClick={e => {
										e.preventDefault();
										this.setState({ showFields: true });
									}}
									text="Alterar dados de login e senha"
									style={{ width: "300px" }}
								/>
							)}

							{showFields && (
								<ProgressButton
									showProgress={saving}
									showSuccess={saved}
									disabled={saving || !isValid}
									text={saveButtonText}
									style={{ width: "300px" }}
								/>
							)}
						</Grid>
					</Grid>
				)}
			</Formik>
		);
	}
}

export default withStyles(styles)(ProfileAccountForm);
