import React, { PureComponent } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CryptoJS from "crypto-js";
import ReactGA from "react-ga";

import { Grid, withStyles } from "@material-ui/core";

import { updateUserProfile, getUserProfile } from "../../../js/actions/user";

import Page from "../../UI/Page";
import Form from "./Form";
import styles from "../Style";

class ProfileAccount extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			loaded: false,
			isSaving: false,
			isProfileUpdated: false
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleFieldChange = this.handleFieldChange.bind(this);
	}

	async componentDidMount() {
		await this.props.getUserProfile();
		this.setState({ loaded: true });
	}

	handleFieldChange() {
		this.setState({ isProfileUpdated: false });
	}

	encryptPassword(plainPassword) {
		const parsedPassword = CryptoJS.enc.Utf8.parse(plainPassword);
		return CryptoJS.enc.Base64.stringify(parsedPassword);
	}

	async handleSubmit(values) {
		await this.setState({ isSaving: true });

		const userProfile = {
			...values,
			password: this.encryptPassword(values.password)
		};

		const response = await this.props.updateUserProfile(userProfile);

		if (response) {
			console.error(response);
		} else {
			ReactGA.event({
				category: "profile",
				action: "submit-form",
				label: "account-info"
			});
			await this.setState({ isProfileUpdated: true });
		}

		await this.setState({ isSaving: false });
	}

	render() {
		const { classes, user } = this.props;
		const { loaded, isSaving, isProfileUpdated } = this.state;

		return (
			loaded && (
				<Page width={816} backgroundColor="#EFF2F5" paddingTop={128}>
					<Grid container>
						<Grid item xs={12}>
							<h1 className={classes.title}>Login e senha</h1>
						</Grid>
						<Grid item xs={12}>
							<Form
								initialData={user}
								saving={isSaving}
								saved={isProfileUpdated}
								onSubmit={this.handleSubmit}
								onFieldChange={this.handleFieldChange}
							/>
						</Grid>
					</Grid>
				</Page>
			)
		);
	}
}

const mapStateToProps = state => ({
	user: state.user
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			getUserProfile,
			updateUserProfile
		},
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(ProfileAccount));
