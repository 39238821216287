import React, { PureComponent, Fragment } from "react";

import PhysicianWithCloud from "../../../img/home/physician.svg";
import PlantWithCloud from "../../../img/home/plant.svg";
import Arrow from "../../../img/home/arrow.svg";

import { Grid, withStyles } from "@material-ui/core";

import styles from "../Style";

class index extends PureComponent {
	static propTypes = {};

	render() {
		const { arrowLeftPosition, classes, userName } = this.props;
		return (
			<Fragment>
				{arrowLeftPosition && (
					<img
						src={Arrow}
						alt="arrow"
						className={classes.arrow}
						style={{ left: arrowLeftPosition }}
					/>
				)}
				<Grid container alignItems="center" justify="center">
					<Grid item>
						<img
							src={PhysicianWithCloud}
							alt="physician-illustration"
							className={classes.physicianWithCloud}
						/>
					</Grid>
					<Grid item>
						<h1 className={classes.title}>
							Seja bem-vindo(a), {userName}.{" "}
							<span className={classes.titleBreak}>
								Vamos criar uma receita?
							</span>
						</h1>
					</Grid>
					<Grid item className={classes.alignEnd}>
						<img
							src={PlantWithCloud}
							alt="plant-illustration"
							className={classes.plantWithCloud}
						/>
					</Grid>
				</Grid>
			</Fragment>
		);
	}
}

export default withStyles(styles)(index);
