import styled from 'styled-components'

const PageTitle = styled.h2`
  margin: 45px 0 5px 0;
  font-size: 24px;
  font-weight: 700;
  color: #7C7CE4;

  span {
    color: #333;
  }
`

export default PageTitle
