import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { ArrowBack } from "@material-ui/icons";

const styles = () => ({
	root: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "4rem",
		height: "4rem",
		backgroundColor: "#EFF2F5",
		cursor: "pointer",
		color: "inherit",
		"&:hover": {
			backgroundColor: "#E7ECF0"
		}
	},
	flex: {
		flex: 1
	}
});

const BackButton = React.forwardRef(
	({ classes, flex, onClick, style }, ref) => (
		<div ref={ref} className={flex ? classes.flex : ""}>
			<div style={style} className={classes.root} onClick={onClick}>
				<ArrowBack />
			</div>
		</div>
	)
);

export default withStyles(styles)(BackButton);
