import React from "react";

import { rem } from "polished";
import { Grid, withStyles } from "@material-ui/core";

const styles = () => ({
	root: {
		borderStyle: "solid",
		borderWidth: `${rem(1)} 0 0 0`,
		borderColor: "#FFFFFF",
		padding: `0 ${rem(6)}`,
		textDecoration: "none"
	}
});

const ListRow = ({ children, classes, height, justify, key, ...other }) => {
	return (
		<Grid item xs={12} key={key}>
			<Grid
				container
				justify={justify ? justify : "space-between"}
				className={classes.root}
				style={height ? { height: height } : {}}
				{...other}
			>
				{children}
			</Grid>
		</Grid>
	);
};

export default withStyles(styles)(ListRow);
