import React, { Component } from "react";

import ReactGA from "react-ga";

import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import validator from "validator";
import CryptoJS from "crypto-js";
import Input from "./Input";
import Button from "./Button";
import Icon from "./Icon";
import { confirmResetUserPassword, signin } from "../actions/user";
import Alert from "react-s-alert";
import * as ALERT from "../data/alerts";
import { Flex, Box } from "grid-styled";

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      confirmResetUserPassword,
      signin
    },
    dispatch
  );

class RecoverPassword extends Component {
  static propTypes = {
    token: PropTypes.string,
    confirmResetUserPassword: PropTypes.func,
    signin: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      resetToken: null
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePass = this.handlePass.bind(this);
  }

  componentWillMount() {
    this.setState({
      resetToken: this.props.token
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    ReactGA.event({
      category: "reset-password",
      action: "submit-form",
      label: "reset-password"
    });

    const data = {
      email: this.state.email,
      password: this.handlePass(this.state.password)
    };

    if (validator.isEmpty(data.email)) {
      Alert.error(ALERT.form.missingEmail);
      return;
    }

    if (validator.isEmpty(data.password)) {
      Alert.error(ALERT.form.missingPassword);
      return;
    }

    if (!validator.isEmail(data.email)) {
      Alert.error(ALERT.form.invalidEmail);
      return;
    }

    this.props
      .confirmResetUserPassword(data, this.state.resetToken)
      .then(() => {
        ReactGA.event({
          category: "reset-password",
          action: "reset-password-success",
          value: 1
        });
        this.props
          .signin(data.email, this.state.password)
          .then(() => {
            //Alert.success(ALERT.recover.success);
            this.props.history.push("/inicio");
          })
          .catch(() => {
            Alert.error(ALERT.recover.invalidData);
            return;
          });
      })
      .catch(() => {
        ReactGA.event({
          category: "reset-password",
          action: "reset-password-error",
          value: 1
        });
        Alert.error(ALERT.generic.somethingWrong);
        return;
      });
  }

  handlePass(value) {
    const parsed = CryptoJS.enc.Utf8.parse(value);
    const pass = CryptoJS.enc.Base64.stringify(parsed);
    return pass;
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <Flex my={10}>
          <Box width={1}>
            <Input
              type="email"
              name="email"
              placeholder="Email"
              onChange={event => {
                this.setState({ email: event.target.value });
              }}
            />
          </Box>
        </Flex>

        <Flex my={10}>
          <Box width={1}>
            <Input
              type="password"
              name="password"
              placeholder="Nova Senha"
              onChange={event => {
                this.setState({ password: event.target.value });
              }}
            />
          </Box>
        </Flex>

        <Flex my={10}>
          <Box width={1}>
            <Flex justify="center">
              <Button type="submit">
                <Icon name="send" />
                <span>Enviar</span>
              </Button>
            </Flex>
          </Box>
        </Flex>
      </form>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RecoverPassword)
);
