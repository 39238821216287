import React from "react";

import { rem } from "polished";
import { Grid, withStyles } from "@material-ui/core";

const styles = () => ({
	root: {
		borderStyle: "solid",
		borderWidth: `0 0 ${rem(1)} 0`,
		borderColor: "#FFFFFF"
	}
});

const List = ({ children, classes }) => {
	return (
		<Grid container className={classes.root}>
			{children}
		</Grid>
	);
};

export { default as Row } from "./Row";
export { default as Cell } from "./Cell";
export { default as Button } from "./Button";
export default withStyles(styles)(List);
