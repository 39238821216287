import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import classNames from "classnames";

import { KeyboardArrowDown } from "@material-ui/icons";
import { Button, Menu as MaterialMenu, withStyles } from "@material-ui/core";

import { isAuthenticated } from "../../../../js/utils/authCheck";
import UserWithoutPicture from "../../../../img/home/user.svg";

import Item from "./Item";
import styles from "../Style";

class Menu extends Component {
	constructor(props) {
		super(props);

		this.state = { anchorEl: null };

		this.logout = this.logout.bind(this);
		this.handleExitPrescription = this.handleExitPrescription.bind(this);
		this.handleMenu = this.handleMenu.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	async logout(event) {
		event.preventDefault();
		await this.props.onSignOut();
		if (!isAuthenticated()) {
			this.props.history.push("/entrar");
		}
	}

	handleExitPrescription(e, route) {
		e.preventDefault();
		this.props.history.push(route);
	}

	handleMenu = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	render() {
		const { classes, user } = this.props;
		const { anchorEl } = this.state;
		const open = Boolean(anchorEl);

		return (
			<div>
				<Button
					className={classes.button}
					aria-owns={open ? "menu-appbar" : null}
					aria-haspopup="true"
					onClick={this.handleMenu}
				>
					<div className={classNames(classes.profileIcon, classes.leftIcon)}>
						<img src={UserWithoutPicture} alt="user-without" />
					</div>
					<KeyboardArrowDown />
				</Button>
				<MaterialMenu
					id="menu-appbar"
					anchorEl={anchorEl}
					getContentAnchorEl={null}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center"
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "center"
					}}
					open={open}
					onClose={this.handleClose}
				>
					<Item>{`${user.first_name} ${user.last_name}`}</Item>
					<Item
						onClick={event =>
							this.handleExitPrescription(event, "/meu-perfil/dados-pessoais")
						}
					>
						Meu Perfil
					</Item>
					<Item
						onClick={event =>
							this.handleExitPrescription(
								event,
								"/meu-perfil/local-de-trabalho"
							)
						}
					>
						Locais de trabalho
					</Item>
					<Item
						component={"a"}
						href="http://www.pulsares.com.br/#!/contato"
						target="_blank"
					>
						Contato
					</Item>
					<Item onClick={this.logout}>Sair</Item>
				</MaterialMenu>
			</div>
		);
	}
}

export default withRouter(withStyles(styles)(Menu));
