import React, { PureComponent, createElement, Fragment } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { pick } from "lodash";
import { withRouter } from "react-router-dom";
import CryptoJS from "crypto-js";
import { goToTop } from "react-scrollable-anchor";

import withWidth, { isWidthDown } from "@material-ui/core/withWidth";

import Account from "./Account";
import GeneralInfo from "./GeneralInfo";

import ConflictError from "./Error/Conflict";

import { signup } from "./actions";
import { signin } from "../../js/actions/user";

import DriftButton from "../UI/DriftButton";
import ReactGA from "react-ga";

class SignUp extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			currentStep: 0,
			errors: {},
			showForm: true,
			showConflictError: false
		};

		this.steps = [Account, GeneralInfo];

		this.SIGNUP_FIELDS = [
			"email",
			"password",
			"first_name",
			"last_name",
			"title",
			"user_type",
			"specialty",
			"phone",
			"allowed_call",
			"tos_read"
		];

		this.previousStep = this.previousStep.bind(this);
		this.nextStep = this.nextStep.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.setData = this.setData.bind(this);
		this.getData = this.getData.bind(this);
		this.isLastStep = this.isLastStep.bind(this);
		this.showErrors = this.showErrors.bind(this);
		this.hideErrors = this.hideErrors.bind(this);
	}

	async previousStep() {
		const { currentStep } = this.state;

		await this.setState({ showForm: false });

		ReactGA.event({
			category: "sign-up",
			action: "button-click",
			label: `previous-${this.state.currentStep}`
		});

		if (currentStep > 0) {
			await this.setState({ currentStep: currentStep - 1 });
			goToTop();
			this.setState({ showForm: true });
		} else {
			this.props.history.push("/");
		}
	}

	async nextStep() {
		await this.setState({ showForm: false });
		await this.setState({ currentStep: this.state.currentStep + 1 });
		goToTop();
		this.setState({ showForm: true });
		ReactGA.event({
			category: "sign-up",
			action: "button-click",
			label: `next-${this.state.currentStep}`
		});
	}

	setData(data) {
		if (data) {
			this.setState({ ...data });
		}
	}

	getData = () => ({ ...pick(this.state, this.SIGNUP_FIELDS) });

	isLastStep = () => this.state.currentStep === this.steps.length - 1;

	async handleSubmit(data, actions) {
		const { signup } = this.props;
		const { isLastStep, setData, getData, nextStep, showErrors } = this;

		await setData(data);

		if (isLastStep()) {
			ReactGA.event({
				category: "sign-up",
				action: "submit-form",
				label: "sign-up"
			});

			const { error, status } = await signup(getData());

			if (error) {
				showErrors(error, status);
				//actions.setSubmitting(false);
				ReactGA.event({
					category: "sign-up",
					action: "sign-up-error",
					value: 1
				});
			} else {
				ReactGA.event({
					category: "sign-up",
					action: "sign-up-success",
					label: "sign-up-form-submit",
					value: 1
				});

				this.signIn();
			}
		} else {
			nextStep();
		}
	}

	async signIn() {
		const parsed = CryptoJS.enc.Base64.parse(this.state.password);
		const plainPassword = CryptoJS.enc.Utf8.stringify(parsed);

		await this.props.signin(this.state.email, plainPassword);

		this.props.history.push("/inicio");
	}

	showErrors(errors, status) {
		this.setState({ showConflictError: true });
	}

	hideErrors() {
		this.setState({ showConflictError: false });
	}

	decryptPass(password) {
		const parsed = CryptoJS.enc.Base64.parse(password);
		return CryptoJS.enc.Utf8.stringify(parsed);
	}

	createStepComponent = step =>
		createElement(step, {
			data: { ...this.state },
			onSubmit: this.handleSubmit,
			onBackClick: this.previousStep,
			show: this.state.showForm
		});

	render() {
		const { isLoading } = this.props;
		const { state, createStepComponent, steps } = this;
		const { showConflictError } = this.state;

		const isSmallScreen = isWidthDown("sm", this.props.width);

		const step = steps[state.currentStep];
		const CurrentStep = () => createStepComponent(step);

		return (
			<Fragment>
				<CurrentStep />

				<ConflictError
					onClose={this.hideErrors}
					onSubmit={(values, actions) => this.handleSubmit(values, actions)}
					data={{ ...this.state }}
					show={!isLoading && showConflictError}
				/>
				{!isSmallScreen && <DriftButton />}
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	isLoading: state.loader.isLoading
});

const mapDispatchToProps = dispatch =>
	bindActionCreators({ signup, signin }, dispatch);
export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(withWidth()(SignUp))
);
