import React, { useEffect } from "react";
import ReactGA from "react-ga";
import FacebookPixel from "./config/pixel";
import {GA_TRACKING_CODE} from "./config/api";

export const withTracker = (WrappedComponent, options = {}) => {
	ReactGA.initialize(GA_TRACKING_CODE)

	const trackPage = page => {
		ReactGA.set({
			page,
			...options
		});

		ReactGA.pageview(page);
		FacebookPixel.pageView();
	};

	const HOC = props => {
		useEffect(() => trackPage(props.location.pathname), [
			props.location.pathname
		]);

		return <WrappedComponent {...props} />;
	};

	return HOC;
};
