import React, { PureComponent } from "react";

import PrescriptionCreation from "../components/PrescriptionCreation";

class PrescriptionCreationScreen extends PureComponent {
	render() {
		const { location } = this.props;
		const toCopy = location.pathname.replace(/\//g, "").endsWith("copiar");

		return (
			<PrescriptionCreation id={this.props.match.params.id} toCopy={toCopy} />
		);
	}
}

export default PrescriptionCreationScreen;
