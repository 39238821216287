import React, { PureComponent } from "react";

import {
	TextField as MaterialTextField,
	InputAdornment,
	IconButton
} from "@material-ui/core";
import { Visibility, VisibilityOff, Done } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
	asterisk: {
		display: "none"
	},
	formControl: {
		"&::after": {
			content: "' (opcional)'"
		}
	},
	input: {
		backgroundColor: "rgba(231, 236,240, 0.4)"
	},
	done: {
		color: "#42C39B"
	}
});

class PasswordField extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			showPassword: false
		};
	}

	handleClickShowPassword = () => {
		this.setState(state => ({ showPassword: !state.showPassword }));
	};

	render() {
		const { classes, verified, required, shrink } = this.props;
		const formLabelClasses = {
			classes: { asterisk: classes.asterisk }
		};
		const inputLabelclasses = {
			classes: {
				formControl: classes.formControl
			}
		};

		const labelPropsRequired = required
			? { ...formLabelClasses }
			: { ...inputLabelclasses };

		const inputLabelProps = {
			...labelPropsRequired,
			shrink: shrink
		};
		const inputPropsClasses = {
			classes: {
				root: classes.input
			}
		};

		const visibleEndornment = (
			<InputAdornment variant="filled" position="end">
				<IconButton
					aria-label="Toggle password visibility"
					onClick={this.handleClickShowPassword}
				>
					{this.state.showPassword ? <VisibilityOff /> : <Visibility />}
				</IconButton>
			</InputAdornment>
		);

		const verifiedEndornment = (
			<InputAdornment
				classes={{ filled: classes.done }}
				variant="filled"
				position="end"
			>
				<Done />
			</InputAdornment>
		);

		return (
			<MaterialTextField
				{...this.props}
				type={this.state.showPassword ? "text" : "password"}
				InputLabelProps={inputLabelProps}
				InputProps={{
					...inputPropsClasses,
					endAdornment: verified ? verifiedEndornment : visibleEndornment
				}}
			/>
		);
	}
}

export default withStyles(styles)(PasswordField);
