import React, { PureComponent, Fragment } from "react";

import PatientPage from "../../components/Patient/Page";
import MenuBar from "../../components/UI/MenuBar";
import MenuItem from "../../components/UI/MenuBar/MenuItem";
import SubMenu from "../../components/UI/MenuBar/SubMenu";
import SubMenuItem from "../../components/UI/MenuBar/SubMenu/SubMenuItem";
import Footer from "../../components/UI/Footer";

class PatientPageScreen extends PureComponent {
	render() {
		return (
			<Fragment>
				<MenuBar
					buttonText="Adicionar paciente"
					buttonIcon="person_add"
					buttonGoTo="/novo-paciente"
					showButton
					showAlerts
					showSettings
					showBackButton
					onBackClick={() => this.props.history.goBack()}
				>
					<MenuItem active to={"/meus-pacientes"}>
						Meus pacientes
					</MenuItem>
				</MenuBar>
				<SubMenu>
					<SubMenuItem active to="/meus-pacientes" icon="contacts">
						Pacientes cadastrados
					</SubMenuItem>
				</SubMenu>
				<PatientPage id={this.props.match.params.id} />
				<Footer />
			</Fragment>
		);
	}
}

export default PatientPageScreen;
