export const GENDER_OPTIONS = [
  {
    value: "",
    label: "Gênero"
  },
  {
    value: "M",
    label: "Masculino"
  },
  {
    value: "F",
    label: "Feminino"
  }
];

export const FIRST_INTAKE_OPTIONS = [
  {
    value: "05:00",
    label: "05h"
  },
  {
    value: "06:00",
    label: "06h"
  },
  {
    value: "07:00",
    label: "07h"
  },
  {
    value: "08:00",
    label: "08h"
  },
  {
    value: "09:00",
    label: "09h"
  },
  {
    value: "10:00",
    label: "10h"
  }
];

export const LAST_INTAKE_OPTIONS = [
  {
    value: "20:00",
    label: "20h"
  },
  {
    value: "21:00",
    label: "21h"
  },
  {
    value: "22:00",
    label: "22h"
  },
  {
    value: "23:00",
    label: "23h"
  },
  {
    value: "00:00",
    label: "0h"
  }
];
