import styled, { css } from "styled-components";
import { rem, darken } from "polished";
import { space } from "styled-system";
import ButtonWrapper from "./ButtonWrapper";

const Button = styled(ButtonWrapper)`
  display: flex,
  align-items: center,
  height: ${props => rem(props.size || 43)};
  padding: 0;
  margin-top: ${rem(10)};
  margin-bottom: ${rem(20)};
  font-size: ${props => rem(props.size || 15)};
  font-weight: bold;
  line-height: ${props => rem(props.size || 43)};
  color: white;
  border: 0;
  border-radius: ${rem(25)};
  cursor: pointer;
  transition: all 0.3s;
  ${props => (props.large ? "width: 75%;" : "")}
  ${space}

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.4;
  }

  i {
    ${props => (props.large ? "float: left" : "")}
  }

  i, span {
    display: inline-block;
    vertical-align: middle;
  }

  i {
    width: ${props => rem(props.size || 43)};
    height: ${props => rem(props.size || 43)};
    font-size: ${props => rem(props.size || 15)};
    text-align: center;
    line-height: ${props => rem(props.size || 40)};
    background-color: #6666CC;
    border-radius: 25px;

    :hover {
      color: #fff;
    }
  }

  span {
    margin: 0 ${rem(15)} 0 ${props =>
  props.large && props.withIcon ? "-43px !important" : rem(15)}
  }

  ${props =>
    props.type === "raw-primary" &&
    css`
      color: #7c7ce4;
      text-decoration: underline;
    `}

  ${props =>
    props.type === "bordered-submit" &&
    css`
      background-color: transparent;
      border: 1px solid #7c7ce4;
      color: #7c7ce4;
      :hover {
        border-color: ${darken(0.05, "#7C7CE4")};
        color: ${darken(0.05, "#7C7CE4")};
      }
      i {
        background-color: rgba(0, 0, 0, 0.05);
      }
    `}

  ${props =>
    props.type === "bordered-primary" &&
    css`
      background-color: transparent;
      border: 1px solid #7c7ce4;
      color: #7c7ce4;
      :hover {
        border-color: ${darken(0.05, "#7C7CE4")};
        color: ${darken(0.05, "#7C7CE4")};
      }

      i {
        :hover {
          color: ${darken(0.05, "#7C7CE4")};
        }
        background-color: #fff;
        margin-bottom: 2px;
        width: ${props => rem(props.size || 39)};
        height: ${props => rem(props.size || 39)};
      }
    `}

  ${props =>
    props.type === "bordered-danger" &&
    css`
      background-color: transparent;
      border: 1px solid #c62828;
      color: #c62828;
      :hover {
        border-color: ${darken(0.05, "#C62828")};
        color: ${darken(0.05, "#C62828")};
      }
      i {
        :hover {
          color: ${darken(0.05, "#c62828")};
        }
        background-color: #fff;
        margin-bottom: 2px;
        width: ${props => rem(props.size || 39)};
        height: ${props => rem(props.size || 39)};
      }
    `}

  ${props =>
    props.type === "submit" &&
    css`
      background-color: #7c7ce4;
      :hover {
        background-color: ${darken(0.05, "#7C7CE4")};
      }
    `}

  ${props =>
    props.type === "primary" &&
    css`
      background-color: #7c7ce4;
      :hover {
        background-color: ${darken(0.05, "#7C7CE4")};
      }
    `}

  ${props =>
    props.type === "danger" &&
    css`
      background-color: #c62828;
      :hover {
        background-color: ${darken(0.05, "#C62828")};
      }
    `}
`;

export default Button;
