import React, { PureComponent, Fragment } from "react";

import { withStyles } from "@material-ui/core";
import PlayArrow from "@material-ui/icons/PlayArrow";

import YouTube from "react-youtube";

import Modal from "../UI/Modal.jsx";

import styles from "./Style";

class videoWithCover extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false
		};

		this.handleClick = this.handleClick.bind(this);
		this.handleModalClose = this.handleModalClose.bind(this);
	}

	handleClick() {
		this.setState({ showModal: true });
	}

	handleModalClose() {
		this.setState({ showModal: false });
	}

	render() {
		const { alt, classes, coverImage, videoId } = this.props;

		return (
			<Fragment>
				<div className={classes.videoCover}>
					<div className={classes.playButton}>
						<PlayArrow className={classes.play} onClick={this.handleClick} />
					</div>
					<img src={coverImage} alt={alt} className={classes.cover} />
				</div>
				<Modal
					show={this.state.showModal}
					onClose={this.handleModalClose}
					title={this.props.title}
				>
					<YouTube
						videoId={videoId}
						opts={{
							playerVars: {
								autoplay: 1
							}
						}}
					/>
				</Modal>
			</Fragment>
		);
	}
}

export default withStyles(styles)(videoWithCover);
