import axios from "axios";
import storageService from "./storage";
import accessService from "./access";
import Utility from "./Utility";

export default {
  ensureAuthentication(promise) {
    const token = accessService.getAccessToken();
    if (!token) {
      return accessService.refreshAccessToken(promise);
    }

    return promise();
  },

  // Generic methods with authorization
  fetch(url, payload = {}) {
    return this.ensureAuthentication(() =>
      axios({
        method: "get",
        url,
        params: payload,
        headers: {
          Authorization: `Bearer ${accessService.getAccessToken()}`
        }
      })
    );
  },

  uploadFile(url, file) {
    const data = new FormData();
    data.append("file", file, file.name);

    return this.ensureAuthentication(() =>
      axios({
        method: "post",
        url,
        data: data,
        headers: {
          Authorization: `Bearer ${accessService.getAccessToken()}`
        }
      })
    );
  },

  create(url, payload = {}) {
    return this.ensureAuthentication(() =>
      axios({
        method: "post",
        url,
        data: payload,
        headers: {
          Authorization: `Bearer ${accessService.getAccessToken()}`
        }
      })
    );
  },

  createWithFile(url, payload = {}) {
    const data = Utility.convertModelToFormData(payload);

    return this.ensureAuthentication(() =>
      axios({
        method: "post",
        url,
        data,
        headers: {
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
          Authorization: `Bearer ${accessService.getAccessToken()}`
        }
      })
    );
  },

  update(url, payload = {}) {
    return this.ensureAuthentication(() =>
      axios({
        method: "put",
        url,
        data: payload,
        headers: {
          Authorization: `Bearer ${accessService.getAccessToken()}`
        }
      })
    );
  },

  delete(url) {
    return this.ensureAuthentication(() =>
      axios({
        method: "delete",
        url,
        headers: {
          Authorization: `Bearer ${accessService.getAccessToken()}`
        }
      })
    );
  },

  getCurrentUser() {
    return storageService().get("user");
  }
};
