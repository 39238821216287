export const getFrequencyOptions = () => [
  {
    label: "1 vez ao dia",
    value: "49c51751-052b-11e8-920d-0800278b9ed0",
    options: ["1 vez ao dia", "uma vez ao dia"]
  },
  {
    label: "2 vezes ao dia",
    value: "e16e1816-2152-11e7-b7d7-0800278b9ed0",
    options: ["2 vezes ao dia", "duas vezes ao dia"]
  },
  {
    label: "3 vezes ao dia",
    value: "e16e3228-2152-11e7-b7d7-0800278b9ed0",
    options: ["3 vezes ao dia", "três vezes ao dia", "tres vezes ao dia"]
  },
  {
    label: "4 vezes ao dia",
    value: "e16e4b7e-2152-11e7-b7d7-0800278b9ed0",
    options: ["4 vezes ao dia", "quatro vezes ao dia"]
  },
  {
    label: "6 vezes ao dia",
    value: "7e33d0b9-640e-11e8-b958-0800275904db",
    options: ["6 vezes ao dia", "seis vezes ao dia"]
  },
  {
    label: "12/12h",
    value: "e16e1816-2152-11e7-b7d7-0800278b9ed0"
  },
  {
    label: "8/8h",
    value: "e16e3228-2152-11e7-b7d7-0800278b9ed0"
  },
  {
    label: "6/6h",
    value: "e16e4b7e-2152-11e7-b7d7-0800278b9ed0"
  },
  {
    label: "4/4h",
    value: "7e33d0b9-640e-11e8-b958-0800275904db"
  },
  {
    label: "manhã",
    value: "e16d824d-2152-11e7-b7d7-0800278b9ed0"
  },
  {
    label: "tarde",
    value: "e16e721f-2152-11e7-b7d7-0800278b9ed0"
  },
  {
    label: "noite",
    value: "e16e8e78-2152-11e7-b7d7-0800278b9ed0"
  },
  {
    label: "antes de dormir",
    value: "89d2a2bd-0abe-11e8-a7f3-0800278b9ed0",
    options: ["antes de dormir", "ao deitar"]
  },
  {
    label: "em jejum",
    value: "e16ec457-2152-11e7-b7d7-0800278b9ed0",
    options: ["em jejum", "jejum"]
  },
  {
    label: "30 minutos antes do café",
    value: "e16f903a-2152-11e7-b7d7-0800278b9ed0"
  },
  {
    label: "antes do café da manhã",
    value: "e16f903a-2152-11e7-b7d7-0800278b9ed0"
  },
  {
    label: "café da manhã",
    value: "e16fc2c0-2152-11e7-b7d7-0800278b9ed0"
  },
  {
    label: "depois do café da manhã",
    value: "43e063a6-d3b1-11e7-9bae-0800278b9ed0",
    options: ["depois do café da manhã", "após o café da manhã"]
  },
  {
    label: "30 minutos antes do almoço",
    value: "e16fa1aa-2152-11e7-b7d7-0800278b9ed0"
  },
  {
    label: "antes do almoço",
    value: "e16fa1aa-2152-11e7-b7d7-0800278b9ed0"
  },
  {
    label: "almoço",
    value: "e16fe9f5-2152-11e7-b7d7-0800278b9ed0"
  },
  {
    label: "1 vez ao dia com refeição",
    value: "e16fe9f5-2152-11e7-b7d7-0800278b9ed0",
    options: ["1 vez ao dia com refeição", "uma vez ao dia com refeição"]
  },
  {
    label: "depois do almoço",
    value: "e16f5143-2152-11e7-b7d7-0800278b9ed0",
    options: ["depois do almoço", "após o almoço"]
  },
  {
    label: "30 minutos antes do jantar",
    value: "e16fb24b-2152-11e7-b7d7-0800278b9ed0"
  },
  {
    label: "antes do jantar",
    value: "e16fb24b-2152-11e7-b7d7-0800278b9ed0"
  },
  {
    label: "jantar",
    value: "96e47a05-7449-11e7-8252-0800278b9ed0"
  },
  {
    label: "depois do jantar",
    value: "e16f710d-2152-11e7-b7d7-0800278b9ed0",
    options: ["depois do jantar", "após o jantar"]
  },
  {
    label: "30 minutos antes da ceia",
    value: "43e14c0b-d3b1-11e7-9bae-0800278b9ed0"
  },
  {
    label: "antes da ceia",
    value: "43e14c0b-d3b1-11e7-9bae-0800278b9ed0"
  },
  {
    label: "ceia",
    value: "43e15c50-d3b1-11e7-9bae-0800278b9ed0"
  },
  {
    label: "depois da ceia",
    value: "43e16ab1-d3b1-11e7-9bae-0800278b9ed0",
    options: ["depois da ceia", "após a ceia"]
  },
  {
    label: "06:00",
    value: "43e17e96-d3b1-11e7-9bae-0800278b9ed0",
    options: ["06:00", "06 horas", "6 da manhã"]
  },
  {
    label: "07:00",
    value: "43e18ecb-d3b1-11e7-9bae-0800278b9ed0",
    options: ["07:00", "07 horas", "7 da manhã"]
  },
  {
    label: "08:00",
    value: "43e19e82-d3b1-11e7-9bae-0800278b9ed0",
    options: ["08:00", "08 horas", "8 da manhã"]
  },
  {
    label: "09:00",
    value: "43e1ae1b-d3b1-11e7-9bae-0800278b9ed0",
    options: ["09:00", "09 horas", "9 da manhã"]
  },
  {
    label: "10:00",
    value: "43e1bdaa-d3b1-11e7-9bae-0800278b9ed0",
    options: ["10:00", "10 horas", "10 da manhã"]
  },
  {
    label: "11:00",
    value: "43e1cd14-d3b1-11e7-9bae-0800278b9ed0",
    options: ["11:00", "11 horas", "11 da manhã"]
  },
  {
    label: "12:00",
    value: "43e1dc73-d3b1-11e7-9bae-0800278b9ed0",
    options: ["12:00", "12 horas"]
  },
  {
    label: "13:00",
    value: "43e1ea56-d3b1-11e7-9bae-0800278b9ed0",
    options: ["13:00", "13 horas", "1 da tarde"]
  },
  {
    label: "14:00",
    value: "43e1fa4b-d3b1-11e7-9bae-0800278b9ed0",
    options: ["14:00", "14 horas", "2 da tarde"]
  },
  {
    label: "15:00",
    value: "43e209b8-d3b1-11e7-9bae-0800278b9ed0",
    options: ["15:00", "15 horas", "3 da tarde"]
  },
  {
    label: "16:00",
    value: "43e22753-d3b1-11e7-9bae-0800278b9ed0",
    options: ["16:00", "16 horas", "4 da tarde"]
  },
  {
    label: "17:00",
    value: "43e234db-d3b1-11e7-9bae-0800278b9ed0",
    options: ["17:00", "17 horas", "5 da tarde"]
  },
  {
    label: "18:00",
    value: "43e24526-d3b1-11e7-9bae-0800278b9ed0",
    options: ["18:00", "18 horas"]
  },
  {
    label: "19:00",
    value: "43e25309-d3b1-11e7-9bae-0800278b9ed0",
    options: ["19:00", "19 horas", "7 da noite"]
  },
  {
    label: "20:00",
    value: "43e2627f-d3b1-11e7-9bae-0800278b9ed0",
    options: ["20:00", "20 horas", "8 da noite"]
  },
  {
    label: "21:00",
    value: "43e271e0-d3b1-11e7-9bae-0800278b9ed0",
    options: ["21:00", "21 horas", "9 da noite"]
  },
  {
    label: "22:00",
    value: "43e2812c-d3b1-11e7-9bae-0800278b9ed0",
    options: ["22:00", "22 horas", "10 da noite"]
  }
];

export const translatePeriodName = period => {
  const dictionary = {
    fasting: "em jejum",
    before_breakfast: "antes do café da manhã",
    breakfast: "no café da manhã",
    after_breakfast: "após o café da manhã",
    before_lunch: "antes do almoço",
    lunch: "no almoço",
    after_lunch: "após o almoço",
    before_dinner: "antes do jantar",
    dinner: "no jantar",
    after_dinner: "após o jantar",
    before_supper: "antes da ceia",
    supper: "na ceia",
    after_supper: "após a ceia"
  };

  return dictionary[period] || period;
};

export const getDefaultAmountOptions = () => {
  const MAX_AMOUNT = 1000;

  const options = [];

  // Setup max amount options.
  for (let i = 1; i <= MAX_AMOUNT; i++) {
    options.push({
      label: i,
      value: i
    });
  }

  return options;
};

export const getPillAmountOptions = () => {
  const MAX_AMOUNT = 500;

  const options = [
    { label: "Meio", value: 0.5 },
    { label: "Metade", value: 0.5 },
    { label: "Um quarto", value: 0.25 }
  ];

  // Setup max amount options.
  for (let i = 1; i <= MAX_AMOUNT; i++) {
    options.push({
      label: i,
      value: i
    });
  }

  return options;
};

export const getMillilitersAmountOptions = () => {
  const MAX_AMOUNT = 1000;

  const options = [];

  // Setup max amount options.
  for (let i = 0.5; i <= MAX_AMOUNT; i += 0.5) {
    options.push({
      label: i.toString().replace(".", ","),
      value: i
    });
  }

  return options;
};

export const getTreatmentOptions = () => {
  const MAX_DAYS = 90;
  const MAX_WEEKS = 12;
  const MAX_MONTHS = 3;

  const options = [
    {
      label: "uso contínuo",
      value: "uso contínuo"
    },
    {
      label: "se necessário",
      value: "se necessário"
    },
    {
      label: "segunda-feira",
      value: "segunda-feira"
    },
    {
      label: "terça-feira",
      value: "terça-feira"
    },
    {
      label: "quarta-feira",
      value: "quarta-feira"
    },
    {
      label: "quinta-feira",
      value: "quinta-feira"
    },
    {
      label: "sexta-feira",
      value: "sexta-feira"
    },
    {
      label: "sábado",
      value: "sábado"
    },
    {
      label: "domingo",
      value: "domingo"
    },
    {
      label: "uma vez na semana",
      value: "uma vez na semana"
    },

    {
      label: "duas vezes na semana",
      value: "duas vezes na semana"
    },

    {
      label: "três vezes na semana",
      value: "três vezes na semana"
    },
    {
      label: "quatro vezes na semana",
      value: "quatro vezes na semana"
    },

    {
      label: "cinco vezes na semana",
      value: "cinco vezes na semana"
    },

    {
      label: "seis vezes na semana",
      value: "seis vezes na semana"
    }
  ];

  // Setup day options.
  for (let i = 1; i <= MAX_DAYS; i++) {
    const label = i > 1 ? "dias" : "dia";

    options.push({
      label: `${i} ${label}`,
      value: i
    });
  }

  // Setup week options.
  for (let i = 1; i <= MAX_WEEKS; i++) {
    const label = i > 1 ? "semanas" : "semana";
    const weekInDays = i * 7;

    options.push({
      label: `${i} ${label}`,
      value: weekInDays
    });
  }

  // Setup month options.
  for (let i = 1; i <= MAX_MONTHS; i++) {
    const label = i > 1 ? "meses" : "mês";
    const monthInDays = i * 30;

    options.push({
      label: `${i} ${label}`,
      value: monthInDays
    });
  }

  return options;
};
