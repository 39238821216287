import React, { PureComponent } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import moment from "moment";
import { find } from "lodash";
import ReactGA from "react-ga";

import { CircularProgress, Grid, withStyles } from "@material-ui/core";

import {
	getAllPrescriptions,
	removePrescription
} from "../../js/actions/prescription";

import PrescriptionList from "./List";
import styles from "./Style";

import SearchBar from "../UI/SearchBar";
import SearchDate from "../UI/SearchDate";
import Page from "../UI/Page";
import ConfirmationDialog from "../UI/ConfirmationDialog";

class Prescription extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			filteredList: [],
			offset: 20,
			isLoading: false,
			search: "",
			notFoundMessage: "",
			showDeleteConfirmation: false,
			prescriptionToDelete: {}
		};

		this.handleSearch = this.handleSearch.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.closeDeleteConfirmation = this.closeDeleteConfirmation.bind(this);
		this.deletePrescription = this.deletePrescription.bind(this);
	}

	async componentDidMount() {
		window.addEventListener("scroll", this.handleScroll);

		await this.props.getAllPrescriptions();
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll);
	}

	async getMorePrescriptions() {
		const { limit, offset } = this.state;

		if (offset < limit) {
			await this.setState({ isLoading: true });

			window.setTimeout(async () => {
				await this.setState({ offset: offset + offset });
				await this.setState({ isLoading: false });
			}, 1000);
		}
	}

	getPrescriptionList() {
		const { search, filteredList, offset } = this.state;

		const prescriptions = search ? filteredList : this.props.prescriptions;
		const limit = prescriptions.length;

		this.setState({ limit });

		if (limit === 0) {
			if (search) {
				this.setNotFoundMessage(`Nenhuma receita encontrada para '${search}'.`);
			} else {
				this.setNotFoundMessage("Ainda não foi criada nenhuma receita.");
			}
		}

		if (prescriptions && Array.isArray(prescriptions)) {
			return prescriptions.slice(0, offset < limit ? offset : limit);
		}

		return [];
	}

	setNotFoundMessage(message) {
		this.setState({
			notFoundMessage: message
		});
	}

	handleScroll = () => {
		const isEndOfPage =
			document.documentElement.scrollHeight -
				(window.innerHeight + window.scrollY) <=
			64;

		if (isEndOfPage) {
			this.getMorePrescriptions();
		}
	};

	handleSearch = async search => {
		const { prescriptions } = this.props;

		await this.setState({ search });

		const filteredList = prescriptions.filter(prescription =>
			prescription.patient.name.toLowerCase().match(search.toLowerCase())
		);

		await this.setState({ filteredList });

		ReactGA.event({
			category: "prescription-list",
			action: "seacrh",
			label: "by-text"
		});
	};

	handleSearchByDate = async search => {
		const { prescriptions } = this.props;

		await this.setState({ search });

		const filteredList = prescriptions.filter(
			prescription =>
				moment(prescription.updated).format("DD/MM/YYYY") === search
		);

		await this.setState({ filteredList });

		ReactGA.event({
			category: "prescription-list",
			action: "search",
			label: "by-date"
		});
	};

	async handleDelete(id) {
		const prescriptionToDelete = find(this.props.prescriptions, { id: id });
		await this.setState({ showDeleteConfirmation: true, prescriptionToDelete });
		ReactGA.event({
			category: "prescription-list",
			action: "button-click",
			label: "remove-patient"
		});
	}

	async deletePrescription() {
		this.closeDeleteConfirmation();
		await this.props.removePrescription(this.state.prescriptionToDelete.id);
		ReactGA.event({
			category: "prescription-list",
			action: "button-click",
			label: "remove-prescription"
		});
	}

	closeDeleteConfirmation() {
		this.setState({ showDeleteConfirmation: false, prescriptionToDelete: {} });
	}

	render() {
		const { classes } = this.props;
		const { isLoading, notFoundMessage } = this.state;

		const prescriptions = this.getPrescriptionList();

		return (
			<Page width={816} backgroundColor="#EFF2F5" paddingTop={128}>
				<Grid container justify="center">
					<Grid item xs={12}>
						<h1 className={classes.title}>Últimas receitas</h1>
					</Grid>
					<Grid item className={classes.search} xs={12}>
						<SearchDate onSearch={this.handleSearchByDate} />
						<SearchBar onSearch={this.handleSearch} />
					</Grid>
					<Grid item xs={12}>
						<PrescriptionList
							prescriptions={prescriptions}
							onDelete={e => this.handleDelete(e)}
							notFoundMessage={notFoundMessage}
						/>
					</Grid>
					{isLoading && (
						<Grid item>
							<CircularProgress className={classes.loader} />
						</Grid>
					)}
				</Grid>
				<ConfirmationDialog
					cancelText="Cancelar"
					confirmText="Confirmar"
					onClose={this.closeDeleteConfirmation}
					onConfirm={this.deletePrescription}
					show={this.state.showDeleteConfirmation}
					title="Confirmar a exclusão"
				>
					{`Você tem certeza que deseja excluir a prescrição do paciente `}
					<strong>
						{this.state.prescriptionToDelete.patient &&
							this.state.prescriptionToDelete.patient.name}
					</strong>
					{" criada no dia "}
					<strong>
						{this.state.prescriptionToDelete &&
							moment(this.state.prescriptionToDelete.created).format(
								"DD/MM/YYYY"
							)}
					</strong>
					?
				</ConfirmationDialog>
			</Page>
		);
	}
}

const mapStateToProps = state => ({
	prescriptions: state.prescription.list
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			getAllPrescriptions,
			removePrescription
		},
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Prescription));
