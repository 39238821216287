import React from "react";

import { Link } from "react-router-dom";
import { rem } from "polished";
import { withStyles } from "@material-ui/core";

import FooterDecoration from "../../img/footer-decoration.svg";

const styles = () => ({
	root: {
		position: "absolute",
		bottom: 0,
		left: 0,
		width: "100%"
	},
	footer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: rem(58),
		backgroundColor: "#9AAFBF"
	},
	link: {
		margin: `0 ${rem(35)}`,
		textAlign: "center",
		textDecoration: "none",
		color: "#FFFFFF",
		fontFamily: "Montserrat, sans-serif",
		fontSize: rem(12)
	},
	decoration: {
		backgroundColor: "#eff2f5",
		backgroundImage: `url(${FooterDecoration})`,
		backgroundSize: "cover",
		width: "100%",
		height: rem(18)
	}
});

const Footer = ({ classes, backgroundColor }) => {
	return (
		<div className={classes.root}>
			<div className={classes.decoration} />
			<footer
				className={classes.footer}
				style={{ backgroundColor: backgroundColor }}
			>
				<a
					href="https://www.pulsares.com.br/#about-us"
					target="_blank"
					rel="noopener noreferrer"
					className={classes.link}
				>
					Sobre a Pulsares
				</a>
				<a
					href="https://www.pulsares.com.br/#contact-us"
					target="_blank"
					rel="noopener noreferrer"
					className={classes.link}
				>
					Contato
				</a>
				<a
					href="https://medium.com/pulsares-saude"
					target="_blank"
					rel="noopener noreferrer"
					className={classes.link}
				>
					Blog
				</a>
				<Link to="/termos-de-uso" className={classes.link}>
					Termos de uso
				</Link>
			</footer>
		</div>
	);
};

export default withStyles(styles)(Footer);
