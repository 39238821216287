import React from "react";
import deburr from "lodash/deburr";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

function renderInputComponent(inputProps) {
	const {
		autoFocus,
		classes,
		disabled,
		inputRef = () => {},
		ref,
		...other
	} = inputProps;

	return (
		<TextField
			fullWidth
			disabled={disabled}
			autoFocus={autoFocus}
			InputProps={{
				disableUnderline: true,
				inputRef: node => {
					ref(node);
					inputRef(node);
				},
				classes: {
					input: classes.input
				}
			}}
			{...other}
		/>
	);
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
	const matches = match(suggestion.label, query);
	const parts = parse(suggestion.label, matches);

	return (
		<MenuItem selected={isHighlighted} component="div">
			<div>
				{parts.map(part => (
					<span
						key={part.text}
						style={{ fontWeight: part.highlight ? 500 : 400 }}
					>
						{part.text}
					</span>
				))}
			</div>
		</MenuItem>
	);
}

function getSuggestions(value, suggestions) {
	const inputValue = deburr(value.trim()).toLowerCase();
	const inputLength = inputValue.length;
	let count = 0;

	return inputLength === 0
		? []
		: suggestions.filter(suggestion => {
				const keep =
					count < 5 &&
					suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

				if (keep) {
					count += 1;
				}

				return keep;
		  });
}

function getSuggestionValue(suggestion) {
	return suggestion.label;
}

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1
	},
	container: {
		position: "relative"
	},
	suggestionsContainerOpen: {
		position: "absolute",
		zIndex: 1,
		marginTop: theme.spacing(1),
		left: 0,
		right: 0
	},
	suggestion: {
		display: "block"
	},
	suggestionsList: {
		margin: 0,
		padding: 0,
		listStyleType: "none"
	},
	divider: {
		height: theme.spacing(2)
	},
	input: {
		fontSize: 24,
		color: "#8080EC"
	}
}));

export default function IntegrationAutosuggest(props) {
	const classes = useStyles();
	const { label, placeholder, suggestions, inputClass } = props;

	const [stateSuggestions, setSuggestions] = React.useState([]);

	const handleSuggestionsFetchRequested = ({ value }) => {
		setSuggestions(getSuggestions(value, suggestions));
	};

	const handleSuggestionsClearRequested = () => {
		setSuggestions([]);
	};

	const handleChange = name => (event, { newValue }) => {
		props.onChange(newValue);
	};

	const autosuggestProps = {
		renderInputComponent,
		suggestions: stateSuggestions,
		onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
		onSuggestionsClearRequested: handleSuggestionsClearRequested,
		getSuggestionValue,
		renderSuggestion
	};

	return (
		<div className={classes.root}>
			<Autosuggest
				{...autosuggestProps}
				inputProps={{
					classes: inputClass ? { ...classes, input: inputClass } : classes,
					id: "react-autosuggest-simple",
					label: label,
					placeholder: placeholder,
					value: props.value,
					onChange: handleChange("single"),
					disabled: props.disabled,
					autoFocus: props.autoFocus
				}}
				theme={{
					container: classes.container,
					suggestionsContainerOpen: classes.suggestionsContainerOpen,
					suggestionsList: classes.suggestionsList,
					suggestion: classes.suggestion
				}}
				renderSuggestionsContainer={options => (
					<Paper {...options.containerProps} square>
						{options.children}
					</Paper>
				)}
			/>
		</div>
	);
}
