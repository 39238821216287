import styled, { css } from 'styled-components'

const AbsoluteWrapper = styled.div`
  position: absolute;
  ${props => css`
    top: ${props.top}px;
    bottom: ${props.bottom}px;
    right: ${props.right}px;
    left: ${props.left}px;
    z-index: ${props.zIndex}
  `}
`

export default AbsoluteWrapper
