import React, { Component } from "react";

import ReactGA from "react-ga";

import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import validator from "validator";
import Icon from "./Icon";
import Button from "./Button";
import Input from "./Input";
import { recoverPassword } from "../actions/user";
import Alert from "react-s-alert";
import * as ALERT from "../data/alerts";
import { Flex, Box } from "grid-styled";
import PageTitle from "../components/PageTitle";

import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  itemCentered: {
    textAlign: "center"
  },
  marginTopItem: {
    marginTop: 35
  }
});

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      recoverPassword
    },
    dispatch
  );

class Forgot extends Component {
  static propTypes = {
    recoverPassword: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      showSucessMessage: false
    };

    this.handleBackClick = this.handleBackClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleBackClick(event) {
    event.preventDefault();

    ReactGA.event({
      category: "forgot-password",
      action: "button-click",
      label: "previous-1"
    });
    this.props.history.push("/entrar");
  }

  handleSubmit(event) {
    event.preventDefault();

    ReactGA.event({
      category: "forgot-password",
      action: "submit-form",
      label: "forgot-password"
    });

    const data = {
      email: this.state.email
    };

    if (validator.isEmpty(data.email)) {
      Alert.error(ALERT.form.missingEmail);
      return;
    }

    if (!validator.isEmail(data.email)) {
      Alert.error(ALERT.form.invalidEmail);
      return;
    }

    this.props.recoverPassword(data).then(() => {
      ReactGA.event({
        category: "reset-password",
        action: "reset-password-request",
        value: 1
      });
      this.setState({ showSucessMessage: true });
    });
  }

  render() {
    const { showSucessMessage } = this.state;
    const { classes } = this.props;
    if (showSucessMessage)
      return (
        <Grid
          container
          direction={"row"}
          alignItems={"center"}
          justify={"flex-start"}
          spacing={2}
          className={classes.itemCentered}
        >
          <Grid item xs={12}>
            <PageTitle>
              <Grid
                container
                direction={"row"}
                alignItems={"center"}
                justify={"flex-start"}
                spacing={2}
              >
                <Grid item xs={12}>
                  <Icon name="email" size={35} />
                </Grid>
                <Grid item xs={12}>
                  Solicitação enviada! Verifique sua caixa de e-mail.
                </Grid>
              </Grid>
            </PageTitle>
          </Grid>
          <Grid item xs={12}>
            Caso não tenha recebido o e-mail entre em contato por
            contato@pulsares.com.br.
          </Grid>
          <Grid className={classes.marginTopItem} item xs={12}>
            <Button onClick={this.handleBackClick} type="submit">
              <Icon name="arrow_back" />
              <span>Voltar para o Login</span>
            </Button>
          </Grid>
        </Grid>
      );
    else
      return (
        <div>
          <Flex justify="center">
            <PageTitle>Esqueci minha senha</PageTitle>
          </Flex>
          <Flex justify="center">
            <form onSubmit={this.handleSubmit}>
              <p>
                Preencha seu email abaixo e clique em Enviar para receber
                instruções para redefinir sua senha.
              </p>
              <Flex my={10}>
                <Box width={1}>
                  <Input
                    type="email"
                    name="signin-email"
                    placeholder="Email"
                    onChange={event => {
                      this.setState({ email: event.target.value });
                    }}
                  />
                </Box>
              </Flex>

              <Flex my={10} align="center">
                <Box width={1 / 2}>
                  <Link to="entrar" className="btn-secondary">
                    <Icon name="arrow_back" />
                  </Link>
                </Box>

                <Box width={1 / 2}>
                  <Flex justify="flex-end">
                    <Button type="submit">
                      <Icon name="send" />
                      <span>Enviar</span>
                    </Button>
                  </Flex>
                </Box>
              </Flex>
            </form>
          </Flex>
        </div>
      );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(Forgot))
);
