import { rem } from "polished";

const styles = () => ({
  root: {},
  textfield: { width: "100%", maxWidth: rem(394) },
  submitButton: {
    margin: `${rem(25)} 0`
  },
  title: {
    color: "#8080EC",
    fontFamily: "Bree Serif, serif",
    fontSize: rem(26),
    textAlign: "center",
    marginBottom: rem(15)
  },
  subtitle: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: rem(16),
    color: "#372F4E",
    marginTop: rem(5),
    marginBottom: rem(25),
    textAlign: "center"
  },
  help: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: rem(14),
    color: "#8080EC",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: `${rem(50)}`
  }
});

export default styles;
