import {
  TOUR_RUN,
  TOUR_STOP,
  TOUR_RESET,
  TOUR_NEXT_STEP,
  TOUR_PREVIOUS_STEP,
  TOUR_SET_STEP
} from "../constants";

export const run = () => ({
  type: TOUR_RUN
});

export const stop = () => ({
  type: TOUR_STOP
});

export const reset = () => ({
  type: TOUR_RESET
});

export const next = () => ({ type: TOUR_NEXT_STEP });

export const previous = () => ({
  type: TOUR_PREVIOUS_STEP
});

export const setCurrentStep = step => ({
  type: TOUR_SET_STEP,
  payload: { step }
});
