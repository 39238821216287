import React, { PureComponent } from "react";

import { Grid, Slide, withStyles } from "@material-ui/core";

import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import classnames from "classnames";
import ReactGA from "react-ga";

import { signout, getUserProfile } from "../../../js/actions/user";
import { isAuthenticated } from "../../../js/utils/authCheck";

import MenuItem from "./MenuItem";
import Settings from "./Settings";
import styles from "./Style";

import BackButton from "../BackButton";

import Logo from "../../../img/home/logo-mini.png";
import RingAlert from "../../../img/home/ring-alert.svg";
import FlatButton from "../FlatButton";

class MenuBar extends PureComponent {
	constructor(props) {
		super(props);

		this.state = { showBackButton: false };

		this.setButtonPosition = this.setButtonPosition.bind(this);
		this.prescriptionButtonRef = React.createRef();
		this.handleLogoClick = this.handleLogoClick.bind(this);
	}

	async componentDidMount() {
		const authenticated = isAuthenticated();
		if (authenticated) {
			if (!this.props.user.first_name) {
				await this.props.getUserProfile();
			}
		}

		if (this.props.showButton) {
			this.setButtonPosition();
			window.addEventListener("resize", this.setButtonPosition);
		}

		if (this.props.showBackButton) {
			this.setState({ showBackButton: true });
		}
	}

	componentWillUnmount() {
		if (this.props.showButton) {
			window.removeEventListener("resize", this.setButtonPosition);
		}
	}

	setButtonPosition() {
		const prescriptionButton = this.prescriptionButtonRef.current;

		if (prescriptionButton && this.props.onButtonPositioned) {
			const pos = prescriptionButton.getBoundingClientRect();
			this.props.onButtonPositioned(pos.left);
		}
	}

	handleLogoClick = async () => {
		await this.setState({ showBackButton: false });
		setTimeout(() => {
			this.props.history.push("/");
		}, 250);
	};

	render() {
		const {
			children,
			classes,
			buttonText,
			buttonGoTo,
			buttonIcon,
			buttonOnClick,
			buttonComponent,
			onBackClick,
			showAlerts,
			showButton,
			buttonClassName,
			showSettings
		} = this.props;

		const { showBackButton } = this.state;

		return (
			<Grid container className={classnames(classes.root, "noPrint")}>
				<Grid item className={classes.content} xs={12}>
					<Slide
						direction="right"
						in={showBackButton}
						mountOnEnter
						unmountOnExit
					>
						<BackButton
							onClick={() => {
								this.setState({ showBackButton: false });
								ReactGA.event({
									category: "menu-bar",
									action: "button-click",
									label: "back"
								});
								setTimeout(() => {
									onBackClick();
								}, 250);
							}}
							flex
							style={{ backgroundColor: "rgba(92,59,196, 0.5)" }}
						/>
					</Slide>

					<Grid
						container
						justify="space-between"
						alignItems="center"
						className={classes.padding}
					>
						<Grid item>
							<Grid container alignItems="center" spacing={5}>
								<MenuItem onClick={this.handleLogoClick} flex>
									<img src={Logo} alt="Logo" />
								</MenuItem>
								{children}
							</Grid>
						</Grid>

						<Grid item>
							<Grid container alignItems="center" spacing={4}>
								{!!showButton && (
									<Grid item>
										<div
											className={buttonClassName}
											ref={this.prescriptionButtonRef}
										>
											<FlatButton
												component={buttonComponent}
												onClick={buttonOnClick}
												to={buttonGoTo ? buttonGoTo : "/nova-receita"}
												text={buttonText ? buttonText : "Criar receita"}
												icon={buttonIcon}
												customStyle={{
													border: "2px solid #FFFFFF"
												}}
											/>
										</div>
									</Grid>
								)}
								{showAlerts && (
									<Grid item>
										<div className={classes.alert}>
											<img src={RingAlert} alt="alerts" />
										</div>
									</Grid>
								)}
								{showSettings && (
									<Grid item>
										<Settings
											user={this.props.user}
											onSignOut={this.props.signout}
										/>
									</Grid>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

const mapStateToProps = state => ({
	user: state.user,
	isLoading: state.loader.isLoading
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			signout,
			getUserProfile
		},
		dispatch
	);

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(styles)(MenuBar))
);
