import React, { PureComponent } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { find } from "lodash";
import ReactGA from "react-ga";

import { Grid, withStyles } from "@material-ui/core";

import {
	createClinic,
	updateClinic
} from "../../../js/components/Clinic/ClinicActions";

import Page from "../../UI/Page";
import Form from "./Form";
import styles from "../Style";

class ProfileWorkplaceNew extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			loaded: false,
			isSaving: false,
			isClinicUpdated: false,
			isEditMode: false,
			initialData: {
				id: "",
				name: "",
				address: {
					zip: "",
					street: "",
					number: "",
					complement: "",
					district: "",
					city: "",
					state: "",
					phone: ""
				}
			}
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleFieldChange = this.handleFieldChange.bind(this);
	}

	async componentDidMount() {
		await this.setInitialData();

		await this.setState({ loaded: true });
	}

	async setInitialData() {
		const { id, clinics } = this.props;

		if (id) {
			const clinic = find(clinics, { id: id });

			await this.setState({ initialData: clinic, isEditMode: true });
		}
	}

	handleFieldChange() {
		this.setState({ isClinicUpdated: false });
	}

	async handleSubmit(values) {
		const { isEditMode } = this.state;

		await this.setState({ isSaving: true });

		const clinic = {
			id: values.id,
			name: values.name,
			address: {
				...values.address,
				phone: values.address.phone.replace(/\D/g, "")
			}
		};

		if (isEditMode) {
			await this.updateClinic(clinic);
		} else {
			await this.addClinic(clinic);
		}

		await this.setState({ isSaving: false });
	}

	async updateClinic(clinic) {
		const response = await this.props.updateClinic(clinic, clinic.id);

		if (!response.error) {
			ReactGA.event({
				category: "workplace",
				action: "submit-form",
				label: "update"
			});
			await this.setState({ isClinicUpdated: true });
			window.setTimeout(async () => {
				this.props.history.push("/meu-perfil/local-de-trabalho");
			}, 1000);
		}
	}

	async addClinic(clinic) {
		const response = await this.props.createClinic(clinic);

		if (!response.error) {
			ReactGA.event({
				category: "workplace",
				action: "submit-form",
				label: "create"
			});
			await this.setState({ isClinicUpdated: true });
			window.setTimeout(async () => {
				this.props.history.push("/meu-perfil/local-de-trabalho");
			}, 1000);
		}
	}

	render() {
		const { classes } = this.props;
		const {
			loaded,
			isSaving,
			isClinicUpdated,
			initialData,
			isEditMode
		} = this.state;

		return (
			loaded && (
				<Page width={816} backgroundColor="#EFF2F5" paddingTop={128}>
					<Grid container>
						<Grid item xs={12}>
							<h1 className={classes.title}>
								{isEditMode ? "Atualize o " : "Adicione um "} local de trabalho
							</h1>
							<h2 className={classes.subtitle}>
								As informações de local de trabalho irão aparecer em sua
								receita.
							</h2>
						</Grid>
						<Grid item xs={12}>
							<Form
								initialData={initialData}
								isEditMode={isEditMode}
								saving={isSaving}
								saved={isClinicUpdated}
								onSubmit={this.handleSubmit}
								onFieldChange={this.handleFieldChange}
							/>
						</Grid>
					</Grid>
				</Page>
			)
		);
	}
}

const mapStateToProps = state => ({
	clinics: state.clinic.clinics
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			createClinic,
			updateClinic
		},
		dispatch
	);

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(styles)(ProfileWorkplaceNew))
);
