import React, { Component } from "react";

import HeaderBar from "../../components/UI/HeaderBar";
import Page from "../components/Page";
import PageTitle from "../components/PageTitle";
import ClinicProfile from "../components/Clinic/ClinicProfile";

class ClinicsConfigPage extends Component {
  render() {
    return (
      <Page>
        <HeaderBar showButton />
        <PageTitle>Locais de Trabalho</PageTitle>
        <ClinicProfile />
      </Page>
    );
  }
}

export default ClinicsConfigPage;
