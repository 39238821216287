import createReducer from "../utils/createReducer";

import {
  TOUR_RUN,
  TOUR_STOP,
  TOUR_RESET,
  TOUR_NEXT_STEP,
  TOUR_PREVIOUS_STEP,
  TOUR_SET_STEP
} from "../constants";

const INITIAL_STATE = {
  run: false,
  currentStep: 0
};

const user = createReducer(INITIAL_STATE, {
  [TOUR_RUN]: state => ({
    ...state,
    run: true
  }),
  [TOUR_STOP]: state => ({
    ...state,
    run: false
  }),
  [TOUR_RESET]: state => ({
    ...state,
    currentStep: 0
  }),
  [TOUR_NEXT_STEP]: state => ({
    ...state,
    currentStep: state.currentStep + 1
  }),
  [TOUR_PREVIOUS_STEP]: state => ({
    ...state,
    currentStep: state.currentStep - 1
  }),
  [TOUR_SET_STEP]: (state, action) => ({
    ...state,
    currentStep: action.payload.step
  })
});

export default user;
