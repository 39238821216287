import { rem } from "polished";
import MenuBackground from "../../../img/home/menu-background.svg";

const styles = theme => ({
	flex: {
		display: "flex"
	},
	root: {
		backgroundImage: "linear-gradient(to right, #6844D5, #A9A9F2 )",
		position: "fixed",
		top: 0,
		left: 0,
		zIndex: 999
	},
	content: {
		display: "flex",
		height: rem(64),
		color: "#FFFFFF",
		fontFamily: "Montserrat, sans-serif",
		fontSize: rem(14),
		backgroundImage: `url(${MenuBackground})`,
		backgroundRepeat: "repeat-x",
		backgroundPositionX: "left",
		backgroundPositionY: "center"
	},
	padding: {
		padding: `0 ${rem(24)}`
	},
	alert: {
		height: rem(40),
		width: rem(40),
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		cursor: "pointer",
		"&:hover": {
			borderRadius: "50%",
			backgroundColor: "#8080EC"
		}
	},
	leftIcon: {
		marginRight: theme.spacing(1)
	},
	rightIcon: {
		marginLeft: theme.spacing(1)
	},
	profileIcon: {
		height: rem(40),
		width: rem(40),
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	},
	button: {
		color: "#FFFFFF",
		fontSize: "16px",
		fontWeight: "bold",
		border: "none",
		textTransform: "none",
		padding: 0
	},

	menuItem: {
		fontFamily: "Montserrat, sans-serif",
		fontSize: rem(14),
		"&:hover": {
			color: "#FFFFFF",
			backgroundColor: "#8080EC"
		}
	}
});

export default styles;
