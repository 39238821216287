import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { rem } from "polished";

import Signin from "./Signin";

import Dialog from "@material-ui/core/Dialog";
import { Grid, DialogContent } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";

import Loader from "./Loader";

const styles = {
	root: {
		padding: 0
	},
	paper: {
		minWidth: rem(800),
		width: rem(800),
		maxWidth: rem(800),
		borderRadius: rem(8)
	},
	dialogTitle: {
		color: "#7C7CE4",
		fontSize: rem(20),
		textAlign: "center",
		fontWeight: 700
	},
	subTitle: {
		color: "#282828",
		fontSize: rem(14),
		paddingTop: rem(14)
	}
};

class LoginDialog extends PureComponent {
	render() {
		const { classes } = this.props;

		return (
			<Dialog
				open={this.props.show}
				scroll={"body"}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				classes={{
					root: classes.root,
					paper: classes.paper
				}}
			>
				<DialogTitle
					disableTypography={true}
					classes={{
						root: classes.dialogTitle
					}}
					id="alert-dialog-title"
				>
					Sua sessão com o servidor expirou
					<p className={classes.subTitle}>
						Faça o login novamente para continuar
					</p>
				</DialogTitle>
				{this.props.isLoading && <Loader modal={true} />}
				<DialogContent>
					<Grid container justify="center">
						<Grid item xs={8}>
							<Signin
								hideTitle={true}
								showLegend={false}
								showLinks={false}
								onSignin={this.props.onSignin}
							/>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		);
	}
}

const mapStateToProps = state => ({
	isLoading: state.loader.isLoading
});

LoginDialog = connect(
	mapStateToProps,
	null
)(LoginDialog);

export default withStyles(styles)(LoginDialog);
