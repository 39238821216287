import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import Icon from "./Icon";
import Input from "./Input";
import Button from "./Button";
import Select from "./Select";
import { updateUserProfile, getUserProfile } from "../actions/user";
import CryptoJS from "crypto-js";
import { VOCATIVE_OPTIONS, GENDER_OPTIONS } from "../data/signup";
import Alert from "react-s-alert";
import HelpInfo from "./HelpInfo";
import { find, pick, values } from "lodash";
import { Flex, Box } from "grid-styled";
import RelativeWrapper from "./RelativeWrapper";
import AbsoluteWrapper from "./AbsoluteWrapper";
import AlertList from "./AlertList";
import moment from "moment";
import LoginDialog from "./LoginDialog";

import { isAuthenticated } from "../utils/authCheck";

import { FormControlLabel, Checkbox } from "@material-ui/core";

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUserProfile,
      updateUserProfile
    },
    dispatch
  );

class Configurations extends Component {
  static propTypes = {
    user: PropTypes.object,
    updateUserProfile: PropTypes.func,
    getUserProfile: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      error: false,
      errorStatus: "",
      showLogin: false,
      gender: "",
      title: "",
      registration: {},
      current_password: "",
      password: "",
      allowed_call: false,
      billing_plan: ""
    };

    this.handlePass = this.handlePass.bind(this);
    this.handleSignin = this.handleSignin.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleVocative = this.handleVocative.bind(this);
  }

  async componentWillMount() {
    const authenticated = isAuthenticated();
    if (authenticated) {
      await this.props.getUserProfile();
      this.setState({
        ...pick(this.props.user, this.userFields),
        birthday: this.props.user.birthday
          ? moment(this.props.user.birthday, "YYYY/MM/DD").format("DD/MM/YYYY")
          : "",
        clinic: this.props.user.clinic
      });
    }
  }

  userFields = [
    "first_name",
    "last_name",
    "email",
    "document",
    "user_type",
    "title",
    "gender",
    "birthday",
    "registration",
    "current_password",
    "password",
    "allowed_call",
    "billing_plan"
  ];

  handlePass(event) {
    const parsed = CryptoJS.enc.Utf8.parse(event.target.value);
    return CryptoJS.enc.Base64.stringify(parsed);
  }

  handleVocative(selected) {
    const title = selected.value;
    let gender;

    switch (title) {
      case "Dr.":
        gender = "M";
        break;

      case "Dra.":
        gender = "F";
        break;

      default:
        gender = "";
    }

    this.setState({
      title,
      gender
    });
  }

  handleSignin(response) {
    if (response && response.error) return;
    this.setState({ error: false, errorStatus: "", showLogin: false });
  }

  async handleSubmit(event) {
    event.preventDefault();

    if (!this.state.birthday) {
      Alert.error("Preencha a data de nascimento.", { offset: 39 });
      return;
    }

    const data = {
      ...pick(this.state, this.userFields),
      birthday: this.state.birthday
        ? moment(this.state.birthday, "DD/MM/YYYY").format("YYYY-MM-DD")
        : "",
      registration: {
        ...this.state.registration,
        number: this.state.registration.number
      },
      document: this.state.document.replace(".", "")
    };

    const response = await this.props.updateUserProfile(data);

    if (response) {
      const error = response.error;
      this.setState({ error: true, errorStatus: error.response.status }, () => {
        const authenticated = isAuthenticated();

        if (error.response.status === 403 && !authenticated) {
          this.setState({ showLogin: true });
        }

        return;
      });

      let messages = values(error.response.data.validation_errors);
      if (error.response.status === 409) {
        messages = ["Confira os dados inseridos e sua senha atual"];
      }

      Alert.error(
        <AlertList>
          {messages.map((message, index) => (
            <li key={index}>{message}</li>
          ))}
        </AlertList>,
        { timeout: 5000, offset: 39 }
      );
    } else {
      //Alert.success(ALERT.signup.profileUpdated, { offset: 39 });
      this.props.history.push("/inicio");
    }
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <fieldset disabled={this.state.showLogin}>
            <Flex my={10}>
              <Box width={1 / 3}>
                <Select
                  id="title"
                  name="title"
                  placeholder="Vocativo"
                  items={VOCATIVE_OPTIONS}
                  onChange={this.handleVocative}
                  value={find(VOCATIVE_OPTIONS, { value: this.state.title })}
                />
              </Box>
              <Box width={1 / 3} mx={10}>
                <Input
                  id="first-name"
                  type="text"
                  name="first-name"
                  placeholder="Nome"
                  onChange={event => {
                    this.setState({ first_name: event.target.value });
                  }}
                  value={this.state.first_name}
                />
              </Box>
              <Box width={1 / 3}>
                <Input
                  id="last-name"
                  type="text"
                  name="last-name"
                  placeholder="Sobrenome"
                  onChange={event => {
                    this.setState({ last_name: event.target.value });
                  }}
                  value={this.state.last_name}
                />
              </Box>
            </Flex>

            <Flex my={10}>
              <Box width={1 / 3}>
                <Select
                  id="gender"
                  name="gender"
                  placeholder="Gênero"
                  items={GENDER_OPTIONS}
                  onChange={selected => {
                    this.setState({ gender: selected.value });
                  }}
                  value={find(GENDER_OPTIONS, { value: this.state.gender })}
                />
              </Box>
              <Box width={1 / 3} mx={10}>
                <Input
                  type="text"
                  id="birthday"
                  name="birthday"
                  placeholder="Data de nascimento (DD/MM/AAAA)"
                  mask={[
                    /\d/,
                    /\d/,
                    "/",
                    /\d/,
                    /\d/,
                    "/",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/
                  ]}
                  value={this.state.birthday}
                  onChange={date => {
                    this.setState({ birthday: date });
                  }}
                />
              </Box>
              <Box width={1 / 3}>
                <Input
                  id="email"
                  type="text"
                  name="email"
                  placeholder="Email"
                  value={this.state.email}
                  readOnly
                />
              </Box>
            </Flex>

            <Flex my={10}>
              <Box width={1 / 3}>
                <Input
                  id="crm"
                  type="text"
                  name="crm"
                  placeholder="CRM"
                  value={this.state.registration.number}
                  readOnly
                />
              </Box>
              <Box width={1 / 3} mx={10}>
                <Input
                  id="crm-state"
                  type="text"
                  name="crm-state"
                  placeholder="UF do registro"
                  value={this.state.registration.state}
                  readOnly
                />
              </Box>
              <Box is={RelativeWrapper} width={1 / 3}>
                <Input
                  id="cpf"
                  type="text"
                  name="cpf"
                  placeholder="CPF"
                  value={this.state.document}
                  readOnly
                />
              </Box>
            </Flex>

            <Flex my={10}>
              <Box is={RelativeWrapper} width={1}>
                <Input
                  id="new-password"
                  type="password"
                  name="new-password"
                  placeholder="Nova senha (apenas caso queira trocar)"
                  onChange={event => {
                    this.setState({ password: this.handlePass(event) });
                  }}
                />
                <AbsoluteWrapper top={8} right={10} zIndex={10}>
                  <HelpInfo position="right">
                    Preencha apenas se quiser editar sua senha.
                    <br />
                    Dica: Sua senha deve conter no mínimo 8 caracteres e 1 letra
                    maiúscula
                  </HelpInfo>
                </AbsoluteWrapper>
              </Box>
            </Flex>

            <Flex my={10}>
              <Box is={RelativeWrapper} width={1}>
                <Input
                  id="password"
                  type="password"
                  name="password"
                  placeholder="Digite sua senha atual para salvar"
                  onChange={event => {
                    this.setState({ current_password: this.handlePass(event) });
                  }}
                />
              </Box>
            </Flex>
            <Flex my={10}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="allowed_call"
                    name="allowed_call"
                    checked={this.state.allowed_call}
                    onChange={event => {
                      this.setState({ allowed_call: !this.state.allowed_call });
                    }}
                    value={this.state.allowed_call}
                    color="primary"
                  />
                }
                label="Podemos entrar em contato através do seu telefone"
              />
            </Flex>
            <Button
              disabled={
                this.state.showLogin || this.state.current_password === ""
              }
              type="submit"
              mr={20}
            >
              <Icon name="thumb_up" />
              <span>Salvar</span>
            </Button>

            <Link to="/">Cancelar</Link>
          </fieldset>
        </form>
        <LoginDialog show={this.state.showLogin} onSignin={this.handleSignin} />
      </div>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Configurations)
);
