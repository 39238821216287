import React, { Component } from "react";

import HeaderBar from "../../components/UI/HeaderBar";
import Page from "../components/Page";
import { PatientList } from "../components/Patient/";

import "./HistoryPage.css";

class HistoryPage extends Component {
  render() {
    return (
      <div>
        <div className="responsivity-alert-logged">
          Sugerimos o uso em um tablet ou em um computador para uma melhor
          experiência.
        </div>
        <Page>
          <HeaderBar />
          <PatientList />
        </Page>
      </div>
    );
  }
}

export default HistoryPage;
