import createReducer from '../utils/createReducer'
import {
  SEARCH_MEDICATION,
  SEARCH_MEDICATION_FAILURE,
  SELECT_MEDICATION,
  IS_EDIT_MEDICATION_MODE,
} from '../constants/'


const INITIAL_STATE = {
  entries: [],
  editMedicationMode: {},
  selectedMedication: {},
}

const medication = createReducer(INITIAL_STATE, {
  [SEARCH_MEDICATION]: (state, action) => (
    Object.assign({}, state, {
      entries: action.payload,
    })
  ),

  [SEARCH_MEDICATION_FAILURE]: (state, action) => (
    Object.assign({}, state, {
      error: action.error,
    })
  ),

  [IS_EDIT_MEDICATION_MODE]: (state, action) => (
    Object.assign({}, state, {
      editMedicationMode: action.payload,
    })
  ),

  [SELECT_MEDICATION]: (state, action) => (
    Object.assign({}, state, {
      selectedMedication: action.payload,
    })
  ),

})


export default medication
