import axios from "axios";
import { toastr } from "react-redux-toastr";

import * as types from "../../constants/";
import services from "../../services";

import { API_URL } from "../../config/api";
import { requestLoader } from "../../actions/loader";

export const getZipCodeInformation = zipCode =>
	function getZipCodeInformationFn(dispatch) {
		dispatch(requestLoader(true));

		return axios["get"](`https://api.postmon.com.br/v1/cep/${zipCode}`)
			.then(response => {
				dispatch(getZipCodeInformationSuccess(response.data));
				dispatch(requestLoader(false));
			})
			.catch(() => {
				toastr.error("CEP inválido!");
				dispatch(requestLoader(false));
			});
	};

export const getZipCodeInformationSuccess = data => ({
	type: "ZIPCODE_INFORMATION_FETCHED",
	payload: data
});

export const getClinicsSuccess = data => ({
	type: types.GET_USER_CLINICS,
	payload: data
});

export const getClinics = () =>
	function getUserProfileFn(dispatch) {
		const url = `${API_URL}/clinics/`;

		dispatch(requestLoader(true));
		return services
			.fetch(url)
			.then(response => {
				dispatch(getClinicsSuccess(response.data));
				dispatch(requestLoader(false));
			})
			.catch(() => {
				dispatch(requestLoader(false));
			});
	};

export const createClinic = clinic => async dispatch => {
	try {
		const url = `${API_URL}/clinics/`;

		const response = await services.createWithFile(url, clinic);

		return dispatch(createClinicSuccess(response.data));
	} catch (e) {
		return dispatch(createClinicFailure(e));
	}
};

export const uploadClinicLogo = (clinicId, logo) => async dispatch => {
	try {
		const url = `${API_URL}/clinics/${clinicId}/`;
		dispatch(requestLoader(true));
		await services.uploadFile(url, logo);
		dispatch(requestLoader(false));
	} catch (error) {
		dispatch(requestLoader(false));
		console.error(error);
	}
};

export const createClinicSuccess = data => ({
	type: types.CREATE_USER_CLINIC,
	payload: data
});

export const createClinicFailure = error => ({
	type: types.CREATE_USER_CLINIC_FAILURE,
	error
});

export const removeClinicSuccess = id => ({
	type: types.REMOVE_PATIENT,
	payload: { id }
});

export const removeClinic = id =>
	function removeClinictFn(dispatch) {
		const url = `${API_URL}/clinics/${id}`;

		dispatch(requestLoader(true));

		return services.delete(url).then(() => {
			dispatch(requestLoader(false));
		});
	};

export const updateClinicSuccess = data => ({
	type: types.UPDATE_USER_CLINIC,
	payload: data
});

export const updateClinicFailure = error => ({
	type: types.UPDATE_USER_CLINIC_FAILURE,
	error
});

export const updateClinic = (data, id) =>
	function updateClinicFn(dispatch) {
		const url = `${API_URL}/clinics/${id}`;

		dispatch(requestLoader(true));

		return services
			.update(url, data)
			.then(response => {
				dispatch(updateClinicSuccess(response.data));
				dispatch(requestLoader(false));
				return response;
			})
			.catch(e => {
				dispatch(requestLoader(false));
				return dispatch(updateClinicFailure(e));
			});
	};
