import PropTypes from 'prop-types';
import React from 'react';

const TableBody = (props) => (
  <tbody>
    {props.children}
  </tbody>
)

TableBody.propTypes = {
  children: PropTypes.node,
}


export default TableBody
