import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";
import { rem, lighten } from "polished";
import { space } from "styled-system";
import { includes, omit } from "lodash";
import Label from "./Label";
import MaskedInput from "react-text-mask";

export const Container = styled.div`
  overflow: auto;
  ${space};
`;

export const FieldStyle = css`
  background: inherit;
  border: 0;
  border-bottom: 1px solid #ccc;
  width: 100%;
  height: ${props => rem(props.height || 40)};
  min-height: ${props => rem(props.height || 40)};
  padding: 0;
  margin: 0;
  color: #333;
  font-size: ${rem(16)};
  font-weight: 300;
  outline: none;
  transition: all 0.3s;
  &[readonly] {
    color: #ccc;
    cursor: default;
  }
  &:focus:not([readonly]) {
    border-color: ${lighten(0.1, "#7C7CE4")};
  }
`;

const TextField = styled.input`
  ${FieldStyle};
`;

const MaskedField = styled(MaskedInput)`
  ${FieldStyle};
`;

const TextareaField = styled.textarea`
  ${FieldStyle} overflow: hidden;
  resize: none;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const CheckBoxField = styled.input`
  position: absolute;
  left: -9999px;
  visibility: hidden;

  :checked + label::before {
    border-color: transparent;
  }

  :checked + label::after {
    z-index: 0;
    background-color: #7c7ce4;
    border: 2px solid ${lighten(0.1, "#7C7CE4")};
    transform: scale(1);
  }
`;

const CheckBoxLabel = styled.label`
  display: inline-block;
  position: relative;
  height: ${rem(25)};
  padding-left: ${rem(30)};
  line-height: ${rem(25)};
  font-size: $rem(15)};
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    width: ${rem(16)};
    height: ${rem(16)};
    margin: ${rem(4)} 0;
    transition: 280ms ease;
  }

  &::before {
    border: 2px solid #5a5a5a;
  }

  &::after {
    transform: scale(0);
  }
`;

const textTypes = ["text", "password", "number", "email"];

const resizeTextarea = e => {
  const textarea = e.target;
  textarea.style.height = `${e.target.scrollHeight}px`;
};

const Input = props => (
  <Container {...omit(props, ["id"])}>
    {props.label && props.type !== "checkbox" && (
      <Label htmlFor={props.id}>{props.label}</Label>
    )}

    {props.multiLine && <TextareaField {...props} onKeyUp={resizeTextarea} />}

    {includes(textTypes, props.type) && props.mask && (
      <MaskedField {...props} />
    )}

    {includes(textTypes, props.type) && !props.mask && !props.autoFocus && (
      <TextField {...props} />
    )}
    {includes(textTypes, props.type) && !props.mask && props.autoFocus && (
      <TextField autoFocus={true} {...props} />
    )}

    {props.type === "checkbox" && (
      <div>
        <CheckBoxField {...props} />
        <CheckBoxLabel htmlFor={props.id}>{props.label}</CheckBoxLabel>
      </div>
    )}
  </Container>
);

Input.propTypes = {
  type: PropTypes.string,
  multiLine: PropTypes.bool,
  label: PropTypes.any,
  height: PropTypes.number,
  id: PropTypes.string,
  mask: PropTypes.oneOfType([PropTypes.array, PropTypes.bool, PropTypes.func])
};

Input.defaultProps = {
  mask: false,
  keepCharPositions: true,
  placeholderChar: "\u2000"
};

export default Input;
