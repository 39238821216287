import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";

import SearchIcon from "@material-ui/icons/Search";

import { rem } from "polished";

const placeholder = {
	opacity: 1
};

const styles = () => ({
	root: {
		display: "flex",
		flex: "1",
		alignItems: "center",
		height: 56,
		margin: `${rem(20)} 0`,
		borderRadius: rem(28)
	},
	icon: {
		color: "#9AAFBF",
		margin: `${rem(15)} ${rem(20)}`,
		fontSize: rem(26)
	},
	input: {
		marginLeft: 8,
		flex: 1,
		fontFamily: "Montserrat, sans-serif",
		fontSize: rem(14),
		color: "#9AAFBF",
		"&::-webkit-input-placeholder": placeholder,
		"&::-moz-placeholder": placeholder, // Firefox 19+
		"&:-ms-input-placeholder": placeholder, // IE 11
		"&::-ms-input-placeholder": placeholder // Edge
	}
});

const SearchBar = ({ classes, onSearch }) => {
	return (
		<Paper elevation={0} className={classes.root}>
			<SearchIcon className={classes.icon} />
			<InputBase
				classes={{ input: classes.input }}
				placeholder="Busque pelo nome"
				onChange={e => onSearch(e.target.value)}
			/>
		</Paper>
	);
};

export default withStyles(styles)(SearchBar);
