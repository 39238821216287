import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import Icon from "./Icon";
import Button from "./Button";
import Input from "./Input";
import { signin, getUserProfile } from "../actions/user";
import { partial } from "lodash";

import Alert from "react-s-alert";
import * as ALERT from "../data/alerts";

import PageTitle from "../components/PageTitle";

import { Grid, TextField } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  loginForm: {
    backgroundColor: "#f7f7f7",
    padding: "20px 0"
  },
  formTitle: {
    margin: 0
  },
  circularBox: {
    backgroundColor: "#f7f7f7",
    borderRadius: "50%",
    width: "30px",
    padding: "5px 0",
    margin: "0 auto"
  },
  noMarginBottom: {
    marginBottom: "5px"
  },
  itemCentered: {
    textAlign: "center"
  },
  marginTop: {
    marginTop: "25px"
  },
  rememberPassword: {
    textAlign: "left"
  }
});

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      signin,
      getUserProfile
    },
    dispatch
  );

class Signin extends Component {
  static propTypes = {
    signin: PropTypes.func,
    onSignin: PropTypes.func,
    showLegend: PropTypes.bool,
    showLinks: PropTypes.bool
  };

  static defaultProps = {
    showLegend: true,
    showLinks: true
  };

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      remember: false
    };

    this.handleSignup = this.handleSignup.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSignup(event) {
    event.preventDefault();
    this.props.history.push("/cadastrar");
  }

  handleFieldChange(field, event) {
    this.setState({ [field]: event.target.value });
  }

  async handleSubmit(event) {
    event.preventDefault();

    const result = await this.props.signin(
      this.state.username,
      this.state.password
    );

    if (!result) {
      Alert.error(ALERT.signin.loginFailure);
      return;
    }

    await this.props.getUserProfile();

    if (this.props.onSignin) this.props.onSignin(result);
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        direction={"column"}
        alignItems={"stretch"}
        justify={"center"}
        spacing={2}
      >
        <Grid item>
          <form className={classes.loginForm} onSubmit={this.handleSubmit}>
            <Grid
              container
              direction={"row"}
              alignItems={"center"}
              justify={"center"}
              spacing={2}
              className={classes.itemCentered}
            >
              <Grid item xs={12}>
                {!this.props.hideTitle && (
                  <PageTitle className={classes.formTitle}>
                    Faça seu login
                  </PageTitle>
                )}
              </Grid>
              <Grid item xs={10} sm={8}>
                <TextField
                  fullWidth
                  required
                  id="required"
                  label="Email"
                  type="email"
                  name="signin-email"
                  margin="none"
                  onChange={partial(this.handleFieldChange, "username")}
                />
              </Grid>

              <Grid item xs={10} sm={8}>
                <TextField
                  fullWidth
                  required
                  label="Senha"
                  type="password"
                  name="signin-password"
                  margin="none"
                  onChange={partial(this.handleFieldChange, "password")}
                />
              </Grid>

              <Grid item xs={10} sm={8} className={classes.rememberPassword}>
                <Input
                  type="checkbox"
                  label="Lembrar minha senha"
                  name="signin-remember"
                  id="signin-remember"
                  onChange={partial(this.handleFieldChange, "remember")}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  large
                  withIcon
                  className={classes.noMarginBottom}
                  type="submit"
                >
                  <Icon name="lock" />
                  <span>Entrar</span>
                </Button>
              </Grid>

              {this.props.showLinks && (
                <Grid item xs={12}>
                  <Link to="recuperar-senha" className="secondary">
                    Esqueci minha senha
                  </Link>
                </Grid>
              )}
            </Grid>
          </form>
        </Grid>
        {this.props.showLinks && (
          <Grid item>
            <Grid container className={classes.itemCentered}>
              <Grid item xs={12}>
                <div className={classes.circularBox}>ou</div>
              </Grid>
              <Grid item xs={12}>
                <Button
                  large
                  onClick={this.handleSignup}
                  type="bordered-submit"
                >
                  <span>Crie sua conta</span>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(Signin))
);
