import styled from "styled-components";

const ButtonWrapper = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;
`;

export default ButtonWrapper;
