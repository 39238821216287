import React from "react";

import { Icon } from "@material-ui/core";

import "./Style.css";

const ProgressButton = ({
	disabled,
	text,
	type,
	icon,
	showProgress,
	showSuccess,
	...other
}) => {
	return (
		<button
			type={type}
			disabled={disabled}
			className={`progress-btn ${
				showProgress && !showSuccess ? "active" : ""
			} ${showSuccess ? "success" : ""}`}
			{...other}
		>
			{showSuccess && <Icon className="icon">check</Icon>}

			<div className="text">
				{!showSuccess && icon && <Icon className="icon">{icon}</Icon>} {text}
			</div>
			<div className="progress" />
		</button>
	);
};

export default ProgressButton;
