import React, { PureComponent } from "react";

import { pick, isEmpty } from "lodash";
import { Formik } from "formik";
import * as Yup from "yup";
import isValidCpf from "@brazilian-utils/is-valid-cpf";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";

import Close from "@material-ui/icons/Close";

import Form from "./Form";
import styles from "./style";

class SignUpErrorConflict extends PureComponent {
	constructor(props) {
		super(props);

		this.CONFLICT_ERROR_FIELDS = ["email", "document"];
	}

	getInitialValues() {
		const receivedData = {
			...pick(this.props.data, this.CONFLICT_ERROR_FIELDS)
		};

		if (!isEmpty(receivedData)) {
			return receivedData;
		}

		return {
			email: "",
			document: ""
		};
	}

	validationSchema = () =>
		Yup.object().shape({
			email: Yup.string("Insira seu e-mail")
				.email("Insira um e-mail válido.")
				.required("O e-mail é obrigatório."),
			document: Yup.string("")
				.required("O CPF é obrigatório.")
				.test("document", "O CPF inserido não é valido.", document =>
					isValidCpf(document)
				)
		});

	handleSubmit(values) {
		if (this.props.onSubmit) {
			this.props.onSubmit(values);
		}
	}

	render() {
		const inititalValues = this.getInitialValues();
		const { classes, show } = this.props;

		return (
			<Dialog
				open={show}
				classes={{
					paper: classes.paper
				}}
				aria-labelledby="form-dialog-title"
			>
				<DialogTitle id="form-dialog-title">
					<h2 className={classes.title}>Oops! Revise seus dados.</h2>
					<IconButton
						className={classes.closeButton}
						color="inherit"
						onClick={this.props.onClose}
						aria-label="Fechar"
					>
						<Close />
					</IconButton>
				</DialogTitle>
				<DialogContent classes={{ root: classes.content }}>
					<DialogContentText classes={{ root: classes.subTitle }}>
						Notamos que seu email ou CPF já foram cadastrados em nosso sistema.
						Isso pode significar que você já tem uma conta conosco. Revise essas
						informações abaixo e tente novamente.
					</DialogContentText>
					{show && (
						<Formik
							onSubmit={values => this.handleSubmit(values)}
							render={props => <Form {...props} />}
							initialValues={inititalValues}
							validationSchema={this.validationSchema}
						/>
					)}
				</DialogContent>
			</Dialog>
		);
	}
}

export default withStyles(styles)(SignUpErrorConflict);
