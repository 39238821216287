import { rem } from "polished";

const styles = () => ({
  title: {
    color: "#8080EC",
    fontFamily: "Bree Serif, serif",
    fontSize: rem(26),
    textAlign: "center",
    margimBottom: rem(5)
  },
  loader: {
    margin: `${rem(30)} 0`
  }
});

export default styles;
