import * as Yup from "yup";

const validationSchema = () =>
  Yup.object().shape({
    email: Yup.string("Insira seu e-mail")
      .email("Insira um e-mail válido.")
      .required("O e-mail é obrigatório."),
    emailConfirmation: Yup.string("")
      .required("Confirme seu email")
      .oneOf([Yup.ref("email")], "Os emails não conferem."),
    password: Yup.string("")
      .min(8, "A senha deve conter no mínimo 8 caracteres.")
      .max(32, "A senha deve conter no máximo 32 caracteres.")
      .required("A senha é obrigatória."),
    passwordConfirmation: Yup.string("")
      .required("Confirme a sua senha")
      .oneOf([Yup.ref("password")], "As senhas não conferem.")
  });

export default validationSchema;
