import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import { isAuthenticated } from "../js/utils/authCheck";

import SignIn from "../components/SignIn";

class SignInScreen extends Component {
	componentWillMount() {
		if (isAuthenticated()) {
			this.props.history.push("/inicio");
			return;
		}
	}

	render() {
		return <SignIn />;
	}
}

export default withRouter(SignInScreen);
