import React from "react";

import { Link } from "react-router-dom";
import { rem } from "polished";
import classNames from "classnames";

import { Grid, withStyles } from "@material-ui/core";

const styles = () => ({
	root: {
		color: "#FFFFFF",
		textDecoration: "none",
		"&:hover": {
			borderBottom: `${rem(8)} solid #FFA30F`,
			paddingBottom: rem(14)
		}
	},
	active: {
		borderBottom: `${rem(8)} solid #FFA30F`,
		paddingBottom: `${rem(14)} !important`
	},
	flex: {
		cursor: "pointer",
		display: "flex"
	}
});

const index = ({ active, children, classes, flex, to, ...other }) => {
	return (
		<Grid
			item
			className={
				flex
					? classes.flex
					: classNames(classes.root, active ? classes.active : "")
			}
			component={to ? Link : "div"}
			to={to}
			{...other}
		>
			{children}
		</Grid>
	);
};

export default withStyles(styles)(index);
