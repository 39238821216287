import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import { isAuthenticated } from "../../../js/utils/authCheck";
import styles from "./Style";

import classNames from "classnames";

import {
	ArrowDropDown,
	Email,
	ExitToApp,
	Settings,
	Place
} from "@material-ui/icons";

import {
	Button,
	ListItemIcon,
	ListItemText,
	Menu as MaterialMenu,
	MenuItem,
	withStyles
} from "@material-ui/core";

class Menu extends Component {
	constructor(props) {
		super(props);

		this.state = { anchorEl: null };

		this.logout = this.logout.bind(this);
		this.handleExitPrescription = this.handleExitPrescription.bind(this);
		this.handleMenu = this.handleMenu.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	async logout(event) {
		event.preventDefault();
		await this.props.onSignOut();
		if (!isAuthenticated()) {
			this.props.history.push("/entrar");
		}
	}

	handleExitPrescription(e, route) {
		e.preventDefault();
		this.props.history.push(route);
	}

	handleMenu = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	render() {
		const { classes, user } = this.props;
		const { anchorEl } = this.state;
		const open = Boolean(anchorEl);
		const userFirstLetter = (user.first_name || "").charAt(0);

		return (
			<div className={classes.menu}>
				<Button
					className={classes.button}
					aria-owns={open ? "menu-appbar" : null}
					aria-haspopup="true"
					onClick={this.handleMenu}
				>
					<div className={classNames(classes.profileIcon, classes.leftIcon)}>
						{userFirstLetter}
					</div>
					<ArrowDropDown />
				</Button>
				<MaterialMenu
					id="menu-appbar"
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: "top",
						horizontal: "right"
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right"
					}}
					open={open}
					onClose={this.handleClose}
				>
					<MenuItem
						onClick={event => this.handleExitPrescription(event, "/perfil")}
					>
						<ListItemIcon>
							<Settings />
						</ListItemIcon>
						<ListItemText inset primary="Editar Perfil" />
					</MenuItem>
					<MenuItem
						onClick={event => this.handleExitPrescription(event, "/locais")}
					>
						<ListItemIcon>
							<Place />
						</ListItemIcon>
						<ListItemText inset primary="Locais de Trabalho" />
					</MenuItem>
					<MenuItem
						component={"a"}
						href="http://www.pulsares.com.br/#!/contato"
						target="_blank"
					>
						<ListItemIcon>
							<Email />
						</ListItemIcon>
						<ListItemText inset primary="Contato" />
					</MenuItem>
					<MenuItem onClick={this.logout}>
						<ListItemIcon>
							<ExitToApp />
						</ListItemIcon>
						<ListItemText inset primary="Sair" />
					</MenuItem>
				</MaterialMenu>
			</div>
		);
	}
}

export default withRouter(withStyles(styles)(Menu));
