import React from "react";

import { rem } from "polished";

import {
	TextField as MaterialTextField,
	InputAdornment
} from "@material-ui/core";
import { Done } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
	asterisk: {
		display: "none"
	},
	formControl: {
		"&::after": {
			content: "' (opcional)'"
		}
	},
	input: {
		backgroundColor: "rgba(231, 236,240, 0.4)"
	},
	done: {
		color: "#42C39B",
		padding: `0 ${rem(10)}`
	}
});

const TextField = props => {
	const { classes } = props;

	const verifiedEndornment = (
		<InputAdornment
			classes={{ filled: classes.done }}
			variant="filled"
			position="end"
		>
			<Done />
		</InputAdornment>
	);

	const formLabelClasses = { classes: { asterisk: classes.asterisk } };
	const inputLabelclasses = {
		classes: {
			formControl: classes.formControl
		}
	};

	const labelPropsRequired = props.required
		? { ...formLabelClasses }
		: { ...inputLabelclasses };

	const inputLabelProps = { ...labelPropsRequired, shrink: props.shrink };
	const inputProps = {
		classes: {
			root: classes.input
		},
		endAdornment: props.verified ? verifiedEndornment : "",
		...props.InputProps
	};

	return (
		<MaterialTextField
			{...props}
			InputLabelProps={inputLabelProps}
			InputProps={inputProps}
		/>
	);
};

export default withStyles(styles)(TextField);
