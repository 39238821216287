import React from "react";

import { rem } from "polished";

import { Link } from "react-router-dom";

import { Grid, Icon, withStyles } from "@material-ui/core";

const styles = () => ({
	button: {
		height: rem(40),
		border: "none",
		padding: "0 1.5rem",
		outline: "none",
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		backgroundColor: "transparent",
		borderRadius: 50,
		textDecoration: "none",
		color: "#FFFFFF",
		"&:hover": {
			backgroundColor: "rgba(255,255,255, .3)"
		},
		"&:disabled": {
			cursor: "not-allowed",
			opacity: 0.4
		}
	},
	text: {
		fontFamily: "Montserrat, sans-serif",
		fontSize: ".875rem",
		fontWeight: "800"
	},
	icon: {
		fontSize: rem(24),
		marginRight: rem(12)
	}
});

const FlatButton = ({
	classes,
	component,
	customStyle,
	to,
	text,
	disabled,
	icon,
	onClick
}) => (
	<Grid
		component={component ? component : to ? Link : "div"}
		className={classes.button}
		style={{ ...customStyle }}
		disabled={disabled}
		to={to}
		onClick={onClick}
	>
		{icon && <Icon className={classes.icon}>{icon}</Icon>}
		<span className={classes.text}>{text ? text : ""}</span>
	</Grid>
);

export default withStyles(styles)(FlatButton);
