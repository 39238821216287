const styles = () => ({
  root: {
    paddingTop: "8rem"
  },
  arrow: {
    position: "fixed",
    height: "4.52rem",
    top: "5.2rem"
  },
  physicianWithCloud: {
    height: "17.82rem"
  },
  plantWithCloud: {
    height: "14.46rem"
  },
  alignEnd: {
    alignSelf: "flex-end"
  },
  title: {
    color: "#8080EC",
    fontFamily: "Bree Serif, serif",
    fontSize: "2rem",
    whiteSpace: "pre",
    textAlign: "center",
    margin: "0 2rem"
  },
  titleBreak: {
    "&:before": {
      content: '"\\A"'
    }
  },
  videos: {
    flexGrow: 1,
    marginTop: "2.5rem"
  },
  cover: {
    margin: "0 .5rem"
  },
  videoCover: {
    position: "relative",
    top: "0",
    left: "0"
  },
  playButton: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    msTransform: "translate(-50%, -50%)"
  },
  play: {
    backgroundColor: "#ec867b",
    padding: ".5rem",
    borderRadius: "5rem",
    height: "2.75rem !important",
    width: "2.75rem !important",
    color: "#fff",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#e57c71"
    }
  }
});

export default styles;
