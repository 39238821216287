import React, { Component, Fragment } from "react";

import PropTypes from "prop-types";
import classnames from "classnames";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { partial, isArray, trim, debounce } from "lodash";
import Highlighter from "react-highlight-words";
import Alert from "react-s-alert";
import ReactGA from "react-ga";

import { Grid, Paper, TextField, MenuItem } from "@material-ui/core";
import { AddCircleOutline, Cancel, Edit } from "@material-ui/icons";

import { withStyles } from "@material-ui/core/styles";

import AbsoluteWrapper from "../../../../js/components/AbsoluteWrapper";
import Loader from "../../../../js/components/Loader";

import {
	currentMedication,
	searchMedication,
	isEditMedicationMode
} from "../../../../js/actions/medication";
import {
	addMedication,
	editMedication
} from "../../../../js/actions/prescription";
import { setFormInProgress } from "../../../../js/actions/ui-prescription";

import {
	next as tourNextStep,
	previous as tourPreviousStep,
	run as runTour,
	stop as stopTour,
	reset as resetTour,
	setCurrentStep as tourSetCurrentStep
} from "../../../../js/actions/tour";

import {
	getTreatmentOptions,
	getDefaultAmountOptions,
	getMillilitersAmountOptions,
	getPillAmountOptions,
	getFrequencyOptions
} from "../../../../js/data/prescription";
import * as ALERT from "../../../../js/data/alerts";

import FlatButton from "../../../UI/FlatButton";

import styles from "./Style";

const TREATMENT_OPTIONS = getTreatmentOptions();
const TREATMENT_DEFAULT_VALUE = TREATMENT_OPTIONS[0].value;

const AMOUNT_OPTIONS = getDefaultAmountOptions();
const AMOUNT_DEFAULT_OPTION = AMOUNT_OPTIONS[0].value;

const FREQUENCY_OPTIONS = getFrequencyOptions();
const FREQUENCY_DEFAULT_VALUE = FREQUENCY_OPTIONS[0];

const mapStateToProps = state => {
	const suggestion = state.medication.entries || [];

	return {
		suggestion,
		prescriptionEntries: state.prescription.entries,
		editMedicationMode: state.medication.editMedicationMode,
		isLoading: state.loader.isLoading,
		selectedMedication: state.currentMedication
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			searchMedication,
			addMedication,
			editMedication,
			isEditMedicationMode,
			setFormInProgress,
			currentMedication,
			runTour,
			stopTour,
			resetTour,
			tourNextStep,
			tourPreviousStep,
			tourSetCurrentStep
		},
		dispatch
	);

class RenewPrescriptionForm extends Component {
	static propTypes = {
		suggestion: PropTypes.array,
		prescriptionEntries: PropTypes.array,
		editMedicationMode: PropTypes.object,
		searchMedication: PropTypes.func,
		addMedication: PropTypes.func,
		editMedication: PropTypes.func,
		isEditMedicationMode: PropTypes.func,
		setFormInProgress: PropTypes.func,
		currentMedication: PropTypes.func,
		isLoading: PropTypes.bool
	};

	constructor(props) {
		super(props);
		this.state = {
			enterKeyHits: 0,
			hasSelectedMedication: false,
			medicationName: "",
			queryToSearch: "",
			suggestion: [],
			shouldShowSuggestion: false,
			isEditMode: false,
			medicationRoute: "",
			treatment_days: TREATMENT_DEFAULT_VALUE,
			notes: "",
			unit: "",
			doses: [
				{
					amount: AMOUNT_DEFAULT_OPTION,
					frequency: FREQUENCY_DEFAULT_VALUE
				}
			],
			amountOptions: AMOUNT_OPTIONS,
			showMlInput: false,
			showNotes: false,
			focused: "",
			elevation: 3,
			showFrequencyModal: false
		};

		this.onChangeField = this.onChangeField.bind(this);
		this.setData = this.setData.bind(this);
		this.handleSignin = this.handleSignin.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.handleMedicationClick = this.handleMedicationClick.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.resetFormState = this.resetFormState.bind(this);
		this.addNewDose = this.addNewDose.bind(this);
		this.deleteDose = this.deleteDose.bind(this);
		this.handleDoseChange = this.handleDoseChange.bind(this);
		this.handleShowFrequencyModal = this.handleShowFrequencyModal.bind(this);
		this.handleSaveFrequency = this.handleSaveFrequency.bind(this);
	}

	componentDidMount() {
		const { editMedicationMode } = this.props;

		if (editMedicationMode.isEditMode) {
			const itemToEdit = this.props.prescriptionEntries[
				editMedicationMode.editPrescriptionId
			];

			const data = {
				editPrescriptionId: editMedicationMode.editPrescriptionId,
				isEditMode: true,
				hasSelectedMedication: true,
				medicationName: itemToEdit.medicationName,
				medicationRoute: itemToEdit.medicationRoute,
				form_id: itemToEdit.form_id,
				treatment_days: itemToEdit.treatment_days,
				medication_id: itemToEdit.medication_id,
				notes: itemToEdit.notes,
				doses: itemToEdit.doses,
				unit: itemToEdit.unit
			};

			this.setData(data);
		} else {
			if (this.props.isNewUser && this.props.prescriptionEntries.length === 0) {
				this.props.tourSetCurrentStep(3);
				setTimeout(() => {
					this.props.runTour();
				}, 1000);
			}
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.state.suggestion !== nextProps.suggestion) {
			this.setState({ suggestion: nextProps.suggestion });
		}

		const { editMedicationMode } = nextProps;

		if (editMedicationMode.isEditMode) {
			const itemToEdit = this.props.prescriptionEntries[
				editMedicationMode.editPrescriptionId
			];

			let treatment = itemToEdit.treatment_days;

			if (!itemToEdit.treatment_days.label) treatment = TREATMENT_DEFAULT_VALUE;

			const data = {
				editPrescriptionId: editMedicationMode.editPrescriptionId,
				isEditMode: true,
				hasSelectedMedication: true,
				medicationName: itemToEdit.medicationName,
				medicationRoute: itemToEdit.medicationRoute,
				form_id: itemToEdit.form_id,
				treatment_days: treatment,
				medication_id: itemToEdit.medication_id,
				notes: itemToEdit.notes,
				doses: itemToEdit.doses,
				unit: itemToEdit.unit
			};

			this.setData(data);
		}
	}

	onChangeField() {
		const dosesData = this.state.doses.map(dose => ({
			amount: dose.amount,
			frequency: dose.frequency
		}));

		const data = {
			medicationName: this.state.medicationName,
			treatment_days: this.state.treatment_days,
			medication_id: this.state.medication_id,
			form_id: this.state.form_id,
			notes: this.state.notes,
			doses: dosesData,
			unit: this.state.unit,
			medicationRoute: this.state.medicationRoute
		};

		this.props.currentMedication(data);
	}

	setData(data) {
		let newAmountOptions = [];

		let showMlInput = false;

		if (data.unit === "comprimido") {
			newAmountOptions = getPillAmountOptions();
		} else {
			if (data.unit === "ml") {
				showMlInput = true;
				newAmountOptions = getMillilitersAmountOptions();
			} else {
				newAmountOptions = getDefaultAmountOptions();
			}
		}

		this.setState({
			editPrescriptionId: data.editPrescriptionId,
			isEditMode: data.isEditMode,
			hasSelectedMedication: data.hasSelectedMedication,
			medicationName: data.medicationName,
			medicationRoute: data.medicationRoute,
			form_id: data.form_id,
			treatment_days: data.treatment_days,
			medication_id: data.medication_id,
			notes: data.notes,
			doses: data.doses,
			unit: data.unit,
			amountOptions: newAmountOptions,
			showMlInput: showMlInput
		});
	}

	handleSignin(response) {
		if (response.error) return;
		this.setState({ error: undefined });
	}

	handleSearch(event) {
		this.doSearch(event.target.value.toString());
		this.setState({ medicationName: event.target.value }, function() {
			this.onChangeField();
		});
		this.props.setFormInProgress(true);
	}

	doSearch = debounce(
		async value => {
			if (value.length < 3) {
				this.setState({ shouldShowSuggestion: false, loading: false });
				return;
			}

			this.setState({ queryToSearch: value, loading: true });
			const { error } = await this.props.searchMedication({
				query: trim(value)
			});
			if (error) {
				this.setState({ error, loading: false });
				this.props.onError(error);
			} else {
				this.setState({ shouldShowSuggestion: true, loading: false });
			}
		},
		1000,
		{ trailing: true }
	);

	handleMedicationClick(medication, variation) {
		let newAmountOptions = [];
		let newInitialAmountOption;
		let showMlInput = false;

		if (variation.unit === "comprimido") {
			newAmountOptions = getPillAmountOptions();
			newInitialAmountOption = newAmountOptions[3].value;
		} else {
			if (variation.unit === "ml") {
				showMlInput = true;
				newAmountOptions = getMillilitersAmountOptions();
				newInitialAmountOption = newAmountOptions[1].value;
			} else {
				newAmountOptions = getDefaultAmountOptions();
				newInitialAmountOption = newAmountOptions[0].value;
			}
		}

		const dosesData = this.state.doses.map(dose => ({
			amount: newInitialAmountOption,
			frequency: dose.frequency
		}));

		this.setState(
			{
				hasSelectedMedication: true,
				medication_id: medication.id,
				form_id: variation.id,
				medicationRoute: variation.route,
				unit: variation.unit,
				notes: variation.note || "",
				treatment_days: TREATMENT_DEFAULT_VALUE,
				medicationName: medication.name,
				shouldShowSuggestion: false,
				amountOptions: newAmountOptions,
				doses: dosesData,
				showMlInput: showMlInput
			},
			function() {
				this.onChangeField();
			}
		);
	}

	handleCancel(event) {
		if (event) event.preventDefault();

		this.resetFormState(event);
		if (this.props.onClose) this.props.onClose();
	}

	handleSubmit(event) {
		event.preventDefault();

		const { medicationName, hasSelectedMedication } = this.state;

		if (!medicationName) {
			Alert.error(ALERT.prescription.medicationNameEmpty, { offset: 39 });
			return;
		}

		if (!hasSelectedMedication) {
			Alert.error(ALERT.prescription.chooseMedicationFromList, { offset: 39 });
			return;
		}

		const dosesData = this.state.doses.map(dose => ({
			amount: dose.amount,
			frequency: dose.frequency
		}));

		const data = {
			medicationName: this.state.medicationName,
			treatment_days: this.state.treatment_days,
			medication_id: this.state.medication_id,
			form_id: this.state.form_id,
			notes: this.state.notes,
			doses: dosesData,
			unit: this.state.unit,
			medicationRoute: this.state.medicationRoute
		};

		if (this.state.isEditMode) {
			this.props
				.editMedication(this.state.editPrescriptionId, data)
				.then(() => {
					this.props.currentMedication({});
					//Alert.success(ALERT.prescription.edited, { offset: 39 });
					this.handleCancel();
					ReactGA.event({
						category: "prescription-creation",
						action: "submit-form",
						label: "edit-medication"
					});
				});
		} else {
			this.props.addMedication(data).then(() => {
				this.props.currentMedication({});
				//Alert.success(ALERT.prescription.added, { offset: 39 });

				if (
					this.props.isNewUser &&
					this.props.prescriptionEntries.length === 1
				) {
					setTimeout(() => {
						this.props.tourSetCurrentStep(9);
						this.props.runTour();
					}, 1200);
				}

				this.handleCancel();
				ReactGA.event({
					category: "prescription-creation",
					action: "submit-form",
					label: "add-medication"
				});
			});
		}
	}

	resetFormState(e) {
		if (e) {
			e.preventDefault();
		}

		this.setState({
			hasSelectedMedication: false,
			medicationName: "",
			queryToSearch: "",
			suggestion: [],
			shouldShowSuggestion: false,
			isEditMode: false,
			medicationRoute: "",
			treatment_days: TREATMENT_DEFAULT_VALUE,
			unit: "",
			notes: "",
			amountOptions: AMOUNT_OPTIONS,
			doses: [
				{
					amount: AMOUNT_DEFAULT_OPTION,
					frequency: FREQUENCY_DEFAULT_VALUE
				}
			],
			showMlInput: false
		});

		this.props.currentMedication({});
		this.props.isEditMedicationMode({});
		this.props.setFormInProgress(false);
	}

	addNewDose(event) {
		event.preventDefault();
		const newDoses = this.state.doses;
		newDoses.push({
			amount: AMOUNT_DEFAULT_OPTION,
			frequency: FREQUENCY_DEFAULT_VALUE
		});
		this.setState({ doses: newDoses });
	}

	deleteDose(event, key) {
		event.preventDefault();
		const newDoses = this.state.doses;
		const first = newDoses.slice(0, key);
		const second = newDoses.slice(key + 1);
		this.setState({ doses: first.concat(second) });
		this.onChangeField();
	}

	handleDoseChange(index, type, event) {
		const { doses } = this.state;
		const editedDose = doses[index];

		switch (type) {
			case "amount":
				editedDose.amount = event.target.value;
				break;
			case "frequency":
				editedDose.frequency = event.target.value;
				break;
			default:
				return;
		}

		const newDoses = [].concat(
			doses.slice(0, index),
			editedDose,
			doses.slice(index + 1)
		);

		this.setState({ doses: newDoses });
		this.onChangeField();
	}

	renderSuggestions(item) {
		const { forms } = item;
		const singleForm = forms.length === 1;

		return forms.map(variation => {
			let title = `${item.name}`;
			let form = `${variation.form}`;
			const subtitle = `${item.active_ingredient}`;

			if (!singleForm) {
				form += ` (${variation.unit})`;
			}

			return (
				<MenuItem className={this.props.classes.suggestionItem}>
					<Grid
						container
						key={variation.id}
						className="suggestion-result__item"
						onClick={partial(this.handleMedicationClick, item, variation)}
					>
						<Grid item>
							<span className="medication">
								<b>
									<Highlighter
										highlightClassName="highlight-word"
										searchWords={this.state.queryToSearch.split(" ")}
										textToHighlight={title}
										autoEscape={true}
									/>
								</b>
								<span className="form"> {form}</span>
							</span>
							<small>
								<Highlighter
									highlightClassName="highlight-word"
									searchWords={this.state.queryToSearch.split(" ")}
									textToHighlight={subtitle}
									autoEscape={true}
								/>
							</small>
							<small>{item.manufacturer}</small>
						</Grid>
					</Grid>
				</MenuItem>
			);
		});
	}

	renderDoseLine(item, key) {
		const { classes } = this.props;
		const { doses } = this.state;
		const currentDose = doses[key];

		return (
			<Fragment>
				<Grid item xs={3}>
					<Paper
						className={classes.paper}
						elevation={
							this.state.focused === `amount-${key}` ? this.state.elevation : 0
						}
					>
						{!this.state.showMlInput && (
							<TextField
								id="amount"
								label="Quantidade"
								name="amount"
								items={TREATMENT_OPTIONS}
								select
								value={currentDose.amount}
								onChange={event => {
									this.handleDoseChange(key, "amount", event);
								}}
								required
								fullWidth
								className={classnames(classes.textField, "tour-step-6")}
								margin="none"
								InputProps={{
									disableUnderline: true
								}}
								onFocus={() => this.setFocus(`amount-${key}`)}
								onBlur={this.clearFocus}
							>
								{this.state.amountOptions.map((option, index) => (
									<MenuItem key={index} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</TextField>
						)}

						{this.state.showMlInput && (
							<TextField
								id="amount"
								name="amount"
								type="number"
								value={this.state.amountOptions}
								onChange={event => {
									this.handleDoseChange(key, "amount", event);
								}}
								label="Quantidade"
								fullWidth
								className={classes.textField}
								margin="none"
								InputProps={{
									disableUnderline: true
								}}
								onFocus={() => this.setFocus(`amount-${key}`)}
								onBlur={this.clearFocus}
							/>
						)}
					</Paper>
				</Grid>
				<Grid item xs={3}>
					<Paper
						className={classes.paper}
						elevation={
							this.state.focused === `frequency-${key}`
								? this.state.elevation
								: 0
						}
					>
						<TextField
							id="frequency"
							label="Como tomar"
							name="frequency"
							select
							value={currentDose.frequency}
							onChange={event => {
								this.handleDoseChange(key, "frequency", event);
							}}
							required
							fullWidth
							className={classnames(classes.textField, "tour-step-7")}
							margin="none"
							InputProps={{
								disableUnderline: true
							}}
							onFocus={() => this.setFocus(`frequency-${key}`)}
							onBlur={this.clearFocus}
						>
							{FREQUENCY_OPTIONS.map((option, index) => (
								<MenuItem key={index} value={option}>
									{option.label}
								</MenuItem>
							))}
						</TextField>
					</Paper>
				</Grid>
				<Grid item xs={6}>
					{doses.length > 1 && (
						<Cancel
							className={classnames(
								classes.iconButton,
								classes.cancelDoseButton
							)}
							htmlColor="#E87265"
							onClick={e => this.deleteDose(e, key)}
						/>
					)}
					{key === doses.length - 1 && (
						<AddCircleOutline
							className={classnames(classes.iconButton, "tour-step-8")}
							htmlColor="#9AAFBF"
							onClick={this.addNewDose}
						/>
					)}
				</Grid>
			</Fragment>
		);
	}

	setFocus = name => {
		this.setState({ focused: name });
	};

	clearFocus = () => {
		this.setState({ focused: "" });
	};

	addNotes = () => {
		this.setState({ showNotes: true });
	};

	handleShowFrequencyModal = showFrequencyModal => {
		this.setState({ showFrequencyModal });
	};

	handleSaveFrequency = frequency => {};

	render() {
		const { classes } = this.props;
		const { doses, isEditMode, showNotes } = this.state;
		const submitLabel = isEditMode
			? "Salvar alterações"
			: "Adicionar medicamento";

		return (
			<div
				className={classes.root}
				style={{ backgroundColor: isEditMode ? "#FBF8D6" : "#E7ECF0" }}
			>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={7} className={classes.relative}>
						<Paper
							className={classes.paper}
							elevation={
								this.state.focused === "medication" ? this.state.elevation : 0
							}
						>
							<TextField
								fullWidth
								autoFocus
								autoComplete="off"
								margin="none"
								name="medication"
								className={classnames(classes.textField, "tour-step-4")}
								value={this.state.medicationName}
								label={
									this.props.isEditMode
										? "Edite o medicamento"
										: "Selecione o medicamento"
								}
								InputProps={{
									disableUnderline: true,
									readOnly: this.state.loading
								}}
								onChange={this.handleSearch}
								onFocus={() => this.setFocus("medication")}
								onBlur={this.clearFocus}
							/>

							{this.state.loading && (
								<AbsoluteWrapper right={30} top={30}>
									<Loader />
								</AbsoluteWrapper>
							)}

							{this.state.shouldShowSuggestion && (
								<div className="suggestion-result">
									{this.state.suggestion.length ? (
										<Paper>
											{this.state.suggestion.map(item =>
												this.renderSuggestions(item)
											)}
										</Paper>
									) : (
										<p>Nenhum resultado encontrado.</p>
									)}
								</div>
							)}
						</Paper>
					</Grid>
					<Grid item xs={3}>
						<Paper
							className={classes.paper}
							elevation={
								this.state.focused === "duration" ? this.state.elevation : 0
							}
						>
							<TextField
								id="treatment-days"
								label="Tempo de uso"
								name="treatment-days"
								items={TREATMENT_OPTIONS}
								select
								value={this.state.treatment_days}
								onChange={event => {
									let treatmentDays = TREATMENT_DEFAULT_VALUE;
									if (event && !isArray(event))
										treatmentDays = event.target.value;
									this.setState({ treatment_days: treatmentDays });
									this.onChangeField();
								}}
								fullWidth
								className={classnames(classes.textField, "tour-step-5")}
								margin="none"
								InputProps={{
									disableUnderline: true
								}}
								onFocus={() => this.setFocus("duration")}
								onBlur={this.clearFocus}
							>
								{TREATMENT_OPTIONS.map((option, index) => (
									<MenuItem key={index} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</TextField>
						</Paper>
					</Grid>

					<Grid item xs={1}>
						{/*
						<InsertInvitation
							className={classes.iconButton}
							onClick={() => this.handleShowFrequencyModal(true)}
							htmlColor="#9AAFBF"
						/>
						<Frequency
							onClose={() => this.handleShowFrequencyModal(false)}
							onSave={this.handleSaveFrequency}
							show={showFrequencyModal}
						/>
						*/}
					</Grid>

					{doses.map((dose, key) => this.renderDoseLine(dose, key))}

					<Grid item xs={12}>
						{showNotes && (
							<Paper
								className={classes.paper}
								elevation={
									this.state.focused === "notes" ? this.state.elevation : 0
								}
							>
								<TextField
									label="Observações"
									name="notes"
									value={this.state.notes}
									onChange={event => {
										this.setState({ notes: event.target.value });
										this.onChangeField();
									}}
									multiLine
									fullWidth
									className={classes.textField}
									margin="none"
									InputProps={{
										disableUnderline: true
									}}
									onFocus={() => this.setFocus("notes")}
									onBlur={this.clearFocus}
								>
									{this.state.notes}
								</TextField>
							</Paper>
						)}
						{!showNotes && (
							<Grid
								container
								spacing={1}
								alignItems="center"
								onClick={this.addNotes}
								className={classes.iconButton}
							>
								<Grid item>
									<Edit htmlColor="#9AAFBF" />
								</Grid>
								<Grid item className={classes.notes}>
									Adicione uma observação
								</Grid>
							</Grid>
						)}
					</Grid>
				</Grid>
				<Grid
					container
					spacing={2}
					justify="center"
					alignItems="center"
					className={classes.buttons}
				>
					<Grid item>
						<FlatButton
							text="Cancelar"
							customStyle={{
								color: "#9AAFBF",
								border: "2px solid #9AAFBF"
							}}
							onClick={this.handleCancel}
						/>
					</Grid>
					<Grid item className="tour-step-9">
						<FlatButton
							text={submitLabel}
							icon="add_circle_outline"
							customStyle={{
								backgroundColor: "#42C39B",
								padding: "8px 20px 8px 8px"
							}}
							onClick={this.handleSubmit}
						/>
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(RenewPrescriptionForm));
