import React, { Component, Fragment } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import { Formik } from "formik";
import * as Yup from "yup";

import ReactGA from "react-ga";

import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import Form from "./Form";
import styles from "./Style";

import { signin, getUserProfile } from "../../js/actions/user";

import Alert from "react-s-alert";
import * as ALERT from "../../js/data/alerts";

import LogoBar from "../UI/LogoBar";
import LogoWithMandala from "../../img/signin/logo-with-mandala.svg";

class SignIn extends Component {
	constructor(props) {
		super(props);

		this.forgotPassword = this.forgotPassword.bind(this);
		this.signUp = this.signUp.bind(this);
	}

	validationSchema = () =>
		Yup.object().shape({
			email: Yup.string("Insira seu e-mail")
				.email("Insira um e-mail válido.")
				.required("O e-mail é obrigatório."),
			password: Yup.string("").required("A senha é obrigatória.")
		});

	getInitialValues = () => ({
		email: "",
		password: "",
		remember: false
	});

	async handleSubmit(values, actions) {
		const { email, password, remember } = values;

		if (remember) {
			ReactGA.event({
				category: "log-in",
				action: "submit-form",
				label: "log-in-remember"
			});
		} else {
			ReactGA.event({
				category: "log-in",
				action: "submit-form",
				label: "log-in"
			});
		}

		const result = await this.props.signin(email, password);

		if (!result) {
			this.showError();
			actions.setSubmitting(false);
		} else {
			await this.logIn(result);
		}
	}

	showError() {
		Alert.error(ALERT.signin.loginFailure, {
			position: "top-left"
		});

		ReactGA.event({
			category: "log-in",
			action: "log-in-error"
		});
	}

	forgotPassword() {
		ReactGA.event({
			category: "log-in",
			action: "link-click",
			label: "forgot-password"
		});
		this.props.history.push("/recuperar-senha");
	}

	signUp() {
		ReactGA.event({
			category: "log-in",
			action: "link-click",
			label: "sign-up"
		});
		this.props.history.push("/cadastrar");
	}

	async logIn(result) {
		ReactGA.event({
			category: "log-in",
			action: "log-in-success"
		});

		await this.props.getUserProfile();
		if (this.props.onSignin) this.props.onSignin(result);
		this.props.history.push("/inicio");
	}

	render() {
		const { classes } = this.props;
		const initialValues = this.getInitialValues();

		return (
			<Fragment>
				<LogoBar className={classes.headbar} />
				<Grid container justify="center" className={classes.left}>
					<Grid item className={classes.content}>
						<Grid container direction="column" alignItems="stretch">
							<h2 className={classes.title}>Bem-vindo</h2>
							<h3 className={classes.subtitle}>
								Não tem uma conta?{" "}
								<a
									href="#signup"
									className={classes.link}
									onClick={this.signUp}
								>
									Faça seu cadastro
								</a>
							</h3>
							<Formik
								onSubmit={(values, actions) =>
									this.handleSubmit(values, actions)
								}
								render={props => <Form {...props} />}
								validationSchema={this.validationSchema}
								initialValues={initialValues}
							/>
							<a
								href="#forgot"
								className={classes.link}
								onClick={this.forgotPassword}
							>
								Esqueceu sua senha?
							</a>
						</Grid>
					</Grid>
				</Grid>
				<div className={classes.right}>
					<div className={classes.overlay} />
					<div className={classes.logo}>
						<img src={LogoWithMandala} alt="Styled Logo" />
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	isLoading: state.loader.isLoading
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			signin,
			getUserProfile
		},
		dispatch
	);
export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(withStyles(styles)(SignIn))
);
