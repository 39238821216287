import React, { PureComponent } from "react";

import { rem } from "polished";
import { Formik, Field } from "formik";

import { Grid, withStyles } from "@material-ui/core";

import ProgressButton from "../../UI/ProgressButton";
import FormTextField from "../../UI/Form/TextField";

import CPFMaskedInput from "../../../js/components/common/form/CPFMaskedInput";
import PhoneMaskedInput from "../../../js/components/common/form/PhoneMaskedInput";
import CellPhoneMaskedInput from "../../../js/components/common/form/CellPhoneMaskedInput";

import validationSchema from "./Validation";

const isCellPhone = value => {
	if (value) {
		const phone = value.replace(/\D/g, "");
		return phone.length > 10;
	}
	return false;
};

const styles = () => ({
	root: {},
	textfield: { width: "100%", maxWidth: rem(394) },
	submitButton: {
		margin: `${rem(25)} 0`
	}
});

class PatientPageForm extends PureComponent {
	render() {
		const {
			classes,
			initialData,
			onFieldChange,
			onSubmit,
			saving,
			saved
		} = this.props;
		const saveButtonText = saved
			? "Alterações salvas!"
			: saving
			? "Salvando..."
			: "Salvar Alterações";

		return (
			<Formik
				isInitialValid={false}
				initialValues={initialData}
				validationSchema={validationSchema}
				onSubmit={values => onSubmit(values)}
			>
				{({ handleSubmit, isValid, values }) => (
					<Grid
						container
						direction="column"
						justify="center"
						alignItems="center"
						className={classes.root}
						component="form"
						onSubmit={handleSubmit}
					>
						<Field
							name="name"
							label="Nome"
							className={classes.textfield}
							required
							component={FormTextField}
							onChange={onFieldChange}
						/>

						<Field
							label="Data de nascimento"
							name="birthday"
							type="date"
							className={classes.textfield}
							shrink
							component={FormTextField}
							onChange={onFieldChange}
						/>

						<Field
							label="CPF"
							name="document"
							className={classes.textfield}
							component={FormTextField}
							onChange={onFieldChange}
							InputProps={{
								inputComponent: CPFMaskedInput
							}}
						/>

						<Field
							label="Telefone"
							name="phone"
							className={classes.textfield}
							component={FormTextField}
							onChange={onFieldChange}
							InputProps={{
								inputComponent: isCellPhone(values.phone)
									? CellPhoneMaskedInput
									: PhoneMaskedInput
							}}
						/>
						<Field
							name="email"
							type="email"
							label="Email"
							className={classes.textfield}
							component={FormTextField}
							onChange={onFieldChange}
						/>
						<Grid item className={classes.submitButton}>
							<ProgressButton
								showProgress={saving}
								showSuccess={saved}
								disabled={saving || !isValid}
								text={saveButtonText}
							/>
						</Grid>
					</Grid>
				)}
			</Formik>
		);
	}
}

export default withStyles(styles)(PatientPageForm);
