import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { ArrowForward } from "@material-ui/icons";

const styles = () => ({
	button: {
		border: "none",
		padding: 0,
		outline: "none",
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		width: "10rem",
		backgroundColor: "#8080EC",
		borderRadius: 50,
		"&:hover": {
			backgroundColor: "#7171DD"
		},
		"&:disabled": {
			cursor: "not-allowed",
			opacity: 0.4
		}
	},
	text: {
		color: "#FFF",
		fontFamily: "Montserrat, sans-serif",
		fontSize: ".875rem",
		fontWeight: "bold"
	},
	icon: {
		color: "#8080EC",
		backgroundColor: "#FFF",
		margin: ".3125rem",
		padding: ".3125rem",
		borderRadius: "50%",
		marginRight: "1.3125rem"
	},
	progress: {
		display: "flex",
		alignItems: "center"
	}
});

const SubmitIconButton = ({
	classes,
	type,
	text,
	disabled,
	showIcon,
	progress,
	...other
}) => (
	<button {...other} className={classes.button} type={type} disabled={disabled}>
		<div className={classes.progress}>
			{showIcon && (
				<span className={classes.icon}>
					<ArrowForward />
				</span>
			)}
			<span className={classes.text}>{text ? text : "Confirmar"}</span>
		</div>
	</button>
);

export default withStyles(styles)(SubmitIconButton);
