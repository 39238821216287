import React, { PureComponent, Fragment } from "react";

import { withStyles } from "@material-ui/core/styles";

import Drift from "react-driftjs";

const styles = () => ({
	root: {
		cursor: "pointer",
		position: "absolute",
		right: "-3.5rem",
		top: "45vh",
		fontSize: ".75rem",
		fontWeight: "bold",
		fontFamily: "Montserrat, sans-serif",
		color: "#FFFFFF",
		backgroundColor: "#C2D5E5",
		borderRadius: ".5rem .5rem 0 0",
		padding: ".5rem .625rem",
		transform: "rotate(270deg)",
		"&:hover": {
			backgroundColor: "#C2C8E5"
		}
	}
});

class DriftComponentWrapper extends PureComponent {
	constructor(props) {
		super(props);
		this.driftApi = "";

		this.state = {
			showButton: false
		};

		this.openSidebar = this.openSidebar.bind(this);
	}

	componentDidMount() {
		window.drift.on("ready", api => {
			this.driftApi = api;
			api.widget.hide();
			this.setState({ showButton: true });
		});
	}

	openSidebar(event) {
		event.preventDefault();
		this.driftApi.sidebar.open();
		return false;
	}

	render() {
		const { children } = this.props;
		const { showButton } = this.state;

		return (
			<Fragment>
				<Drift appId="xgehnuixbb37" />
				{showButton &&
					React.cloneElement(children, { onClick: this.openSidebar })}
			</Fragment>
		);
	}
}

export default withStyles(styles)(DriftComponentWrapper);
