import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { Formik, Field } from "formik";

import FormTextField from "../../UI/Form/TextField";
import ProgressButton from "../../UI/ProgressButton";

import validationSchema from "./Validation";

import { STATE_UF } from "../../../js/data/signup";
import { updateUserProfile, getUserProfile } from "../../../js/actions/user";

import styles from "./style";

class RegistrationDialog extends Component {
	constructor(props) {
		super(props);

		this.state = {
			saving: false,
			saved: false
		};

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	async componentDidMount() {
		await this.props.getUserProfile();
	}

	async handleSubmit(values) {
		await this.setState({ saving: true });

		const response = await this.props.updateUserProfile(values);

		if (response) {
			console.error(response);
		} else {
			await this.setState({ saved: true });
			setTimeout(() => {
				this.props.onClose();
			}, 500);
		}

		await this.setState({ saving: false });
	}

	render() {
		const { show, onClose, classes, user } = this.props;
		const { saving, saved } = this.state;

		return (
			<Formik
				isInitialValid={false}
				initialValues={user}
				validationSchema={validationSchema}
				onSubmit={values => this.handleSubmit(values)}
			>
				{({ handleSubmit, isValid }) => (
					<Dialog
						open={show}
						onClose={onClose}
						aria-labelledby="form-dialog-title"
						classes={{ paper: classes.dialog }}
					>
						<DialogTitle
							id="form-dialog-title"
							disableTypography
							classes={{ root: classes.title }}
						>
							<IconButton
								className={classes.closeButton}
								aria-label="Cancelar"
								onClick={onClose}
							>
								<CloseIcon />
							</IconButton>
							Complete os dados de assinatura{" "}
						</DialogTitle>
						<DialogContent>
							<DialogContentText className={classes.text}>
								Complete estas informações que irão aparecer junto a sua
								assinatura
							</DialogContentText>
							<Grid
								container
								direction="row"
								justify="center"
								alignItems="center"
								spacing={2}
								className={classes.root}
								component="form"
								onSubmit={handleSubmit}
							>
								{user.user_type === "student" && (
									<Fragment>
										<Field
											name="university.name"
											label="Nome da instituição de ensino"
											component={FormTextField}
											required
										/>
										<Field
											name="university.enrollment"
											label="Matrícula"
											component={FormTextField}
											required
										/>
									</Fragment>
								)}

								{user.user_type !== "student" && (
									<Fragment>
										<Grid item xs={6}>
											<Field
												name="registration.number"
												label="Número do conselho"
												required
												component={FormTextField}
												fullWidth
											/>
										</Grid>
										<Grid item xs={4}>
											<Field
												name="registration.state"
												label="UF do conselho"
												select
												items={STATE_UF}
												component={FormTextField}
												required
												fullWidth
											/>
										</Grid>
										<Grid item xs={12} className={classes.buttons}>
											<ProgressButton
												showProgress={saving}
												showSuccess={saved}
												disabled={saving || !isValid}
												text="Confirmar"
											/>
										</Grid>
									</Fragment>
								)}
							</Grid>
						</DialogContent>
					</Dialog>
				)}
			</Formik>
		);
	}
}

const mapStateToProps = state => ({
	user: state.user
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			updateUserProfile,
			getUserProfile
		},
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(RegistrationDialog));
