import React from "react";

const illustrationStyle = {
	alignSelf: "center",
	marginTop: "1.25rem",
	width: "28.25%",
	borderRadius: 50
};

const RoundedMiniIllustration = ({ path, alt }) => (
	<img style={illustrationStyle} src={path} alt={alt} />
);

export default RoundedMiniIllustration;
