import React, { PureComponent } from "react";

import { Link } from "react-router-dom";
import moment from "moment";

import List, { Row, Cell, Button } from "../UI/List";

class PatientList extends PureComponent {
	render() {
		const { patients, notFoundMessage, onDelete } = this.props;

		return (
			<List>
				{patients &&
					patients.map((patient, index) => (
						<Row component={Link} to={`/pacientes/${patient.id}`} key={index}>
							<Cell xs={3}>{patient.name}</Cell>
							<Cell>{moment(patient.updated_at).format("DD/MM/YYYY")}</Cell>
							<Cell>
								<Button
									to={`/nova-receita?paciente=${patient.id}`}
									label="Criar receita"
									icon="control_point"
									color="purple"
								/>
								<Button
									to={`/pacientes/${patient.id}`}
									label="Perfil"
									icon="person"
									color="green"
								/>
								<Button
									label="Excluir"
									icon="delete"
									color="red"
									onClick={() => onDelete(patient.id)}
								/>
							</Cell>
						</Row>
					))}

				{patients.length === 0 && (
					<Row justify="center" height="64px">
						<Cell>{notFoundMessage}</Cell>
					</Row>
				)}
			</List>
		);
	}
}

export default PatientList;
