import createReducer from "../utils/createReducer";
import {
  CREATE_PATIENT,
  CREATE_PATIENT_FAILURE,
  UPDATE_PATIENT,
  UPDATE_PATIENT_FAILURE,
  REMOVE_PATIENT,
  GET_PATIENT,
  GET_ALL_PATIENTS,
  GET_ALL_PRESCRIPTION_FOR_PATIENT
} from "../constants/";

const INITIAL_STATE = {
  entries: [],
  selected: {},
  prescription: []
};

const patient = createReducer(INITIAL_STATE, {
  [CREATE_PATIENT]: (state, action) => {
    const entries = state.entries;
    entries.push(action.payload);

    return Object.assign({}, state, {
      entries,
      selected: action.payload
    });
  },

  [CREATE_PATIENT_FAILURE]: (state, action) =>
    Object.assign({}, state, {
      error: action.error
    }),

  [UPDATE_PATIENT]: (state, action) =>
    Object.assign({}, state, {
      entries: state.entries.map(entry => {
        if (entry.id === action.payload.id) return action.payload;
        return entry;
      }),
      selected: action.payload
    }),

  [UPDATE_PATIENT_FAILURE]: (state, action) =>
    Object.assign({}, state, {
      error: action.error
    }),

  [GET_PATIENT]: (state, action) =>
    Object.assign({}, state, {
      selected: action.payload
    }),

  [GET_ALL_PATIENTS]: (state, action) =>
    Object.assign({}, state, {
      entries: action.payload
    }),

  [REMOVE_PATIENT]: (state, action) => {
    const newState = state.entries.filter(
      entry => entry.id !== action.payload.id
    );

    return Object.assign({}, state, {
      entries: newState
    });
  },

  [GET_ALL_PRESCRIPTION_FOR_PATIENT]: (state, action) =>
    Object.assign({}, state, {
      prescription: action.payload
    })

  /*[REMOVE_PRESCRIPTION]: (state, action) => {
    const newState = state.prescription.filter(entry => entry.id !== action.payload.id)

    return Object.assign({}, state, {
      prescription: newState,
    })
  },*/
});

export default patient;
