import React, { Component, Fragment } from "react";

import Profile from "../../components/Profile/Account";
import MenuBar from "../../components/UI/MenuBar";
import MenuItem from "../../components/UI/MenuBar/MenuItem";
import SubMenu from "../../components/UI/MenuBar/SubMenu";
import SubMenuItem from "../../components/UI/MenuBar/SubMenu/SubMenuItem";
import Footer from "../../components/UI/Footer";

class ProfileAccountScreen extends Component {
	render() {
		return (
			<Fragment>
				<MenuBar
					buttonText="Adicionar paciente"
					buttonIcon="person_add"
					buttonGoTo="/novo-paciente"
					showAlerts
					showSettings
					showBackButton
					onBackClick={() => this.props.history.goBack()}
				>
					<MenuItem active to={"/meu-perfil/dados-pessoais"}>
						Meu perfil
					</MenuItem>
				</MenuBar>
				<SubMenu>
					<SubMenuItem to="/meu-perfil/dados-pessoais" icon="account_circle">
						Dados pessoais
					</SubMenuItem>
					<SubMenuItem to="/meu-perfil/local-de-trabalho" icon="place">
						Local de trabalho
					</SubMenuItem>
					<SubMenuItem active to="/meu-perfil/conta" icon="lock">
						Login e senha
					</SubMenuItem>
				</SubMenu>
				<Profile />
				<Footer />
			</Fragment>
		);
	}
}

export default ProfileAccountScreen;
