import { combineReducers } from "redux";
import { routerReducer as routing } from "react-router-redux";
import { reducer as formReducer } from "redux-form";
import { reducer as toastrReducer } from "react-redux-toastr";

import user from "./user";
import patient from "./patient";
import medication from "./medication";
import prescription from "./prescription";
import uiPrescription from "./ui-prescription";
import loader from "./loader";
import tour from "./tour";
import clinic from "../components/Clinic/ClinicReducer";
import signup from "../../components/SignUp/reducer";

const rootReducer = combineReducers({
  user,
  clinic,
  signup,
  patient,
  medication,
  prescription,
  uiPrescription,
  loader,
  tour,
  routing,
  form: formReducer,
  toastr: toastrReducer
});

export default rootReducer;
