import { API_URL } from "../config/api";
import services from "../services/";
import * as types from "../constants/";
import { requestLoader } from "./loader";

export const createPatientSuccess = data => ({
  type: types.CREATE_PATIENT,
  payload: data
});

export const createPatientFailure = error => ({
  type: types.CREATE_PATIENT_FAILURE,
  error
});

export const createPatient = data =>
  function createPatientFn(dispatch) {
    const url = `${API_URL}/patients/`;

    return services
      .create(url, data)
      .then(response => {
        dispatch(createPatientSuccess(response.data));

        return response;
      })
      .catch(e => {
        return dispatch(createPatientFailure(e));
      });
  };

export const updatePatientSuccess = data => ({
  type: types.UPDATE_PATIENT,
  payload: data
});

export const updatePatientFailure = error => ({
  type: types.UPDATE_PATIENT_FAILURE,
  error
});

export const updatePatient = (data, id) =>
  function updatePatientFn(dispatch) {
    const url = `${API_URL}/patients/${id}`;

    dispatch(requestLoader(true));

    return services
      .update(url, data)
      .then(response => {
        dispatch(updatePatientSuccess(response.data));
        dispatch(requestLoader(false));
        return response;
      })
      .catch(e => {
        dispatch(requestLoader(false));
        return dispatch(updatePatientFailure(e));
      });
  };

export const removePatientSuccess = id => ({
  type: types.REMOVE_PATIENT,
  payload: { id }
});

export const removePatient = id =>
  function removePatientFn(dispatch) {
    const url = `${API_URL}/patients/${id}`;

    dispatch(requestLoader(true));

    return services.delete(url).then(() => {
      dispatch(removePatientSuccess(id));
      dispatch(requestLoader(false));
    });
  };

export const getPatientSuccess = data => ({
  type: types.GET_PATIENT,
  payload: data
});

export const getPatient = id =>
  function getPatientFn(dispatch) {
    const url = `${API_URL}/patients/${id}`;

    dispatch(requestLoader(true));

    return services
      .fetch(url)
      .then(response => {
        dispatch(getPatientSuccess(response.data));
        dispatch(requestLoader(false));
      })
      .catch(() => dispatch(requestLoader(false)));
  };

export const getAllPatientsSuccess = data => ({
  type: types.GET_ALL_PATIENTS,
  payload: data
});

export const getAllPatients = () =>
  function getAllPatientsFn(dispatch) {
    const url = `${API_URL}/patients/`;

    dispatch(requestLoader(true));

    return services
      .fetch(url)
      .then(response => {
        dispatch(getAllPatientsSuccess(response.data));
        dispatch(requestLoader(false));
      })
      .catch(() => dispatch(requestLoader(false)));
  };

export const getAllPrescriptionForPatientSuccess = data => ({
  type: types.GET_ALL_PRESCRIPTION_FOR_PATIENT,
  payload: data
});

export const getAllPrescriptionForPatient = id =>
  function getAllPrescriptionForPatientFn(dispatch) {
    const url = `${API_URL}/patients/${id}/prescriptions`;

    dispatch(requestLoader(true));

    return services
      .fetch(url)
      .then(response => {
        dispatch(getAllPrescriptionForPatientSuccess(response.data));
        dispatch(requestLoader(false));
      })
      .catch(() => dispatch(requestLoader(false)));
  };
