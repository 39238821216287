import React from "react";

import classNames from "classnames";
import { Button, Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
	leftIcon: {
		marginRight: theme.spacing(1)
	},
	button: {
		borderWidth: "1px",
		borderStyle: "solid",
		borderColor: props => (props.color ? props.color : "rgba(0, 0, 0, 0.54)"),
		marginLeft: theme.spacing(2),
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		color: props => (props.color ? props.color : "rgba(0, 0, 0, 0.54)"),
		height: 38,
		width: 38,
		minWidth: 38,
		borderRadius: 20,
		textTransform: "capitalize",
		transition: "width 250ms",

		"& .label": {
			opacity: 0,
			transition: "opacity 100ms",
			transitionDelay: "250ms"
		},

		"&:hover": {
			"& .label": {
				opacity: 1
			},
			width: 150,
			backgroundColor: "#8080EB",
			borderColor: "#8080EB",
			color: "#fff"
		}
	},
	deleteButton: {
		"&:hover": {
			backgroundColor: "#bf5449",
			borderColor: "#bf5449"
		}
	}
}));

const ToggleButton = props => {
	const {
		icon,
		iconColor,
		isDelete,
		label,
		style,
		hoverStyle,
		...other
	} = props;

	const [state, setState] = React.useState({
		showLabel: false
	});

	const handleShowLabel = () => {
		setState({ ...state, showLabel: true });
	};

	const handleHideLabel = () => {
		setState({ ...state, showLabel: false });
	};

	const styles = { ...style, ...hoverStyle };

	const { showLabel } = state;
	const classes = useStyles(props);

	return (
		<Button
			className={classNames(
				classes.button,
				isDelete ? classes.deleteButton : ""
			)}
			onMouseEnter={handleShowLabel}
			onMouseLeave={handleHideLabel}
			style={showLabel ? styles : style}
			{...other}
		>
			<Icon className={showLabel ? classes.leftIcon : ""} htmlColor={iconColor}>
				{icon}
			</Icon>
			<div className="label">{showLabel && label}</div>
		</Button>
	);
};

export default ToggleButton;
