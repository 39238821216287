const styles = () => ({
  root: {
    marginTop: "4rem"
  },
  textfield: {},
  title: {
    textAlign: "center",
    fontSize: "1.625rem",
    color: "#8080EC",
    fontFamily: "Bree Serif, serif",
    margin: "1.75rem 0 .625rem 0 "
  },
  legend: {
    color: "#372F4E",
    fontFamily: "Montserrat, sans-serif",
    fontSize: ".875rem",
    fontWeight: "bold",
    marginTop: "1.5rem"
  },
  buttons: {
    margin: "1rem 0"
  }
});

export default styles;
