import React, { PureComponent } from "react";

import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Slide from "@material-ui/core/Slide";
import Paper from "@material-ui/core/Paper";

import withWidth, { isWidthDown } from "@material-ui/core/withWidth";

import Illustration from "../Illustration";
import LogoWithBackButton from "../LogoWithBackButton";
import RoundedMiniIllustration from "../RoundedMiniIllustration";

class SplitWithIllustration extends PureComponent {
	render() {
		const {
			children,
			onBackClick,
			miniIllustrationPath,
			miniillustrationAlt,
			illustrationPath,
			illustrationAlt,
			show,
			width
		} = this.props;

		return (
			<div>
				<Grid
					container
					direction="column"
					alignItems="center"
					style={{ width: isWidthDown("sm", width) ? "100%" : "42.5%" }}
				>
					<LogoWithBackButton onBackClick={onBackClick} />
					{miniIllustrationPath && (
						<Hidden mdUp>
							<RoundedMiniIllustration
								path={miniIllustrationPath}
								alt={miniillustrationAlt}
							/>
						</Hidden>
					)}
					<Slide direction="right" in={show} mountOnEnter unmountOnExit>
						<Paper elevation={0}>{children}</Paper>
					</Slide>
				</Grid>
				<Hidden smDown>
					{show && (
						<Slide direction="left" in={show} mountOnEnter unmountOnExit>
							<Paper elevation={0}>
								<Illustration path={illustrationPath} alt={illustrationAlt} />
							</Paper>
						</Slide>
					)}
				</Hidden>
			</div>
		);
	}
}

export default withWidth()(SplitWithIllustration);
