import React, { PureComponent, Fragment } from "react";

import { rem } from "polished";
import { Formik, Field } from "formik";

import { Grid, withStyles } from "@material-ui/core";

import { STATE_UF, USER_TYPES, VOCATIVES } from "../../../js/data/signup";
import CPFMaskedInput from "../../../js/components/common/form/CPFMaskedInput";
import ProgressButton from "../../UI/ProgressButton";

import FormTextField from "../../UI/Form/TextField";
import validationSchema from "./Validation";

const styles = () => ({
	root: {},
	textfield: { width: "100%", maxWidth: rem(394) },
	submitButton: {
		margin: `${rem(25)} 0`
	}
});

class ProfilePersonalDataForm extends PureComponent {
	render() {
		const {
			classes,
			initialData,
			onFieldChange,
			onSubmit,
			saving,
			saved
		} = this.props;
		const saveButtonText = saved
			? "Alterações salvas!"
			: saving
			? "Salvando..."
			: "Salvar Alterações";

		return (
			<Formik
				isInitialValid={false}
				initialValues={initialData}
				validationSchema={validationSchema}
				onSubmit={values => onSubmit(values)}
			>
				{({ values, handleSubmit, isValid }) => (
					<Grid
						container
						direction="column"
						justify="center"
						alignItems="center"
						className={classes.root}
						component="form"
						onSubmit={handleSubmit}
					>
						<Field
							name="user_type"
							label="Eu atuo como"
							className={classes.textfield}
							select
							items={USER_TYPES}
							required
							component={FormTextField}
							onChange={onFieldChange}
						/>
						<Field
							name="title"
							label="Vocativo"
							className={classes.textfield}
							select
							items={VOCATIVES}
							component={FormTextField}
							onChange={onFieldChange}
						/>

						<Field
							label="Seu nome"
							name="first_name"
							className={classes.textfield}
							required
							component={FormTextField}
							onChange={onFieldChange}
						/>

						<Field
							label="Sobrenome"
							name="last_name"
							className={classes.textfield}
							required
							component={FormTextField}
							onChange={onFieldChange}
						/>

						<Field
							label="Data de nascimento"
							name="birthday"
							type="date"
							className={classes.textfield}
							required
							shrink
							component={FormTextField}
							onChange={onFieldChange}
						/>

						<Field
							label="CPF"
							name="document"
							className={classes.textfield}
							required
							component={FormTextField}
							onChange={onFieldChange}
							InputProps={{
								inputComponent: CPFMaskedInput
							}}
						/>

						{values.user_type === "student" && (
							<Fragment>
								<Field
									name="university.name"
									label="Nome da instituição de ensino"
									className={classes.textfield}
									component={FormTextField}
									onChange={onFieldChange}
									required
								/>
								<Field
									name="university.enrollment"
									label="Matrícula"
									className={classes.textfield}
									component={FormTextField}
									onChange={onFieldChange}
									required
								/>
							</Fragment>
						)}

						{values.user_type !== "student" && (
							<Fragment>
								<Field
									name="registration.number"
									label="Número do conselho"
									className={classes.textfield}
									required
									component={FormTextField}
									onChange={onFieldChange}
								/>
								<Field
									name="registration.state"
									label="UF do conselho"
									className={classes.textfield}
									select
									items={STATE_UF}
									component={FormTextField}
									onChange={onFieldChange}
									required
								/>
							</Fragment>
						)}
						<Grid item className={classes.submitButton}>
							<ProgressButton
								showProgress={saving}
								showSuccess={saved}
								disabled={saving || !isValid}
								text={saveButtonText}
							/>
						</Grid>
					</Grid>
				)}
			</Formik>
		);
	}
}

export default withStyles(styles)(ProfilePersonalDataForm);
