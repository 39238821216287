import { API_URL } from "../config/api";
import accountService from "../services/account";
import services from "../services";
import * as types from "../constants/";
import { requestLoader } from "./loader";

// Get basic user data
// @TODO: It could be defined on local storage.
export const getUserData = token => ({
  type: types.GET_USER_DATA,
  payload: { token }
});

// Login
export const signinSuccess = data => ({
  type: types.SIGNIN,
  payload: data
});

export const signin = (username, password) =>
  function signinFn(dispatch) {
    dispatch(requestLoader(true));
    return accountService
      .signin(username, password)
      .then(response => {
        dispatch(signinSuccess(response.data));
        dispatch(requestLoader(false));
        return response;
      })
      .catch(() => {
        dispatch(requestLoader(false));
      });
  };

// Register
export const signupSuccess = data => ({
  type: types.SIGNUP,
  payload: data
});

export const signupFailure = error => ({
  type: types.SIGNUP_FAILURE,
  error: error.response.data.validation_errors || [error.response.data.error],
  status: error.response.status
});

export const signup = data =>
  function signupFn(dispatch) {
    dispatch(requestLoader(true));
    return accountService
      .signup(data)
      .then(response => {
        dispatch(requestLoader(false));
        return dispatch(signupSuccess(response.data));
      })
      .catch(e => {
        dispatch(requestLoader(false));
        return dispatch(signupFailure(e));
      });
  };

export const signupConfirmation = token =>
  function signupFn(dispatch) {
    dispatch(requestLoader(true));
    return accountService
      .signupconfirmation(token)
      .then(response => {
        dispatch(requestLoader(false));
        return dispatch(signupConfirmationSuccess());
      })
      .catch(e => {
        dispatch(requestLoader(false));
        return dispatch(signupConfirmationFailure(e));
      });
  };

export const signupConfirmationSuccess = data => ({
  type: types.SIGNUP_CONFIRMATION,
  payload: data
});

export const signupConfirmationFailure = error => ({
  type: types.SIGNUP_CONFIRMATION_FAILURE,
  error: error,
  status: error.response.status
});

// Logout
export const signoutSuccess = () => ({
  type: types.SIGNOUT,
  payload: null
});

export const signout = () =>
  function signoutFn(dispatch) {
    return new Promise(resolve => {
      resolve(dispatch(signoutSuccess()));
    });
  };

// Reset password
export const recoverPasswordSuccess = data => ({
  type: types.RECOVER_PASSWORD,
  payload: data
});

export const recoverPassword = email =>
  function recoverPasswordFn(dispatch) {
    dispatch(requestLoader(true));
    return accountService
      .recover(email)
      .then(response => {
        dispatch(recoverPasswordSuccess(response.data));
        dispatch(requestLoader(false));
      })
      .catch(() => {
        dispatch(requestLoader(false));
      });
  };

export const confirmResetUserPasswordSuccess = data => ({
  type: types.CONFIRM_RESET_USER_PASSWORD,
  payload: data
});

export const confirmResetUserPassword = (data, resetToken) =>
  function confirmResetUserPasswordFn(dispatch) {
    dispatch(requestLoader(true));
    return accountService
      .confirmResetUserPassword(data, resetToken)
      .then(response => {
        dispatch(recoverPasswordSuccess(response.data));
        dispatch(requestLoader(false));
      })
      .catch(() => {
        dispatch(requestLoader(false));
      });
  };

// User profile
export const getUserProfileSuccess = data => ({
  type: types.GET_USER_PROFILE,
  payload: data
});

export const getUserProfileFailure = error => ({
  type: types.GET_USER_PROFILE_FAILURE,
  error
});

export const getUserProfile = () =>
  function getUserProfileFn(dispatch) {
    const url = `${API_URL}/profile`;

    dispatch(requestLoader(true));
    return services
      .fetch(url)
      .then(response => {
        dispatch(getUserProfileSuccess(response.data));
        dispatch(requestLoader(false));
      })
      .catch(error => {
        dispatch(requestLoader(false));
        return dispatch(getUserProfileFailure(error));
      });
  };

export const updateUserProfileSuccess = data => ({
  type: types.UPDATE_USER_PROFILE,
  payload: data
});

export const updateUserProfileFailure = error => ({
  type: types.UPDATE_USER_PROFILE_FAILURE,
  error
});

export const updateUserProfile = data =>
  function updateUserProfileFn(dispatch) {
    const url = `${API_URL}/profile`;

    return services
      .create(url, data)
      .then(response => {
        dispatch(updateUserProfileSuccess(response.data));
      })
      .catch(e => {
        return dispatch(updateUserProfileFailure(e));
      });
  };
