import React, { useState } from "react";

import { rem } from "polished";

import "moment/locale/pt-br";
import moment from "@date-io/moment";

import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker
} from "@material-ui/pickers";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
	root: {
		marginRight: rem(25)
	}
});

const SearchDate = ({ classes, onSearch }) => {
	const [selectedDate, handleDateChange] = useState(null);

	return (
		<MuiPickersUtilsProvider utils={moment} locale="pt-BR">
			<KeyboardDatePicker
				disableToolbar
				autoOk
				invalidDateMessage="Formato de data inválido"
				minDateMessage="Essa data é muito antiga."
				variant="inline"
				margin="normal"
				inputVariant="filled"
				className={classes.root}
				id="date-search"
				label="Data"
				format="DD/MM/YYYY"
				maskChar=" "
				value={selectedDate}
				onChange={date => {
					handleDateChange(date);
					if (date) onSearch(date.format("DD/MM/YYYY"));
				}}
			/>
		</MuiPickersUtilsProvider>
	);
};

export default withStyles(styles)(SearchDate);
