import React, { PureComponent } from "react";

import { Formik, Field } from "formik";

import { Grid, withStyles } from "@material-ui/core";

import ProgressButton from "../../../UI/ProgressButton";

import FormTextField from "../../../UI/Form/TextField";
import validationSchema from "../Validation";

import ZipCodeMaskedInput from "../../../../js/components/common/form/ZipCodeMaskedInput";
import CellPhoneMaskedInput from "../../../../js/components/common/form/CellPhoneMaskedInput";
import PhoneMaskedInput from "../../../../js/components/common/form/PhoneMaskedInput";
import { STATE_UF } from "../../../../js/data/signup";

import styles from "./Style";

class ProfileAccountForm extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {};
	}

	isCellPhone = values => {
		if (values.address && values.address.phone) {
			const phone = values.address.phone.replace(/\D/g, "");
			return phone.length > 10;
		}

		return false;
	};

	render() {
		const {
			classes,
			initialData,
			isEditMode,
			onFieldChange,
			onSubmit,
			saving,
			saved
		} = this.props;

		const saveButtonText = saved
			? "Local salvo!"
			: saving
			? isEditMode
				? "Atualizando..."
				: "Adicionando..."
			: isEditMode
			? "Atualizar este local"
			: "Adicionar este local";

		return (
			<Formik
				isInitialValid={false}
				initialValues={initialData}
				validationSchema={validationSchema}
				onSubmit={values => onSubmit(values)}
			>
				{({ handleSubmit, isValid, values }) => (
					<Grid
						container
						direction="column"
						justify="center"
						alignItems="center"
						className={classes.root}
						component="form"
						onSubmit={handleSubmit}
					>
						<Field
							name="name"
							label="Nome do seu local de trabalho"
							className={classes.textfield}
							required
							component={FormTextField}
							onChange={onFieldChange}
						/>
						<Field
							name="address.zip"
							label="CEP"
							className={classes.textfield}
							required
							component={FormTextField}
							onChange={onFieldChange}
							InputProps={{
								inputComponent: ZipCodeMaskedInput
							}}
						/>
						<Field
							name="address.street"
							label="Rua"
							className={classes.textfield}
							required
							component={FormTextField}
							onChange={onFieldChange}
						/>
						<Field
							name="address.number"
							label="Número"
							className={classes.textfield}
							required
							component={FormTextField}
							onChange={onFieldChange}
						/>
						<Field
							name="address.complement"
							label="Complemento"
							className={classes.textfield}
							component={FormTextField}
							onChange={onFieldChange}
						/>
						<Field
							name="address.district"
							label="Bairro"
							className={classes.textfield}
							required
							component={FormTextField}
							onChange={onFieldChange}
						/>
						<Field
							name="address.city"
							label="Cidade"
							className={classes.textfield}
							required
							component={FormTextField}
							onChange={onFieldChange}
						/>
						<Field
							name="address.state"
							label="Estado"
							className={classes.textfield}
							required
							component={FormTextField}
							onChange={onFieldChange}
							select
							items={STATE_UF}
						/>
						<Field
							name="address.phone"
							label="Telefone"
							className={classes.textfield}
							required
							component={FormTextField}
							onChange={onFieldChange}
							InputProps={{
								inputComponent: this.isCellPhone(values)
									? CellPhoneMaskedInput
									: PhoneMaskedInput
							}}
						/>
						<Grid item className={classes.submitButton}>
							<ProgressButton
								showProgress={saving}
								showSuccess={saved}
								disabled={saving || !isValid}
								text={saveButtonText}
								icon="add_location"
								style={{ width: "210px" }}
							/>
						</Grid>
					</Grid>
				)}
			</Formik>
		);
	}
}

export default withStyles(styles)(ProfileAccountForm);
