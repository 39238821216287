import { API_URL } from "../config/api";
import services from "../services/";
import * as types from "../constants/";

export const searchMedicationSuccess = data => ({
  type: types.SEARCH_MEDICATION,
  payload: data
});

export const searchMedicationFailure = error => ({
  type: types.SEARCH_MEDICATION_FAILURE,
  error
});

export const searchMedication = data =>
  function searchMedicationFn(dispatch) {
    const url = `${API_URL}/medications/`;

    return services
      .fetch(url, data)
      .then(response => {
        dispatch(searchMedicationSuccess(response.data));
        return response;
      })
      .catch(e => dispatch(searchMedicationFailure(e)));
  };

export const isEditMedicationMode = data => ({
  type: types.IS_EDIT_MEDICATION_MODE,
  payload: data
});

export const currentMedication = medication => ({
  type: types.SELECT_MEDICATION,
  payload: medication
});
