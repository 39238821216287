import React, { Component } from "react";

import PropTypes from "prop-types";

import HeaderBar from "../components/Header/HeaderWithPrint";
import ViewPrescriptionEasy from "../components/Prescription/views/ViewPrescriptionEasy";

class PrescriptionViewPage extends Component {
  // @TODO: set correct PropType for location prop.
  static propTypes = {
    location: PropTypes.any
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.setState({ prevPath: this.props.location });
    }
  }

  render() {
    const { location } = this.props;
    let params = new URLSearchParams(location.search);

    return (
      <div>
        <HeaderBar />
        <ViewPrescriptionEasy prescriptionId={params.get("id")} />
      </div>
    );
  }
}

export default PrescriptionViewPage;
