import React, { Component } from "react";

import HeaderBar from "../components/HeaderBar";
import Page from "../components/Page";
import PageTitle from "../components/PageTitle";
import Configurations from "../components/Configurations";

class ConfigurationsPage extends Component {
  render() {
    return (
      <Page>
        <HeaderBar />
        <PageTitle>Editar Perfil</PageTitle>
        <Configurations />
      </Page>
    );
  }
}

export default ConfigurationsPage;
