import React, { PureComponent } from "react";

import { Link } from "react-router-dom";

import { PersonAdd, Search } from "@material-ui/icons";
import logo from "../../../img/logo-pulsares.png";

import { Grid, withStyles } from "@material-ui/core";

import styles from "./Style";

class Controllers extends PureComponent {
	render() {
		const { classes } = this.props;

		return (
			<Grid
				container
				direction={"row"}
				alignItems={"center"}
				justify={"flex-start"}
				spacing={3}
			>
				<Grid item className={classes.logo}>
					<a href="/inicio">
						<img src={logo} alt="Pulsares" width={145} />
					</a>
					<p className={classes.version}>Versão Beta</p>
				</Grid>
				<Grid
					className={classes.menuIcon}
					component={Link}
					to={"/historico"}
					item
				>
					<Search className={classes.linkIcon} />
				</Grid>
				<Grid
					className={classes.menuIcon}
					component={Link}
					to="/novo-paciente"
					item
				>
					<PersonAdd className={classes.linkIcon} id="addPatient" />
				</Grid>
			</Grid>
		);
	}
}

export default withStyles(styles)(Controllers);
