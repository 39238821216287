import React, { Component } from "react";
import PropTypes from "prop-types";
import Joyride from "react-joyride";

class UserTour extends Component {
	state = {
		run: true,
		steps: [
			{
				content: (
					<div>
						<strong>
							Cadastre seu primeiro paciente para gerar a receita.
						</strong>{" "}
						<span>Clique no ícone acima para iniciar.</span>
					</div>
				),
				placement: "bottom-start",
				disableBeacon: true,
				styles: {
					options: {
						zIndex: 10000
					}
				},
				target: "#addPatient",
				title: "Our projects"
			}
		]
	};

	static propTypes = {
		joyride: PropTypes.shape({
			callback: PropTypes.func
		})
	};

	static defaultProps = {
		joyride: {}
	};

	handleClickStart = e => {
		e.preventDefault();

		this.setState({
			run: true
		});
	};

	render() {
		const { run, steps } = this.state;

		return (
			<Joyride
				spotlightClicks
				disableOverlayClose
				run={run}
				steps={steps}
				styles={{
					buttonClose: {
						display: "none"
					},
					spotlight: {
						borderRadius: 50
					},
					tooltip: {
						borderRadius: 10,
						width: "300px",
						marginLeft: "-10px",
						marginTop: "-6px",
						padding: 22
					},
					tooltipTitle: {
						display: "none"
					},
					tooltipContent: {
						color: "#8080EB",
						fontSize: "18px",
						textAlign: "left",
						padding: 0
					},
					tooltipFooter: {
						display: "none"
					}
				}}
			/>
		);
	}
}

export default UserTour;
