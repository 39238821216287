import React from "react";
import ReactDom from "react-dom";

import configureStore from "./js/store/configureStore";
import Root from "./js/containers/Root";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import "./style/source/grid.sass";
import "./style/source/main.sass";

const store = configureStore();

const theme = createMuiTheme({
  palette: {
    primary: { main: "#7C7CE4" }
  },
  typography: {
    fontFamily: "Montserrat, sans-serif"
  }
});

ReactDom.render(
  <MuiThemeProvider theme={theme}>
    <Root store={store} />
  </MuiThemeProvider>,
  document.getElementById("root")
);
