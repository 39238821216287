import React, { PureComponent } from "react";

import { Grid } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import TextField from "../../../js/components/common/form/TextField";
import PasswordField from "../../../js/components/common/form/PasswordField";

import SubmitIconButton from "../../UI/SubmitIconButton";

import styles from "./Style";

class SignUpAccountForm extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			showPassword: false,
			showPasswordConfirmation: false
		};
	}

	setVerifiedFields(errors, touched) {
		let completedSteps = 0;

		if (errors && touched) {
			const emailIsVerified = !errors.email && touched.email;
			const passwordIsVerified = !errors.password && touched.password;
			const passwordConfirmationsIsVerified =
				!errors.passwordConfirmation && touched.passwordConfirmation;

			if (emailIsVerified) completedSteps++;
			if (passwordIsVerified) completedSteps++;
			if (passwordConfirmationsIsVerified) completedSteps++;
		}

		this.props.onStepVerified(completedSteps);
	}

	render() {
		const {
			classes,
			values,
			errors,
			touched,
			handleChange,
			handleBlur,
			handleSubmit,
			isSubmitting,
			isValid,
			onPasswordChange
		} = this.props;

		this.setVerifiedFields(errors, touched);

		return (
			<Grid
				container
				direction="column"
				alignItems="stretch"
				className={classes.root}
			>
				<h1 className={classes.title}>Vamos criar sua conta!</h1>

				<form className={classes.form} onSubmit={handleSubmit} valitade>
					<TextField
						id="email"
						name="email"
						type="email"
						value={values.email}
						onChange={handleChange}
						onBlur={handleBlur}
						label="Seu email"
						margin="normal"
						variant="filled"
						className={classes.textfield}
						required
						error={errors.email && touched.email && values.email !== ""}
						verified={!errors.email && touched.email}
						helperText={
							values.email !== "" && touched.email ? errors.email : ""
						}
					/>
					<PasswordField
						id="password"
						name="password"
						value={values.password}
						onChange={e => {
							onPasswordChange(e.target.value);
							handleChange(e);
						}}
						onBlur={handleBlur}
						label="Crie uma senha"
						margin="normal"
						variant="filled"
						className={classes.textfield}
						required
						error={
							errors.password && touched.password && values.password !== ""
						}
						verified={!errors.password && touched.password}
						helperText={
							values.password !== "" && touched.password ? errors.password : ""
						}
					/>

					<PasswordField
						id="passwordConfirmation"
						name="passwordConfirmation"
						value={values.passwordConfirmation}
						onChange={handleChange}
						onBlur={handleBlur}
						label="Confirme a senha"
						margin="normal"
						variant="filled"
						className={classes.textfield}
						required
						error={
							errors.passwordConfirmation &&
							touched.passwordConfirmation &&
							values.passwordConfirmation !== ""
						}
						verified={
							!errors.passwordConfirmation && touched.passwordConfirmation
						}
						helperText={
							values.passwordConfirmation !== "" && touched.passwordConfirmation
								? errors.passwordConfirmation
								: ""
						}
					/>

					<p className={classes.passwordTip}>
						Use 8 ou mais caracteres misturando letras,
						<br />
						números e símbolos
					</p>
					<div className={classes.submitButton}>
						<SubmitIconButton
							showIcon
							disabled={isSubmitting || !isValid}
							type="submit"
						/>
					</div>
				</form>
			</Grid>
		);
	}
}

export default withStyles(styles)(SignUpAccountForm);
