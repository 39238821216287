import React, { Component } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";

import {
	next as tourNextStep,
	previous as tourPreviousStep,
	run as runTour,
	stop as stopTour,
	reset as resetTour
} from "../../../js/actions/tour";

import Form from "./Form";
import List from "./List";
import AddButton from "./AddButton";

const styles = () => ({
	root: {}
});

class PrescriptionCreationMedication extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showForm: false,
			showAddButton: true
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleAddClick = this.handleAddClick.bind(this);
		this.handleCloseForm = this.handleCloseForm.bind(this);
		this.hideAddButton = this.hideAddButton.bind(this);
		this.showAddButton = this.showAddButton.bind(this);
	}

	handleChange = name => event => {
		this.setState({ [name]: event.target.value });
	};

	handleAddClick = () => {
		this.setState({ showForm: true, showAddButton: false });
	};

	handleCloseForm = () => {
		this.setState({ showForm: false, showAddButton: true });
	};

	hideAddButton = () => {
		this.setState({
			showAddButton: false
		});
	};

	showAddButton = () => {
		this.setState({
			showAddButton: true
		});
	};

	render() {
		const { classes, show } = this.props;
		const { showForm, showAddButton } = this.state;

		return show ? (
			<div className={classes.root}>
				<List
					onEditFormShow={this.hideAddButton}
					onEditFormHide={this.showAddButton}
				/>
				{showAddButton && <AddButton onClick={this.handleAddClick} />}
				{showForm && (
					<Form
						isNewUser={this.props.isNewUser}
						onClose={this.handleCloseForm}
					/>
				)}
			</div>
		) : (
			<div />
		);
	}
}

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			runTour,
			stopTour,
			resetTour,
			tourNextStep,
			tourPreviousStep
		},
		dispatch
	);

export default withStyles(styles)(
	connect(
		null,
		mapDispatchToProps
	)(PrescriptionCreationMedication)
);
