import React, { PureComponent } from "react";

import { withRouter } from "react-router-dom";
import { find, isEmpty } from "lodash";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ReactGA from "react-ga";

import Alert from "react-s-alert";
import * as ALERT from "../../js/data/alerts";

import { getFrequencyOptions } from "../../js/data/prescription";

import {
	addMedication,
	getSinglePrescription,
	savePrescription,
	updatePrescription,
	clearPrescription,
	removeMedication,
	getAllPrescriptions
} from "../../js/actions/prescription";

import {
	next as tourNextStep,
	previous as tourPreviousStep,
	run as runTour,
	stop as stopTour,
	reset as resetTour
} from "../../js/actions/tour";

import {
	isEditMedicationMode,
	currentMedication
} from "../../js/actions/medication";
import { getPatient } from "../../js/actions/patient";
import { setFormInProgress } from "../../js/actions/ui-prescription";

import Patient from "./Patient";
import Medication from "./Medication";

import Page from "../UI/Page";
import MenuBar from "../UI/MenuBar";
import MenuItem from "../UI/MenuBar/MenuItem";

import Tour from "../Tour";

class PrescriptionCreation extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			patient: "",
			showMedication: false,
			runTour: false,
			tourStep: 0
		};

		this.formRef = React.createRef();

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handlePatientSelection = this.handlePatientSelection.bind(this);
	}

	async componentDidMount() {
		this.props.clearPrescription();
		this.props.resetTour();

		this.verifyIfIsNewUser();

		this.loadPrescription();
	}

	async verifyIfIsNewUser() {
		if (this.props.prescription.list.length === 0) {
			await this.props.getAllPrescriptions();

			await this.setState({
				isNewUser: this.props.prescription.list.length === 0
			});
		}
	}

	componentWillUnmount() {
		this.props.isEditMedicationMode({});
	}

	async loadPrescription() {
		if (this.props.id) {
			await this.props.getSinglePrescription(this.props.id);

			this.props.getPatient(this.props.prescription.saved.patient.id);

			this.props.prescription.saved.medications.forEach(medication =>
				this.props.addMedication({
					doses: medication.doses.map(dose => ({
						amount: dose.amount,
						frequency: find(getFrequencyOptions(), {
							value: dose.frequency.id
						})
					})),
					form_id: medication.form.id,
					medicationName: medication.medication.name,
					medicationRoute: medication.form.route,
					medication_id: medication.medication.id,
					notes: medication.notes,
					treatment_days: medication.treatment_description,
					treatment_days_label: medication.treatment_days,
					unit: medication.form.unit
				})
			);

			await this.setState({
				patient: this.props.prescription.saved.patient.id,
				showMedication: true
			});
		}
	}

	handlePatientSelection = async patient => {
		await this.setState({ patient });

		this.props.getPatient(this.state.patient);

		if (this.state.isNewUser) {
			setTimeout(() => {
				this.props.tourNextStep();
				this.props.runTour();
			}, 1000);
		}
		await this.setState({
			showMedication: true
		});
	};

	handleSubmit = async event => {
		if (event) event.preventDefault();

		let entries = this.props.prescription.entries;

		if (!entries.length) {
			if (isEmpty(this.props.selectedMedication)) {
				Alert.error(ALERT.prescription.emptyMedication, { offset: 39 });
				return;
			} else {
				entries = [this.props.selectedMedication];
			}
		}

		// @TODO: Find a better way to structure these data.
		const data = {
			patient_id: this.state.patient,
			medications: entries.map(entry => {
				const dataToSend = {
					medicationName: entry.medicationName,
					treatment_days: !isNaN(entry.treatment_days)
						? entry.treatment_days
						: null,
					treatment_description: entry.treatment_days,
					medication_id: entry.medication_id,
					form_id: entry.form_id,
					notes: entry.notes,
					doses: entry.doses.map(dose => ({
						amount: dose.amount,
						frequency_id: dose.frequency.value
					}))
				};

				return dataToSend;
			})
		};

		const requestMethod = `${
			this.props.toCopy ? "save" : this.props.id ? "update" : "save"
		}Prescription`;

		const { error } = await this.props[requestMethod](data, this.props.id);

		if (error) {
			this.setState({ error });
			return;
		}

		this.props.currentMedication({});

		const prescriptionId = this.props.prescription.saved.id;

		//Alert.success(ALERT.prescription.saved, { offset: 39 });

		ReactGA.event({
			category: "prescription-creation",
			action: "submit-form",
			label: this.props.toCopy ? "copy" : this.props.id ? "update" : "create"
		});

		this.props.history.push(`/visualizar-receita/easy?id=${prescriptionId}`);
	};

	render() {
		const { prescription, id, stopTour, tourNextStep } = this.props;
		const { patient, isNewUser, showMedication } = this.state;

		return (
			<form ref={this.formRef} onSubmit={this.handleSubmit}>
				<MenuBar
					showAlerts
					showSettings
					showBackButton
					onBackClick={() => this.props.history.goBack()}
					buttonText="Visualizar receita"
					buttonIcon="remove_red_eye"
					buttonComponent="button"
					showButton={prescription.entries && prescription.entries.length}
					buttonClassName="tour-step-10"
				>
					<MenuItem active>Sua receita</MenuItem>
				</MenuBar>
				<Page backgroundColor="#FFFFFF" width="100%" paddingTop={64}>
					<Patient
						patientId={patient}
						onSelect={this.handlePatientSelection}
						disabled={id ? true : false}
						isNewUser={isNewUser}
					/>
					<Medication show={showMedication} isNewUser={isNewUser} />
				</Page>
				<Tour
					onClose={() => {
						if (
							this.props.tour.currentStep === 0 ||
							this.props.tour.currentStep === 2 ||
							this.props.tour.currentStep === 8
						) {
							stopTour();
						} else {
							tourNextStep();
						}
					}}
				/>
			</form>
		);
	}
}

const mapStateToProps = state => {
	const {
		medication,
		prescription,
		uiPrescription,
		patient,
		user,
		tour
	} = state;

	return {
		tour,
		user,
		prescription,
		uiPrescription,
		medication,
		selectedPatient: patient.selected,
		selectedMedication: medication.selectedMedication,
		editedMedication: medication.editMedicationMode,
		currentMedication: state.currentMedication
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			savePrescription,
			updatePrescription,
			clearPrescription,
			removeMedication,
			getAllPrescriptions,
			getPatient,
			isEditMedicationMode,
			currentMedication,
			setFormInProgress,
			getSinglePrescription,
			addMedication,
			runTour,
			stopTour,
			resetTour,
			tourNextStep,
			tourPreviousStep
		},
		dispatch
	);

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(PrescriptionCreation)
);
