import React, { Component } from "react";

import PropTypes from "prop-types";

import RenewPrescription from "../components/Prescription/RenewPrescription/RenewPrescription";

class NewPrescriptionPage extends Component {
  static propTypes = {
    location: PropTypes.any,
    params: PropTypes.any
  };

  render() {
    const { location } = this.props;
    let params = new URLSearchParams(location.search);

    const toCopy = location.pathname.replace(/\//g, "").endsWith("copiar");

    return (
      <div>
        <RenewPrescription
          patient={params.get("paciente")}
          id={this.props.match.params.id}
          toCopy={toCopy}
        />
      </div>
    );
  }
}

export default NewPrescriptionPage;
