import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components'
import { width } from 'styled-system'
import Icon from './Icon'

const Text = styled.div`
  background: #fff;
  display: none;
  border: 1px solid #686868;
  color: #686868;
  padding: 10px 20px;
  position: absolute;
  top: 0;
  width: 300px;
  ${width}

  ${props => props.position === 'left' && css`
    left: 40px;
  `}

  ${props => props.position === 'right' && css`
    right: 40px;
  `}
`

const Wrapper = styled.div`
  position: relative;
  cursor: ${props => props.cursor};

  :hover ${Text} {
    display: block;
  }
`

const HelpInfo = ({ children, position, icon, cursor, onClick }) => (
  <Wrapper cursor={cursor} onClick={onClick}>
    <Icon name={icon} />
    <Text position={position}>
      {children}
    </Text>
  </Wrapper>
)

HelpInfo.propTypes = {
  children: PropTypes.any.isRequired,
  position: PropTypes.oneOf(['left', 'right']).isRequired,
  icon: PropTypes.string,
  cursor: PropTypes.string,
  onClick: PropTypes.func,
}

HelpInfo.defaultProps = {
  icon: 'help',
  cursor: 'help',
}

export default HelpInfo
