import React from "react";
import Page from "../components/Page";
import { Flex } from "grid-styled";
import Forgot from "../components/Forgot";
import logo from "../../img/logo-pulsares.png";
import { Link } from "react-router-dom";

const ForgotPage = () => (
  <Page>
    <Flex justify="center">
      <Link to="entrar">
        <img src={logo} alt="Logotipo" />
      </Link>
    </Flex>

    <Forgot />
  </Page>
);

export default ForgotPage;
